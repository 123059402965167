import React, { useState, useEffect } from "react";

//MUI Import
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Autocomplete, Box, Button, TextField, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

//Import Component
import ReportSection from "./ReportSection";

//Import CSS File
import "./ReportWriterTab.css";

//Import Services
import ClientDataService from "../../service/ClientDataService";
import AuditReportService from "../../service/AuditReportService";

const sectionsList = [
  {
    id: "reissueSection",
    name: "Reissue Section",
    isChecked: true,
    reportList: [
      {
        type: "note",
        value: "<p>Notes: This is the Story</p>",
        isEdit: false,
      },
      {
        type: "note",
        value: "<p>Hello</p>",
        isEdit: false,
      },
      {
        type: "note",
        value: "<p>How are you</p>",
        isEdit: false,
      },
      {
        type: "note",
        value: "<p>Revedia</p>",
        isEdit: false,
      },
    ],
  },
  {
    id: "about",
    name: "About",
    isChecked: false,
    reportList: [],
  },
  {
    id: "Intro",
    name: "Intro",
    isChecked: false,
    reportList: [],
  },
  {
    id: "body",
    name: "Body",
    isChecked: false,
    reportList: [],
  },
  {
    id: "contract",
    name: "Contract",
    isChecked: false,
    reportList: [],
  },
  {
    id: "results",
    name: "Results",
    isChecked: false,
    reportList: [],
  },
  {
    id: "conclusion",
    name: "Conclusions",
    isChecked: false,
    reportList: [
      {
        type: "note",
        value: "<p>Notes: This is the Story</p>",
        isEdit: false,
      },
    ],
  },
];

const reportItems = [
  {
    id: "notes",
    name: "Notes",
  },
];

const fontStyles = {
  fontWeight: "400",
  fontSize: "0.85rem",
};

const ReportWriterTab = () => {
  const [clientList, setClientList] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [client, setClient] = useState(null);
  const [network, setNetwork] = useState(null);
  const [isNetworkClientSelected, setIsNetworkClientSelected] = useState(false);

  const [sectionList, setSectionList] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);
  const [reportSectionList, setReportSectionList] = useState([]);

  const [isEditActive, setIsEditActive] = useState(false);

  const [editorContent, setEditorContent] = useState("");

  const userKey = JSON.parse(localStorage.getItem("userKey") || "0");

  const getAuditClients = (key) => {
    ClientDataService.getClients(key)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setClientList(data);
      })
      .catch((err) => console.log(err));
  };

  const getAuditNetwork = (key) => {
    ClientDataService.getUserNetworks(key)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setNetworkList(data);
      })
      .catch((err) => console.log(err));
  };

  const getAuditReportService = () => {
    AuditReportService.getAuditReportSection()
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        // setReportSections(data);
        console.log("ReportSection", data);
      })
      .catch((err) => console.log(err));
  };

  const getAuditReportItem = () => {
    AuditReportService.getAuditReportItem()
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        // setReportItems(data);
        console.log("ReportItem", data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAuditClients(userKey); //Calling the client dropdown first
  }, [userKey]);

  const handleClientChange = (newValue) => {
    setClient(newValue ? newValue : null);
    setNetwork(null);
    setSectionList([]);
    setReportSectionList([]);
    setIsNetworkClientSelected(false);
    getAuditNetwork(userKey); //Calling the network dropdown after client selection
  };

  const handleNetworkChange = (newValue) => {
    setNetwork(newValue);
    setSectionList([]);
    setReportSectionList([]);
    setIsNetworkClientSelected(false);
  };

  const handleDropdownSelection = () => {
    //Get Report section and Report Items
    getAuditReportService();
    getAuditReportItem();

    setIsNetworkClientSelected(client && network);
    setSectionList(sectionsList);
    setReportSectionList(sectionsList.filter((d) => d.isChecked));
  };

  const handleSelectSection = (event, item) => {
    event.stopPropagation();
    const arrTemp = sectionList.map((d) => {
      const obj = Object.assign({}, d);
      if (obj.id === item.id) {
        obj.isChecked = !obj.isChecked;
      }
      return obj;
    });
    setSectionList(arrTemp);
  };

  const handleSaveSectionList = () => {
    setReportSectionList(sectionList.filter((d) => d.isChecked));
  };

  const handleAddEditor = () => {
    setIsEditActive(true);
    setEditorContent("");

    const list = [...reportSectionList];
    list[currentTab].reportList.unshift({
      type: "note",
      value: "",
      isEdit: true,
    });
    setReportSectionList(list.filter((d) => d.isChecked));
  };

  const handleDeleteNote = (event, deleteIndex) => {
    event.stopPropagation();
    setIsEditActive(false);

    const list = [...reportSectionList];

    list[currentTab].reportList.splice(
      list.findIndex((a, i) => i === deleteIndex),
      1
    );

    setReportSectionList(list.filter((d) => d.isChecked));
  };

  const handleContentChange = (newContent) => {
    setEditorContent(newContent);
    console.log("content changed:", newContent);
  };

  const handleDoneEdit = () => {
    setIsEditActive(false);

    if (
      editorContent !== "" ||
      editorContent.replaceAll(" ", "") !== "<p><br></p>"
    ) {
      const list = [...reportSectionList];
      list[currentTab].reportList.map((row) => {
        const obj = Object.assign({}, row);
        if (row.isEdit) {
          row.value = editorContent;
          row.isEdit = false;
        }
        return obj;
      });
      setReportSectionList(list.filter((d) => d.isChecked));
      setEditorContent("");
    }
  };

  const handleChangeToEdit = (event, text, rowIndex) => {
    event.stopPropagation();
    if (!isEditActive) {
      const list = [...reportSectionList];
      list[currentTab].reportList.map((row, index) => {
        const obj = Object.assign({}, row);
        if (index === rowIndex) {
          row.isEdit = !row.isEdit;
        } else {
          row.isEdit = false;
        }
        return obj;
      });

      const elemRemove = list[currentTab].reportList.filter(
        (item) =>
          item.value === "" || item.value.replaceAll(" ", "") === "<p><br/></p>"
      );

      if (elemRemove.length > 0) {
        list[currentTab].reportList.splice(
          list.findIndex((a) => a.value === elemRemove.value),
          1
        );
      }

      setIsEditActive(true);
      setEditorContent(text);

      // setSectionList(list);
      setReportSectionList(list.filter((d) => d.isChecked));
    }
  };

  return (
    <Box className="report-writer-tab">
      <Box
        className="report-writer-tab__audit-report"
        width={isNetworkClientSelected ? "50%" : "100%"}
      >
        <p className="audit-report-for">
          <div>Audit Report for: &nbsp;</div>
          <Stack direction="row" spacing={1}>
            {client && <Chip label={client.clientName} />}
            {network && <Chip label={network.networkdesc} />}
          </Stack>
        </p>

        <Stack
          direction="row"
          spacing={2}
          className="report-writer-tab_selector"
        >
          <Autocomplete
            name="client"
            id="client"
            value={client}
            sx={{ width: 250 }}
            onChange={(_, data) => {
              handleClientChange(data);
            }}
            options={clientList}
            isOptionEqualToValue={(option, value) =>
              option.clientName === value.clientName
            }
            getOptionLabel={(option) => option.clientName}
            renderInput={(params) => <TextField {...params} label="Client" />}
            size="small"
          />

          <Autocomplete
            name="network"
            id="network"
            value={network}
            sx={{ width: 250 }}
            onChange={(_, data) => {
              handleNetworkChange(data);
            }}
            options={networkList}
            disabled={!client}
            isOptionEqualToValue={(option, value) =>
              option.networkdesc === value.networkdesc
            }
            getOptionLabel={(option) => option.networkdesc}
            renderInput={(params) => <TextField {...params} label="Network" />}
            size="small"
          />

          <Button
            color="primary"
            variant="outlined"
            disabled={!(client && network)}
            onClick={() => handleDropdownSelection()}
          >
            Select
          </Button>
        </Stack>

        {isNetworkClientSelected ? (
          <>
            {" "}
            <Stack direction="row" spacing={4}>
              <Box className="report-writer_sections">
                <FormControl sx={{ width: "100%" }}>
                  <p className="sections-header">Sections:</p>
                  {sectionList.map((section, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={section.isChecked}
                          onChange={(e) => handleSelectSection(e, section)}
                        />
                      }
                      label={section.name}
                      labelPlacement="start"
                      style={{
                        marginLeft: "0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    />
                  ))}
                </FormControl>
              </Box>

              {/* <Divider orientation="vertical" flexItem /> */}

              <FormControl className="report-writer__report-items">
                <p className="report-items-header">Report Items:</p>
                {reportItems.map((reportItem, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={fontStyles}>{reportItem.name}</p>
                    <Tooltip
                      title={`Add ${reportItem.name} Content`}
                      placement="right"
                    >
                      <IconButton
                        color="primary"
                        onClick={handleAddEditor}
                        disabled={isEditActive}
                      >
                        <ArrowForwardIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))}
              </FormControl>
            </Stack>
            <div style={{ marginTop: "16px" }}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className="report-writer-tab__save-btn"
                sx={{ padding: "8px 24px" }}
                onClick={() => handleSaveSectionList()}
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <div className="layout-empty">Select Client & Network</div>
        )}
      </Box>

      {isNetworkClientSelected && (
        <ReportSection
          client={client}
          network={network}
          reportSectionList={reportSectionList}
          setReportSectionList={setReportSectionList}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          handleDeleteNote={handleDeleteNote}
          isEditActive={isEditActive}
          handleDoneEdit={handleDoneEdit}
          editorContent={editorContent}
          handleContentChange={handleContentChange}
          handleChangeToEdit={handleChangeToEdit}
        />
      )}
    </Box>
  );
};

export default ReportWriterTab;
