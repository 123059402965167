import { jsonHttp } from "./http-common";

class FileTypeStageService {
    getAll() {
        return jsonHttp.get("/FileTypeStage");
    }

    get(id) {
        return jsonHttp.get(`/FileTypeStage/${id}`);
    }

    
}
export default new FileTypeStageService();