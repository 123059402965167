import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AdvancedPortModel } from "../link/link";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";

export const BusinessNodeWidget = props => {
    return (
        <div className="node" >
            <div
                className="node-header-container"
                style={{ backgroundColor: props.node.color }}
            >
                <div className="my-icon" />
                <div className="node-header-text">Business Rule</div>
            </div>

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            <div className="node-content">Business Rule Description</div>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class BusinessRuleModel extends NodeModel {
    constructor(options) {
        super({
            type: "BusinessRule"
        });
        this.depth = 0;
        this.precendentNodes = new Set();

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            precendentNodes: Array.from(this.precendentNodes),
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }


}

// TODO: Refactor to hooks
export class BusinessRuleNodeFactory extends AbstractReactFactory {
    constructor() {
        super("BusinessRule");
    }

    generateModel(initialConfig) {
        return new BusinessRuleModel();
    }

    generateReactWidget(event) {
        return <BusinessNodeWidget engine={this.engine} node={event.model} />;
    }
}