import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import JoinFullIcon from '@mui/icons-material/JoinFull';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Stack, useTheme, Autocomplete } from '@mui/material'
import Header from "../../Header";

import ClearIcon from '@mui/icons-material/Clear';

import FileSchemaDataService from "../../../service/FileSchemaService";
import Button from '@mui/material/Button';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';



import AddIcon from '@mui/icons-material/Add';

export const JoinDataNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div >*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">Join Data</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            {/*<div className="node-content">*/}
            {/*    <Box>*/}
            {/*        <JoinFullIcon sx={{ fontSize: 40 }} />*/}
            {/*    </Box>*/}
            {/*</div>*/}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <JoinFullIcon
                    width="65px"
                    height="65px"
                    sx={{ width: "65px", height: '65px' }}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class JoinDataModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            name: options?.name || "Join",
            type: "JoinData"
        });
        this.depth = 0;
        this.leftTableInputFields = new Array();
        this.rightTableInputFields = new Array();
        this.outputFields = new Array();
        this.precendentNodes = new Set();
        this.nodedata = options?.nodedata;

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            leftTableInputFields: this.leftTableInputFields,
            rightTableInputFields: this.rightTableInputFields,
            outputFields: this.outputFields,
            name: this.options.name,
            precendentNodes: Array.from(this.precendentNodes),
            nodedata: this.nodedata,
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.leftTableInputFields = event.data.leftTableInputFields;
        this.rightTableInputFields = event.data.rightTableInputFields;
        this.outputFields = event.data.outputFields;
        this.nodedata = event.data.nodedata;
        this.options.name = event.data.name;
        this.precendentNodes = new Set(event.data.precendentNodes);
    }


}

// TODO: Refactor to hooks
export class JoinDataNodeFactory extends AbstractReactFactory {
    constructor() {
        super("JoinData");
    }

    generateModel(initialConfig) {
        return new JoinDataModel();
    }

    generateReactWidget(event) {
        return <JoinDataNodeWidget engine={this.engine} node={event.model} />;
    }
}

export function JoinComponent(props) {

    const forceUpdate = React.useReducer(bool => !bool)[1];

    const currentComponent = props.currentComponent;

    const engine = props.engine;

    const [nodeName, setNodeName] = React.useState('');


    const JOINERS = ["Inner", "Left Outer", "Right Outer", "Full Outer"];

    const Column_Operators = ["Drop Column", "Update Name"];

    const [selectedInputFiles, setSelectedInputFiles] = React.useState([]);


    const [leftOpen, setLeftOpen] = React.useState(false);

    const [rightOpen, setRightOpen] = React.useState();


    const [leftMatchColumns, setLeftMatchColumns] = React.useState([]);

    const [leftTable, setLeftTable] = React.useState();

    const [joiner, setJoiner] = React.useState("");

    const [rightMatchColumns, setRightMatchColumns] = React.useState([]);

    const [rightTable, setRightTable] = React.useState();

    const [matchedColumns, setMatchedColums] = React.useState([{ leftMatchColumn: '', rightMatchColumn: '' }]);

    const [leftTableSelectColumns, setLeftTableSelectColumns] = React.useState([]);

    const [rightTableSelectColumns, setRightTableSelectColumns] = React.useState([]);

    const [duplicatedColumns, setDuplicatedColumns] = React.useState([]);

    const [duplicated, setDuplicated] = React.useState('');


    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (currentComponent.nodedata) {
            if (currentComponent.nodedata.keyColumns) {
                setMatchedColums(currentComponent.nodedata.keyColumns)
            }
         
            if (currentComponent.nodedata.leftTable) {
                const nodes = engine.getModel().getNodes();
                const tempNode = nodes.filter(node => node.options.id === currentComponent.nodedata.leftTable)[0];
                setLeftTable(tempNode)
                if (tempNode.outputFields) {
                    setLeftMatchColumns(tempNode.outputFields)
                }
            }

            if (currentComponent.nodedata.rightTable) {
                const nodes = engine.getModel().getNodes();
                const tempNode = nodes.filter(node => node.options.id === currentComponent.nodedata.rightTable)[0];
                setRightTable(tempNode)
                if (tempNode.outputFields) {
                    setRightMatchColumns(tempNode.outputFields);
                }
            }

            if (currentComponent.nodedata.joinType) {
                setJoiner(currentComponent.nodedata.joinType)
            }

            if (currentComponent.nodedata.leftTableSelect) {
                setLeftTableSelectColumns(currentComponent.nodedata.leftTableSelect)
            } else {
                currentComponent.nodedata.leftTableSelect = null;
            }

            if (currentComponent.nodedata.rightTableSelect) {
                setRightTableSelectColumns(currentComponent.nodedata.rightTableSelect)
            } else {
                currentComponent.nodedata.rightTableSelect = null;
            }
        }

    },[]);



    const handleOnOpen = () => {

        //const nodes = engine.getModel().getNodes();

        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);

        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.nodedata) {
                    parent.nodedata.outputId = props.currentComponent.options.id;
                } else {
                    parent.nodedata = {
                        outputId: props.currentComponent.options.id
                    }
                }
            })
        }

        setSelectedInputFiles(parentsNodes);
        console.log("calling handleOnOpen")
    } 



    const handleAddMatchedColumn = (event) => {
        setMatchedColums(matchedColumns => [...matchedColumns, { }])
    };

    const handleDeleteMatchedColumn = (event, id) => {
        setMatchedColums(matchedColumns => matchedColumns.filter((column, index) => index !== id))
    };


    const handleAddLeftSelectColumn = (event) => {
        setLeftTableSelectColumns(leftTableSelectColumns => [...leftTableSelectColumns, { originalName: '', newName: '', operator: '' }])
    };

    const handleDeleteLeftSelectColumn = (event, id) => {
        const newLeftSelectColumn = leftTableSelectColumns.filter((column, index) => index !== id)
        setLeftTableSelectColumns(newLeftSelectColumn)
        currentComponent.nodedata.leftTableSelect = newLeftSelectColumn;

    };


    const handleAddRightSelectColumn = (event) => {
        setRightTableSelectColumns(rightTableSelectColumns => [...rightTableSelectColumns, { originalName:'', newName: '', operator: '' }])
    };

    const handleDeleteRightSelectColumn = (event, id) => {
        const newRightSelectColumn = rightTableSelectColumns.filter((column, index) => index !== id);
        setRightTableSelectColumns(newRightSelectColumn)
        currentComponent.nodedata.rightTableSelect = newRightSelectColumn;
    };

    const handleLeftTableChange = (event) => {
        setLeftTable(event.target.value)
        if (currentComponent.nodedata) {
            currentComponent.nodedata.leftTable = event.target.value.options.id
        } else {
            currentComponent.nodedata = {
                leftTable: event.target.value.options.id
            }
        }
        setLeftOpen(false)

        if (event.target.value && event.target.value.outputFields) {
            const inputFields = event.target.value.outputFields;
            setLeftMatchColumns(inputFields);
            currentComponent.leftTableInputFields = inputFields;
        }
    }

    const handleJoinerChange = (event) => {
        setJoiner(event.target.value)

        if (currentComponent.nodedata) {
            currentComponent.nodedata.joinType = event.target.value;
        } else {
            currentComponent.nodedata = {
                joinType: event.target.value
            }
        }
    }

    const handleDuplicatedDropChange = (event) => {

        
        setDuplicated(event.target.value);

        const intersections = leftMatchColumns.map(left => left.FieldName).filter(value => rightMatchColumns.map(right => right.FieldName).includes(value));


        const sameColums = intersections.map((col => { return { originalName: col, newName: '', operator: 'Drop Column' } }));
        setDuplicatedColumns(sameColums);

        if (event.target.value == 'left') {

            //const leftRemainingColumns = leftMatchColumns.filter(col => intersections.includes(col.FieldName));
           // setLeftMatchColumns(leftRemainingColumns)
            currentComponent.nodedata.leftDuplicatedColumns = sameColums;
            currentComponent.nodedata.rightDuplicatedColumns = null;
        } else {

            currentComponent.nodedata.leftDuplicatedColumns = null;
            currentComponent.nodedata.rightDuplicatedColumns = sameColums;
        }

        if (currentComponent.nodedata) {
            const fields = new Array();
            if (rightMatchColumns) {
                var rightFields = Array.from(rightMatchColumns);

                rightFields.forEach(element => {
                    var result = rightTableSelectColumns.filter(obj => {
                        return obj.originalName === element.FieldName
                    })[0]

                    if (result) {
                        if (result.newName != '') {
                            fields.push({ id: fields.length + 1, FieldName: result.newName });
                        } else {
                            fields.push({ id: fields.length + 1, FieldName: result.originalName });
                        }
                    } else {
                        fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                    }
                })
            }
            if (leftMatchColumns) {
                var leftFields = Array.from(leftMatchColumns);

                leftFields.forEach(element => {
                    var result = leftTableSelectColumns.filter(obj => {
                        return obj.originalName === element.FieldName
                    })[0]

                    if (result) {
                        if (result.newName != '') {
                            fields.push({ id: fields.length + 1, FieldName: result.newName });
                        } else {
                            fields.push({ id: fields.length + 1, FieldName: result.originalName });
                        }
                    } else {
                        fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                    }
                })
            }
            currentComponent.outputFields = fields;
        }


        forceUpdate();
    }


    const handleRightTableChange = (event) => {
        setRightTable(event.target.value)
        if (currentComponent.nodedata) {
            currentComponent.nodedata.rightTable = event.target.value.options.id;
        } else {
            currentComponent.nodedata = {
                rightTable: event.target.value.options.id
            }
        }


        if (event.target.value && event.target.value.outputFields) {
            const inputFields = event.target.value.outputFields;
            setRightMatchColumns(inputFields);
            currentComponent.rightTableInputFields = inputFields;
        }
    }


    const handleLeftAddAllClick = (event) => {
        if (leftTable) {
            setLeftTableSelectColumns(leftTable.outputFields.map(col => { return { originalName: col.FieldName, newName: 'left_' + col.FieldName, operator: 'Update Name' } }))
        }
    }

    const handleRightAddAllClick = (event) => {
        if (rightTable) {
            setRightTableSelectColumns(rightTable.outputFields.map(col => { return { originalName: col.FieldName, newName: 'right_' + col.FieldName, operator: 'Update Name' } }))
        }
    }



    return (<Stack>
        <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
            <TextField
                autoComplete='off'
                label="Node Name"
                value={nodeName}
                onChange={(event) => {
                    setNodeName(event.target.value)
                    currentComponent.options.name = event.target.value;
                }}
            />
        </FormControl>

        <br />

        <Typography variant="h5" gutterBottom>
            1. Select Tables and Join Type
        </Typography>
        <br />

        <Stack direction="row" spacing={5} width={"50%"}>
            <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                <InputLabel id="left-table-select-label">Left Table</InputLabel>
                <Select
                    labelId="left-table-select-label"
                    id="left-table-select-label"
                    label="left File"
                    value={leftTable || ''}
                    open={leftOpen}
                    onClose={() => setLeftOpen(false)}
                    onOpen={() => { handleOnOpen(); setLeftOpen(true) }}
                    onChange={handleLeftTableChange}
                    renderValue={(leftTable) => leftTable.options.name}
                >
                    {selectedInputFiles.map(file => (
                        <MenuItem key={file?.options.id} value={file}>
                            {file?.options.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                <InputLabel id="joiner-select-label">Joiner</InputLabel>
                <Select
                    labelId="joiner-select-label"
                    id="joiner-select-label"
                    label="Joiner"
                    value={joiner}
                    onChange={handleJoinerChange}
                >
                    {JOINERS.map((joiner, index) => (
                        <MenuItem key={index} value={joiner}>
                            {joiner}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                <InputLabel id="right-table-select-label">Right Table</InputLabel>
                <Select
                    labelId="right-table-select-label"
                    id="right-table-select-label"
                    label="right File"
                    open={rightOpen}
                    onClose={() => setRightOpen(false)}
                    onOpen={() => { handleOnOpen(); setRightOpen(true) }}
                    value={rightTable || ''}
                    onChange={handleRightTableChange}
                    renderValue={(rightTable) => rightTable.options.name}
                >
                    {selectedInputFiles.map(file => (
                        <MenuItem key={file?.options.id} value={file}>
                            {file?.options.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
        <br />

        <Typography variant="h5" gutterBottom>
            2. Choose Match Columns
        </Typography>

        <br />

        {matchedColumns.map((matchedColumn, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <InputLabel id="left-column-select-label">Left Column</InputLabel>
                    <Select
                        labelId="left-column-select-label"
                        id="left-column-select-label"
                        label="Left Column"
                        value={matchedColumn.leftMatchColumn}
                        onChange={(event) => {
                            const newCoumns = [...matchedColumns];
                            const column = newCoumns[index]
                            column.leftMatchColumn = event.target.value
                            setMatchedColums(newCoumns)
                            currentComponent.nodedata.keyColumns = newCoumns;
                        }}
                    >
                        {leftMatchColumns.map(column => (
                            <MenuItem key={column.id} value={column.FieldName}>
                                {column.FieldName}
                            </MenuItem>
                        ))}
                    </Select>
                    <br />
                </FormControl>

                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <InputLabel id="right-column-select-label">Right Column</InputLabel>
                    <Select
                        labelId="right-column-select-label"
                        id="right-column-select-label"
                        label="Right Column"
                        value={matchedColumn.rightMatchColumn}
                        onChange={(event) => {
                            const newCoumns = [...matchedColumns];
                            const column = newCoumns[index]
                            column.rightMatchColumn = event.target.value
                            setMatchedColums(newCoumns)
                            currentComponent.nodedata.keyColumns = newCoumns;

                           // currentComponent.nodedata.keyColumns = newCoumns.map(column => ({ [column.leftMatchColumn]: column.rightMatchColumn }));
                            //newCoumns.reduce((previousValue, currentValue) => Object.assign(previousValue, { [currentValue.leftMatchColumn]: currentValue.rightMatchColumn }), {});
                        }}

                    >
                        {rightMatchColumns.map(column => (
                            <MenuItem key={column.id} value={column.FieldName}>
                                {column.FieldName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteMatchedColumn(event, index)}>
                    <ClearIcon />
                </IconButton>

            </Stack>

        ))
        }

        <IconButton aria-label="Add"
            sx={{
                minWidth: 30,
                maxWidth: 30,
            }} onClick={handleAddMatchedColumn}>
            <AddIcon />
        </IconButton>

        <br />

        <Typography variant="h5" gutterBottom>
            3. Drop Duplicated Columns
        </Typography>


        <FormControl>
            <RadioGroup
                row
                defaultValue="Drop Left table"
                name="radio-buttons-group"
                value={duplicated}
                onChange={handleDuplicatedDropChange}
            >
                <FormControlLabel value="left" control={<Radio />} label="drop-left-table" />
                <FormControlLabel value="right" control={<Radio />} label="drop-right-table" />
            </RadioGroup>
        </FormControl>


        {duplicatedColumns.map((duplicatedColumn, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField value={duplicatedColumn.originalName}>
                    </TextField>
                    <br />
                </FormControl>


                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <InputLabel id="column-operation-select-label">Column Operator</InputLabel>
                    <Select
                        labelId="column-operation-select-label"
                        id="column-operation-select-label"
                        label="Column Operator"
                        value={duplicatedColumn.operator}
                        onChange={(event) => {
                            const newCoumns = [...duplicatedColumns];
                            const column = newCoumns[index]
                            column.operator = event.target.value
                            setDuplicatedColumns(newCoumns)

                            const tempColumns = newCoumns.map(column => {
                                if (column.operator === 'Drop Column') {
                                    column.newName = '';
                                    return column;
                                } else {
                                    return column;
                                }
                            });

                            if (duplicated === 'left') {
                                if (currentComponent.nodedata) {
                                    currentComponent.nodedata.leftDuplicatedColumns = tempColumns
                                } else {
                                    currentComponent.nodedata =
                                    {
                                        leftDuplicatedColumns: tempColumns
                                    }
                                }
                            } else {
                                if (currentComponent.nodedata) {
                                    currentComponent.nodedata.rightDuplicatedColumns = tempColumns
                                } else {
                                    currentComponent.nodedata =
                                    {
                                        rightDuplicatedColumns: tempColumns
                                    }
                                }
                            }
                        }}
                    >
                        {Column_Operators.map((operation, index) => (
                            <MenuItem key={index} value={operation}>
                                {operation}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        disableAutoFocus='true'
                        autoComplete='off'
                        label="New Column Name"
                        value={duplicatedColumn.newName}
                        onChange={(event) => {
                            const newCoumns = [...duplicatedColumns];
                            const column = newCoumns[index]
                            column.newName = event.target.value
                            setDuplicatedColumns(newCoumns)

                            const tempColumns = newCoumns.map(column => {
                                if (column.operator === 'Drop Column') {
                                    column.newName = '';
                                    return column;
                                } else {
                                    return column;
                                }
                            });

                            if (duplicated === 'left') {
                                if (currentComponent.nodedata) {
                                    currentComponent.nodedata.leftDuplicatedColumns = tempColumns
                                } else {
                                    currentComponent.nodedata =
                                    {
                                        leftDuplicatedColumns: tempColumns
                                    }
                                }
                            } else {
                                if (currentComponent.nodedata) {
                                    currentComponent.nodedata.rightDuplicatedColumns = tempColumns
                                } else {
                                    currentComponent.nodedata =
                                    {
                                        rightDuplicatedColumns: tempColumns
                                    }
                                }
                            }
                        }}
                    />
                </FormControl>

                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteLeftSelectColumn(event, index)}>
                    <ClearIcon />
                </IconButton>

            </Stack>

        ))
        }



        <br />

        <Typography variant="h5" gutterBottom>
            4. Alter Output Columns
        </Typography>

        <Stack direction='row' spacing={5}>
            <Box>


                <Stack direction="row" spacing={2}  >
                    <Typography variant="h6" gutterBottom>
                        left table alteration
                    </Typography>

                    <Button variant="contained" color="primary" onClick={handleLeftAddAllClick}>
                        Alter All Columns
                    </Button>
                </Stack>
                <br />



                {leftTableSelectColumns.map((leftSelectColumn, index) => (
                    <Stack direction="row" spacing={2} width={"50%"}>
                        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                            <InputLabel id="left-column-alteration-select-label">Left Alteration Column</InputLabel>
                            <Select
                                labelId="left-column-alteration-select-label"
                                id="left-column-alteration-select-label"
                                label="Left Alteration Column"
                                value={leftSelectColumn.originalName}
                                onChange={(event) => {
                                    const newCoumns = [...leftTableSelectColumns];
                                    const column = newCoumns[index]
                                    column.originalName = event.target.value
                                  
                                    const tempColumns = newCoumns.map(column => {
                                        if (column.operator === 'Drop Column') {
                                            column.newName = '';
                                            return column;
                                        } else {
                                            return column;
                                        }
                                    });

                                    setLeftTableSelectColumns(tempColumns)


                                    if (currentComponent.nodedata) {
                                        currentComponent.nodedata.leftTableSelect = tempColumns;
                                    } else {
                                        currentComponent.nodedata =
                                       {
                                            leftTableSelect: tempColumns
                                        }
                                    }

                                    if (currentComponent.nodedata) {
                                        const fields = new Array();
                                        if (rightMatchColumns) {
                                            var rightFields = Array.from(rightMatchColumns);

                                            rightFields.forEach(element => {
                                                var result = rightTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        if (leftMatchColumns) {
                                            var leftFields = Array.from(leftMatchColumns);

                                            leftFields.forEach(element => {
                                                var result = leftTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        currentComponent.outputFields = fields;
                                    }
                                }}
                            >
                                {leftMatchColumns.map(column => (
                                    <MenuItem key={column.id} value={column.FieldName}>
                                        {column.FieldName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br />
                        </FormControl>


                        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                            <InputLabel id="column-operation-select-label">Column Operator</InputLabel>
                            <Select
                                labelId="column-operation-select-label"
                                id="column-operation-select-label"
                                label="Column Operator"
                                value={leftSelectColumn.operator}
                                onChange={(event) => {
                                    const newCoumns = [...leftTableSelectColumns];
                                    const column = newCoumns[index]
                                    column.operator = event.target.value
                                    setLeftTableSelectColumns(newCoumns)

                                    const tempColumns = newCoumns.map(column => {
                                        if (column.operator === 'Drop Column') {
                                            column.newName = '';
                                            return column;
                                        } else {
                                            return column;
                                        }
                                    });

                                    if (currentComponent.nodedata) {
                                        currentComponent.nodedata.leftTableSelect = tempColumns
                                    } else {
                                        currentComponent.nodedata =
                                        {
                                            leftTableSelect: tempColumns
                                        }
                                    }

                                   

                                    if (currentComponent.nodedata) {
                                        const fields = new Array();
                                        if (rightMatchColumns) {
                                            var rightFields = Array.from(rightMatchColumns);
                                            rightFields.forEach(element => {
                                                var result = rightTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]
                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        if (leftMatchColumns) {
                                            var leftFields = Array.from(leftMatchColumns);

                                            leftFields.forEach(element => {
                                                var result = leftTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        currentComponent.outputFields = fields;
                                    }
                                }}
                            >
                                {Column_Operators.map((operation, index) => (
                                    <MenuItem key={index} value={operation}>
                                        {operation}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                            <TextField
                                disableAutoFocus='true'
                                autoComplete='off'
                                label="New Column Name"
                                value={leftSelectColumn.newName}
                                onChange={(event) => {
                                    const newCoumns = [...leftTableSelectColumns];
                                    const column = newCoumns[index]
                                    column.newName = event.target.value
                                    setLeftTableSelectColumns(newCoumns)

                                    const tempColumns = newCoumns.map(column => {
                                        if (column.operator === 'Drop Column') {
                                            column.newName = '';
                                            return column;
                                        } else {
                                            return column;
                                        }
                                    });

                                    if (currentComponent.nodedata) {
                                        currentComponent.nodedata.leftTableSelect = tempColumns
                                    } else {
                                        currentComponent.nodedata =
                                        {
                                            leftTableSelect: tempColumns
                                        }
                                    }


                                    if (currentComponent.nodedata) {
                                        const fields = new Array();
                                        if (rightMatchColumns) {
                                            var rightFields = Array.from(rightMatchColumns);
                                            rightFields.forEach(element => {
                                                var result = rightTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]
                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        if (leftMatchColumns) {
                                            var leftFields = Array.from(leftMatchColumns);

                                            leftFields.forEach(element => {
                                                var result = leftTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        currentComponent.outputFields = fields;
                                    }
                                }}
                            />
                        </FormControl>

                        <IconButton aria-label="delete"
                            sx={{
                                minWidth: 20,
                                maxWidth: 20,
                            }} onClick={(event) => handleDeleteLeftSelectColumn(event, index)}>
                            <ClearIcon />
                        </IconButton>

                    </Stack>

                ))
                }

                <IconButton aria-label="Add"
                    sx={{
                        minWidth: 30,
                        maxWidth: 30,
                    }} onClick={handleAddLeftSelectColumn}>
                    <AddIcon />
                </IconButton>
            </Box>

            <br />

            <Box>
                <Stack direction="row" spacing={2}  >
                <Typography variant="h6" gutterBottom>
                    right table alteration
                </Typography>

                    <Button variant="contained" color="primary" onClick={handleRightAddAllClick}>
                        Alter All Columns
                    </Button>
                 </Stack>
                <br />
                {rightTableSelectColumns.map((rightSelectColumn, index) => (
                    <Stack direction="row" spacing={2} width={"50%"}>
                        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                            <InputLabel id="right-column-alteration-select-label">Right Alteration Column</InputLabel>
                            <Select
                                labelId="right-column-alteration-select-label"
                                id="right-column-alteration-select-label"
                                label="Right Alteration Column"
                                value={rightSelectColumn.originalName}
                                onChange={(event) => {
                                    const newCoumns = [...rightTableSelectColumns];
                                    const column = newCoumns[index]
                                    column.originalName = event.target.value
                                    setRightTableSelectColumns(newCoumns)

                                    const tempColumns = newCoumns.map(column => {
                                        if (column.operator === 'Drop Column') {
                                            column.newName = '';
                                            return column;
                                        } else {
                                            return column;
                                        }

                                    });

                                    if (currentComponent.nodedata) {
                                        currentComponent.nodedata.rightTableSelect = tempColumns
                                    } else {
                                        currentComponent.nodedata =
                                        {
                                            rightTableSelect: tempColumns
                                        }
                                    }

                                  

                                    if (currentComponent.nodedata) {
                                        const fields = new Array();
                                        if (rightMatchColumns) {
                                            var rightFields = Array.from(rightMatchColumns);
                                            rightFields.forEach(element => {
                                                var result = rightTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]
                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        if (leftMatchColumns) {
                                            var leftFields = Array.from(leftMatchColumns);

                                            leftFields.forEach(element => {
                                                var result = leftTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        currentComponent.outputFields = fields;
                                    }
                                   
                                }}
                  
                            >
                                {rightMatchColumns.map(column => (
                                    <MenuItem key={column.id} value={column.FieldName}>
                                        {column.FieldName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <br />
                        </FormControl>


                        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                            <InputLabel id="column-operation-select-label-right">Column Operator</InputLabel>
                            <Select
                                labelId="column-operation-select-label-right"
                                id="column-operation-select-label"
                                label="Column Operator"
                                value={rightSelectColumn.operator}
                                onChange={(event) => {
                                    const newCoumns = [...rightTableSelectColumns];
                                    const column = newCoumns[index]
                                    column.operator = event.target.value
                                    setRightTableSelectColumns(newCoumns)

                                    const tempColumns = newCoumns.map(column => {
                                        if (column.operator === 'Drop Column') {
                                            column.newName = '';
                                            return column;
                                        } else {
                                            return column;
                                        }
                                    });

                                    if (currentComponent.nodedata) {
                                        currentComponent.nodedata.rightTableSelect = tempColumns

                                    } else {
                                        currentComponent.nodedata = {
                                            rightTableSelect: tempColumns
                                        }
                                    }

                                    

                                    if (currentComponent.nodedata) {
                                        const fields = new Array();
                                        if (rightMatchColumns) {
                                            var rightFields = Array.from(rightMatchColumns);
                                            rightFields.forEach(element => {
                                                var result = rightTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]
                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        if (leftMatchColumns) {
                                            var leftFields = Array.from(leftMatchColumns);

                                            leftFields.forEach(element => {
                                                var result = leftTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        currentComponent.outputFields = fields;
                                    }
                                }}
                            >
                                {Column_Operators.map((operation, index) => (
                                    <MenuItem key={index} value={operation}>
                                        {operation}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                            <TextField
                                disableAutoFocus='true'
                                autoComplete='off'
                                label="New Column Name"
                                value={rightSelectColumn.newName}
                                onChange={(event) => {
                                    const newCoumns = [...rightTableSelectColumns];
                                    const column = newCoumns[index]
                                    column.newName = event.target.value
                                    setRightTableSelectColumns(newCoumns)

                                    const tempColumns = newCoumns.map(column => {
                                        if (column.operator === 'Drop Column') {
                                            column.newName = '';
                                            return column;
                                        } else {
                                            return column;
                                        }
                                    })

                                    if (currentComponent.nodedata) {
                                        currentComponent.nodedata.rightTableSelect = tempColumns

                                    } else {
                                        currentComponent.nodedata = {
                                            rightTableSelect: tempColumns
                                        }
                                    }
                                    
                                    if (currentComponent.nodedata) {
                                        const fields = new Array();
                                        if (rightMatchColumns) {
                                            var rightFields = Array.from(rightMatchColumns);
                                            rightFields.forEach(element => {
                                                var result = rightTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]
                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        if (leftMatchColumns) {
                                            var leftFields = Array.from(leftMatchColumns);

                                            leftFields.forEach(element => {
                                                var result = leftTableSelectColumns.filter(obj => {
                                                    return obj.originalName === element.FieldName
                                                })[0]

                                                if (result) {
                                                    if (result.newName != '') {
                                                        fields.push({ id: fields.length + 1, FieldName: result.newName });
                                                    } else {
                                                        fields.push({ id: fields.length + 1, FieldName: result.originalName });
                                                    }
                                                } else {
                                                    fields.push({ id: fields.length + 1, FieldName: element.FieldName });
                                                }
                                            })
                                        }
                                        currentComponent.outputFields = fields;
                                    }
                                   
                                }}
                            />
                        </FormControl>

                        <IconButton aria-label="delete"
                            sx={{
                                minWidth: 20,
                                maxWidth: 20,
                            }} onClick={(event) => handleDeleteRightSelectColumn(event, index)}>
                            <ClearIcon />
                        </IconButton>

                    </Stack>

                ))
                }

                <IconButton aria-label="Add"
                    sx={{
                        minWidth: 30,
                        maxWidth: 30,
                    }} onClick={handleAddRightSelectColumn}>
                    <AddIcon />
                </IconButton>
            </Box>
        </Stack>
    </Stack>)
}