import { NodeModel } from "@projectstorm/react-diagrams";
import { AdvancedPortModel } from "../link/link";
import React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Stack, useTheme } from '@mui/material'
import { DefaultPortModel } from '@projectstorm/react-diagrams';
import FormControl from '@mui/material/FormControl';
import Header from "../../Header";
import TextField from '@mui/material/TextField';


export class OutputModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            type: "Output",
            name: options?.name || "Output",
            color: 'rgb(0,192,255)'
        });

        this.depth = 0;
        this.precendentNodes = new Set();
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.nodedata = options?.nodedata;

        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
    }

        serialize() {
            return {
                ...super.serialize(),
                nodedata: this.nodedata,
                depth: this.depth,
                inputFields : this.inputFields,
                outputFields: this.outputFields,
                name: this.options.name,
                precendentNodes: Array.from(this.precendentNodes),
            }
        }

        deserialize(event, engine) {
            super.deserialize(event, engine);
            this.nodedata = event.data.nodedata;
            this.depth = event.data.depth;
            this.inputFields = event.data.inputFields;
            this.outputFields = event.data.outputFields;
            this.options.name = event.data.name;
            this.precendentNodes = new Set(event.data.precendentNodes);
            this.options.selected = false;
        }
}


export const OutputNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div*/}
            {/*    style={{ backgroundColor: props.node.color }}*/}
            {/*>*/}
            {/*    <Box>*/}
            {/*        <div className="node-header-text">Output</div>*/}
            {/*     </Box>*/}
             
            {/*</div>*/}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <img
                    draggable="false"
                    alt="Plus"
                    width="65px"
                    height="65px"
                    src={"../../assets/cloud_upload.svg"}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>
            {/*<div className="node-content">*/}
            {/*    <DownloadIcon sx={{ fontSize: 40 }} />*/}
            {/*</div>*/}
        </div>
    );
};

// TODO: Refactor to hooks
export class OutputNodeFactory extends AbstractReactFactory {
    constructor() {
        super('Output');
    }

    generateModel(initialConfig) {
        return new OutputModel();
    }

    generateReactWidget(event) {
        return <OutputNodeWidget engine={this.engine} node={event.model} />;
    }
}

export function OutputComponent(props) {

    const currentComponent = props.currentComponent;

    const model = props.model;

    const [nodeName, setNodeName] = React.useState('');

    const [outputFile, setOutputFile] = React.useState('');
    const forceUpdate = React.useReducer(bool => !bool)[1];
    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (props.currentComponent.nodedata && props.currentComponent.nodedata.OutPutDataNm) {
            setOutputFile(props.currentComponent.nodedata.OutPutDataNm)
        }
        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);

        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.outputFields) {
                    props.currentComponent.inputFields = parent.outputFields
                    props.currentComponent.outputFields = parent.outputFields
                }
            })
        }
    });

    const handleTextInputChange = (event) => {

        setOutputFile(event.target.value);

        const nodes = model.getNodes();

        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);

        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.nodedata) {
                    parent.nodedata.outputId = props.currentComponent.options.id;
                } else {
                    parent.nodedata = {
                        outputId: props.currentComponent.options.id
                    }
                }
            })
        }

        const inputNode = nodes.filter(node => node.options.type === 'Input')[0];

        if (props.currentComponent && inputNode) {
            props.currentComponent.nodedata = {
                OutPutDataNm: event.target.value,
                OutPutDataLocation: "/Raw/Processed/" + inputNode.nodedata?.FileTypeDesc + "/" + inputNode.nodedata?.clientkey + "/BR/"
            }
        }
    };

    return (<Box spacing={5} width={"50%"}>
        <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
            <TextField
                autoComplete='off'
                label="Node Name"
                value={nodeName}
                onChange={(event) => {
                    setNodeName(event.target.value)
                    currentComponent.options.name = event.target.value;
                }}
            />
        </FormControl>

        <br />
        <br />

        <FormControl fullWidth>
            <TextField
                autoComplete='off'
                label="Output file name"
                defaultValue="defaultFileLocation"
                value={outputFile}
                onChange={handleTextInputChange}
            />
        </FormControl>
    </Box>)
}
