import React, { useState, useEffect, useCallback } from 'react';
import {
    Box as MuiBox,
    Button,
    IconButton,
    useTheme,
    TextField,
    Stack,
    Divider,
    Autocomplete,
    Drawer,
    Toolbar,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    useMediaQuery,
    InputAdornment,
    Modal,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Formik } from "formik";
import * as yup from 'yup';
import ClientDataService from "../../service/ClientDataService";
import AuditClientDataService from "../../service/AuditClientDataService";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RightContainer from "../global/RightContainer";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ContractDataService from "../../service/ContractDataService";
import ContractList from "./contractList";
// import ContractSearchFilters from '../../components/common/ContractSearchFilters';

// Sample contract data
const sampleContracts = [
    {
        id: 1,
        contractName: 'Contract A',
        affiliate: 'Affiliate A',
        client: 'Client A',
        startDate: '2023-01-01',
        endDate: '2023-12-31',
        terms: [
            { id: 1, network: 'Network 1', termType: 'Type 1', termName: 'Term 1' },
            { id: 2, network: 'Network 2', termType: 'Type 2', termName: 'Term 2' },
        ]
    },
    {
        id: 2,
        contractName: 'Contract B',
        affiliate: 'Affiliate B',
        client: 'Client B',
        startDate: '2023-06-01',
        endDate: '2024-05-31',
        terms: [
            { id: 3, network: 'Network 3', termType: 'Type 3', termName: 'Term 3' },
            { id: 4, network: 'Network 4', termType: 'Type 4', termName: 'Term 4' },
        ]
    }
];

// Define a style object for the modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function yyyymmdd(date) {
    var x = new Date(date);
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <MuiBox
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <MuiBox sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </MuiBox>
            )}
        </MuiBox>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const ContractManagement = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [value, setValue] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [gridRows, setGridRows] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);

    const [affliate_open, affliateSetOpen] = useState(false);
    const [affliate_Options, affliateSetOptions] = useState([]);

    const [client_open, clientSetOpen] = useState(false);
    const [client_Options, ClientSetOptions] = useState([]);

    const [auditTypes, setAuditTypes] = useState([]);
 

    const [auditClientSearchClient, setAuditClientSearchClient] = useState(null);
    const [auditAffiliateSearchClient, setAuditAffiliateSearchClient] = useState(null);
    const [auditNetworkSearchClient, setAuditNetworkSearchClient] = useState(null);
    const [auditTypeSearch, setAuditTypeSearch] = useState(null);
    const [auditStatusSearch, setAuditStatusSearch] = useState(null);

    const [auditManagerSearch, setAuditManagerSearch] = useState(null);
    const [auditTeamSearch, setAuditTeamSearch] = useState(null);

    const [ContractName, setContractName] = useState("");

    const allicate_loading = affliate_open && affliate_Options.length === 0;
    const client_loading = client_open && client_Options.length === 0;

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [errorMessage, setErrorMessage] = useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertDto, setAlertDto] = React.useState({
        title: "",
        message: ""
    });


    useEffect(() => {
        console.log("REACT_APP_RUNTIME:" + process.env.REACT_APP_RUNTIME);

        const userKey = JSON.parse(localStorage.getItem("userKey") || "0");

        ClientDataService.getClients(userKey)
            .then((res) => {
                ClientSetOptions(res.data);
                clientSetOpen(true);
            })
            .catch((e) => {
                console.log(e);
            });

        ClientDataService.getAffiliates(userKey)
            .then((res) => {
                affliateSetOptions(res.data);
                affliateSetOpen(true);
            })
            .catch((e) => {
                console.log(e);
            });

        AuditClientDataService.getAuditTypes()
            .then((res) => {
                setAuditTypes(res.data);
            })
            .catch((e) => {
                console.log(e);
            });

        
        setAuditAffiliateSearchClient(null);
        setAuditClientSearchClient(null);
        setAuditNetworkSearchClient(null);
        setAuditTypeSearch(null);
    }, [allicate_loading]);

    const clearOnClick = () => {
        setAuditAffiliateSearchClient(null);
        setAuditClientSearchClient(null);
        setAuditNetworkSearchClient(null);
        setAuditTypeSearch(null);
        setAuditTeamSearch(null);
        setAuditManagerSearch(null);
        setAuditStatusSearch(null);
        setStartDate(null);
        setEndDate(null);
        setContractName("");
        setGridRows([]);
        forceUpdate();
    };

  

    const initialValues = {
        affliate: affliate_Options[0],
        client: client_Options[0],
        startDate: null,
        endDate: null,
    };



    const handleContractNameChange = (event) => {
        setContractName(event.target.value);
    };

    const handleAuditTypeSearchChange = (event) => {
        setAuditTypeSearch(event.target.value);
    };

    const handleFormSubmit2 = (values) => {

        if (!auditClientSearchClient && !auditAffiliateSearchClient && !auditTypeSearch && !startDate && !endDate && !ContractName) {
            setAlertDto({ ...alertDto, title: "Message", message: "Please select at least one filter." });
            handleAlertOpen();
            return;
        }
        

        if (!startDate && !endDate && startDate > endDate) {
            setAlertDto({ ...alertDto, title: "Message", message: "The end time cannot be less than the start time." });
            handleAlertOpen();
            return;
        }

        var data = {
            clientkey: auditClientSearchClient ? auditClientSearchClient.clientKey : -1,
            affiliatekey: auditAffiliateSearchClient ? auditAffiliateSearchClient.clientKey : - 1,
            audittypekey: auditTypeSearch ? auditTypeSearch.audittypekey : -1,
            contractname: ContractName ? ContractName : "",
            startdate: startDate ? yyyymmdd(startDate) : -1,
            enddate: endDate ? yyyymmdd(endDate) : -1,
            auditkey:-1
        }
        console.log("submit", data);

        ContractDataService.getContracts(data)
            .then((res) => {
                return res.data;
            })
            .then(data => {
                console.log(data);
                setGridRows(data);
            })
            .catch(err => console.log(err));
    };

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };
    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    
    return (
        <RightContainer title="Contract Management">
            <MuiBox>
                <Typography sx={{ mb: "15px", fontSize: "25px" }}>Contract Search</Typography>
                {/* <ContractSearchFilters /> */}
                <MuiBox>
                    <Formik onSubmit={handleFormSubmit2} initialValues={initialValues}>
                        {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <MuiBox
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        marginTop: "20px",
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}
                                >
                                    <Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Autocomplete
                                                id="affiliate"
                                                sx={{ width: 300 }}
                                                value={auditAffiliateSearchClient || ""}
                                                isOptionEqualToValue={(option) => option.clientKey}
                                                onChange={(event, value) => setAuditAffiliateSearchClient(value)}
                                                options={affliate_Options}
                                                autoHighlight
                                                getOptionLabel={(option) => option.clientName || ""}
                                                renderOption={(props, option) => (
                                                    <MuiBox
                                                        component="li"
                                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                        {...props}
                                                    >
                                                        {option.clientName}
                                                    </MuiBox>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Choose an Affiliate" />
                                                )}
                                            />

                                            <Autocomplete
                                                id="client"
                                                sx={{ width: 300 }}
                                                value={auditClientSearchClient || ""}
                                                isOptionEqualToValue={(option) => option.networkey}
                                                onChange={(event, value) => setAuditClientSearchClient(value)}
                                                options={client_Options}
                                                autoHighlight
                                                getOptionLabel={(option) => option.clientName || ""}
                                                renderOption={(props, option) => (
                                                    <MuiBox
                                                        component="li"
                                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                        {...props}
                                                    >
                                                        {option.clientName}
                                                    </MuiBox>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Choose a Client" />
                                                )}
                                            />

                                            <FormControl sx={{ width: 280 }}>
                                                <InputLabel htmlFor="name-shared">Choose Audit Type</InputLabel>
                                                <Select
                                                    label="AuditType"
                                                    value={auditTypeSearch || ""}
                                                    onChange={handleAuditTypeSearchChange}
                                                >
                                                    <MenuItem value={null}>NONE</MenuItem>
                                                    {auditTypes.map((type) => (
                                                        <MenuItem key={type.audittypekey} value={type}>
                                                            {type.audittypedesc}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <Button color="secondary" variant="contained" onClick={clearOnClick}>
                                                Clear Filters
                                            </Button>
                                        </Stack>

                                        <br />
                                        <Stack direction="row" spacing={2}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Start Date"
                                                    dateFormat="yyyy-MM-dd"
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        setStartDate(newValue);
                                                    }}
                                                />

                                                <DatePicker
                                                    label="End Date"
                                                    value={endDate}
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={(newValue) => {
                                                        setEndDate(newValue);
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            <FormControl sx={{ width: 280 }}>
                                                <TextField
                                                    name="ContractName"
                                                    type="text"
                                                    id="ContractName"
                                                    value={ContractName}
                                                    onChange={handleContractNameChange}
                                                    label="Choose Contract Name"
                                                    size="medium"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>

                                            <br />
                                            <Button type="submit" color="secondary" variant="contained">
                                                Apply Filters
                                            </Button>

                                            {errorMessage ? <div className="error"> {errorMessage} </div> : null}
                                        </Stack>
                                    </Stack>
                                </MuiBox>
                            </form>
                        )}
                    </Formik>
                </MuiBox>
                <ContractList contractListItems={gridRows}></ContractList>
            </MuiBox>
            <Modal
                open={alertOpen}
                onClose={handleAlertClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <MuiBox sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">{alertDto.title}</h2>
                    <p id="parent-modal-description">
                        {alertDto.message}
                    </p>
                    <MuiBox m="20px 0 0 0" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleAlertClose}>Close</Button>
                    </MuiBox>

                </MuiBox>
            </Modal>
        </RightContainer>
    );
};

export default ContractManagement;