import React, { useRef, useEffect, useState } from 'react';
import { Stack } from '@mui/material'
import AuditReportService from "../../service/AuditReportService"
import RightContainer from "../global/RightContainer";
import QuillEditor from "../../components/common/QuillEditor"
import Button from '@mui/material/Button';


const AuditReport = () => {
    const [affliate_open, affliateSetOpen] = React.useState(false);
    const [editorContent, setEditorContent] = useState('<p>Hello, <strong>Quill!</strong></p>');
    const [reportSections, setReportSections] = useState([]);
    const [reportItems, setReportItems] = useState([]);
    const [reportItem, setReportItem] = useState({
        auditreportitemkey: 0,
        auditreportsectionkey:6,
        auditreportitemdesckey: 1,
        itemorder: 1,
        itemcontent: "Test Data",
        fileconfigkey: 1
    });

    const handleContentChange = (newContent) => {
        setEditorContent(newContent);
        console.log('content changed:', newContent);
    };

    const createMarkup = () => ({
        __html: editorContent,
    });
   
    const getAuditReportService = () => {
        AuditReportService.getAuditReportSection()
            .then((res) => {
                return res.data;
            })
            .then(data => {
                setReportSections(data);
            })
            .catch(err => console.log(err));
    }

    const getAuditReportItem = () => {
        AuditReportService.getAuditReportItem()
            .then((res) => {
                return res.data;
            })
            .then(data => {
                setReportItems(data);
            })
            .catch(err => console.log(err));
    }

    const saveAuditReportItem = () => {
        AuditReportService.saveAuditReportItem(reportItem)
            .then((res) => {
                return res.data;
            })
            .then(data => {
                setReportItem(data);
                alert("Save successful!");
            })
            .catch(err => console.log(err));
    }

    const deleteAuditReportItem = () => {
        let _reportItem = reportItem;
        AuditReportService.deleteAuditReportItem(_reportItem)
            .then((res) => {
                return res.data;
            })
            .then(data => {
                setReportItem({
                    auditreportitemkey: 0,
                    auditreportsectionkey: 6,
                    auditreportitemdesckey: 1,
                    itemorder: 1,
                    itemcontent: "Test Data",
                    fileconfigkey: 1
                });
                alert("Delete successful!");
            })
            .catch(err => console.log(err));
    }
 
    useEffect(() => {
        getAuditReportService();
        getAuditReportItem();
    }, []);

    return (
        <Stack>
            <RightContainer title="Audit Report">
                <div>
                    <QuillEditor onContentChange={handleContentChange} initialValue={editorContent} />
                    <div style={{marginTop:"10px"}}>{editorContent}</div>
                    <hr />
                    <div>
                        <h3>Audit Report Section</h3>
                        <div>
                            <ul>
                                {
                                    reportSections.map(x => (
                                        <li>{x.sectiontitle}</li>
                                    ))
                                }
                             
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <h3>Audit Report Items</h3>
                        <div>
                            <ul>
                                {
                                    reportItems.map(x => (
                                        <li>{x.itemtitle}</li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <h3>Save Report Item</h3>
                        <div>
                            <ul>
                                {Object.entries(reportItem).map(([key, value]) => (
                                    <li key={key}>
                                        <p>Key: {key}</p>
                                        <p>Value: {value}</p>
                                    </li>
                                ))}
                            </ul>
                            <Button variant="contained" onClick={saveAuditReportItem}>Save</Button>
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <h3>Delete Report Item</h3>
                        <div>
                            <ul>
                                {Object.entries(reportItem).map(([key, value]) => (
                                    <li key={key}>
                                        <p>Key: {key}</p>
                                        <p>Value: {value}</p>
                                    </li>
                                ))}
                            </ul>
                            <Button variant="contained" onClick={deleteAuditReportItem}>Delete</Button>
                        </div>
                    </div>
                </div>
            </RightContainer>
        </Stack>
    );
}

export default AuditReport