import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function ContentDialog(props) {

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.close}
                fullWidth={true}
                maxWidth={props.maxWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="content-dialog"
            >
                <DialogTitle id="alert-dialog-title" style={props?.styleTitle}>
                    {props.title}
                </DialogTitle>
               
                {props.close ? (
                    <IconButton
                        aria-label="close"
                        onClick={props.close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
                <DialogContent>
                    {props.children}
                </DialogContent>
                {
                    !props.noActions &&
                    <DialogActions>
                        {
                            !props.hideAllConfirm
                            &&
                            <>
                                {
                                        !props.hideConfirm && !props.disabled && <Button onClick={props.onConfirm} variant="contained" color="success"  style={{  color: 'white' }}>{props.confirmName || 'Save'}</Button>
                                }

                                {
                                        props.disabled && <Button variant="contained" disabled={props.disabled}>{props.confirmName || 'Save'}</Button>
                                }
                            </>
                        }

                            <Button onClick={props.close} variant="contained"  autoFocus>{props.closeName || 'Cancel'}</Button>
                    </DialogActions>
                }

            </Dialog>
        </div>
    );
}
