﻿// authService.ts
import { PublicClientApplication, AuthenticationResult, EndSessionRequest, RedirectRequest, PopupRequest, AccountInfo,BrowserAuthError  } from '@azure/msal-browser';
import { InteractionRequiredAuthError, IdTokenClaims } from '@azure/msal-browser';
import { rolesService } from '../ts/modules/roles/service';
import { RoleComposite } from '../ts/modules/roles/interface';
import { userInfo } from 'os';
import { Token } from '@mui/icons-material';


export type UserInfo = {
//	userKey: number;
	id: string;
	name: string;
	email: string;
	idToken: string;
//	roles: RoleComposite[] | undefined;
    // claims: IdTokenClaims;
    // accessToken: string;
  } | null;


export class AuthService {

	public pca: PublicClientApplication;

	constructor(msalInstance: PublicClientApplication ) {
		this.pca = msalInstance;
	}


	getAccount() {
		const accounts = this.pca.getAllAccounts();
		return  accounts[0];  
	}




	async login(): Promise<AccountInfo | undefined> {
		try {
			const account = this.pca.getAllAccounts()[0];
			if (account) {
				return account;
			}

			const redirectURL = new URLSearchParams(window.location.search).get('redirectRoute')

			const loginRequest: RedirectRequest = {
				scopes: ["openid", "profile", "offline_access","User.Read"],
				redirectUri: window.location.origin + (redirectURL == null ? '' : redirectURL),
				authenticationScheme: 'Bearer',         
				// onRedirectNavigate(url) {

				//   console.log('redirect navigate',url)
				//   return true
				// },
          
			};
			await this.pca.loginRedirect(loginRequest)   
		}
		catch (err) {
			if(err instanceof BrowserAuthError){
			const response = await this.pca.handleRedirectPromise()
			return response?.account
			}
			console.error(err);
		}
	}



	async loginPopup(): Promise<void> {
		try {
			const account = this.pca.getAllAccounts()[0];
			if (account) {
				return;
			}

        
			const loginRequest: RedirectRequest = {
				scopes: ["openid", "profile", "offline_access","User.Read"],
				redirectUri: window.location.origin ,
				authenticationScheme: 'Bearer',
				onRedirectNavigate(url) {
				return true
				},
          
			};
			const authResults = await this.pca.loginPopup(loginRequest); 
		} catch (err) {
			console.error(err);
		}
	}

	async logout(): Promise<void> {
		const account = this.pca.getAllAccounts()[0];
		if (account) {
			const logOutRequest: PopupRequest = {
				scopes: ["openid", "profile", "offline_access","User.Read"],
				account: account,
				redirectUri: window.location.origin + "/login",
				authenticationScheme: 'Bearer',
			};
			await this.pca.logoutRedirect(logOutRequest);
			this.pca.clearCache()
		}
	}


	setAccessToken(): void {
		try {
			let account = this.pca.getAllAccounts()[0];

			if (account === undefined)
				return;

			const accessTokenRequest = {
				scopes: ["openid", "profile", "offline_access", "User.Read"],
				account: account,
			};

			this.pca.acquireTokenSilent(accessTokenRequest)
				.then((tokenResponse) => { localStorage.setItem("userToken", tokenResponse.idToken) })
				.catch((error) => {
					//Acquire token silent failure, and send an interactive request
					if (error instanceof InteractionRequiredAuthError) {
						this.pca
							.acquireTokenPopup(accessTokenRequest)
							.then((tokenResponse) => {

								localStorage.setItem("userToken", tokenResponse.idToken);
							})
							.catch((error) => {
								console.log(error);
							});
					}
					console.log(error);
				});

		} catch (err) {
			console.error(err);
			return;
		}
	}



	getUserInfo(account?: AccountInfo | undefined): UserInfo {

		try {
			if(account === undefined)
			account = this.pca.getAllAccounts()[0];

			if (account === undefined) 
			return null;

			//get roles/permissions for this user via the email (account.username)
			rolesService.fetchCompositeRolesByUserID(account.username)
			.then((result) => {

				localStorage.setItem("userKey", result?.at(0)?.userKey.toString() ?? "0");
				localStorage.setItem("userRoles", JSON.stringify(result ?? ""));
			})
			.catch(error => console.log(error));


			return {
				id: account?.idTokenClaims?.oid || '',
				name: account?.name || (account?.username ?? ''),
				email: account?.username ?? '',
				idToken: account?.idToken || '',
			};

		} catch (err) {
			console.error(err);
			return null;
		}
	}
}
