import React, { useState, useEffect, useRef } from "react";

//MUI Import
import { Box, Button, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Tooltip from "@mui/material/Tooltip";

import QuillEditor from "../../components/common/QuillEditor";

import parse from "html-react-parser";

import "./ReportSection.css";

//Drag & Drop Import
// import { arrayMoveImmutable } from "array-move";
// import { Container, Draggable } from "react-smooth-dnd";

const ReportSection = ({
  client,
  network,
  reportSectionList,
  setReportSectionList,
  currentTab,
  setCurrentTab,
  handleDeleteNote,
  isEditActive,
  handleDoneEdit,
  editorContent,
  handleContentChange,
  handleChangeToEdit,
}) => {
  // const getChildPayload = (index) => index;

  // const onDrop = (dropResult) => {
  //   const { removedIndex, addedIndex } = dropResult;

  //   const arrMoveResult = arrayMoveImmutable(
  //     reportSectionList[currentTab].reportList,
  //     removedIndex,
  //     addedIndex
  //   );

  //   const list = [...reportSectionList];
  //   list[currentTab].reportList = arrMoveResult;

  //   setReportSectionList(list);
  // };

  const isAnyEditActive =
    reportSectionList[currentTab].reportList.filter((d) => d.isEdit).length > 0;

  return (
    <Box className="report-writer-tab__report-section">
      <div className="report-section__header">
        <p>Report Section </p>
        <Button
          color="primary"
          variant="outlined"
          sx={{ height: "36px" }}
          disabled={!(client && network)}
        >
          Save
        </Button>
      </div>

      {reportSectionList &&
      reportSectionList.filter((d) => d.isChecked).length > 0 ? (
        <>
          <div className="report-section_navigation">
            <p>{reportSectionList[currentTab].name}</p>

            <Stack gap={3} direction="horizontal" className="mt-3">
              <Button
                color="primary"
                variant="contained"
                disabled={
                  currentTab === 0 ||
                  reportSectionList.length === 1 ||
                  isEditActive
                }
                onClick={() => setCurrentTab((prev) => prev - 1)}
              >
                <NavigateBeforeIcon />
                Previous Section
              </Button>

              <Button
                color="primary"
                variant="contained"
                disabled={
                  currentTab === reportSectionList.length - 1 ||
                  reportSectionList.length === 1 ||
                  isEditActive
                }
                onClick={() => setCurrentTab((prev) => prev + 1)}
              >
                Next Section
                <NavigateNextIcon />
              </Button>
            </Stack>
          </div>

          <Box className="report-section__writing">
            {reportSectionList[currentTab].reportList.length > 0 ? (
              // <Container
              //   dragHandleSelector=".drag-handle"
              //   lockAxis="y"
              //   onDrop={onDrop}
              //   behaviour="contain"
              //   getChildPayload={getChildPayload}
              // >
              <>
                {reportSectionList[currentTab].reportList.map((item, index) => (
                  // <Draggable key={index}>
                  <Card
                    className={`card-list ${
                      isAnyEditActive && item.isEdit === false
                        ? "edit-text-disabled"
                        : "pointer-cursor"
                    }`}
                    sx={{ marginBottom: "16px", overflow: "visible" }}
                    onClick={(e) => handleChangeToEdit(e, item.value, index)}
                  >
                    <div
                      style={{
                        padding: "16px 0 16px 16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon
                        className="drag-handle"
                        sx={{
                          minWidth: "25px",
                          cursor: "grabbing",
                          marginRight: "5px",
                        }}
                      >
                        <DragIndicatorOutlinedIcon />
                      </ListItemIcon>
                      {item.isEdit ? (
                        <>
                          <div style={{ width: "100%" }}>
                            <QuillEditor
                              width={"100%"}
                              initialValue={editorContent}
                              onContentChange={handleContentChange}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Tooltip title="Edit">
                              <IconButton
                                color="primary"
                                onClick={handleDoneEdit}
                                disabled={
                                  editorContent === "" ||
                                  editorContent.replaceAll(" ", "") ===
                                    "<p><br></p>"
                                }
                              >
                                <DoneIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                color="primary"
                                onClick={(e) => handleDeleteNote(e, index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        <div className="display-parse-text">
                          <div
                            id={`quill-text-${index}`}
                            className="quill-text"
                          >
                            {parse(item.value)}
                          </div>
                          {!isAnyEditActive && item.isEdit === false && (
                            <div className="delete-btn">
                              <Tooltip title="Delete">
                                <IconButton
                                  color="primary"
                                  onClick={(e) => handleDeleteNote(e, index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Card>
                  // </Draggable>
                ))}
              </>
            ) : (
              //  </Container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                No Data
              </div>
            )}
          </Box>
        </>
      ) : (
        <div className="no-rows">
          <p>Report Section Data Not Available</p>
        </div>
      )}
    </Box>
  );
};

export default ReportSection;
