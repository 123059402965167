import React, { useState, useEffect, useCallback } from 'react';
import {
    Box as MuiBox,
    Button,
    IconButton,
    useTheme,
    Typography,
    useMediaQuery,
    Drawer,
    Toolbar,
    Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AuditReportService from "../../service/AuditReportService";
import PdfViewer from '../../components/common/PdfViewer';

// Sample audit report data
const sampleAuditReports = [
    {
        id: 1,
        reportName: 'Audit Report A',
        client: 'Client A',
        network: 'Network 1',
        status: 'Open',
        details: 'Details of Audit Report A',
    },
    {
        id: 2,
        reportName: 'Audit Report B',
        client: 'Client B',
        network: 'Network 2',
        status: 'Published',
        details: 'Details of Audit Report B',
    },
];

const ReportPublisher = (prop) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { currentAudit } = prop

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [gridRows, setGridRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const columns = [
        { field: "rptname", headerName: "Report Name", width: 150 },
        { field: "clientname", headerName: "Client", width: 100 },
        { field: "networkname", headerName: "Network", width: 100 },
        { field: "statusname", headerName: "Status", width: 100 },
    ];

    const handleRowClick = (params) => {
        const selected = gridRows.find((row) => row.id === params.row.id);
        setSelectedReport(selected);
    };

    const handlePublishToggle = () => {
        if (selectedReport) {
            let _data = selectedReport;
            _data.rptapprovalflg =  _data.status === 'Open';
            AuditReportService.updateReportApprovalFlg(_data)
            .then((res) => {
                return res.data;
            })
            .then(data => {
                console.log(data);
            })
            .catch(err => console.log(err));


            const updatedReport = {
                ...selectedReport,
                status: selectedReport.status === 'Open' ? 'Published' : 'Open',
                rptapprovalflg: _data.rptapprovalflg
            };

            const updatedRows = gridRows.map(report =>
                report.id === updatedReport.id ? updatedReport : report
            );

            setGridRows(updatedRows);
            setSelectedReport(updatedReport);
        }
    };

    const handleReviewReportClick = () => {
        setDrawerOpen(true);
    };

    const getAuditReport= (id) => {
        AuditReportService.getAuditReport(id)
            .then((res) => {
                return res.data;
            })
            .then(data => {
                setGridRows(data);
            })
            .catch(err => console.log(err));
    }

	const generateReport = () => {
		AuditReportService.generateReport(selectedReport)
			.then((res) => {
				return res.data;
			})
			.then(data => {
				if (data == true) {
					alert("Successfully generated.");
				}
			})
			.catch(err => console.log(err));
	}

	//const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

    useEffect(() => {
        if (currentAudit && currentAudit.audit.auditkey) {
            getAuditReport(currentAudit.audit.auditkey);
        }
        else {
            getAuditReport(-1);
        }
	//	ClientDataService.getAll(userKey).then((res) => {
	//		return res.data;
	//	}).then(clients => {
	//		SetClients(clients);
	//	}).catch(e => {
	//		console.log(e)
	//	});
    }, [currentAudit]);




	//const handleFileRowClick = (params) => {
	//	setFile(params.row);
	//	DataLakeService.getParquetFile(params.row.fileConfigKey).then(res => {
	//		var columns = [];
	//		var rows = [];
	//		var rowCount = res.data.length;
	//		var colCount = res.data[0].length;

	//		for (let i = 0; i < colCount; i++) {
	//			columns.push(
	//				{
	//					field: i,
	//					headerName: i,
	//					width: 200
	//				})
	//		}


	//		for (let j = 0; j < rowCount; j++) {
	//			var object = {};
	//			object.id = j;

	//			res.data[j].map((row, index) => {
	//				object[index] = row
	//			})
	//			rows.push(object);
	//		}
	//	});
	//}

    return (
        <MuiBox p={3} bgcolor="white">
            <Typography sx={{ mb: "15px", fontSize: "25px" }}>Report Publisher</Typography>
            <MuiBox>
                <MuiBox mt="20px">
                    <MuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h3">Audit Reports</Typography>
                        <Button onClick={() => setDrawerOpen(!drawerOpen)}>
                            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                        </Button>
                    </MuiBox>
                    <MuiBox
                        display="flex"
                        justifyContent="center"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                backgroundColor: "white",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: "white",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: 'white',
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <MuiBox width="50%" height="600px">
                            <DataGrid
                                rows={gridRows}
                                columns={columns}
                                onRowClick={handleRowClick}
                            />
                        </MuiBox>
                        <MuiBox
                            display="flex"
                            flexDirection="column"
                            width="50%"
                            p={2}
                        >
                            {selectedReport ? (
                                <>
                                    <Typography>Report Name: {selectedReport.rptname}</Typography>
                                    <Typography>Client: {selectedReport.clientname}</Typography>
                                    <Typography>Network: {selectedReport.networkname}</Typography>
                                    <Typography>Status: {selectedReport.statusname}</Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2 }}
                                        onClick={handleReviewReportClick}
                                    >
                                        Review Report
                                    </Button>
                                    <MuiBox
                                        p={2}
                                        height="400px"
                                        mt={2}
                                    >
                                        <Typography variant="h6" gutterBottom>Report Details</Typography>
                                        <Typography>{selectedReport.details}</Typography>
                                    </MuiBox>
                                </>
                            ) : (
                                <Typography variant="h6" color="textSecondary">Select a report to see its details.</Typography>
                            )}
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ style: { width: '90%' } }}
            >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <MuiBox p={2} display="flex" flexDirection="column" alignItems="center" bgcolor="white">
                    <MuiBox display="flex" width="100%">
                        {/* Report Details */}
                        <MuiBox width="40%" display="flex" flexDirection="column" p={2} bgcolor="white">
                            {selectedReport ? (
                                <>
                                    <Typography>Report Name: {selectedReport.rptname}</Typography>
                                    <Typography>Client: {selectedReport.clientname}</Typography>
                                    <Typography>Network: {selectedReport.networkname}</Typography>
                                    <Typography>Status: {selectedReport.statusname}</Typography>
                                    {
                                        selectedReport.rptapprovalflg  &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2 }}
                                            disabled
                                        >
                                           Published
                                        </Button>
                                    }
                                    {
                                        !selectedReport.rptapprovalflg  &&
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => { generateReport() }}
                                            sx={{ mt: 2 }}
                                        >
                                            Generate Report
                                        </Button>
                                    }
                                    

                                    <Button
                                        variant="contained"
                                        color={selectedReport.status === 'Open' ? 'primary' : 'secondary'}
                                        onClick={handlePublishToggle}
                                        sx={{ mt: 2 }}
                                    >
                                        {selectedReport.status === 'Open' ? 'Publish Report' : 'Unpublish Report'}
                                    </Button>

                                    <MuiBox
                                        p={2}
                                        height="400px"
                                        mt={2}
                                    >
                                        <Typography variant="h6" gutterBottom>Report Details</Typography>
                                        <Typography>{selectedReport.details}</Typography>
                                    </MuiBox>
                                </>
                            ) : (
                                <Typography variant="h6" color="textSecondary">Select a report to see its details.</Typography>
                            )}
                        </MuiBox>
                        {/* PDF Viewer Placeholder */}
                        <MuiBox width="60%" display="flex" flexDirection="column" alignItems="center" p={2} bgcolor="white">
                            <Typography variant="h4" color="textSecondary" sx={{ marginBottom: "20px" }}>
                                PDF Viewer
                            </Typography>
                            {
                                selectedReport && selectedReport.auditreportkey
                                &&
                                <PdfViewer url={'/api/auditreport/download/' + selectedReport.auditreportkey}></PdfViewer>
                            }
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </Drawer>
            <Divider orientation="horizontal" />
        </MuiBox>
    );
};

export default ReportPublisher;