

import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";

import { Box, Stack, useTheme } from '@mui/material'
import { tokens } from "../../../theme";
import { DefaultPortModel } from '@projectstorm/react-diagrams';
import FileSchemaDataService from "../../../service/FileSchemaService";
import AuditClientDataService from "../../../service/AuditClientDataService"
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FileConfigDataService from '../../../service/FileConfigDataService';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function parse(str) {
    if (!/^(\d){8}$/.test(str)) return "invalid date";
    var y = str.substring(0, 4),
        m = str.substring(4, 6),
        d = str.substring(6, 8);
    return new Date(y, m-1, d);
}

function yyyymmdd(date) {
    var x = new Date(date);
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}


function highlightRow(rowData, valueToMatch) {
    return rowData.FileName === valueToMatch ? 'highlight-row' : '';
}

export class InputModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            type: "Input",
            name: options?.name || "Input",
            color: 'rgb(192,255,0)',
        });

        this.depth = 0;
        this.precendentNodes = new Set()
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.nodedata = options?.nodedata;


        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            nodedata: this.nodedata,
            depth: this.depth,
            inputFields : this.inputFields,
            outputFields: this.outputFields,
            name: this.options.name,
            precendentNodes: Array.from(this.precendentNodes),
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.nodedata = event.data.nodedata;
        this.depth = event.data.depth;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.options.name = event.data.name;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }
}


export const InputNodeWidget = props => {
    return (
        <div className="node">
            {/*<div            */}
            {/*    style={{ backgroundColor: props.node.color }}*/}
            {/*>*/}
            {/*    <Box>*/}
            {/*        <div className="node-header-text">Input</div>*/}
            {/*    </Box>*/}
            {/*</div>*/}
            {/*<div className="node-content">*/}
            {/*    <Box>*/}
            {/*        <InputIcon sx={{ fontSize: 40 }} />*/}
            {/*    </Box>*/}
            {/*</div>*/}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <img
                    draggable="false"
                    alt="Plus"
                    width="65px"
                    height="65px"
                    src={"../../assets/cloud_download.svg"}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};




// TODO: Refactor to hooks
export class InputNodeFactory extends AbstractReactFactory {
    constructor() {
        super("Input");
    }

    generateModel(initialConfig) {
        return new InputModel();
    }

    generateReactWidget(event) {
        return <InputNodeWidget engine={this.engine} node={event.model} />;
    }
}



export function InputComponent(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const currentComponent = props.currentComponent;
    const currentAudit = props.currentAudit;
    const currentProcessflowstagekey = props.currentProcessflowstagekey;

    const [inputFiles, setInputFiles] = React.useState([]);

    const [affiliateFiles, setAffiliateFiles] = React.useState([]);

    const [clientFiles, setClientFiles] = React.useState([]);

    const [fileColumns, setFileColumns] = React.useState([]);

    const [currentFileName, setCurrentFileName] = React.useState("");

    const [auditConfigurations, setAuditConfigurations] = React.useState([]);

    const [nodeName, setNodeName] = React.useState('');

    React.useEffect(() => {
        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
            
        }

        if (currentComponent.nodedata && currentComponent.nodedata.FileName) {
            setCurrentFileName(currentComponent.nodedata.FileName)
        }

        if (currentAudit) {
            console.log('currentProcessflowstagekey:',currentProcessflowstagekey)
            AuditClientDataService
                .getAuditConfigurationsByAudit(currentAudit.id)
                .then(res => {
                    setAuditConfigurations(Object.groupBy(res.data, x => x.auditConfig.auditclientkey))
                }
                ).catch(err => console.log(err));

            FileConfigDataService.getFilesByAffiliateOrClientAndStatusKey(currentAudit.audit.affiliatekey, currentAudit.audit.auditkey, currentProcessflowstagekey)
                .then((res) => {
                    setInputFiles(res.data.map((data, index) => {
                        var result = {
                            id: index,
                            FileName: data.fc.filenm,
                            FileTypeDesc: data.fileTypeTemp.filedesc,
                            SchemaName: data.schemaTemp.clientfiletypedesc,
                            ConversionStatus: data.convStatusTemp ? data.convStatusTemp.statusdesc : "",
                            fileConfigKey: data.fc.fileconfigkey,
                            clientkey: data.fc.systemclientkey,
                            clientfileschemakey: data.fc.clientfileschemakey,
                            StartDate: data.fc.filedatefrom,
                            EndDate: data.fc.filedateto
                        }

                        if (data.participantTemp.participanttype == "Affiliate") {
                            result.Affiliate = data.participantTemp.participantname;
                        }

                        if (data.participantTemp.participanttype == "Client") {
                            result.Client = data.participantTemp.participantname;
                            result.Network = data.fc.networkey;
                        }
                        return result;
                    }))
                })
                .catch(err => console.log(err))

        }
    }, []);



    const inputFile_headers = [
        {
            field: "FileName",
            headerName: "File Name",
            width: 200
        },
        {
            field: "FileTypeDesc",
            headerName: "File Type",
            width: 200
        },
        {
            field: "Affiliate",
            headerName: "Affiliate",
            width: 200
        },
        {
            field: "Client",
            headerName: "Client",
            width: 200
        },
        {
            field: "Network",
            headerName: "Network",
            width: 200
        },
        {
            field: "SchemaName",
            headerName: "Schema Name",
            width: 200
        },
        {
            field: "ConversionStatus",
            headerName: "Conversion Status",
            width: 200
        },
        {
            field: "StartDate",
            headerName: "Start Date",
            width: 200
        },
        {
            field: "EndDate",
            headerName: "End Date",
            width: 200
        } 
    ]

    const handleInputFileChange = (event) => {
        currentComponent.nodedata = event.target.value;

        if (event.target.value && (event.target.value.clientfileschemakey)) {
            FileSchemaDataService.getFileSchemaByClientFileSchemaKey(event.target.value.clientfileschemakey)
                .then((res) => {
                    const inputFields = res.data.map((data, index) => {
                        return {
                            id: index,
                            FieldOrder: data.fieldorder,
                            FieldName: data.fieldname,
                            FieldDesc: data.fielddesc,
                            FieldFormat: data.fieldformatdesc

                        }
                    });
                    setFileColumns(inputFields);
                    currentComponent.outputFields = inputFields;
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }


    const handleFileRowClick = (params) => {
        currentComponent.nodedata = params.row;

        setCurrentFileName(params.row.FileName)

        if (params.row && (params.row.clientfileschemakey)) {
            

            FileSchemaDataService.getFileSchemaByClientFileSchemaKey(params.row.clientfileschemakey)
                .then((res) => {
                    const inputFields = res.data.map((data, index) => {
                        return {
                            id: index,
                            FieldOrder: data.fieldorder,
                            FieldName: data.fieldname,
                            FieldDesc: data.fielddesc,
                            FieldFormat: data.fieldformatdesc

                        }
                    });
                    setFileColumns(inputFields);
                    currentComponent.outputFields = inputFields;
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    return (<Box spacing={5} width={"100%"}>
        <br />
        <Stack direction="row" spacing={5}>
            <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                <TextField
                    autoComplete='off'
                    label="Node Name"
                    value={nodeName}
                    onChange={(event) => {
                        setNodeName(event.target.value)
                        currentComponent.options.name = event.target.value;
                    }}
                />
            </FormControl>


            <br />
            <br />
            <br />
                <InputLabel>Selected File: </InputLabel>
                <InputLabel>{currentFileName}</InputLabel>
        </Stack>
  


        <Box
            m="18px 0 0 0"
            width="100%"
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
            }}
            className="overwrite-table"
        >
            <DataGrid
                autoHeight={true}
                onRowClick={handleFileRowClick}
                rows={inputFiles}
                pageSize={5}
                columns={inputFile_headers}
                components={{ Toolbar: GridToolbar }}
                getRowClassName={(params) => highlightRow(params.row, currentFileName)}
            />
        </Box>


        {/*<FormControl fullWidth>*/}
        {/*    <InputLabel id="input-file-select-label">Input File</InputLabel>*/}
        {/*    <Select*/}
        {/*        labelId="demo-simple-select-label"*/}
        {/*        id="input-file-select-label"*/}
        {/*        label="Input File"*/}
        {/*        value={currentComponent.nodedata}*/}
        {/*        onChange={handleInputFileChange}*/}
        {/*        renderValue={(selected) => selected.FileName}*/}
        {/*    >*/}

        {/*         <ListSubheader>Affiliate Files </ListSubheader>*/}
        {/*        {affiliateFiles.map(file => (*/}
        {/*            <MenuItem key={file.id} value={file}>*/}
        {/*                {file.FileName}*/}
        {/*            </MenuItem>*/}
        {/*        ))}*/}
        {/*        <ListSubheader>Client Files </ListSubheader>*/}
        {/*        {clientFiles.map(file => (*/}
        {/*            <MenuItem key={file.id} value={file}>*/}
        {/*                {file.FileName}*/}
        {/*            </MenuItem>*/}
        {/*        ))}*/}

        {/*    </Select>*/}
        {/*</FormControl>*/}
    </Box >)
}

