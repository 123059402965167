import React, { useState, createContext , useCallback} from 'react';

import {useNavigate } from 'react-router-dom';

import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Authentication from '../service/Authentication';
import { Menu, Sidebar, MenuItem, SubMenu, sidebarClasses } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useTheme, Box, Typography,Button, Link, IconButton, Divider, Stack } from "@mui/material";
import { tokens } from "../theme";
import { MyProSidebarProvider } from "../pages/global/sidebar/sidebarContext";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useSidebarContext } from "../pages/global/sidebar/sidebarContext";
import DataIngestion from '../pages/DataIngestion/index';
import { useAuthContext } from '../providers/AuthProvider';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
.login-container{
   background: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
   height:100vh;

  .login-box {    
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    background-color: #fdfdfd;
    padding: 15px;
    border-radius: 12px;
  }

  .login-grid-item{
     z-index:1;
  }
}

.wave{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
`;


const SidebarContext = createContext({});
const Login = () => {
    const [theme, colorMode] = useMode();

    const navigate = useNavigate();

    const colors = tokens(theme.palette.mode);

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState('');


    const { getUser, login, logout } = useAuthContext();


    const user = getUser()

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            // const token = authService.getToken({
            //     username,
            //     password
            // });

            // setToken('test123');
            navigate('/');
        } catch (error) {
            navigate('/login');
            console.log(error);
            setError('Invalid Username or Password')
        }
    }

    return (
        <>
                <GlobalStyle />
                <Grid
                    container
                    direction="column"
                    justifyContent="start"
                    alignItems="center"
                    sx={{ height: "100%" }}
                    className="login-container"
                >
                    <Grid item className="login-grid-item">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                "& .avater-image": {
                                    backgroundColor: colors.primary[500],
                                },
                            }}
                        >
                            <img

                                alt="profile user"
                                width="400px"
                                height="100%"
                                src={"../../assets/symphonyai-media.png"}

                            />
                        </Box>
                    </Grid>
                    <Grid item className="login-grid-item">
                        <Box className="login-box">
                            <ColorModeContext.Provider value={colorMode}>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />
                                    <Box sx={{ textAlign: 'center', color: "#9ba0a3" }}>
                                        <h1>Login</h1>
                                    </Box>
                                    <form onSubmit={handleSubmit}>
                                        {/* <Box mb="20px">
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Username"
                                                onChange={e => setUserName(e.target.value)}
                                                sx={{ width: "400px" }}
                                            />
                                        </Box>
                                        <Box mb="20px">
                                            <TextField
                                                id="outlined-password-input"
                                                label="Password"
                                                type="password"
                                                autoComplete="current-password"
                                                onChange={e => setPassword(e.target.value)}
                                                sx={{ width: "100%" }}
                                            />
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end">
                                        <Button type="submit" color="secondary" variant="contained" sx={{ width: "100%" }}>Submit</Button>
                                            {error ? <label>{error}</label> : null}
                                        </Box>
                                        <br />
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Link href="#">Forget username or Password?</Link>
                                        </Box> */}

                                        {user ? (
                                                 <div>
                                                   <p>Welcome, {user.name}</p>
                                                   <button onClick={() => logout()}>Logout</button>
                                                 </div>
                                               ) : (
                                                  <Box display="flex" justifyContent="flex-end" mt="10px">
                                                             <Button color="primary" variant="contained" onClick={ () => {
                                                                 login(); 
                                                                // navigate('/');
                                                             }}>Azure AD login</Button>
                                                    </Box>
                                              )}
              

                                    </form>
                                </ThemeProvider>
                            </ColorModeContext.Provider>
                        </Box>
                    </Grid>
            </Grid>
            <Box className="wave">
                <img alt="wave" src={"../../assets/Wave.svg"}  />
            </Box>
            
        </>
        
    )
}

export default Login;