import React from "react";
import { Box, Stack, Button, TextField, useTheme, Typography, IconButton } from '@mui/material'
import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Modal from '@mui/material/Modal';

import "./styles.css";

import FileTypeDataService from "../../service/FileTypeDataService";
import FileTypeStageService from "../../service/FileTypeStageService";
import ClientDataService from "../../service/ClientDataService";
import ClientFileSchemaDataService from "../../service/ClientFileSchemaService";
import FileSchemaDataService from "../../service/FileSchemaService";
import * as _ from 'lodash';
import FormControl from '@mui/material/FormControl';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import RightContainer from "../global/RightContainer";
import ContentDialog from '../../components/common/contentDialog';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const FileTypeManagement = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [fileTypes, setFileTypes] = React.useState([]);
    const [fileTypeNameSearch, setFileTypeNameSearch] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [canDelete, setCanDelete] = React.useState(false);
    const forceUpdate = React.useReducer(bool => !bool)[1];
    const [isShowCreateModal, setIsShowCreateModal] = React.useState(false);
    const [ingestionSchemasModal, setIngestionSchemasModal] = React.useState({
        show:false
    });
    const [affiliateClientModal, setAffiliateClientModal] = React.useState({
        title: "",
        data: [],
        disabled:false,
        show: false,
        selectedType: "",
        searchText:null
    });

    const [clients, setClients] = React.useState([]);
    const [affiliates, setAffiliates] = React.useState([]);
    
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertDto, setAlertDto] = React.useState({
        title: "",
        message:""
    });
    const [fileTypeStages, setFileTypeStages] = React.useState([]);
    const [fileTypeDto, setFileTypeDto] = React.useState({
        filetypekey: 0,
        filedesc: null,
        filetypestagekey: 0,
        activeflag: true,
        affiliates:[],
        clients: [],
        clientFileSchemas:[]
    });

    const [previewSchema, setPreviewSchema] = React.useState({
        title: "",
        show: false,
        data:[]
    });

    const [auditModelSchemas, setAuditModelSchemas] = React.useState({
        title: "",
        isDisable:false,
        show: false,
        data: []
    });

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleAlertOpen = () => {
        setAlertOpen(true);
    };
    const handleAlertClose = () => {
        setAlertOpen(false);
    };


    const handldeFileTypeNameChange = (event) => {
        setFileTypeNameSearch(event.target.value)
    }

    const handldeFileTypeSearchClick = (event) => {
        FileTypeDataService.getFileTypeByName(fileTypeNameSearch).then(res => {
            return res.data;
        }).then(processFlows => {
            setFileTypes(processFlows);
        }).catch(e => {
            console.log(e)
        });
    }



    const handleFlieTypeRowClick = () => { }

    const loadDatas = () => {
	    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

        ClientDataService.getClients(userKey).then((res) => {
            let items = res.data;
            items.map(x => {
                x.checked = false;
                return x;
            });
            setClients(items);
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getAffiliates(userKey).then((res) => {
            let items = res.data;
            items.map(x => {
                x.checked = false;
                return x;
            });
            setAffiliates(items);
        }).catch(e => {
            console.log(e)
        });
    }

    const loadFileTypes = () => {
        FileTypeDataService.getAll().then(res => {
            return res.data;
        }).then(processFlows => {
            setFileTypes(processFlows);
        }).catch(e => {
            console.log(e)
        });
    }

    const loadFileTypeStages = () => {
        FileTypeStageService.getAll().then(res => {
            return res.data;
        }).then(data => {
            setFileTypeStages(data);
        }).catch(e => {
            console.log(e)
        });
    }

    React.useEffect(() => {
        loadFileTypes();
        loadFileTypeStages();
        loadDatas();
    }, []);

    const onDeleteFileTypeNameChange = (val) => {
        console.log(val, selectedRow, selectedRow.filedesc == val);

        var _candelete = false;
        if (val && selectedRow && selectedRow.filedesc) {
            if (val.toLowerCase() == selectedRow.filedesc.toLowerCase()) {
                _candelete = true;
            }
        }
        setCanDelete(_candelete);
    }

    const showDeleteFile = (params) => {
        setSelectedRow(params.row);
        setCanDelete(false);
        handleOpen();
    }

    const deleteFile = () => {
        FileTypeDataService.deleteFileType(selectedRow.filetypekey).then(res => {
            return res.data;
        }).then(data => {
            handleClose();
            handleAlertOpen();
            if (data) {
                loadFileTypes();
                setAlertDto({ ...alertDto, title: "Delete successfully", message: "File type deleted successfully." });
            }
            else {
                setAlertDto({ ...alertDto, title: "Warning!", message: "File Type can not be edited if files are linked to this File Type. Please delete all related files before editing the File Type." });
            }
            
        }).catch(e => {
            console.log(e)
        });
    }


    const fileType_column = [
        {
            field: "filetypekey",
            headerName: "File Type Key",
            width: 200
        },
        {
            field: "filedesc",
            headerName: "File Type Name",
            width: 200
        },
        {
            field: "activeflag",
            headerName: "Active",
            width: 200,
            renderCell: (params) => <div><Checkbox checked={params.row.activeflag} /></div>
        },
        {
            field: "modify",
            headerName: "Modify",
            width: 200,
            renderCell: (params) => <div>
                <Button color="primary" variant="contained" sx={{ width: '100px' }} onClick={() => { showEditFileType(params) }}>
                    Modify
            </Button></div>
        },
        {
            field: "delete",
            headerName: "Delete",
            width: 200,
            renderCell: (params) => <div>
                <Button color="primary" variant="contained" sx={{ width: '100px' }} onClick={() => { showDeleteFile(params) } }>
                    Delete
            </Button></div>
        }
    ]

    const onCreactNewFileType = () => {
        setIsShowCreateModal(true);
        setFileTypeDto({
            filetypekey: 0,
            filedesc: null,
            filetypestagekey: 0,
            activeflag: true,
            affiliates: [],
            clients: [],
            clientFileSchemas:[]
        });
        closeIngestionSchemasModal();
    }

    const createFile = () => {

        FileTypeDataService.create(fileTypeDto).then(res => {
            setAlertDto({ ...alertDto, title: "Saved successfully!", message: "The file type has been successfully saved" });
            handleAlertOpen();
            loadFileTypes();
            closeCreateFileTypeModal();
        }).catch(e => {
            if (e.response.data.status == 500) {
                setAlertDto({ ...alertDto, title: "Warning!", message: e.response.data.detail });
                handleAlertOpen();
            }
        });
    }

    const closeCreateFileTypeModal = () => {
        setIsShowCreateModal(false);
        setFileTypeDto({
            ...fileTypeDto,
            filetypekey: 0,
            filedesc: null,
            filetypestagekey: 0,
            activeflag: true,
            affiliates: [],
            clients: [],
            clientFileSchemas: []
        });
        setAuditModelSchemas({
            ...auditModelSchemas,
            title: "",
            isDisable: false,
            show: false,
            data: []
        });
    }

    React.useEffect(() => {
        if (fileTypeDto.filetypekey > 0) {
            let text = fileTypeStages.filter(x => x.filetypestagekey == fileTypeDto.filetypestagekey)[0]?.filetypestagedesc || "";
            showRightContent(text, true);
        }

    }, [fileTypeDto.filetypekey]);

    const showEditFileType = (params) => {
        setIsShowCreateModal(true);
        console.log(params.row)
        setFileTypeDto(params.row);
    }

    const onFileTypeChange = (event) => {
        setFileTypeDto({ ...fileTypeDto, filetypestagekey: event.target.value });
        let text = fileTypeStages.filter(x => x.filetypestagekey == event.target.value)[0]?.filetypestagedesc || "";
        showRightContent(text);
    }

    const showRightContent = (text,init) => {
        if (text == "Ingestion") {
            showIngestionSchemasModal();
        }
        else {
            closeIngestionSchemasModal();
        }

        if ((text == "Conformed" || text == "Curated" || text == "Final")) {
            showAuditModelSchemasModal(init);
        }
        else {
            setAuditModelSchemas({ ...auditModelSchemas, show: false });
        }
    }

    const onAffiliateClientAssigned = () => {
        let selectedData = affiliateClientModal.data.filter(x => x.checked).map(x=>x.clientKey);
        if (affiliateClientModal.selectedType == "Clients") {
            setFileTypeDto({ ...fileTypeDto, clients: selectedData });
        }
        else {
            setFileTypeDto({ ...fileTypeDto, affiliates: selectedData });
        }
        closeAffiliateClientModal();
    }

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    const showAffiliateClientModal = (type) => {
        let data = [];
        let selectedIds = [];
        if (type === "Clients") {
            data = deepCopy(clients);
            selectedIds = fileTypeDto.clients;
        } else {
            data = deepCopy(affiliates);
            selectedIds = fileTypeDto.affiliates;
        }
        console.log(selectedIds)
        if (selectedIds.length > 0) {
           data = data.map(item => ({ ...item, checked: selectedIds.includes(item.clientKey) }));
        }

        setAffiliateClientModal({ ...affiliateClientModal, searchText:"",show: true, selectedType: type, title: type, data: data });
    }

    const closeAffiliateClientModal = () => {
        setAffiliateClientModal({ ...affiliateClientModal, show: false, selectedType:null });
    }

    const onSelectAll = () => {
        let newAffiliateClientModal = affiliateClientModal;

        newAffiliateClientModal.data.forEach(x => {
            x.checked = true;
        });
        setAffiliateClientModal(newAffiliateClientModal);
        forceUpdate();
    }

    const onDeselectAll = () => {
        let newAffiliateClientModal = affiliateClientModal;

        newAffiliateClientModal.data.forEach(x => {
            x.checked = false;
        });
        setAffiliateClientModal(newAffiliateClientModal);
        forceUpdate();
    }

    const onAffiliateClientItemChecked = (item) => {

        let newAffiliateClientModal = affiliateClientModal;

        newAffiliateClientModal.data.forEach(x => {
            if (item.clientKey == x.clientKey) {
                x.checked = !x.checked;
            }
        });
        setAffiliateClientModal(newAffiliateClientModal);
        forceUpdate();
    }

    const showIngestionSchemasModal = () => {
        setIngestionSchemasModal({ ...ingestionSchemasModal, show: true });
    }

    const closeIngestionSchemasModal = () => {
        setIngestionSchemasModal({ ...ingestionSchemasModal, show: false });
    }

    const onDeleteClientFileSchema = (item, index) => {
        ClientFileSchemaDataService.delete(item.clientfileschemakey).then(res => {
            return res.data;
        }).then(data => {
            handleClose();
            handleAlertOpen();
            if (data) {
                let _fileTypeDto = fileTypeDto;
                _fileTypeDto.clientFileSchemas.splice(index, 1);
                setFileTypeDto(_fileTypeDto);
                loadFileTypes();
                setAlertDto({ ...alertDto, title: "Delete successfully", message: "Client file schema deleted successfully." });
            }
            else {
                setAlertDto({ ...alertDto, title: "Warning!", message: "File Schema can not be deleted if files have been processed under this schema. Please delete all related files before deleting the schema." });
            }

        }).catch(e => {
            console.log(e)
        });
    }

    const getPreviewSchemas = (item) => {
        FileSchemaDataService.getFileSchemaByClientFileSchemaKey(item.clientfileschemakey).then(res => {
            return res.data;
        }).then(data => {
            console.log(data);
            setPreviewSchema({ ...previewSchema, show: true, title: item.clientfiletypedesc, data: data });
        });
    }

    const closePreviewSchemaModal = () => {
        loadFileTypes();
        setPreviewSchema({ ...previewSchema, show: false });
    }

    const showAuditModelSchemasModal = (init) => {
        if (init || auditModelSchemas.data.length == 0) {
            getAuditModelSchemaByFileType()
        }
        else {
            setAuditModelSchemas({ ...auditModelSchemas, show: true });
        }
       
    }

    const getAuditModelSchemaByFileType = () => {
        if (fileTypeDto.filetypekey == 0) return;
        FileTypeDataService.getFileTypeDataModel(fileTypeDto.filetypekey).then(res => {
            return res.data;
        }).then(data => {
            console.log(data);
            setAuditModelSchemas({ ...auditModelSchemas, show: true, isDisable: data.isDisable, data: data.auditModelSchemas });
        });
    }

    const addNewAuditModelSchema = () => {
        let _auditModelSchemas = auditModelSchemas;
        _auditModelSchemas.data.unshift({ auditmodelschemakey: 0, filetypekey: fileTypeDto.filetypekey , fieldname: "", fieldformatdesckey:0 ,error:false});
        setAuditModelSchemas(_auditModelSchemas);
        forceUpdate();
    }

    const  hasDuplicates = (arr)=> {
        for (let i = 0; i < arr.length; i++) {
            const currentObj = arr[i];
            const duplicate = arr.slice(i + 1).find(obj => obj.fieldname === currentObj.fieldname && obj.fieldformatdesckey === currentObj.fieldformatdesckey);
            if (duplicate) {
                return true;
            }
        }
        return false;
    }

    const saveAuditModelSchemaModal = () => {
        console.log(auditModelSchemas.data)
        let error = false;

        let _auditModelSchemas = auditModelSchemas;
        _auditModelSchemas.data.forEach(x => {
            x.error = false;
            if (!x.fieldname || x.fieldformatdesckey <= 0) {
                x.error = true;
                error = true;
            }
        });
       
        if (error) {
            setAuditModelSchemas(_auditModelSchemas);
            forceUpdate();
            setAlertDto({ ...alertDto, title: "Warning!", message: "Proposed Column and Data Type fields cannot be empty." });
            setAlertOpen(true);
            return;
        }
        let data = auditModelSchemas.data.map(x => {
            var item = { fieldname : x.fieldname, fieldformatdesckey : x.fieldformatdesckey };
            return item;
        });
        
        if (hasDuplicates(data)) {
            setAlertDto({ ...alertDto, title: "Warning!", message: "Cannot add duplicate entries." });
            setAlertOpen(true);
            return;
        }
       
        FileTypeDataService.updateAuditModelSchema(auditModelSchemas.data).then(res => {
            return res.data;
        }).then(data => {

            setAlertDto({ ...alertDto, title: "Saved successfully", message: "Data type schema saved successfully." });
            setAlertOpen(true);
            getAuditModelSchemaByFileType();
        });
    }

    const deleteAuditModelSchemaItem = (item,index) => {
        if (window.confirm('Are you sure you want to delete "' + item.fieldname + '" item?')) {
            let _auditModelSchemas = auditModelSchemas;
            if (_auditModelSchemas.data[index].auditmodelschemakey <= 0) {
                _auditModelSchemas.data.splice(index, 1);
            }
            else {
                _auditModelSchemas.data[index].deleted = true;
            }
            
            setAuditModelSchemas(_auditModelSchemas);
            forceUpdate();
        }
    }

    return (
        <Box>
            <RightContainer title="File Type Management ">
                <Box>
                    <Grid item xs={8} md={9}>
                        <Stack direction='row' spacing={5}>
                            <Box>
                                 <FormControl sx={{ width: 280 }}>
                                    <TextField
                                        name="File Type"
                                        type="text"
                                        id="FileTypeName"
                                        value={fileTypeNameSearch}
                                        onChange={handldeFileTypeNameChange}
                                        label="File Type"
                                        size="medium"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        />
                                        </FormControl>
                            </Box>

                           <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button  color="secondary" variant="contained" sx={{ width: '100px' }}>
                                    Reset
                                </Button>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color="primary" variant="contained" onClick={handldeFileTypeSearchClick}  sx={{ width: '100px' }}>
                                    Search
                                </Button>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color="primary" variant="contained" sx={{ width: '100px' }} onClick={() => { onCreactNewFileType() } }>
                                    Create New File Type
                                </Button>
                          </Box>

                        </Stack>
                      </Grid>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            onRowClick={handleFlieTypeRowClick}
                            getRowId={(row) => row.filetypekey}
                            rows={fileTypes}
                            pageSize={10}
                            columns={fileType_column}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </Box>
                <Modal
                    open={open}
                    onClose={(event, reason) => {
                        if (reason === 'backdropClick') {
                            return;
                        }
                        handleClose();
                    }}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...style, width: 400 }}>
                        <h2 id="parent-modal-title">Confirm Delete File Type</h2>
                        <Box>
                            <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="File Type Name"
                                onChange={(event) => { onDeleteFileTypeNameChange(event.target.value) }}
                            />
                        </Box>

                        <Box m="20px 0 0 0" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" sx={{ backgroundColor: "red", marginLeft: "10px" }} disabled={!canDelete} onClick={deleteFile}>Delete</Button>
                        </Box>
                        
                    </Box>
                </Modal>
                <Modal
                    open={alertOpen}
                    onClose={handleAlertClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...style, width: 400 }}>
                        <h2 id="parent-modal-title">{alertDto.title}</h2>
                        <p id="parent-modal-description">
                            { alertDto.message}
                        </p>
                        <Box m="20px 0 0 0" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="outlined" onClick={handleAlertClose}>Close</Button>
                        </Box>

                    </Box>
                </Modal>
                <ContentDialog open={isShowCreateModal} maxWidth={"lg"} title='Create File Type'
                    disabled={!fileTypeDto.filedesc || !fileTypeDto.filetypestagekey}
                    onConfirm={() => createFile()} close={() => closeCreateFileTypeModal()}>
                    <Grid container spacing={ 2 }>
                       {/*left*/}
                        <Grid item xs={5}>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h6" gutterBottom>
                                        File Type name
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="File Type Name"
                                        value={fileTypeDto.filedesc}
                                        onChange={(event) => { setFileTypeDto({ ...fileTypeDto, filedesc: event.target.value }) }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Active
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Checkbox
                                        value={fileTypeDto.activeflag}
                                        onChange={(event) => { setFileTypeDto({ ...fileTypeDto, activeflag: event.target.checked }) }}
                                        defaultChecked />
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Data Type
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl sx={{ m: 1, width: "100%" }}>
                                        <Select
                                            value={fileTypeDto.filetypestagekey}
                                            onChange={(event) => { onFileTypeChange(event) }}
                                            autoWidth
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {
                                                fileTypeStages.map((item, index) => (
                                                    <MenuItem key={'filetypestage' + index} value={item.filetypestagekey}>{item.filetypestagedesc}</MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Affiliate
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button color="secondary" variant="contained" sx={{ width: "160px" }} onClick={() => { showAffiliateClientModal('Affiliates') }}>Modify</Button>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Clients
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Button color="secondary" variant="contained" sx={{ width: "160px" }} onClick={() => { showAffiliateClientModal('Clients') }}>Modify</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* right*/}
                        {
                            ingestionSchemasModal.show &&
                            <>
                                <Grid item xs={6} sx={{ borderLeft: "1px solid #c4c4c4", marginLeft: "20px", paddingRight: "10px" }}>
                                    <Box sx={{ textAlign: "center" }}>Ingestion Schemas</Box>
                                    <Box sx={{ marginTop: "10px" }}>
                                        <List
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                overflow: 'auto',
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            {fileTypeDto.clientFileSchemas.map((item, index) => (
                                                <li key={`section-${index}`}>
                                                    <ul>
                                                        <ListItem key={`item-${index}`}
                                                            sx={{
                                                                border: "1px solid #c4c4c4",
                                                                ':firsh-child': { borderBottom: "0px" }
                                                            }}

                                                            secondaryAction={
                                                                <>
                                                                    <Button color="secondary" variant="contained" sx={{ width: "100px", marginRight: "10px" }} onClick={() => { getPreviewSchemas(item) }}>Preview</Button>
                                                                    <Button color="secondary" variant="contained" sx={{ width: "100px" }} onClick={() => { onDeleteClientFileSchema(item,index) }}>Delete</Button>
                                                                </>
                                                            }
                                                        >

                                                            <ListItemText primary={item.clientfiletypedesc || ''} />
                                                        </ListItem>
                                                    </ul>
                                                </li>
                                            ))}
                                        </List>
                                    </Box>
                                </Grid>
                            </>
                        }

                        {
                            auditModelSchemas.show &&
                            <>
                                <Grid item xs={6} sx={{ borderLeft: "1px solid #c4c4c4",marginLeft:"20px", paddingRight: "10px" }}>
                                    <Grid container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <IconButton aria-label="add"
                                                style={{
                                                    border: "1px solid #c4c4c4",
                                                    borderRadius: "0",
                                                    color: "red",
                                                    backgroundColor: "white",
                                                    padding: "4px"
                                                }}
                                                onClick={() => { addNewAuditModelSchema() }}>
                                                <AddIcon></AddIcon>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Button color="secondary" variant="contained" sx={{ width: "160px" }} disabled={auditModelSchemas.isDisabled} onClick={() => { saveAuditModelSchemaModal() }}>Save</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={5} sx={{ padding: "8px" }}>Proposed Column</Grid>
                                        <Grid item xs={5} sx={{ padding: "8px" }}>Data Type</Grid>
                                        <Grid item xs={2} sx={{ padding: "8px" }}></Grid>
                                        {
                                            auditModelSchemas.data.map((item, index) => {
                                                return (
                                                    !item.deleted && <>
                                                        <Grid item xs={5} sx={{ borderTop: "1px solid #c4c4c4", padding: "8px" }}>
                                                            <TextField
                                                                fullWidth
                                                                size="small" 
                                                                required
                                                                id="outlined-required"
                                                                value={item.fieldname}
                                                                onChange={(event) => {
                                                                    item.fieldname = event.target.value;
                                                                    forceUpdate();
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{ borderTop: "1px solid #c4c4c4", padding: "8px" }}>
                                                            <FormControl fullWidth size="small" >
                                                                <Select
                                                                    value={item.fieldformatdesckey}
                                                                    onChange={(event) => {
                                                                        item.fieldformatdesckey = event.target.value;
                                                                        forceUpdate();
                                                                    }}
                                                                    disabled={auditModelSchemas.isDisabled}
                                                                >
                                                                    <MenuItem value={1}>String</MenuItem>
                                                                    <MenuItem value={2}>Long</MenuItem>
                                                                    <MenuItem value={3}>Double</MenuItem>
                                                                    <MenuItem value={4}>Timestamp</MenuItem>
                                                                    <MenuItem value={5}>varchar </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2} sx={{ borderTop: "1px solid #c4c4c4", padding: "10px" }}>
                                                            {
                                                                !auditModelSchemas.isDisabled &&
                                                                <IconButton aria-label="delete"
                                                                    style={{
                                                                        border: "1px solid #c4c4c4",
                                                                        borderRadius: "0",
                                                                        color: "red",
                                                                        backgroundColor: "white",
                                                                        padding: "4px"
                                                                    }}
                                                                    onClick={() => { deleteAuditModelSchemaItem(item, index) }}>
                                                                    <CloseIcon></CloseIcon>
                                                                </IconButton>
                                                            }
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        }
                       
                    </Grid>

                    
                </ContentDialog>

                <ContentDialog open={previewSchema.show} maxWidth={"sm"} title={previewSchema.title} hideConfirm={true}  close={() => closePreviewSchemaModal()}>
                    <Grid container>
                        <Grid item xs={6} sx={{ padding: "8px" }}>File Schema</Grid>
                        <Grid item xs={6} sx={{ padding: "8px" }}>Data Type</Grid>
                        {
                            previewSchema.data.map((item,index) => {
                                return (
                                    !item.deleted && <>
                                        <Grid item xs={6} sx={{ borderTop: "1px solid #c4c4c4", padding: "15px" }}>{item.fieldname}</Grid>
                                        <Grid item xs={6} sx={{ borderTop: "1px solid #c4c4c4", padding: "8px" }}>
                                            <FormControl fullWidth size="small" >
                                                <Select
                                                    defaultValue={item.fieldformatdesckey}
                                                    disabled
                                                >
                                                    <MenuItem value={1}>String</MenuItem>
                                                    <MenuItem value={2}>Long</MenuItem>
                                                    <MenuItem value={3}>Double</MenuItem>
                                                    <MenuItem value={4}>Timestamp</MenuItem>
                                                    <MenuItem value={5}>varchar </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid>
                </ContentDialog>

                <ContentDialog open={affiliateClientModal.show} maxWidth={"sm"} title={affiliateClientModal.title } confirmName="assign"
                    onConfirm={() => onAffiliateClientAssigned()} close={() => closeAffiliateClientModal()}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button color="secondary" variant="contained" sx={{ width: "160px",marginBottom:"20px" }} onClick={() => { onSelectAll() }}>Select All</Button>
                            <br/>
                            <Button color="secondary" variant="contained" sx={{ width: "160px" }} onClick={() => { onDeselectAll() }}>Deselect All</Button>
                        </Grid>
                        <Grid item xs={8}>
                            <Box sx={{marginBottom:"20px"}}>
                                <FormControl sx={{ width: 280 }}>
                                    <TextField
                                        name="Search"
                                        type="text"
                                        id="SearchOption"
                                        value={affiliateClientModal.searchText}
                                        onChange={(event) => { setAffiliateClientModal({ ...affiliateClientModal,searchText:event.target.value }) } }
                                        label="Search"
                                        size="medium"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {affiliateClientModal.data.map((item) => {
                                        const labelId = `checkbox-list-label-${item.clientKey}`;

                                        return (

                                            (!affiliateClientModal.searchText || item.clientName.toLowerCase().includes(affiliateClientModal.searchText.toLowerCase())) &&
                                            <ListItem
                                                key={item.clientKey}
                                                disablePadding
                                            >
                                                <ListItemButton onClick={() => { onAffiliateClientItemChecked(item) }} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={item.checked}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': item.clientKey }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={'TextItem' + item.clientKey} primary={`${item.clientName}`} />
                                                </ListItemButton>
                                            </ListItem>

                                           
                                        );
                                    })}
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </ContentDialog>
            </RightContainer>
        </Box>
    );
}

export default FileTypeManagement;
