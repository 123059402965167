import React, { useEffect, useState } from 'react';

import { Stack, TextField, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import RightContainer from "../global/RightContainer";
import './RoleManagement.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { permissionService } from '../../ts/modules/permission/service';
import { Permission } from '../../ts/modules/permission/interface';


const PermissionManagement: React.FC = () => {
	const [permissions, setPermissions] = useState<Permission[]>([]);
	const [filteredPermission, setFilteredPermission] = useState<Permission[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isNewPermissionDialogOpen, setIsNewPermissionDialogOpen] = useState<boolean>(false);
	const [newPermissionName, setNewPermissionName] = useState<string>('');
	const [searchText, setSearchText] = useState<string>('');
	const [editRow, setEditRow] = useState<GridRenderCellParams<any, any, any> | undefined>(undefined);
	const [deleteRow, setDeleteRow] = useState<GridRenderCellParams<any, any, any> | undefined>(undefined);
	const [editValue, setEditValue] = useState('');

    // Use useRef to manage input focus

    useEffect(() => {
        const fetchPermissions = async () => {
            setIsLoading(true);
            const data = await permissionService.fetchPermissions();
            setPermissions(data);
            setFilteredPermission(data)
            setIsLoading(false);
        };

        fetchPermissions();
    }, []);


	const handleEditClick = (params: GridRenderCellParams<any, any, any>) => {
		setEditRow(params);
		setEditValue(params.row.roleName);
	};


	const handleDeleteClick = (params: GridRenderCellParams<any, any, any>) => {
		setDeleteRow(params);
	};


	const handleAddNewPermission = async () => {
		if (!newPermissionName.trim()) return;
		setIsLoading(true);
		const newPermission: Omit<Permission, 'rolePermissionKey'> = { rolePermissionDescription: newPermissionName, createDate: new Date() };
		const addedPermission = await permissionService.addPermission(newPermission);
		setPermissions(prevPermissions => [...prevPermissions, addedPermission]);
		setFilteredPermission(prevPermissions => [...prevPermissions, addedPermission]);
		setIsNewPermissionDialogOpen(false);
		setNewPermissionName('');
		setIsLoading(false);
	};

	const handleSearch = () => {
		const lowercasedFilter = searchText.toLowerCase();
		const filteredData = permissions.filter(item => {
			return Object.keys(item).some(() =>
				item.rolePermissionDescription.toLowerCase().includes(lowercasedFilter)
			);
		});
		setFilteredPermission(filteredData);
	};


	const handleEditChange = (event: { target: { name: any; value: any; }; }) => {
		setEditValue(event.target.value);
	}


	const handleCancel = () => {
		setEditRow(undefined);
		setDeleteRow(undefined);
	};


	const handleDelete = async (id: GridRowId) => {
		setIsLoading(true);

		try {
			const deletedPermission = await permissionService.deletePermission(Number(id.toString()));

			console.log("permissionmanagement, handleDelete, deletedPermission", deletedPermission);

			// Update the rows state with the deleted role
			setPermissions(permissions.filter(item => item.rolePermissionKey !== deletedPermission.rolePermissionKey));
			setFilteredPermission(permissions.filter(item => item.rolePermissionKey !== deletedPermission.rolePermissionKey));
		}
		catch (error) {
			console.error("Failed to delete role", error);
		} finally {
			setIsLoading(false);
			setDeleteRow(undefined); // Exit delete mode
		}
	};


	const handleReset = () => {
        setSearchText('');
        setFilteredPermission(permissions);
    };

    // const handleCellEditCommit = async (params: GridCellEditCommitParams) => {
    //     const { id, field, value } = params;

    //     if (field === 'name') {
    //       setIsLoading(true); // Show loading indicator while updating

    //       try {
    //         // Assuming your updatePermission method requires id and the new name
    //         await permissionService.updatePermission(id as number, value.toString());

    //         // Update the local state to reflect the change
    //         setPermissions(prevPermissions => prevPermissions.map(permission => 
    //           permission.id === id ? { ...permission, name: value.toString() } : permission
    //         ));

    //         setIsLoading(false); // Hide loading indicator after update
    //       } catch (error) {
    //         console.error('Failed to update permission name:', error);
    //         setIsLoading(false); // Ensure loading indicator is hidden even on failure
    //       }
    //     }
    //   };

	const handleSave = async (id: GridRowId) => {
		setIsLoading(true);

		//	try {
		//		let roleUpdate: ParticipantRole = {
		//			roleKey: Number(id.toString()),
		//			roleDescription: editValue,
		//			createDate: new Date(),
		//			participantKeys: selected
		//		}
		//		const updatedRole = await rolesService.updateRoleName(Number(id.toString()), { name: editValue });
		//		// Update the rows state with the updated role
		//		setRoles(currentRows => currentRows.map(role => role.roleKey === id ? updatedRole : role));
		//	} catch (error) {
		//		console.error("Failed to update role", error);
		//	} finally {
		//		setIsLoading(false);
		//		setEditRow(undefined); // Exit edit mode
		//	}
	};






	const columns: GridColDef[] = [
		{
			field: 'rolePermissionKey',
			headerName: 'ID',
			width: 90
		},
		{
			field: 'rolePermissionDescription',
			headerName: 'Permission Name',
			width: 200,
			renderCell: (params) => {
				if (params.id === editRow?.id) {
					return (
						<TextField
							value={editValue}
							onChange={handleEditChange}
							autoFocus
							size="small"
							fullWidth
						/>
					);
				}
				return params.value;
			},
			//   renderCell: (params) => {
			//     const isEditing = editRowId === params.id;
			//     return isEditing ? (
			//       <TextField
			//         defaultValue={params.value}
			//         autoFocus
			//         // onBlur={() => handleCellEditCommit(params.id as number, params.formattedValue || '')}
			//         onBlur={(e) => {
			//             // Correctly typing the event target
			//             const target = e.target as HTMLInputElement; // Assuming it's an input element
			//             handleCellEditCommit(params.id as number, target.value);
			//           }}
			//         inputRef={(el) => (inputRefs.current[params.id] = el as Element)}
			//         onKeyDown={(e) => {
			//           if (e.key === 'Enter') {
			//             handleCellEditCommit(params.id as number, e.currentTarget.value);
			//           }
			//         }}
			//       />
			//     ) : (
			//       <div>{params.value}</div>
			//     );
			//   },
		},
		{
			field: 'createDate',
			headerName: 'Created On',
			width: 200,
			valueFormatter: ({ value }) => {
				return value == null ? '' : (value as Date).toLocaleString();
			}
		},
		{
			field: 'actions',
			headerName: 'Actions',
			width: 250,
			renderCell: (params) => (
				<div>
					{editRow?.id === params.id ? (
						<>
							<Button
								variant="contained"
								color="primary"
								size="small"
								onClick={() => handleSave(params.id)}
								style={{ marginRight: 16 }}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								color="secondary"
								size="small"
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</>
					) : (
						deleteRow?.id === params.id ? (
							<>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => handleDelete(params.id)}
									style={{ marginRight: 16 }}
								>
									Confirm?
								</Button>
								<Button
									variant="outlined"
									color="secondary"
									size="small"
									onClick={handleCancel}
								>
									Cancel
								</Button>
							</>
						) : (
							<>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => handleEditClick(params)}
									style={{ marginLeft: 16 }}
								>
									Edit
								</Button>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => handleDeleteClick(params)}
									style={{ marginLeft: 16 }}
								>
									Delete
								</Button>
							</>
						)
					)}
				</div>
			),
		}
        // {
        //   field: 'actions',
        //   headerName: 'Actions',
        //   width: 150,
        //   renderCell: (params) => (
        //     <Button
        //       variant="contained"
        //       color="primary"
        //       size="small"
        //       onClick={() => {
        //         setEditRowId(params.id as number);
        //         // Ensure the TextField gets focus
        //         setTimeout(() => {
        //           inputRefs.current[params.id]?.focus();
        //         }, 100);
        //       }}
        //     >
        //       Edit
        //     </Button>
        //   ),
        // },
      ];



    return (

        <Stack>
            <RightContainer title="Permission Management">

                <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <TextField
                        label="Search Permission"
                        variant="outlined"
                        value={searchText}
                        autoComplete="off"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: '50%', height: '100%' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                        Search
                    </Button>
                    <Button variant="outlined" onClick={handleReset}>
                        Reset
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsNewPermissionDialogOpen(true)}
                        style={{ marginLeft: 'auto', order: 3 }}
                    >
                        Add New Permission
                    </Button>
                </div>


                {/* <Paper style={{ padding: 20, margin: 20 }}> */}
                <div style={{ height: 400, width: '100%' }}>
                    <Dialog open={isNewPermissionDialogOpen} onClose={() => setIsNewPermissionDialogOpen(false)}>
                        <DialogTitle>Add New Permission</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Permission Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={newPermissionName}
                                onChange={(e) => setNewPermissionName(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsNewPermissionDialogOpen(false)}>Cancel</Button>
                            <Button onClick={handleAddNewPermission} disabled={isLoading}>Add</Button>
                        </DialogActions>
                    </Dialog>

                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <DataGrid
                            // item={true}
                            // xs={12} sm={12} lg={12} md={12} 
                            rows={filteredPermission}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection={false}
                            rowsPerPageOptions={[5]}
							    disableSelectionOnClick
							    getRowId={(row) => row.rolePermissionKey}
                            // onCellEditCommit={handleCellEditCommit}
                            // processRowUpdate={async (newRow) => {
                            //     await handleUpdatePermission(newRow.id, newRow.name);
                            //     return newRow;
                            // }}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    )}
                </div>
                {/* </Paper> */}
            </RightContainer>
        </Stack>
    );
};



export { PermissionManagement } 