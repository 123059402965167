import { Permission } from "./interface";
import { getConfiguration } from '../../../config.js';

class PermissionService {
	private apiUrl: string = getConfiguration().secApiUrl + '/Permission';
	private localStorageKey: string = 'permissions';

	private mockPermissions: Permission[] = [
		{ rolePermissionKey: 1, rolePermissionDescription: 'Create User', createDate: new Date() },
		{ rolePermissionKey: 2, rolePermissionDescription: 'Edit User', createDate: new Date() },
		{ rolePermissionKey: 3, rolePermissionDescription: 'Delete User', createDate: new Date() },
	];

	public async fetchPermissions(): Promise<Permission[]> {
		return new Promise<Permission[]>((resolve) => {
			try {
				const getUrl = this.apiUrl;

				//const permissionsJson = localStorage.getItem(this.localStorageKey);

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
//							let permissions: Permission[] = permissionsJson ? JSON.parse(permissionsJson) : "";

							resolve(response.json()); //resolve(permissions); 

							//localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
		});

	//	return new Promise<Permission[]>((resolve) => {
	//		setTimeout(() => {
	//			try {
	//				const permissionsJson = localStorage.getItem(this.localStorageKey);
	//				let permissions: Permission[] = permissionsJson ? JSON.parse(permissionsJson) : this.mockPermissions;
	//				localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
	//				resolve(permissions);
	//			} catch (error) {
	//				console.error('Error fetching permissions:', error);
	//				resolve(this.mockPermissions); // Fallback to mock data in case of error
	//			}
	//		}, 1000); // Simulated delay of 1 second
	//	});
	}

	/*
	  fetch('/', {
   method: 'post',
   headers: {'Content-Type':'application/json'},
   body: {
    "first_name": this.firstName.value
   }
  });
	*/
	public async addPermission(permission: Omit<Permission, 'rolePermissionKey'>): Promise<Permission> {
		return new Promise<Permission>((resolve) => {
			try {
				const getUrl = this.apiUrl;
				let body = permission
				let hdrs = new Headers({ 'Content-Type': 'application/json' } )

				let params: RequestInit = {
					headers: hdrs,
					method: "POST",
					body: JSON.stringify(body)
				}

				fetch(getUrl, params)
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							//							let permissions: Permission[] = permissionsJson ? JSON.parse(permissionsJson) : "";

							resolve(response.json()); //resolve(permissions); 

							//localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
		//return new Promise((resolve) => {
		//	const permissions: Permission[] = JSON.parse(localStorage.getItem('permissions') || '[]');
		//	const newPermission: Permission = { ...permission, rolePermissionKey: Date.now() }; // Use timestamp as mock ID
		//	const updatedPermissions = [...permissions, newPermission];
		//	localStorage.setItem(this.localStorageKey, JSON.stringify(updatedPermissions));
		//	setTimeout(() => resolve(newPermission), 500); 
		});
	}


	public async deletePermission(permissionKey: number): Promise<Permission> {
		return new Promise<Permission>((resolve) => {
			try {
				const delUrl = this.apiUrl + '/' + permissionKey;
				let hdrs = new Headers({ 'Content-Type': 'application/json' })

				let params: RequestInit = {
					headers: hdrs,
					method: "DELETE",
					body: ""
				}

				fetch(delUrl, params)
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							resolve(response.json()); //resolve(permissions); 
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
			//return new Promise((resolve) => {
			//	const permissions: Permission[] = JSON.parse(localStorage.getItem('permissions') || '[]');
			//	const newPermission: Permission = { ...permission, rolePermissionKey: Date.now() }; // Use timestamp as mock ID
			//	const updatedPermissions = [...permissions, newPermission];
			//	localStorage.setItem(this.localStorageKey, JSON.stringify(updatedPermissions));
			//	setTimeout(() => resolve(newPermission), 500); 
		});
	}


	public async updatePermission(id: number, name: string): Promise<void> {
		return new Promise((resolve, reject) => {
			let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions') || '[]');
			const index = permissions.findIndex(p => p.rolePermissionKey === id);
			if (index === -1) {
				return reject(new Error("Permission not found"));
			}

			permissions[index] = { ...permissions[index], rolePermissionDescription: name };
			localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
			setTimeout(resolve, 500);
		});
	}

}

export const permissionService = new PermissionService();