import React, { useState } from 'react'
import { Box, Button, TextField, Stack, Divider, Autocomplete, Tabs, Tab, Typography, useMediaQuery, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import ClientDataService from "../../service/ClientDataService"
import DataLakeService from "../../service/DataLakeService"
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RightContainer from "../global/RightContainer"; // Make sure this path is correct
import AuditClientDataService from "../../service/AuditClientDataService";
import DataGridSample from '../dataOverview/outputMapping';
import ProcessFlowGrid from '../dataOverview/outputMapping';


function yyyymmdd(date) {
    var x = new Date(date);
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}

const ContractIngestion = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [warning, setWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const handleClose = () => {
        setWarning(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const [uploading, setUploading] = useState(false);
    const [affiliateOptions, setAffiliateOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [messages, setMessages] = useState([]);
    const [auditTypeSearch, setAuditTypeSearch] = React.useState();
    const [auditTypes, setAuditTypes] = React.useState([]);

    React.useEffect(() => {
        const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

        ClientDataService.getAffiliates(userKey).then((res) => {
            return res.data;
        }).then(affiliates => {
            setAffiliateOptions(affiliates);
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getAll(userKey).then((res) => {
            return res.data;
        }).then(clients => {
            setClientOptions(clients);
        }).catch(e => {
            console.log(e)
        });

        AuditClientDataService.getAuditTypes().then((res) => {
            setAuditTypes(res.data);
        }).catch(e => {
            console.log(e)
        });

    }, []);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    function validateFileType(filename) {
        var parts = filename.split('.');
        var ext = parts[parts.length - 1];
        switch (ext.toLowerCase()) {
            case 'pdf':
                return true;
        }
        return false;
    }

    const handleFormSubmit = (values) => {
        if (!values.fileSelected || !values.client || !values.affiliate || !values.startDate || !values.endDate || !values.auditType || !values.contractName) {
            setWarning(true);
            setWarningMessage("Please fill out all the required fields: file, client, affiliate, start date, end date, audit type, and contract name");
            return;
        }

        const formData = new FormData();
        var file = values.fileSelected;

        if (validateFileType(file.name)) {

            formData.append("client", values.client.clientKey);
            formData.append("affiliate", values.affiliate.clientKey);
            formData.append("startDate", yyyymmdd(values.startDate));
            formData.append("endDate", yyyymmdd(values.endDate));
            formData.append("auditTypeKey", values.auditType.audittypekey);
            formData.append("contractName", values.contractName);

            formData.append(file.name, file);
        } else {
            setWarning(true);
            setWarningMessage("Only pdf files are accepted format !!!")
            return;
        }

        try {
            setUploading(true);
            DataLakeService.uploadContractConfigFile(formData)
                .then((res) => {
                    setUploading(false);
                    setMessages([...messages, "Upload File Successfully"]);
                }).catch((err) => {
                    setMessages([...messages, "Upload Failed: " + err]);
                    console.log(err);
                    setUploading(false);
                })
        } catch (err) {
            console.log(err);
            setUploading(false);
        }
    };

    const initialValues = {
        client: null,
        affiliate: null,
        fileSelected: null,
        fileType: null,
        startDate: '',
        endDate: '',
        auditType: '',
        contractName: ''
    };

    const checkoutSchema = yup.object().shape({
        fileSelected: yup.string().required('Required').min(10, "Please choose a file"),
        startDate: yup.date().required('Required'),
        endDate: yup.date().required('Required').min(yup.ref('startDate'), "End date must be after start date"),
        auditType: yup.string().required('Required'),
        contractName: yup.string().required('Required')
    });

    const handleAuditTypeSearchChange = (event) => {
        setAuditTypeSearch(event.target.value);
    };

    const Form = (props) => {
        return (
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}
            // validationSchema={checkoutSchema}
            >
                {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}>

                            <Stack>
                                <Typography
                                    variant="h3"
                                    sx={{ marginBottom: "10px" ,marginTop:"20px"}}
                                >Upload Files Locally</Typography>

                                <Autocomplete
                                    size="small"
                                    name="client"
                                    disablePortal
                                    id="client"
                                    value={values.client}
                                    getOptionLabel={(option) => option.qbclientkey + " - " + option.clientName}
                                    options={clientOptions}
                                    onChange={(event, value) => {
                                        setFieldValue("client", value !== null ? value : initialValues.client);
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Client" />}
                                />
                                <br />
                                <Autocomplete
                                    size="small"
                                    name="affiliate"
                                    disablePortal
                                    id="affiliate"
                                    value={values.affiliate}
                                    getOptionLabel={(option) => option.qbclientkey + " - " + option.clientName}
                                    options={affiliateOptions}
                                    onChange={(event, value) => {
                                        setFieldValue("affiliate", value !== null ? value : initialValues.affiliate);
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Affiliate" />}
                                />
                                <br />
                                <TextField
                                    type="date"
                                    name="startDate"
                                    label="Start Date"
                                    onChange={handleChange}
                                    value={values.startDate}
                                    error={touched.startDate && Boolean(errors.startDate)}
                                    helperText={touched.startDate && errors.startDate}
                                    size="small"
                                    sx={{ width: 300 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <br />
                                <TextField
                                    type="date"
                                    name="endDate"
                                    label="End Date"
                                    onChange={handleChange}
                                    value={values.endDate}
                                    error={touched.endDate && Boolean(errors.endDate)}
                                    helperText={touched.endDate && errors.endDate}
                                    size="small"
                                    sx={{ width: 300 }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <br />
                                <FormControl sx={{ width: 280 }}>
                                    <InputLabel htmlFor="name-shared">Choose Audit Type</InputLabel>
                                    <Select
                                        label="AuditType"
                                        value={values.auditType || ''}
                                        onChange={(event) => {
                                            console.log(values);
                                            setFieldValue("auditType", event.target.value);
                                        }}
                                    >
                                        <MenuItem value={null}>NONE</MenuItem>
                                        {auditTypes.map(type => (
                                            <MenuItem key={type.audittypekey} value={type}>
                                                {type.audittypedesc}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <TextField
                                    type="text"
                                    name="contractName"
                                    label="Contract Name"
                                    onChange={handleChange}
                                    value={values.contractName}
                                    error={touched.contractName && Boolean(errors.contractName)}
                                    helperText={touched.contractName && errors.contractName}
                                    size="small"
                                    sx={{ width: 300 }}
                                />
                                <br />

                                <input id="fileSelected" type="file" name="file"
                                    accept=".pdf"
                                    onChange={(event) => { setFieldValue("fileSelected", event.target.files[0]) }} />

                                {messages &&
                                    <div>
                                        {messages.map((message, index) => (
                                            <li key={index}>{message}</li>
                                        ))}
                                    </div>
                                }
                            </Stack>
                        </Box>
                        <Box justifyContent="end" mt="20px">
                            <LoadingButton type="submit" loading={uploading} color="secondary" variant="contained">
                                <span>Upload</span>
                            </LoadingButton>
                        </Box>
                        <br />
                    </form>
                )}
            </Formik>
        )
    }

    return (
        <Stack>
            <RightContainer title="Contract Ingestion" >
                <div>
                    <Dialog
                        open={warning}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Invalid input !!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {warningMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} autoFocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Box m="20px">
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="contract ingestion tabs">
                        <Tab label="Upload File" />
                    </Tabs>
                    {tabIndex === 0 && (
                        <Box>
                            <Form />
                            <Divider />
                        </Box>
                    )}
                </Box>
            </RightContainer>
        </Stack>
    );
}

export default ContractIngestion;