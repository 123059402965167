import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { Box, ListItemIcon, Stack, ListItemText, Typography } from '@mui/material'

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import Header from "../../Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FileSchemaDataService from "../../../service/FileSchemaService";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';



export const GroupByNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div >*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">GroupBy</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <CloseFullscreenIcon
                    alt="Plus"
                    width="65px"
                    height="65px"
                    sx={{ width:"65px",height:'65px'}}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            {/*<div className="node-content">*/}
            {/*    <Box>*/}
            {/*        <CloseFullscreenIcon sx={{ fontSize: 40 }} />*/}
            {/*    </Box>*/}
            {/*</div>*/}


            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class GroupByModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            name: options?.name || "GroupBy",
            type: "GroupBy"
        });
        this.depth = 0;
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.precendentNodes = new Set();
        this.nodedata = options?.nodedata;

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            name: this.options.name,
            inputFields : this.inputFields,
            outputFields : this.outputFields,
            precendentNodes: Array.from(this.precendentNodes),
            nodedata: this.nodedata,
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.nodedata = event.data.nodedata;
        this.options.name = event.data.name;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }


}

// TODO: Refactor to hooks
export class GroupByNodeFactory extends AbstractReactFactory {
    constructor() {
        super("GroupBy");
    }

    generateModel(initialConfig) {
        return new GroupByModel();
    }

    generateReactWidget(event) {
        return <GroupByNodeWidget engine={this.engine} node={event.model} />;
    }
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export function GroupByComponent(props) {

    const currentComponent = props.currentComponent;

    const engine = props.engine;

    const [selected, setSelected] = React.useState([]);

    const [aggregatedColums, setAggregatedColums] = React.useState([]);

    const [open, setOpen] = React.useState(false);

    const [fileColumns, setFileColumns] = React.useState([]);

    const [nodeName, setNodeName] = React.useState('');

    const aggregatingFunctions = ["SUM", "COUNT", "AVERAGE", "COUNTDISTINCT"]

    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (currentComponent.nodedata && currentComponent.nodedata.selectedColumns) {
            setSelected(currentComponent.nodedata.selectedColumns)
        }

        if (currentComponent.nodedata && currentComponent.nodedata.aggregatingColumns) {
            setAggregatedColums(currentComponent.nodedata.aggregatingColumns)
        }


        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes.length>0) {
            parentsNodes.forEach(parent => {
                if (parent.outputFields) {
                    props.currentComponent.inputFields = parent.outputFields
                }
            })
        }

        if (props.currentComponent.inputFields) {
            setFileColumns(props.currentComponent.inputFields);
        }

    });


    const handleOnOpen = () => {
 
        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.outputFields) {
                    props.currentComponent.inputFields = parent.outputFields
                }
            })
        }
        if (props.currentComponent.inputFields) {
            setFileColumns(props.currentComponent.inputFields);
        }

        setOpen(true)
        console.log("calling handleOnOpen")
    } 

    const handleGroupByChange = (event) => {
        const value = event.target.value;

        if (value[value.length - 1] === "all") {
            setSelected(selected.length === fileColumns.length ? [] : fileColumns.map(columns => columns.FieldName));
            return;
        }
        setSelected(value);

        if (currentComponent.nodedata) {
            currentComponent.nodedata.selectedColumns = event.target.value;
        } else {
            currentComponent.nodedata = { selectedColumns: event.target.value }
        }

        const outputFields = new Array();

        if (currentComponent.nodedata.selectedColumns) {
            currentComponent.nodedata.selectedColumns.forEach(selectedColumn => {
                outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn })
            })
        }

        if (currentComponent.nodedata.aggregatingColumns) {
            currentComponent.nodedata.aggregatingColumns.forEach(aggregatingColumn => {
                if (aggregatingColumn.newName) {
                    outputFields.push({ id: outputFields.length + 1, FieldName: aggregatingColumn.newName })
                }
            });
        }

  
        currentComponent.outputFields = outputFields;

    }

    const handleAddAggregatedColumn = (event) => {
        setAggregatedColums(aggregatedColums => [...aggregatedColums, { newName: '', column: '', groupFunction:'' }])
    };


    const handleDeleteAggregatedColumn = (event, id) => {
        setAggregatedColums(aggregatedColums => aggregatedColums.filter((column, index) => index !== id))
    };


    return (
        <Stack direction="row" spacing={5} width={"50%"}>
        <Box spacing={5} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        autoComplete='off'
                        label="Node Name"
                        value={nodeName}
                        onChange={(event) => {
                            setNodeName(event.target.value)
                            currentComponent.options.name = event.target.value;
                        }}
                    />
                </FormControl>

                <Typography variant="h5" gutterBottom>
                    1. Select group columns
                </Typography>
                <br />

                <Box>
        <FormControl fullWidth>
            <InputLabel id="input-file-select-label">Group By</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="input-file-select-label"
                multiple
                label="Group By"
                value={selected}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => {handleOnOpen()}}
                onChange={handleGroupByChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >

                <MenuItem value="all">
                    <ListItemIcon>
                        <Checkbox
                            checked={fileColumns.length > 0 && selected.length === fileColumns.length}
                            indeterminate={selected.length > 0 && selected.length < selected.length}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem>

                {fileColumns.map(column => (
                    <MenuItem key={column.id} value={column.FieldName}>
                        <Checkbox checked={selected.indexOf(column.FieldName) > -1} />
                        {column.FieldName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
                    </Box>

                <br />

                <Typography variant="h5" gutterBottom>
                    2.  aggregating columns
                </Typography>

        {aggregatedColums.map((aggregatedColumn, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        disableAutoFocus='true'
                        autoComplete='off'
                        label="New Column Name"
                        value={aggregatedColumn.newName}
                        onChange={(event) => {
                            const newColumns = [...aggregatedColums];
                            const column = newColumns[index]
                            column.newName = event.target.value
                            setAggregatedColums(newColumns)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.aggregatingColumns = newColumns
                            } else {
                                currentComponent.nodedata = { aggregatingColumns: newColumns }
                            }

                            const outputFields = new Array();

                            if (currentComponent.nodedata.selectedColumns) {
                                currentComponent.nodedata.selectedColumns.forEach(selectedColumn => {
                                    outputFields.push({ id: outputFields.length + 1, FieldName: selectedColumn })
                                })
                            }

                            if (currentComponent.nodedata.aggregatingColumns) {
                                currentComponent.nodedata.aggregatingColumns.forEach(aggregatingColumn => {
                                    if (aggregatingColumn.newName) {
                                        outputFields.push({ id: outputFields.length + 1, FieldName: aggregatingColumn.newName })
                                    }
                                });
                            }

                            currentComponent.outputFields = outputFields;
                        }}
                    />
                </FormControl>

                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <InputLabel id="Aggregated-column-select-label">Aggregated Column</InputLabel>
                    <Select
                        labelId="Aggregated-column-select-label"
                        id="Aggregated-column-select-label"
                        label="Aggregated Column"
                        value={aggregatedColumn.column}
                        onChange={(event) => {
                            const newColumns = [...aggregatedColums];
                            const newColumn = newColumns[index]
                            newColumn.column = event.target.value
                            setAggregatedColums(newColumns)
                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.aggregatingColumns = newColumns
                            } else {
                                currentComponent.nodedata = { aggregatingColumns: newColumns }
                            }
                        }}
                    >
                        {fileColumns.map(column => (
                            <MenuItem key={column.id} value={column.FieldName}>
                                {column.FieldName}
                            </MenuItem>
                        ))}
                    </Select>
                    <br />
                </FormControl>

                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <InputLabel id="aggregating-function-select-label">Aggregating Function</InputLabel>
                    <Select
                        labelId="raggregating-function-select-label"
                        id="aggregating-function-select-label"
                        label="Aggregating Function"
                        value={aggregatedColumn.groupFunction}
                        onChange={(event) => {
                            const newColumns = [...aggregatedColums];
                            const newColumn = newColumns[index]
                            newColumn.groupFunction = event.target.value
                            setAggregatedColums(newColumns)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.aggregatingColumns = newColumns
                            } else {
                                currentComponent.nodedata = { aggregatingColumns: newColumns }
                            }
                        }}

                    >
                        {aggregatingFunctions.map(aggregatingFunction => (
                            <MenuItem key={aggregatingFunction} value={aggregatingFunction}>
                                {aggregatingFunction}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteAggregatedColumn(event, index)}>
                    <ClearIcon />
                </IconButton>

            </Stack>

        ))
        }

        <IconButton aria-label="Add"
            sx={{
                minWidth: 30,
                maxWidth: 30,
            }} onClick={handleAddAggregatedColumn}>
            <AddIcon />
        </IconButton>

            </Box>
        </Stack>
        )
}
