import { RoleComposite, Role } from "./interface";
import { getConfiguration } from '../../../config.js';


class RolesService {

	private apiParticipantUrl: string = getConfiguration().secApiUrl + '/ParticipantRole';
	private apiRoleUrl: string = getConfiguration().secApiUrl + '/Role';


	public async addRole(newRole: Omit<RoleComposite, 'roleKey'>): Promise<RoleComposite> {
		return new Promise<RoleComposite>((resolve) => {
			try {
				const getUrl = this.apiParticipantUrl;

				let hdrs = new Headers({ 'Content-Type': 'application/json' })

				let params: RequestInit = {
					headers: hdrs,
					method: "POST",
					body: JSON.stringify(newRole)
				}

				fetch(getUrl, params)
					.then((response) => {

						console.log("addRole response", response);

						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							//							let permissions: Permission[] = permissionsJson ? JSON.parse(permissionsJson) : "";

							resolve(response.json()); //resolve(permissions); 

							//localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
		});
	}


	public fetchUserRoles(): Promise<Role[]> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiRoleUrl;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the roles:', error);
				throw error;
			}
		});
	}


	public fetchCompositeRolesByUserID(userId: string | undefined): Promise<RoleComposite[] | []> {
		return new Promise((resolve, reject) => {
			try {
				const getUrl = this.apiRoleUrl + '/' + userId;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok) {
							console.log(`Network response was not ok (status: ${response.status})`);
						}
						else {
							resolve(response.json());
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the roles:', error);
				throw error;
			}
		});
	}


	public fetchParticipantKeysByRoleKey(roleKey: number): Promise<RoleComposite> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiParticipantUrl + '/' + roleKey;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the roles:', error);
				throw error;
			}
		});
	}


	public fetchParticipantRoles(): Promise<RoleComposite[]> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiParticipantUrl;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the roles:', error);
				throw error;
			}
		});
	}


	public async updateRoleName(roleKey: number, newName: string): Promise<Role> {
		return new Promise<Role>((resolve) => {
			try {
				const getUrl = this.apiParticipantUrl + '/' + roleKey;
				let body = newName
				let hdrs = new Headers({ 'Content-Type': 'application/json' })

				let params: RequestInit = {
					headers: hdrs,
					method: "PUT",
					body: JSON.stringify(body)
				}

				fetch(getUrl, params)
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							//							let permissions: Permission[] = permissionsJson ? JSON.parse(permissionsJson) : "";

							resolve(response.json()); //resolve(permissions); 

							//localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
		});
	}



	public async deleteParticipantRole(roleKey: number): Promise<RoleComposite> {
		return new Promise<RoleComposite>((resolve) => {
			try {
				const delUrl = this.apiParticipantUrl + '/' + roleKey;
				let hdrs = new Headers({ 'Content-Type': 'application/json' })

				let params: RequestInit = {
					headers: hdrs,
					method: "DELETE",
					body: ""
				}

				fetch(delUrl, params)
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status}, message: ${response.statusText})`);
						else {
							resolve(response.json());
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
		});
	}



	public async updateParticipantRole(roleKey: number, newParticipantKeys: number[]): Promise<RoleComposite> {
		return new Promise<RoleComposite>((resolve) => {
			try {
				const getUrl = this.apiParticipantUrl + '/' + roleKey;
				let body = newParticipantKeys
				let hdrs = new Headers({ 'Content-Type': 'application/json' })

				let params: RequestInit = {
					headers: hdrs,
					method: "PUT",
					body: JSON.stringify(body)
				}

				fetch(getUrl, params)
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							//							let permissions: Permission[] = permissionsJson ? JSON.parse(permissionsJson) : "";

							resolve(response.json()); //resolve(permissions); 

							//localStorage.setItem(this.localStorageKey, JSON.stringify(permissions));
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the permissions:', error);
				throw error;
			}
		});
	}
}

export const rolesService = new RolesService();