import React, { useEffect } from 'react'
import { Box, Button, useTheme, TextField, Stack, Divider, Autocomplete } from '@mui/material'
import { Formik } from "formik";
import { useField, useFormikContext } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService"
import AuditClientDataService from "../../service/AuditClientDataService";
import FileTypeDataService from "../../service/FileTypeDataService";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, addMonths } from 'date-fns'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CollapsibleTable from '../../components/common/collapsibleTable';
import FileConfigDataService from "../../service/FileConfigDataService"
import SAIMEntityDataService from '../../service/SAIMEntityDataService';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import RightContainer from "../global/RightContainer";

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import { FilterNodeFactory } from "../../components/diagram/filterNode/Filter";
import { JoinDataNodeFactory } from "../../components/diagram/joinDataNode/JoinData";
import { GroupByNodeFactory } from "../../components/diagram/groupByNode/GroupBy";
import { SelectNodeFactory } from "../../components/diagram/selectNode/Select";

import { AppendRowNodeFactory } from "../../components/diagram/appendRowNode/AppendRow";
import { AlterationNodeFactory } from "../../components/diagram/alterationNode/Alteration";
import { InputNodeFactory } from "../../components/diagram/inputNode/Input";
import { OutputNodeFactory } from "../../components/diagram/outputNode/Output";
import { BusinessRuleNodeFactory } from "../../components/diagram/businessRuleNode/BusinessRule";
import { FunctionNodeFactory } from "../../components/diagram/functionNode/Function";
import ProcessFlowDataService from "../../service/ProcessFlowDataService";
import { Graph } from "../../components/diagram/GraphDistance";
import createEngine, { DiagramModel } from "@projectstorm/react-diagrams";
import { CreatorWidget } from "../../components/diagram/creator-widget/CreatorWidget";

import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RecursiveTreeView from '../../components/treeView/RecursiveTreeView';

import { CSVLink, CSVDownload } from "react-csv";

import AzureTableDataService from '../../service/AzureTableDataService';
import DataLakeService from "../../service/DataLakeService";
import ContractDataService from "../../service/ContractDataService";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReportWriterTab from '../ReportWriter/ReportWriterTab';
import ProcessFlowGrid from "../dataOverview/outputMapping";
import Approval from "../wizard/index";
import ContractList from "../contractManagement/contractList";
import ReportPublisher from "../../pages/schemaProcessed/index";


function parse(str) {
    if (!/^(\d){8}$/.test(str)) return "invalid date";
    var y = str.substring(0, 4),
        m = str.substring(4, 6),
        d = str.substring(6, 8);
    return new Date(y, m - 1, d);
}

function yyyymmdd(date) {
    var x = new Date(date)
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}


function createTree(arr) {
    var tree = [];

    var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
        if (arr[i].network.systemclientkey) {
            arrElem = arr[i];
            mappedArr[arrElem.id] = arrElem;
            mappedArr[arrElem.id]['children'] = [];
        }
    }

    for (var id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
            mappedElem = mappedArr[id];
            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElem.parentid) {
                mappedArr[mappedElem['parentid']]['children'].push(mappedElem);
            }
            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElem);
            }
        }
    }
    return tree;
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const REPORT_WRITER_PATH = "/compositepage/report-writer";
const REPORT_WRITER_TAB_NAME = "REPORT WRITER";

const CompositeTab = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const forceUpdate = React.useReducer(bool => !bool)[1];

    const currentAudit = props.audit;
    const { processFlows,searchDto } = props;
    const [executingFiles, setExecutingFiles] = React.useState();

    const [executingFilesModalOpen, setExecutingFilesModalOpen] = React.useState(false);


    const [editDisabled, setEditDisabled] = React.useState(true);

    const [value, setValue] = React.useState(0);
    const [processFlowTabValue, setprocessFlowTabValue] = React.useState(0);
    const [auditConfigurations, setAuditConfigurations] = React.useState([]);

    const [clientNetworkOpen, setClientNetworkOpen] = React.useState(false);

    const [clientNetworks, setClientNetworks] = React.useState();
    const [alreadySelected, setAlreadySelected] = React.useState([]);
    const [selectedPair, setSelectedPair] = React.useState([]);

   // const [processFlows, setProcessFlows] = React.useState([]);

    const [processFlow, setProcessFlow] = React.useState();
    const [processFlowName, setProcessFlowName] = React.useState('');
    const [processFlowReadOnly, setProcessFlowReadOnly] = React.useState(false);
    
    const [diagramEngine, setDiagramEngine] = React.useState();
    const [procssFlowModalOpen, setProcssFlowModalOpen] = React.useState(false);
    const [currentComponent, setCurrentComponent] = React.useState();

    const [inputDateSets, setInputDateSets] = React.useState([]);
    const [outputDateSets, setOutputDateSets] = React.useState([]);

    const [dataSetIsOpen, setDataSetIsOpen] = React.useState(false);
    const [dataSetName, setDataSetName] = React.useState();
    const [datatSetRows, setDatatSetRows] = React.useState([]);
    const [dataSetColumns, setDataSetColumns] = React.useState([]);

    const [startDate, setStartDate] = React.useState()
    const [endDate, setEndDate] = React.useState()

    const [startDateString, setStartDateString] = React.useState()
    const [endDateString, setEndDateString] = React.useState()

    const [auditType, setAuditType] = React.useState();
    const [auditTeam, setAuditTeam] = React.useState();
    const [auditManager, setAuditManager] = React.useState();

    const [auditTypes, setAuditTypes] = React.useState([]);
    const [auditTeams, setAuditTeams] = React.useState([]);
    const [auditManagers, setAuditManagers] = React.useState([]);
    const [executeProcessFlowDto, setExecuteProcessFlowDto] = React.useState({
        processFlowKey: 0,
        currentInputs:[],
        inputs: []
    });
    const [executeProcessFlowSelectedModel, setExecuteProcessFlowSelectedModel] = React.useState([]);
    const [canSaveExecuteProcessFlow,setCanSaveExecuteProcessFlow] = React.useState(false);

    const [processSameFile, setProcessSameFile] = React.useState(false);
    const [approvalModel, setApprovalModel] = React.useState({
        auditkey: 0,
        processflowkey: 0,
        fileTypes: [],
        outputItems:[]
    });

    const [auditModelSchemas, setAuditModelSchemas] = React.useState([]);
    const [processflowDataGrid, setProcessflowDataGrid] = React.useState([]);
    const [contractMappingProcessflowDataGrid, setContractMappingProcessflowDataGrid] = React.useState([]);
    const [finalReportProcessflowDataGrid, setFinalReportProcessflowDataGrid] = React.useState([]);
    const [contractListItems, setContractListItems] = React.useState([]);

    const [selectedParticipantType, setSelectedParticipantType] = React.useState({id:"",name:""});
    const [participantTypeOptions, setParticipantTypeOptions] = React.useState([
        { id: 'Affiliate', name:'Affiliate'},
        { id: 'Client', name: 'Client' }
    ]);
    const [clientNetworkByAuditOptions, setClientNetworkByAuditOptions] = React.useState([]);
    const [clientByAuditOptions, setClientByAuditOptions] = React.useState([]);
    const [networkByAuditByClientOptions, setNetworkByAuditByClientOptions] = React.useState([]);
    const [selectedClientByAudit, setSelectedClientByAudit] = React.useState({});
    const [selectedNetworkByAudit, setSelectedNetworkByAudit] = React.useState({});
    const [selectedAffiliateByAudit, setSelectedAffiliateByAudit] = React.useState({});
    const [currentProcessflowstagekey, setCurrentProcessflowstagekey] = React.useState(null);

    const handleDataSetClose = () => {
        setDataSetIsOpen(false);
    }

    const handleExecutingProcessFlowModalClose = () => {
        setExecutingFilesModalOpen(false);
    }

    const handleClientNetworkClose = () => {
        setClientNetworkOpen(false);
    }

 
	const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

    const updateCurrentProcessflowstagekey = (key) => {
        setCurrentProcessflowstagekey(key);
    }

    const handleEditClientNetworks = () => {
        ClientDataService
            .getUserNetworksByDate(userKey, currentAudit.audit.auditstartdtkey, currentAudit.audit.auditenddtkey)
            .then(res => {
			  const groupedBy = Object.groupBy(res.data.value, x => x.participant.systemclientkey);

                const tree = Object.keys(groupedBy)
                    .map((key) => ({
                        id: key,
                        isNetwork: false,
					name: groupedBy[key][0].participant.participantname,
                        children: groupedBy[key].map((child) => ({
                            id: child.network.networkkey,
                            parentId: key,
                            isNetwork: true,
                            name: child.network.networkdesc || " "
                        }))
                    }));

                setClientNetworks({
                    id: 0,
                    name: "client/Networks",
                    children: tree
                });
                setClientNetworkOpen(true);
            })
            .catch (err => console.log(err));
       
    }


    const handleClientNetworkSave = () => {

        const configs = selectedPair.filter(item => item).map(item => ({
            auditkey: currentAudit.audit.auditkey,
            auditclientkey: item.auditclientkey,
            auditnetworkkey: item.auditnetworkkey.id,
            auditstatuskey: 1
        }));

        AuditClientDataService
            .createAuditConfigurationsByAudit(configs)
            .then(res => {
                setClientNetworkOpen(false);
            })
            .then(() => {

                AuditClientDataService
                    .getAuditConfigurationsByAudit(currentAudit.id)
                    .then(res => {
                        setAuditConfigurations(Object.groupBy(res.data, x => x.auditConfig.auditclientkey))
                        setAlreadySelected(res.data.filter(item => item.network.systemclientkey));
                    }

                    ).catch(err => console.log(err))

            }

            )
            .catch(err => console.log(err));
        console.log(selectedPair);
    }

    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (event.target?.innerText === REPORT_WRITER_TAB_NAME) {
            navigate(REPORT_WRITER_PATH);
        }
    };


    const handleProcessFlowTabChange = (event, newValue) => {
        setprocessFlowTabValue(newValue);
    };


    const handleProcessFlowNameChange = (event) => {
        setProcessFlowName(event.target.value);
    };


    const initClientNetworkDropdown = () => {
        setSelectedParticipantType({ id: "", name: '' });
        setSelectedClientByAudit({});
        setSelectedNetworkByAudit({});
    }

    const createNewProcessFlow = () => {

        setProcessFlow()
        setInputDateSets([])
        setOutputDateSets([])
        initClientNetworkDropdown();
        const engine = createEngine({
            registerDefaultPanAndZoomCanvasAction: false,
            registerDefaultZoomCanvasAction: false
        });
        var model = new DiagramModel();
        engine.setModel(model);

        // Create custom node
        engine.getNodeFactories().registerFactory(new InputNodeFactory());
        engine.getNodeFactories().registerFactory(new OutputNodeFactory());
        engine.getNodeFactories().registerFactory(new BusinessRuleNodeFactory());
        engine.getNodeFactories().registerFactory(new FunctionNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new JoinDataNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new SelectNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendRowNodeFactory());
        engine.getNodeFactories().registerFactory(new AlterationNodeFactory());

        setDiagramEngine(engine)

        model.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {

                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity)
                            forceUpdate();
                            console.log(nodeSelectionChange.entity.options.id)
                            console.log("Set CurrentComponent 1", nodeSelectionChange.entity);
                        }

                    }
                })
                console.log(event.entity.options.id)
            },
        });

        setProcssFlowModalOpen(true)
    }

    const saveProcessFlow = (event) => {
        event.preventDefault();

        //var nodesCount = _.keys(diagramEngine.getModel().getNodes()).length;
        let g = new Graph(diagramEngine.getModel().getNodes());

        diagramEngine.getModel().getNodes().forEach(node => {
            node.precendentNodes = new Set();
        })

        diagramEngine.getModel().getLinks().forEach((link) => {
            g.addEdge(link.sourcePort.parent.options.id, link.targetPort.parent.options.id, 1);
            link.targetPort.parent.precendentNodes.add(link.sourcePort.parent.options.id)
        })

        let s = [];

        diagramEngine.getModel().getNodes().forEach(node => {
            if (node.precendentNodes.size == 0) {
                node.depth = 0;
                s = [...s, node];
            }
        })
        console.log("Following are longest distances from source vertex " + s);

        s.forEach(root => {
            const dist = g.longestPath(root);

            diagramEngine.getModel().getNodes().forEach(node => {
                node.depth = Math.max(node.depth, dist.get(node.options.id));
            })
        })
       

        if (processFlow) {
            diagramEngine.getModel().getNodes().forEach(node => {
                if (node.options.type == 'Output' && node.nodedata.OutPutDataLocation) {
                    node.nodedata.OutPutDataLocation = node.nodedata.OutPutDataLocation + processFlow.processflowkey;
                }
            })

            //ser
            var str = JSON.stringify(diagramEngine.getModel().serialize());

            processFlow.processflowstagekey = currentProcessflowstagekey;
            processFlow.processflownm = processFlowName;
            processFlow.processflowjsondata = str;
            processFlow.auditkey = currentAudit.audit.auditkey;

            if (selectedParticipantType.name === "Client") {
                processFlow.auditconfigurationkey = selectedNetworkByAudit.auditconfigurationkey;
                processFlow.affiliatekey = null;
            }
            if (selectedParticipantType.name === "Affiliate") {
                processFlow.affiliatekey = selectedAffiliateByAudit.affiliatekey;
                processFlow.auditconfigurationkey = null;
            }
         
            ProcessFlowDataService.update(processFlow.processflowkey, processFlow).then((res) => {
                console.log(res.data)
                handleProcessFlowModalClose();
            }).catch((err) => {
                console.log(err);
            })
        } else {

            //ser
            var str = JSON.stringify(diagramEngine.getModel().serialize());

            var data = {
                auditkey: currentAudit.audit.auditkey,
                processflownm: processFlowName,
                processflowjsondata: str,
                processflowstagekey: currentProcessflowstagekey,
                auditconfigurationkey : null,
                affiliatekey: null
            }

            if (selectedParticipantType.name === "Client") {
                data.auditconfigurationkey = selectedClientByAudit.auditconfigurationkey;
            }
            if (selectedParticipantType.name === "Affiliate") {
                data.affiliatekey = selectedAffiliateByAudit.affiliatekey;
            }

            ProcessFlowDataService.create(data).then((res) => {

                diagramEngine.getModel().getNodes().forEach(node => {
                    if (node.options.type == 'Output') {

                        if (node.nodedata) {
                            node.nodedata.OutPutDataLocation = node.nodedata.OutPutDataLocation + res.data.processflowkey;
                        } else {
                            node.nodedata = {
                                OutPutDataLocation: "defaultOutPutDataLocation"
                            }
                        }
                    }
                })
                //ser
                var str = JSON.stringify(diagramEngine.getModel().serialize());

                res.data.processflowjsondata = str

                ProcessFlowDataService.update(res.data.processflowkey, res.data).then((res) => {
                    console.log(res.data)
                    handleProcessFlowModalClose();
                }).catch((err) => {
                    console.log(err);
                })
               // handleModalClose();
            }).catch((err) => {
                console.log(err);
            })
        }
    }


    const getFileConfigByProcessFlowKey = (dataSet, callback) => {
        let outputname = dataSet.nodedata.OutPutDataNm;
        FileConfigDataService.getFileConfigByProcessFlowKey(processFlow.processflowkey).then((res) => {
            let fileData = res.data.filter(data => data.processflowcsvlocation.includes(outputname))[0]
            if (fileData && callback) {
                callback(fileData);
            }
        }).catch(err => {
            console.log(err);
        })
    }


    const handleDataSetOutPutClick = (dataSet) => {
        getFileConfigByProcessFlowKey(dataSet, getOutPutDataFromAzureTable);
    };

    const handleExportDataSetOutPutClick = (dataSet) => {
        getFileConfigByProcessFlowKey(dataSet, exportOutPutDataFromAzureTable);
    };


    const getOutPutDataFromAzureTable = (fileData) => {

        DataLakeService.getParquetFile(fileData).then(res => {
            var columnNames = res.data.columnNames;
            var table = res.data.table;

            var columns = [];
            var rows = [];


            var rowCount = res.data.table.length;
            var colCount = columnNames.length;

            for (let i = 0; i < colCount; i++) {
                columns.push(
                    {
                        field: columnNames[i],
                        headerName: columnNames[i],
                        width: 200
                    })
            }

            for (let j = 0; j < rowCount; j++) {
                var object = {};
                object.id = j;

                table[j].map((row, index) => {
                    object[columns[index].field] = row
                })
                rows.push(object);
            }

            setDataSetColumns(columns);
            setDatatSetRows(rows);
            setDataSetIsOpen(true)
            //setTableHeader(columns);
            // setTableData(rows);
        });

    }

    const exportOutPutDataFromAzureTable = (fileData) => {

        AzureTableDataService.exportOutPutDataFromAzureTable(fileData).then(result => {
            console.log(result)
            if (result.status == 200) {
                window.open(result.data, '_blank');
            }
        }).catch(err => {
            console.log(err);
        })
    }




    const handleProcessFlowModalClose = () => {
        setProcssFlowModalOpen(false);
        setProcessFlow()
    }

    const handleProcessFlowRowClick = (params) => {
        initClientNetworkDropdown();

        setProcessFlow(params.row)
        console.log("selected process flow",params.row);
        
        //deser
        var model2 = new DiagramModel();
        setProcessFlowName(params.row.processflownm)
        setProcessFlowReadOnly(params.row.lockflg == true);
        if (params.row.affiliatekey > 0) {
            setSelectedParticipantType({ id: 'Affiliate', name: 'Affiliate' });
        }
        else if (params.row.auditconfigurationkey > 0) {
            setSelectedParticipantType({ id: 'Client', name: 'Client' });
            console.log(clientNetworkByAuditOptions);
            let value = clientNetworkByAuditOptions.filter(x => x.auditconfigurationkey == params.row.auditconfigurationkey)[0];
            
            let networks = clientNetworkByAuditOptions.filter(x => x.clientkey == value.clientkey);

            setSelectedClientByAudit({ clientkey: value.clientkey, clientname: value.clientname });
            setNetworkByAuditByClientOptions(networks);
            setSelectedNetworkByAudit(value);
        }
        
        model2.deserializeModel(JSON.parse(params.row.processflowjsondata), diagramEngine);
        diagramEngine.setModel(model2);

        const nodes = model2.getNodes();

        const inputNodes = nodes.filter(node => node.options.type == 'Input');
        setInputDateSets(inputNodes)

        const outputNodes = nodes.filter(node => node.options.type == 'Output');
        setOutputDateSets(outputNodes)

        console.log("outputNodes:", outputNodes);

        console.log(inputNodes);
        let fileTypes = [];
        let fileconfigkeys = [];
        let inputs = [];
        console.log("auditModelSchemas:",auditModelSchemas);
        inputNodes.forEach(input => {
            
            if (input.nodedata) {
                fileTypes.push(input.nodedata?.FileTypeDesc);
                fileconfigkeys.push(input.nodedata?.fileConfigKey);
            }
            
            inputs.push(
                {
                    inputobjid: input.options.id,
                    inputName: input.options.name,
                    fileName: input.nodedata?.FileName,
                    fileType: input.nodedata?.FileTypeDesc,
                    items: []
                }
            );
        });

        let fileTypeArr = [];
        let outputItemsArr = [];
        outputNodes.forEach((output, index) => {
            if (fileTypes[index]) {

                fileTypeArr.push({ name: fileTypes[index], dataModel: auditModelSchemas.filter(x => x.filetypedesc === fileTypes[index]).map(m => m.fieldname) || [] });  
            }

            let filename = "";
            if (inputNodes[index] && inputNodes[index].nodedata) {
                filename = inputNodes[index].nodedata?.FileName;
            }
            else if (inputNodes[0].nodedata && inputNodes[0].nodedata?.FileName) {
                filename = inputNodes[0].nodedata.FileName;
            }
            outputItemsArr.push({ id: index + 1, outputNodeName: output.nodedata.OutPutDataNm, fileConfigKey: fileconfigkeys[index] || null, fileName: filename, type: 'Type 1', dataModel: output.outputFields.map(s => s.FieldName) });
        });
        setApprovalModel({ ...approvalModel, fileTypes: fileTypeArr, outputItems: outputItemsArr, auditkey: currentAudit.audit.auditkey, processflowkey: params.row.processflowkey });
        setExecuteProcessFlowDto({ ...executeProcessFlowDto, processFlowKey: params.row.processflowkey, currentInputs: inputs });
        FileConfigDataService.getFilesByAffiliateOrClientAndFileType(currentAudit.audit.affiliatekey, fileTypes.join())
            .then((res) => {
                console.log(res.data);
                setExecutingFiles(res.data.map((data, index) => {
                    var result = {
                        id: index,
                        FileName: data.fc.filenm,
                        FileTypeDesc: data.fileTypeTemp?.filedesc || "",
                        SchemaName: data.schemaTemp.clientfiletypedesc,
                        ConversionStatus: data.convStatusTemp ? data.convStatusTemp.statusdesc : "",
                        fileConfigKey: data.fc.fileconfigkey,
                        clientkey: data.fc.systemclientkey,
                        clientfileschemakey: data.fc.clientfileschemakey,
                        StartDate: data.fc.filedatefrom,
                        EndDate: data.fc.filedateto,
                        InputName: null,
                        Checked: false
                    }

                    if (data.participantTemp.participanttype == "Affiliate") {
                        result.Affiliate = data.participantTemp.participantname;
                    }

                    if (data.participantTemp.participanttype == "Client") {
                        result.Client = data.participantTemp.participantname;
                        result.Network = data.fc.networkey;
                    }

                    return result;
                }))
                //console.log(executingFiles);
            })
            .catch(err => console.log(err))

     
        

        model2.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity)
                            console.log(nodeSelectionChange.entity.options.id)
                            console.log("Set CurrentComponent 2", nodeSelectionChange.entity);
                        }

                    }
                })

            },
        });

        model2.getNodes().forEach((node) => {
            node.registerListener({
                selectionChanged: (e) => {
                    if (e.isSelected) {
                        setCurrentComponent(e.entity)
                        console.log(e.entity.options.id)
                        console.log("Set CurrentComponent 3", e.entity);
                    }

                }
            });
        })
        setProcssFlowModalOpen(true);
        console.log("currentComponent",currentComponent);
    };

    const onClientByAuditChange = (value) => {
        setSelectedClientByAudit(value);
        let networks = clientNetworkByAuditOptions.filter(x => x.clientkey == value.clientkey);
        setNetworkByAuditByClientOptions(networks);
    }

    const executeProcessFlow = () => {
        let dto = initExecuteProcessFlowDto();
        let _executeProcessFlowSelectedModel = [];
        let _executingFiles = executingFiles;
        _executingFiles.forEach((result,index) => {
            dto.inputs.forEach(ipt => {
                if (ipt.fileType === result.FileTypeDesc) {
                    result.InputName = ipt.inputName;
                }
                if (ipt.fileName === result.FileName) {
                    result.Checked = true;
                    _executeProcessFlowSelectedModel.push(index);
                }
            });
        });

        setExecutingFiles(_executingFiles);
        setExecuteProcessFlowSelectedModel(_executeProcessFlowSelectedModel);
        setCanSaveExecuteProcessFlow(_executeProcessFlowSelectedModel.length > 0);
        setExecutingFilesModalOpen(true);
    }

    const initExecuteProcessFlowDto = () => {
        const _executeProcessFlowDto = executeProcessFlowDto;
        _executeProcessFlowDto.inputs = deepCopy(_executeProcessFlowDto.currentInputs);
        setExecuteProcessFlowDto(_executeProcessFlowDto);
        return _executeProcessFlowDto;
    }

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    const onExecuteProcessFlowSelected = (option) => {
        setExecuteProcessFlowSelectedModel(option);
        let files = executingFiles;
        files.forEach(x => {
            x.Checked = false;
            if (option.includes(x.id)) {
                x.Checked = true;
            }
        });
        setExecutingFiles(files);
        setCanSaveExecuteProcessFlow(option.length > 0);
    };


    const confirmExecuteProcessFlow = () => {
        const inputNode = diagramEngine.getModel().getNodes().filter(node => node.options.type == 'Input')[0];

        
        let dto = executeProcessFlowDto;
        executingFiles.forEach(x => {
            if (x.Checked) {
                dto.inputs.forEach(option => {
                    if (option.fileType == x.FileTypeDesc) {
                        option.items.push(x.fileConfigKey);
                    }
                });
            }
        });

        var data = {
            fc: {
                processflowKey: processFlow.processflowkey || 1,
                systemclientkey: inputNode.nodedata.clientkey
            },
            audit: currentAudit.audit,
            processFlowKey: dto.processFlowKey,
            inputs:dto.inputs
        }
        console.log("executeProcessFlowDto:", data);
        //console.log(executingFiles);

        FileConfigDataService.executeProcessFlow(data).then((res) => {
            console.log(res);
            if (res.status == 200) {
                alert("Process flow pipeline sent successfully");
            }
            else {
                alert("operation failed");
            }
        }).then(() => {
            setExecutingFilesModalOpen(false);
        }).catch(err => {
            console.log(err);
            if (err.response.status == 400) {
                let data = err.response;
                if (data.data) {
                    alert(data.data);
                }
            }
        })
    }

    

    const executingFiles_headers = [
        //{
        //    field: "Selected",
        //    headerName: "Selected",
        //    width: 200,
        //    renderCell: (params) => <div><Checkbox /></div>
        //},
        {
            field: "InputName",
            headerName: "Input Node Name",
            width: 200
        },
        {
            field: "FileName",
            headerName: "File Name",
            width: 200
        },
        {
            field: "FileTypeDesc",
            headerName: "File Type",
            width: 200
        },
        {
            field: "Affiliate",
            headerName: "Affiliate",
            width: 200
        },
        {
            field: "Client",
            headerName: "Client",
            width: 200
        },
        {
            field: "Network",
            headerName: "Network",
            width: 200
        },
        {
            field: "SchemaName",
            headerName: "Schema Name",
            width: 200
        },
        {
            field: "ConversionStatus",
            headerName: "Conversion Status",
            width: 200
        },
        {
            field: "StartDate",
            headerName: "Start Date",
            width: 200
        },
        {
            field: "EndDate",
            headerName: "End Date",
            width: 200
        }
    ]

    const handleDataSetClick = (dataSet) => {

        if (dataSet && dataSet.nodedata && dataSet.nodedata.FileName) {
            setDataSetName(dataSet.nodedata.FileName);

            setDataSetIsOpen(true)

            AzureTableDataService.getByFile(dataSet.nodedata).then(res => {
                var firstElement = res.data[0];
                var resultData = res.data.slice(1);

                if (firstElement['rowKeyPrefixes'] == 'csv' || firstElement['rowKeyPrefixes'] == 'txt') {
                    var columns = [];
                    var columnNames = [];
                    if (firstElement) {
                        let i = 0;
                        while (resultData[0]["COL" + String(i).padStart(3, '0')] != null || resultData[0]["COL" + String(i).padStart(3, '0')] != undefined ) {
                            var columnName = "COL" + String(i).padStart(3, '0')
                            columnNames.push(columnName);
                            columns.push(
                                {
                                    field: columnName,
                                    headerName: columnName,
                                    width: 200
                                }
                            );
                            i++;
                        }
                    }
                    setDataSetColumns(columns);

                    var rows = resultData.map((data, index) => {

                        var object = {};
                        object.id = index;

                        columnNames.forEach(colNm => {
                            object[colNm] = data[colNm];
                        });
                        return object;
                    });
                    setDatatSetRows(rows);
                } else {
                    //setSheets(firstElement['rowKeyPrefixes'].split("*"));
                    //setSheetsData(resultData);
                }

            }).catch(err => {
                console.log(err);
            })
        }
    }

    const unlockOnClick = () => {
        if (!editDisabled) {
            AuditClientDataService.update(currentAudit.audit.auditkey, {
                auditkey : currentAudit.audit.auditkey,
                affiliatekey : currentAudit.audit.affiliatekey,
                auditname : currentAudit.audit.auditname,
                auditstartdtkey : currentAudit.audit.auditstartdtkey,
                auditenddtkey : currentAudit.audit.auditenddtkey,
                audittypekey : currentAudit.auditType.audittypekey,
                auditteamkey : currentAudit.auditTeam.auditteamkey,
                auditManagerkey : currentAudit.auditManager?.userkey
            })
                .then(res => forceUpdate())
                .catch(err => console.log(err));
        }

        setEditDisabled(!editDisabled);

    }


    React.useEffect(() => {

        const engine = createEngine({
            registerDefaultPanAndZoomCanvasAction: false,
            registerDefaultZoomCanvasAction: false
        });
        var model = new DiagramModel();
        engine.setModel(model);

        // Create custom node
        engine.getNodeFactories().registerFactory(new InputNodeFactory());
        engine.getNodeFactories().registerFactory(new OutputNodeFactory());
        engine.getNodeFactories().registerFactory(new BusinessRuleNodeFactory());
        engine.getNodeFactories().registerFactory(new FunctionNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new JoinDataNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new SelectNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendRowNodeFactory());
        engine.getNodeFactories().registerFactory(new AlterationNodeFactory());

        setDiagramEngine(engine)

        model.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity || {})
                            console.log("Set CurrentComponent 4", nodeSelectionChange.entity);
                        }

                    }
                })
                console.log(event.entity.options.id)
            },
        });



        AuditClientDataService.getAuditTypes().then((res) => {
            setAuditTypes(res.data);
        }).catch(e => {
            console.log(e)
        });

        SAIMEntityDataService.getAuditManagers().then((res) => {
            setAuditManagers(res.data);
        }).catch(e => {
            console.log(e)
        });

        SAIMEntityDataService.getAuditTeams().then((res) => {
            setAuditTeams(res.data);
        }).catch(e => {
            console.log(e)
        });

        FileTypeDataService.getAuditModelSchemas().then((res) => {
            setAuditModelSchemas(res.data);
        }).catch(e => {
            console.log(e)
        });

        if (currentAudit && currentAudit.audit) {
            FileConfigDataService.getProcessflowDataGrid(currentAudit.audit.auditkey).then((res) => {
                setProcessflowDataGrid(res.data.processflow);
                setContractMappingProcessflowDataGrid(res.data.contractmapping);
                setFinalReportProcessflowDataGrid(res.data.finalreport);
            }).catch(e => {
                console.log(e)
            });
        }

        if (currentAudit && currentAudit.audit) {
            var data = {
                clientkey: searchDto.clientKey,
                affiliatekey: searchDto.affiliatekey,
                audittypekey: searchDto.audittypekey,
                contractname:  "",
                startdate: searchDto.startDate,
                enddate: searchDto.endDate,
                auditkey: currentAudit.audit.auditkey
                //auditkey: -1
            }
            console.log("submit", data);

            ContractDataService.getContracts(data)
                .then((res) => {
                    return res.data;
                })
                .then(data => {
                    console.log(data);
                    setContractListItems(data);
                })
                .catch(err => console.log(err));

            AuditClientDataService.getClientNetworkByAuditKey(currentAudit.audit.auditkey)
                .then((res) => {
                    const seen = new Set();
                    let clients = res.data.items.map(item => ({
                        clientkey: item.clientkey,
                        clientname: item.clientname
                    }))
                        .filter(item => {
                            const isNew = !seen.has(item.clientkey);
                            seen.add(item.clientkey);
                            return isNew; 
                        });
                    setClientByAuditOptions(clients);
                    setClientNetworkByAuditOptions(res.data.items);
                    setSelectedAffiliateByAudit(res.data.affiliate);
                }).catch(err => console.log(err));
        }

        
       
        if (currentAudit) {
            AuditClientDataService
                .getAuditConfigurationsByAudit(currentAudit.id)
                .then(res => {
                    //setAuditConfigurations(Object.groupBy(res.data, x => x.network.networkkey))
                    setAuditConfigurations(Object.groupBy(res.data, x => x.auditConfig.auditclientkey))
                    setAlreadySelected(res.data.filter(item => item.network.systemclientkey));
                }

            ).catch(err => console.log(err));


            //FileConfigDataService.getFilesByAffiliateOrClientAndStatusKey(currentAudit.audit.affiliatekey, currentAudit.audit.auditkey, 500)
            //    .then((res) => {
            //        setExecutingFiles(res.data.map((data, index) => {
            //            var result = {
            //                id: index,
            //                FileName: data.fc.filenm,
            //                FileTypeDesc: data.fileTypeTemp.filedesc,
            //                SchemaName: data.schemaTemp.clientfiletypedesc,
            //                ConversionStatus: data.convStatusTemp ? data.convStatusTemp.statusdesc : "",
            //                fileConfigKey: data.fc.fileconfigkey,
            //                clientkey: data.fc.systemclientkey,
            //                clientfileschemakey: data.fc.clientfileschemakey,
            //                StartDate: data.fc.filedatefrom,
            //                EndDate: data.fc.filedateto
            //            }

            //            if (data.participantTemp.participanttype == "Affiliate") {
            //                result.Affiliate = data.participantTemp.participantname;
            //            }

            //            if (data.participantTemp.participanttype == "Client") {
            //                result.Client = data.participantTemp.participantname;
            //                result.Network = data.fc.networkey;
            //            }
            //            return result;;;
            //        }))

            //        console.log(11111);
            //        console.log(executingFiles);  
            //    })
            //    .catch(err => console.log(err))


            setAuditType(currentAudit.auditType);
            setAuditTeam(currentAudit.auditTeam);
            setAuditManager(currentAudit.auditManager);
            console.log(currentAudit);
            var startDate = parse(currentAudit.audit.auditstartdtkey.toString());
            var endDate = parse(currentAudit.audit.auditenddtkey.toString());
            setStartDate(dayjs(startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate()));
            setEndDate(dayjs(endDate.getFullYear() + '-' + endDate.getMonth() + '-' + endDate.getDate()));
            const formattedStartDate = `${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')}/${startDate.getFullYear()}`;
            const formattedEndDate = `${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getDate().toString().padStart(2, '0')}/${endDate.getFullYear()}`;
            setStartDateString(formattedStartDate);
            setEndDateString(formattedEndDate);
        }
        forceUpdate();

    }, [currentAudit, auditType]);



    const auditConfiguration_columns = [

        {
            field: "id",
            headerName: "Audit Configuration Key",
            width: 200
        },
        {
            field: "auditClientKey",
            headerName: "Audit Client",
            width: 200
        },
        {
            field: "auditnetworkKey",
            headerName: "Audit Network",
            width: 200
        },
        {
            field: "auditStatusKey",
            headerName: "Audit Status",
            width: 200
        },
        {
            field: "auditReportDesc",
            headerName: "Audit Report Desc",
            width: 200
        }
    ]

    const processFlow_columns = [
        {
            field: "processflowkey",
            headerName: "Process Flow Key",
            width: 200
        },
        {
            field: "processflownm",
            headerName: "Process Flow Name",
            width: 200
        },
        ,
        {
            field: "processflowjsondata",
            headerName: "Process Flow json data",
            width: 200
        },
        {
            field: "affiliatename",
            headerName: "Affiliate",
            width: 200
        },
        {
            field: "clientname",
            headerName: "Client",
            width: 200
        },
        {
            field: "networkname",
            headerName: "Network",
            width: 200
        }
    ]

    const location = useLocation();
    useEffect(() => {
        if (location.pathname.includes(REPORT_WRITER_PATH)) {
            setValue(7);
        }
    }, [location.pathname]);

    const filterFrocessflow = (stagekey) => {
        return processFlows.filter(x => x.processflowstagekey == stagekey);
    }


    return (
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab color={colors.primary} label="Audit Configuration" {...a11yProps(0)} />
                        <Tab color={colors.primary} label="Contract Configuration" {...a11yProps(1)} />
                        <Tab label="Fieldwork Process Flow" {...a11yProps(2)} onClick={() => { updateCurrentProcessflowstagekey(1)} } />
                        <Tab label="Fieldwork Mapping" {...a11yProps(3)} />
                        <Tab label="Contract Term Process Flow" {...a11yProps(4)} onClick={() => { updateCurrentProcessflowstagekey(3) }} />
                        <Tab label="Contract Mapping" {...a11yProps(5)} />
                        <Tab label="Discrepancy Analysis Process Flow" {...a11yProps(6)} onClick={() => { updateCurrentProcessflowstagekey(5) }} />
                        <Tab label="Final Report Data Mapping" {...a11yProps(7)} />
                        <Tab label="Report Writer" {...a11yProps(8)} />
                        <Tab label="Publish Report" {...a11yProps(9)} />
                    </Tabs>
                </Box>

            <TabPanel value={value} index={0}>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {currentAudit &&
                            <Grid item xs={4} md={3}>
                                <Typography variant="h6" sx={{ fontWeight: 600 }}>{(currentAudit && currentAudit.participant && currentAudit.participant.participantname) || ''}</Typography>
                                <br />
                                <Stack direction="row" spacing={5}>
                                    <Box>
                                        <Typography variant="h7">{(currentAudit && currentAudit.audit && currentAudit.audit.auditname) || ''}</Typography>
                                        {
                                            editDisabled &&
                                            <Stack>
                                                <div class="ul-list w-350">
                                                    <ul>
                                                        <li> <label>Start Date: </label> <label>{startDateString || ''}</label> </li>
                                                        <li> <label>End Date: </label> <label>{endDateString || ''}</label> </li>
                                                        <li> <label>Audit Type: </label> <label>{(auditType && auditType.audittypedesc) || ""}</label> </li>
                                                         <li> <label>Audit Manager: </label> <label>{auditTeam?.auditteamname || ""}</label> </li>
                                                        <li> <label>Audit Team: </label> <label>{auditManager?.username || ""}</label> </li>
                                                    </ul>
                                                </div>
                                            </Stack>
                                        }
                                        {
                                            !editDisabled &&
                                            <Stack spacing={1}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Start Date"
                                                        dateFormat="yyyy-MM-dd"
                                                        disabled={editDisabled}
                                                        value={startDate}
                                                        onChange={(newValue) => {
                                                            setStartDate(newValue)
                                                            currentAudit.audit.auditstartdtkey = yyyymmdd(newValue)
                                                        }
                                                        }
                                                    />

                                                    <DatePicker
                                                        label="endDate"
                                                        value={endDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        disabled={editDisabled}
                                                        onChange={(newValue) => {
                                                            setEndDate(newValue)
                                                            currentAudit.audit.auditenddtkey = yyyymmdd(newValue)
                                                        }
                                                        }
                                                    />
                                                </LocalizationProvider>

                                                <Select
                                                    label="AuditType"
                                                    value={auditType}
                                                    disabled={editDisabled}
                                                    renderValue={(selected) => selected.audittypedesc}
                                                    onChange={
                                                        (event) => {
                                                            setAuditType(event.target.value)
                                                            currentAudit.auditType = event.target.value;
                                                        }}
                                                >
                                                    {auditTypes.map(type => (
                                                        <MenuItem key={type.audittypekey} value={type}>
                                                            {type.audittypedesc}
                                                        </MenuItem>
                                                    ))}
                                                </Select>



                                                <FormControl sx={{ width: 350 }}>

                                                    <InputLabel htmlFor="name-shared">Audit Team</InputLabel>
                                                    <Select
                                                        label="AuditTeam"
                                                        value={auditTeam}
                                                        disabled={editDisabled}
                                                        renderValue={(selected) => selected.auditteamname}
                                                        onChange={(event) => {
                                                            setAuditTeam(event.target.value)
                                                            currentAudit.auditTeam = event.target.value;
                                                        }}
                                                    >
                                                        {auditTeams.map(team => (
                                                            <MenuItem key={team.auditteamkey} value={team}>
                                                                {team.auditteamname}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <FormControl sx={{ width: 350 }}>

                                                    <InputLabel htmlFor="name-shared">Audit Manager</InputLabel>
                                                    <Select
                                                        label="AuditManager"
                                                        value={auditManager}
                                                        disabled={editDisabled}
                                                        renderValue={(selected) => selected.username}
                                                        onChange={(event) => {
                                                            setAuditManager(event.target.value)
                                                            currentAudit.auditManager = event.target.value;
                                                        }}
                                                    >
                                                        {auditManagers.map(manager => (
                                                            <MenuItem key={manager.userkey} value={manager}>
                                                                {manager.username}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        }

                                        <Box sx={{ margin: "15px 0px", display: 'flex', justifyContent: 'center' }}>
                                            <Button type="submit" color="primary" variant="contained" onClick={unlockOnClick} sx={{ width: '131px' }}>
                                                {!editDisabled ? 'Save' : 'Edit'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Stack>
                            </Grid>
                        }
                        {currentAudit && 
                            <Grid item xs={8} md={9}>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Button type="submit" color="primary" variant="contained" onClick={handleEditClientNetworks} sx={{ width: '200px' }}>
                                        Edit Client/Networks
                                    </Button>
                                </Box>
                            
                                <Box m="8px 0 0 0"
                                    width="100%">

                                    <div class="ul-list w-full">
                                        <ul>
                                            {
                                                Object.keys(auditConfigurations).map((clientKey) => (
                                                    <>
                                                        <li class="pl-3" key={`Client-${clientKey}`}> <label>{`Client: ${auditConfigurations[clientKey][0].participant.participantname}`}</label></li>
                                                        {
                                                            auditConfigurations[clientKey].map((configuration) => (
                                                                <li class="text-normal pl-5" key={`${configuration.auditConfig.auditkey}`}>
                                                                    <label>Network: {configuration.network.networkkey + ":"+configuration.network.networkdesc} </label>
                                                                </li>
                                                            ))
                                                        }
                                                    </>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </TabPanel>
            <Divider orientation='horizontal' />
            <TabPanel value={value} index={1}>
                <ContractList contractListItems={contractListItems } />
            </TabPanel>
            <Divider orientation='horizontal' />

            <TabPanel value={value} index={2}>
                <Typography variant="h3" sx={{ mb: "15px" }}>Fieldwork Process Flow</Typography>
                <Box sx={{ margin: "30px 0px 15px 0px" }}>
                    <Button type="submit" color="primary" variant="contained" onClick={createNewProcessFlow}>
                        Create New Process Flow
                    </Button>
                </Box>

                <Box
                    display="grid"
                    gap="30px"
                    m={1}
                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            onRowClick={handleProcessFlowRowClick}
                            getRowId={(row) => row.processflowkey}
                            rows={filterFrocessflow(1)}
                            pageSize={10}
                            columns={processFlow_columns}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </Box>

            </TabPanel>

            <Divider orientation='horizontal' />
            <TabPanel value={value} index={3}>
                <Typography variant="h3" sx={{ mb: "15px" }}>Fieldwork Mapping</Typography>

                <ProcessFlowGrid ProcessflowDataGrid={processflowDataGrid} currentAudit={currentAudit} />
            </TabPanel>

            <Divider orientation='horizontal' />

            <TabPanel value={value} index={4}>
                <Typography variant="h3" sx={{ mb: "15px" }}>Contract Term Process Flow</Typography>
                <Box sx={{ margin: "30px 0px 15px 0px" }}>
                    <Button type="submit" color="primary" variant="contained" onClick={createNewProcessFlow}>
                        Create New Process Flow
                    </Button>
                </Box>

                <Box
                    display="grid"
                    gap="30px"
                    m={1}
                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            onRowClick={handleProcessFlowRowClick}
                            getRowId={(row) => row.processflowkey}
                            rows={filterFrocessflow(3)}
                            pageSize={10}
                            columns={processFlow_columns}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </Box>

            </TabPanel>

            <Divider orientation='horizontal' />
            <TabPanel value={value} index={5}>
                <Typography variant="h3" sx={{ mb: "15px" }}>Contract Term Mapping</Typography>
                <ProcessFlowGrid ProcessflowDataGrid={contractMappingProcessflowDataGrid} currentAudit={currentAudit} />
            </TabPanel>



            <Divider orientation='horizontal' />

            <TabPanel value={value} index={6}>
                <Typography variant="h3" sx={{ mb: "15px" }}>Discrepancy Analysis Process Flow</Typography>
                <Box sx={{ margin: "30px 0px 15px 0px" }}>
                    <Button type="submit" color="primary" variant="contained" onClick={createNewProcessFlow}>
                        Create New Process Flow
                    </Button>
                </Box>

                <Box
                    display="grid"
                    gap="30px"
                    m={1}
                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            onRowClick={handleProcessFlowRowClick}
                            getRowId={(row) => row.processflowkey}
                            rows={filterFrocessflow(5)}
                            pageSize={10}
                            columns={processFlow_columns}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </Box>

            </TabPanel>


            <Divider orientation='horizontal' />
            <TabPanel value={value} index={7}>
                <Typography variant="h3" sx={{ mb: "15px" }}>Final Report Data Mapping</Typography>
                <ProcessFlowGrid ProcessflowDataGrid={finalReportProcessflowDataGrid} currentAudit={currentAudit} />
            </TabPanel>

            <TabPanel value={value} index={8}>
                <ReportWriterTab />
            </TabPanel>

            <TabPanel value={value} index={9}>
                <ReportPublisher currentAudit={currentAudit} />
            </TabPanel>

            <Dialog
                modal={true}
                maxWidth='xl'
                width="100%"
                open={clientNetworkOpen}
                onClose={handleClientNetworkClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "50%",
                        minHeight: '50%',
                        maxHeight: '50%'
                    }
                }}>

                <DialogTitle id="alert-dialog-title">
                    <Header title="Client Networks" />
                </DialogTitle>

                <DialogContent>
                    {clientNetworks &&
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={4} md={3}>
                                <Button type="button" color="primary" variant="contained" sx={{ width: '131px', position:'absolute' }}>
                                        Select All
                                    </Button>
                                <Button type="button" color="primary" variant="contained" sx={{ width: '131px', position: 'absolute',marginTop:"50px" }}>
                                        Deselect All
                                    </Button>
                                </Grid>
                                <Grid item xs={8} md={9}>
                                    <RecursiveTreeView data={clientNetworks} setSelectedPair={setSelectedPair} alreadySelected={alreadySelected} setAlreadySelected={setAlreadySelected}></RecursiveTreeView>
                                </Grid>
                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" variant="contained" onClick={handleClientNetworkSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullWidth={true}
                modal={true}
                maxWidth='xl'
                width="100%"
                open={procssFlowModalOpen}
                onClose={handleProcessFlowModalClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "100%",
                        minHeight: '95%',
                        maxHeight: '95%',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Header title="Fieldwork Process Flow Detail" handleModalClose={handleProcessFlowModalClose} />
                </DialogTitle>

                <DialogContent>

                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={processFlowTabValue} onChange={handleProcessFlowTabChange}>
                                <Tab color={colors.primary} label="Settings" {...a11yProps(0)} />
                                <Tab label="Data Sets" {...a11yProps(1)} />
                                <Tab label="Approval" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <Box>
                            <Button type="submit" color="primary" variant="outlined" sx={{ marginRight: "10px" }} disabled={processFlowReadOnly}>
                                Edit Process Flow
                            </Button>

                            <Button type="submit" color="primary" variant="contained" onClick={executeProcessFlow} disabled={processFlowReadOnly}>
                                Execute Process Flow
                            </Button>
                        </Box>
                    </Stack>
                    <Box sx={{ border: "2px solid #e0e0e0;" }} padding={2}>

                        { /*Setting Tab*/}
                        <TabPanel value={processFlowTabValue} index={0}>
                            <Stack>
                                <Stack direction='row' justifyContent="space-between">
                                    <Box width={220} mb={1}>
                                        <Grid container spacing={2}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                <Typography variant="h5" gutterBottom>
                                                    Process Flow Name
                                                </Typography>

                                                <Stack direction="row" spacing={5}>
                                                    <Box width={220} mb={1}>
                                                        <FormControl >
                                                            <TextField
                                                                key="processFlowName"
                                                                name="processname"
                                                                autoComplete='off'
                                                                type="text"
                                                                id="processFlowName"
                                                                value={processFlowName}
                                                                onChange={handleProcessFlowNameChange}
                                                                size="small"
                                                            />
                                                        </FormControl>

                                                        <Box mt={2}>
                                                            <Autocomplete
                                                                id="country-select-demo"
                                                                size="small"
                                                                sx={{ width: 180 }}
                                                                value={selectedParticipantType || ""}
                                                                isOptionEqualToValue={(option) => option.id}
                                                                onChange={(event, value) => { setSelectedParticipantType(value);}}
                                                                options={participantTypeOptions}
                                                                autoHighlight
                                                                getOptionLabel={(option) => option.name || ""}
                                                                disabled={processFlowReadOnly}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        {option.name}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Choose a Participant Type"
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        {selectedParticipantType &&
                                                            <>
                                                            {
                                                                selectedParticipantType.name === "Affiliate" && selectedAffiliateByAudit &&
                                                                <>
                                                                    <Box mt={2}>
                                                                        {selectedAffiliateByAudit.affiliatename || "N/A"}
                                                                    </Box>
                                                                </>
                                                            }
                                                            {
                                                                selectedParticipantType.name === "Client" &&
                                                                <>
                                                                    <Box mt={2}>
                                                                        <Autocomplete
                                                                            id="country-select-demo-1"
                                                                            size="small"
                                                                            sx={{ width: 180 }}
                                                                            value={selectedClientByAudit || ""}
                                                                            isOptionEqualToValue={(option) => option.clientkey}
                                                                            onChange={(event, value) => {onClientByAuditChange(value) }}
                                                                            options={clientByAuditOptions}
                                                                            autoHighlight
                                                                            disabled={processFlowReadOnly}
                                                                            getOptionLabel={(option) => option.clientname || ""}
                                                                            renderOption={(props, option) => (
                                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                    {option.clientname}
                                                                                </Box>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Choose a Client"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Box>
                                                                    <Box mt={2}>
                                                                        <Autocomplete
                                                                            id="country-select-demo-2"
                                                                            size="small"
                                                                            sx={{ width: 180 }}
                                                                            value={selectedNetworkByAudit || ""}
                                                                            isOptionEqualToValue={(option) => option.networkkey}
                                                                            onChange={(event, value) => { setSelectedNetworkByAudit(value); }}
                                                                            options={networkByAuditByClientOptions}
                                                                            autoHighlight
                                                                            disabled={!selectedClientByAudit || processFlowReadOnly}
                                                                            getOptionLabel={(option) => option.networkname || ""}
                                                                            renderOption={(props, option) => (
                                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                                    {option.networkname}
                                                                                </Box>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Choose a Network"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Box>
                                                                </>
                                                            }
                                                          </>
                                                        }
                                                       
                                                       

                                                    </Box>
                                                    <Box width={280} mb={1}>
                                                        <div class="ul-list w-280">
                                                            <ul>
                                                                <li> <label>Last Exectuion Status: </label> <label>{processFlow && processFlow.lastrunstatus || ''}</label> </li>
                                                                <li> <label>Last Run Date: </label> <label>{processFlow && processFlow.lastrundate || ''}</label> </li>
                                                                <li> <label>Last Run By: </label> <label>{ processFlow && processFlow.lastupdateuserid || ""}</label> </li>
                                                            </ul>
                                                        </div>
                                                    </Box>

                                                    <Box width={220} mb={1}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                key="errorMessage"
                                                                name="errorMessage"
                                                                label="Error Message"
                                                                autoComplete='off'
                                                                type="text"
                                                                id="errorMessage"
                                                                multiline
                                                                rows={5}
                                                                value={processFlow && processFlow.lastrunerrorlog}
                                                                size="small"
                                                                inputProps={
                                                                    { readOnly: true, }
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Stack>
                   
                                                {processSameFile &&

                                                    <Stack direction="row" spacing={5}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                            <Box width={220} mb={1}>
                                                            <DatePicker
                                                                label="Start Date"
                                                                dateFormat="yyyy-MM-dd"
                                                                value={startDate}
                                                                onChange={(newValue) => {
                                                                    setStartDate(newValue)
                                                                    processFlow.processsamefile = processSameFile;
                                                                    processFlow.processstartdate = yyyymmdd(newValue)
                                                                    processFlow.processenddate = yyyymmdd(endDate)

                                                                }
                                                                }
                                                                />
                                                            </Box>

                                                            <Box width={220} mb={1}>
                                                            <DatePicker
                                                                label="endDate"
                                                                value={endDate}
                                                                dateFormat="yyyy-MM-dd"
                                                                onChange={(newValue) => {
                                                                    setEndDate(newValue)
                                                                    processFlow.processsamefile = processSameFile;
                                                                    processFlow.processstartdate = yyyymmdd(startDate)
                                                                    processFlow.processenddate = yyyymmdd(newValue)
                                                                }
                                                                }
                                                             /> </Box>
                                                        </LocalizationProvider>
                                                    </Stack>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={1}>
                                        <Button variant="contained" onClick={() => diagramEngine.zoomToFitSelectedNodes(10)}>zoom to fit</Button>
                                    </Box>
                                </Stack>

                                <Box mt={5} sx={{ width: '100%', height: '25%' }}>
                                    <CreatorWidget processFlowReadOnly={processFlowReadOnly}  engine={diagramEngine} currentAudit={currentAudit} currentProcessflowstagekey={currentProcessflowstagekey} currentComponent={currentComponent} processFlowNameChanger={setProcessFlowName} count={0} />
                                </Box>
                            </Stack>

                        </TabPanel>

                        { /*Data sets tab*/}
                        <TabPanel value={processFlowTabValue} index={1}>
                            <Box>
                                <Typography variant="h6"
                                    sx={{
                                        //fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: 100,
                                        lineHeight: '32px',
                                        color: 'var(--gray-1200, #393F48)'
                                    }}
                                    gutterBottom>
                                    Input DataSet ({inputDateSets.length})
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginLeft: "0px!important" }}>
                                    {inputDateSets.map((inputDataSet, index) => (
                                        <Box sx={{
                                            border: 1, width: '490px',
                                            borderRadius: '5px',
                                            background: 'var(--gray-400, #E7ECF1)'
                                        }} margin={2} >
                                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ padding: "0 10px 0 0" }}>
                                                <Box>
                                                    <List>
                                                        <ListItem height={100}>
                                                            <ListItemAvatar>
                                                                <img
                                                                    alt="dataset"
                                                                    width="48px"
                                                                    height="48px"
                                                                    src={"../../assets/dataset.svg"}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={inputDataSet.nodedata && inputDataSet.nodedata.FileName} onClick={() => { handleDataSetClick(inputDataSet) }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <CSVLink data={datatSetRows} className="export-button">EXPORT DATASET</CSVLink>
                                                    { /*<Button type="submit" variant="outlined" sx={{ backgroundColor:'white' }}>
                                                        Export DataSet
                                                    </Button>*/}
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))
                                    }
                                </Grid>
                            </Box>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <Box sx={{ width: '75%' }}>
                                <Typography variant="h6"
                                    sx={{
                                        //fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: 100,
                                        lineHeight: '32px',
                                        color: 'var(--gray-1200, #393F48)'
                                    }}
                                    gutterBottom>
                                    Output DataSet ({outputDateSets.length})
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginLeft: "0px!important" }}>
                                    {outputDateSets.map((outputDateSet, index) => (
                                        <Box sx={{
                                            border: 1, width: '490px',
                                            borderRadius: '5px',
                                            background: 'var(--gray-400, #E7ECF1)'
                                        }} margin={2} >
                                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ padding: "0 10px 0 0" }}>
                                                <Box>
                                                    <List>
                                                        <ListItem height={100}>
                                                            <ListItemAvatar>
                                                                <img
                                                                    alt="dataset"
                                                                    width="48px"
                                                                    height="48px"
                                                                    src={"../../assets/dataset_linked.svg"}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={outputDateSet.nodedata.OutPutDataNm} onClick={() => { handleDataSetOutPutClick(outputDateSet) }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Button type="submit" variant="outlined" sx={{ backgroundColor: 'white' }} onClick={() => handleExportDataSetOutPutClick(outputDateSet)}>
                                                        Export DataSet
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))
                                    }
                                </Grid>
                            </Box>
                        </TabPanel>

                        { /*Approval Tab*/}
                        <TabPanel value={processFlowTabValue} index={2}>
                            <Box>
                                <Approval approvalModel={approvalModel} processFlowReadOnly={processFlowReadOnly} />
                            </Box>
                        </TabPanel>
                    </Box>

                </DialogContent>

                <DialogActions>
                    {/*{processFlow &&*/}
                    {/*    <Button type="submit" color="error" variant="contained" onClick={deleteProcessFlow}>*/}
                    {/*        DELETE*/}
                    {/*    </Button>   */}
                    {/*}*/}
                    {/*<Button onClick={handleModalClose}>Cancel</Button>*/}

                    <Button type="submit" color="secondary" variant="contained" onClick={saveProcessFlow} disabled={processFlowReadOnly}>
                        Save Process Flow
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                modal={true}
                maxWidth='xl'
                width="100%" 
                open={dataSetIsOpen}
                onClose={handleDataSetClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "90%",
                        minHeight: '90%',
                        maxHeight: '90%'
                    }
                }}>

                <DialogTitle id="alert-dialog-title">
                    <Header title={dataSetName} handleModalClose={handleDataSetClose} />
                </DialogTitle>

                <DialogContent>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            rows={datatSetRows}
                            pageSize={10}
                            columns={dataSetColumns}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>


            <Dialog
                modal={true}
                maxWidth='xl'
                width="100%"
                open={executingFilesModalOpen}
                onClose={handleExecutingProcessFlowModalClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "90%",
                        minHeight: '90%',
                        maxHeight: '90%'
                    }
                }}>

                <DialogTitle id="alert-dialog-title">
                    <Header title="Executing Process Flow for Files" handleModalClose={handleExecutingProcessFlowModalClose} />
                </DialogTitle>

                <DialogContent>
                    <Box width={280} mb={1}>
                        <Typography variant="h6" gutterBottom>
                            Input Node Name:
                        </Typography>
                        <div class="ul-list w-280">
                            <ul>
                                {
                                    executeProcessFlowDto.inputs.map((item) => (
                                        <li> <label>{item.inputName}</label></li>
                                    ))
                                }
                                
                                
                            </ul>
                        </div>
                    </Box>
                    <Box
                        m="18px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                           // onRowClick={handleFileRowClick}
                            onSelectionModelChange={onExecuteProcessFlowSelected}
                            rows={executingFiles}
                            pageSize={5}
                            columns={executingFiles_headers}
                            components={{ Toolbar: GridToolbar }}
                            checkboxSelection
                            disableRowSelectionOnClick
                            selectionModel={executeProcessFlowSelectedModel}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>

                    <Button type="submit" color="secondary" variant="contained" disabled={!canSaveExecuteProcessFlow} onClick={confirmExecuteProcessFlow}>
                        Confirm Executing
                    </Button>
                </DialogActions>
            </Dialog>


           </Box>
    );
}
export default CompositeTab