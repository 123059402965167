import React, { useState } from 'react';
import { Dispatch, SetStateAction } from "react";
import { FormGroup, FormControlLabel, Checkbox, TextField, Button } from '@mui/material';
import { Role } from '../../ts/modules/roles/interface';
import { Permission } from '../../ts/modules/permission/interface';
import { CheckBox } from '@mui/icons-material';
import { isNullOrUndefined, isUndefined } from 'util';


interface RolesSelectionProps {
    allRoles: Role[];
    selectedRoles: Role[];
	//setSelectedRoles: (role: Role[]) => void;
	setSelectedRoles: Dispatch<SetStateAction<Role[]>>;
    title: string;
}

const RolesSelection: React.FC<RolesSelectionProps> = ({ allRoles, selectedRoles, setSelectedRoles, title }) => {

	// const [newRoleName, setNewRoleName] = useState('');
	// const [open, setOpen] = useState(false);

	// const handleRoleChange = (selectedRole: Role) => {
	//     setSelectedRoles((prev: Role[]) => {
	//         const isSelected = prev.some(role => role.id === selectedRole.id);
	//         if (isSelected) {
	//             // If the role is already selected, return a new array without it
	//             return prev.filter(role => role.id !== selectedRole.id);
	//         } else {
	//             // If the role is not selected, return a new array with it included
	//             return [...prev, selectedRole];
	//         }
	//     });
	// };

	const handleRoleChange = (role: Role) => {
		setSelectedRoles((prev: Role[]) => {
			if (prev.includes(role)) {
				return prev.filter(p => p.roleKey !== role.roleKey);
			} else {
				return [...prev, {
					roleKey: role.roleKey,
					roleDescription: role.roleDescription,
					createDate: role.createDate,
					permissions: []
				}]
			}
		});
	};


	const handlePermissionChange = (role: Role, permission: Permission) => {
		const foundRole = selectedRoles.find(r => r.roleKey === role.roleKey);

		if (foundRole === undefined)
			return;

		const foundPermission = foundRole.permissions.find(p => p.rolePermissionKey === permission.rolePermissionKey)

		if (foundPermission === undefined) { //then add it
			const newPermissions = [...foundRole.permissions, permission];

			setSelectedRoles((prev: Role[]) =>
				prev.map(r => {
					if (r.roleKey === foundRole.roleKey) {
						return { roleKey: foundRole.roleKey, roleDescription: foundRole.roleDescription, createDate: foundRole.createDate, permissions: newPermissions };
					}

					return r;
				})
			)
		}
		else { //remove it
			setSelectedRoles((prev: Role[]) =>
				prev.map(r => {
					if (r.roleKey === foundRole.roleKey) {
						return { roleKey: foundRole.roleKey, roleDescription: foundRole.roleDescription, createDate: foundRole.createDate, permissions: r.permissions.filter(p => p.rolePermissionKey !== permission.rolePermissionKey) };
					}

					return r;
				})
			)
		}
	};


	const Permissions = (permission: Permission, currentRole: Role, selectedRoles: Role[]) => {

		return (
			<dd key={permission.rolePermissionKey}>
				<FormControlLabel
					key={permission.rolePermissionKey}
					control=
					{
						<Checkbox
							//disabled=
							checked={
								selectedRoles
									.some(selectedRole => selectedRole.roleKey === currentRole.roleKey
										&& selectedRole.permissions.some(p => p.rolePermissionKey === permission.rolePermissionKey
									))}
							onChange={() => handlePermissionChange(currentRole, permission)}
						/>
					}
					label={permission.rolePermissionDescription}
				/>
			</dd >
		)
	}

    //const handleSelectAll = () => {
    //    setSelectedRoles(roles);
    //};

    //const handleDeselectAll = () => {
    //    setSelectedRoles([]);
    //};

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleAddRole = () => {
    //     if (newRoleName && !roles.some(role => role.name === newRoleName)) {
    //         const newRole: Role = {
    //             id: Math.max(0, ...roles.map(r => r.id)) + 1, // Assuming a simple increment for new role IDs
    //             name: newRoleName,
    //             createdAtUTC: new Date(),
    //             isInternalRole: false, // This could be adjusted based on your logic
    //             permissions: [],
    //         };
    //         setRoles(prevRoles => [...prevRoles, newRole]);
    //         handleClose();
    //         setNewRoleName('');
    //     }
    // };



	return (
		<div>
			<h3>{title}</h3>
			{/*<TextField*/}
			{/*	margin="dense"*/}
			{/*	id="search"*/}
			{/*	label={`Search ${title}`}*/}
			{/*	type="search"*/}
			{/*	variant="outlined"*/}
			{/*	fullWidth*/}
			{/*	value={searchTerm}*/}
			{/*	onChange={handleSearchChange}*/}
			{/*/>*/}
			{/*<Button onClick={handleSelectAll} variant="outlined" sx={{ mt: 1, mr: 1 }}>*/}
			{/*	Select All*/}
			{/*</Button>*/}
			{/*<Button onClick={handleDeselectAll} variant="outlined" sx={{ mt: 1, mr: 1 }}>*/}
			{/*	Deselect All*/}
			{/*</Button>*/}
        
			<FormGroup>
				<dl>
					{allRoles.map(role => (
						<>
							<dt key={role.roleKey}>
								<FormControlLabel
									key={role.roleKey}
									control=
									{
										<Checkbox
											checked={selectedRoles.some(selectedRole => selectedRole.roleKey === role.roleKey)}
											onChange={() => handleRoleChange(role)}
										/>
									}
									label={<span style={{ fontWeight: 'bolder' }}>{role.roleDescription}</span>}
								/>
							</dt>
							{
								role.permissions.map(permission => (
									Permissions(permission, role, selectedRoles)
								))
							}
						</>
					))}
				</dl>
			</FormGroup>
		</div>
	);
};


export { RolesSelection };