import { Participant } from "../participants/interface";
import { getConfiguration } from '../../../config.js';


class ParticipantService {

	private apiUrl: string = getConfiguration().secApiUrl + '/Participant';



	public async fetchAllParticipants(): Promise<Participant[]> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiUrl;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the participants:', error);
				throw error;
			}
		});
	}


/*
	public async addParticipant(newParticipant: Omit<Participant, 'systemClientKey'>): Promise<Participant> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiUrl;

				fetch(getUrl, { method: 'POST', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem adding the participant:', error);
				throw error;
			}
		});
	}

	public async fetchAffiliatesByUser(userKey: number): Promise<Participant[]> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiUrl + '/affiliates/' + userKey;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the affiliates for this user:', error);
				throw error;
			}
		});
	}


	public async fetchClientsByUser(userKey: number): Promise<Participant[]> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiUrl + '/clients/' + userKey;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the clients for this user:', error);
				throw error;
			}
		});
	}


	public async fetchNetworksByUser(userKey: number): Promise<Participant[]> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiUrl + '/networks/' + userKey;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the networks for this user:', error);
				throw error;
			}
		});
	}


	public async updateParticipant(systemClientKey: number, updateData: Participant): Promise<Participant> {
		return new Promise((resolve) => {
			try {
				const getUrl = this.apiUrl;

				fetch(getUrl, { method: 'PUT', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else
							resolve(response.json());
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem updating the participant:', error);
				throw error;
			}
		});
	}
	*/
}

export const participantService = new ParticipantService();

