import React from "react";
import { Typography, Box, useTheme ,Button} from "@mui/material";
import { tokens } from "../theme";
import Grid from '@mui/material/Grid';

const Header = ({ title, subtitle, handleModalClose, showHrLine }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    return (
        <Box mb="15px">
            <Box>
                <Grid container spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography
                            variant="h2"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ mb: "5px" }}
                        >
                            {title}
                        </Typography>
                        <Typography variant="h5" color={colors.greenAccent[400]}>
                            {subtitle}
                        </Typography>
                    </Grid>
                    {
                        handleModalClose &&
                        <Grid item>
                            <Button onClick={handleModalClose}>Cancel</Button>
                        </Grid>
                    }
                </Grid>
            </Box>
            {
                showHrLine &&
                <Box>
                    <hr></hr>
                </Box>
            }
      </Box>
   
      
  );
};

export default Header;
