import { jsonHttp, secHttp } from "./http-common";

class ContractDataService {
  
    getContracts(data) {
        return jsonHttp.post(`/Contract/GetContractManagement`,data);
    }

    getNetworks() {
        return jsonHttp.get(`/Participant/networks`);
    }

    getTerms() {
        return jsonHttp.get(`/Contract/GetTerms`);
    }

    addTerms(data) {
        return jsonHttp.post(`/Contract/AddTerms`,data);
    }

    deleteTerms(data) {
        return jsonHttp.put(`/Contract/DeleteTerms`, data);
    }
    //getContracts(key, data) {
    //    return jsonHttp.post(`/Contract/`, data);
    //}
    
}
export default new ContractDataService();