import { jsonHttp } from "./http-common";


class ConfigDataService {

	getConfigValue(key) {
		return jsonHttp.get(`/ConfigSettings/${key}`);
	}
}


const configDataService = new ConfigDataService();
export default configDataService