import React, { useEffect, useState } from 'react';
import { GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { rolesService } from '../../ts/modules/roles/service';

import './RoleManagement.css';
import { RoleManagement} from './RoleManagement'
import { RoleComposite } from '../../ts/modules/roles/interface';
import { participantService } from '../../ts/modules/participants/service';
import { Participant } from '../../ts/modules/participants/interface';

const AppRolePage: React.FC = () => {
  
	// const [availablePermissions, setAvailableRoles] = useState<Role[]>([]);
	const [participant, setParticipant] = useState<Participant[]>([]);
	const [editRow, setEditRow] = useState< GridRenderCellParams<any, any, any> | undefined>(undefined);
	const [deleteRow, setDeleteRow] = useState<GridRenderCellParams<any, any, any> | undefined>(undefined);
	const [editValue, setEditValue] = useState('');
	const [roles, setRoles] = useState<RoleComposite[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [participantDialogOpen, setParticipantDialogOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState<RoleComposite | null>(null);
	const [searchText, setSearchText] = useState('');
	const [filteredRows, setFilteredRows] = useState<RoleComposite[]>([]);
	const [isNewRoleDialogOpen, setIsNewRoleDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		const fetchParticipants = async () => {
			const participants = await participantService.fetchAllParticipants();
			setParticipant(participants);
		};

		const fetchRoles = async () => {

			try{
				const roles = await rolesService.fetchParticipantRoles();
				setRoles(roles);
				setFilteredRows(roles);
			}catch(error){
				console.error("Failed to fetch roles", error);
				setIsLoading(false);
			}
		};

		setIsLoading(true);
		fetchParticipants();
		fetchRoles()
		setIsLoading(false);
	}, []);


	useEffect(() => {
		setFilteredRows(roles);
	}, [roles]);

 
	const handleEditClick = (params: GridRenderCellParams<any, any, any>) => {
		setEditRow(params);
		setEditValue(params.row.roleName);
	};


	const handleDeleteClick = (params: GridRenderCellParams<any, any, any>) => {
		setDeleteRow(params);
	};


	const handleOpenNewRoleDialog = () => {
		setIsNewRoleDialogOpen(true);
	};

	const handleSave = async (id: GridRowId) => { 
		setIsLoading(true);

	//	try {
	//		let roleUpdate: ParticipantRole = {
	//			roleKey: Number(id.toString()),
	//			roleDescription: editValue,
	//			createDate: new Date(),
	//			participantKeys: selected
	//		}
	//		const updatedRole = await rolesService.updateRoleName(Number(id.toString()), { name: editValue });
	//		// Update the rows state with the updated role
	//		setRoles(currentRows => currentRows.map(role => role.roleKey === id ? updatedRole : role));
	//	} catch (error) {
	//		console.error("Failed to update role", error);
	//	} finally {
	//		setIsLoading(false);
	//		setEditRow(undefined); // Exit edit mode
	//	}
	};

	const handleDelete = async (id: GridRowId) => {
		setIsLoading(true);

		try {
			const deletedRole = await rolesService.deleteParticipantRole(Number(id.toString()));

			// Update the rows state with the deleted role
			setRoles(roles.filter(item => item.roleKey !== deletedRole.roleKey));
		}
		catch (error) {
			console.error("Failed to delete role", error);
		} finally {
			setIsLoading(false);
			setDeleteRow(undefined); // Exit delete mode
		}
	};

	const handleCancel = () => {
		setEditRow(undefined);
		setDeleteRow(undefined);
	};

	const handleReset = () => {
		setSearchText('');
		setFilteredRows(roles);
	};

	const handleSearch = () => {
		const searchLowerCase = searchText.toLowerCase();
		const filtered = roles.filter((role) => role.roleDescription.toLowerCase().includes(searchLowerCase));
		setFilteredRows(filtered);
	};

	const handleOpenParticipantsDialog = async (role: RoleComposite) => {
		//refresh the keys in case things have changed from a different role update
		const updatedRole = await rolesService.fetchParticipantKeysByRoleKey(role.roleKey);
		setSelectedRole(updatedRole);
		setParticipantDialogOpen(true);
	};


	const handleCloseNewRoleDialog = () => {
		setIsNewRoleDialogOpen(false);
	};
  
	const handleUpdateRole = (updatedRole: RoleComposite) => {
		setRoles((prevRows) => prevRows.map((role) => role.roleKey === updatedRole.roleKey ? updatedRole : role));
	};

	const handleSaveNewRole = async (newRole: Omit<RoleComposite, 'roleKey'>) => {
		setIsLoading(true); // Assume you have a loading state to indicate progress
  
		try {
			const addedRole = await rolesService.addRole(newRole);

			setRoles(currentRows => {
				const updatedRows = [...currentRows, addedRole];
				return updatedRows;
			});
       
			// Optionally, show a success message or perform other actions on successful addition
		} catch (error) {
			console.error("Failed to add new role", error);
			// Optionally, show an error message or handle the error
		} finally {
			setIsLoading(false);
			setIsNewRoleDialogOpen(false); // Close the dialog regardless of the outcome
		}
	};
  
      
	return (
		<RoleManagement
			isLoading={isLoading}
			roles={filteredRows}
			participant={participant}
			searchText={searchText}
			onSearchChange={(e) => setSearchText(e.target.value)}
			onSearch={() => { handleSearch() }}
			onReset={() => { handleReset()}}
			onOpenNewRoleDialog={() => handleOpenNewRoleDialog()}
			isNewRoleDialogOpen={isNewRoleDialogOpen}
			onCloseNewRoleDialog={() => handleCloseNewRoleDialog() }
			onSaveNewRole={(newRole) => { handleSaveNewRole(newRole) }}
			participantDialogOpen={participantDialogOpen}
			selectedRole={selectedRole}
			onOpenParticipantsDialog={(role) => { handleOpenParticipantsDialog(role)}}
			onCloseParticipantsDialog={() => setParticipantDialogOpen(false)}
			onUpdateRole={(updatedRole) => { handleUpdateRole(updatedRole)}}
			editRowId={editRow?.id}
			deleteRowId={deleteRow?.id}
			editValue={editValue}
			onEditChange={(e) => setEditValue(e.target.value)}
			onStartEdit={(params: GridRenderCellParams<any, any, any>) => {  handleEditClick(params) }}
			onStartDelete={(params: GridRenderCellParams<any, any, any>) => { handleDeleteClick(params) }}
			onSaveEdit={() => {  if(editRow) handleSave(editRow.id)}}
			onConfirmDelete={() => { if (deleteRow) handleDelete(deleteRow.id) }}
			onCancelEdit={() => { handleCancel() }}
			onCancelDelete={() => { handleCancel() }}
			key={"appRoles"}
			title={'App Role Management'}
		>
		</RoleManagement>
	);
};
export { AppRolePage };
