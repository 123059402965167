import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { Box, ListItemIcon, Stack, ListItemText, Typography } from '@mui/material'

import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

import Header from "../../Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FileSchemaDataService from "../../../service/FileSchemaService";
import TextField from '@mui/material/TextField';


import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';


export const AlterationNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div >*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">Alteration</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            {/*<div className="node-content">*/}
            {/*    <Box>*/}
            {/*        <DriveFileRenameOutlineIcon sx={{ fontSize: 40 }} />*/}
            {/*    </Box>*/}
            {/*</div>*/}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <DriveFileRenameOutlineIcon
                    width="65px"
                    height="65px"
                    sx={{ width: "65px", height: '65px' }}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>


            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class AlterationModel extends NodeModel {
    constructor(options) {
        super({
            name: options?.name || "Alteration",
            id: options?.id,
            type: "Alteration"
        });
        this.depth = 0;
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.precendentNodes = new Set();
        this.nodedata = options?.nodedata;

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            name: this.options.name,
            inputFields: this.inputFields,
            outputFields: this.outputFields,
            precendentNodes: Array.from(this.precendentNodes),
            nodedata: this.nodedata,
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.options.name = event.data.name;
        this.nodedata = event.data.nodedata;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }


}

// TODO: Refactor to hooks
export class AlterationNodeFactory extends AbstractReactFactory {
    constructor() {
        super("Alteration");
    }

    generateModel(initialConfig) {
        return new AlterationModel();
    }

    generateReactWidget(event) {
        return <AlterationNodeWidget engine={this.engine} node={event.model} />;
    }
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export function AlterationComponent(props) {

    const DATA_TYPES = [{ id: 'No Change', value: '' },
        { id: 'Integer', value: 'Integer' },
        { id: 'String', value: 'String' },
        { id: 'Date', value: 'Date' },
        { id: 'Numeric', value: 'Numeric' }]

    const currentComponent = props.currentComponent;

    const [selected, setSelected] = React.useState([]);

    const [selectedColumns, setSelectedColumns] = React.useState([{ originalName: '', newName: '', newDataType: 'No Change' }]);

    const [open, setOpen] = React.useState(false);

    const [fileColumns, setFileColumns] = React.useState([]);

    const [nodeName, setNodeName] = React.useState('');


    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (currentComponent.nodedata && currentComponent.nodedata.alteratingColumns) {
            setSelectedColumns(currentComponent.nodedata.alteratingColumns)
        }
        const parentsNodes = Object.values(currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.outputFields) {
                    currentComponent.inputFields = parent.outputFields
                }
            })
        }
    }, []);

    const handleOnOpen = () => {
        const inputFile = Object.values(currentComponent.ports['in'].links)
            .map(link => link.sourcePort.parent)
            .map(parent => parent.nodedata);

        if (inputFile.length > 0 && inputFile[0] && inputFile[0].clientfileschemakey) {
            FileSchemaDataService.getFileSchemaByClientFileSchemaKey(inputFile[0].clientfileschemakey)
                .then((res) => {
                    setFileColumns(res.data.map((data, index) => {
                        return {
                            id: index,
                            FieldOrder: data.fieldorder,
                            FieldName: data.fieldname,
                            FieldDesc: data.fielddesc,
                            FieldFormat: data.fieldformatdesc
                        }
                    }));
                })
                .catch(err => {
                    console.log(err);
                })
        }
        setOpen(true)

        console.log("calling handleOnOpen")
    } 



    const handleAddSelectedColumn = (event) => {
        setSelectedColumns(selectedColumns => [...selectedColumns, { originalName: '', newName: '', newDataType: 'No Change' }])
    };


    const handleDeleteSelectedColumn = (event, id) => {
        setSelectedColumns(selectedColumns => selectedColumns.filter((column, index) => index !== id))
    };

    const handleAddAllClick = (event) => {
            const inputFile = Object.values(currentComponent.ports['in'].links)
                .map(link => link.sourcePort.parent)
                .map(parent => parent.nodedata);

            if (inputFile.length > 0 && inputFile[0] && inputFile[0].clientfileschemakey) {
                FileSchemaDataService.getFileSchemaByClientFileSchemaKey(inputFile[0].clientfileschemakey)
                    .then((res) => {

                        const columns = res.data.map((data, index) => {
                            return {
                                id: index,
                                FieldOrder: data.fieldorder,
                                FieldName: data.fieldname,
                                FieldDesc: data.fielddesc,
                                FieldFormat: data.fieldformatdesc
                            }
                        })
                        setSelectedColumns(columns.map(col => { return { newName: '', column: col.FieldName} }))
                        setFileColumns(columns);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }

    }


    return (

        <Stack direction="row" spacing={5} width={"50%"}>
        <Box spacing={5} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        autoComplete='off'
                        label="Node Name"
                        value={nodeName}
                        onChange={(event) => {
                            setNodeName(event.target.value)
                            currentComponent.options.name = event.target.value;
                        }}
                    />
                </FormControl>

                <br />

          {selectedColumns.map((selectedColumn, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                  <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <InputLabel id="selected-column-select-label">Column</InputLabel>
                    <Select
                        labelId="selected-column-select-label"
                        id="selected-column-select-label"
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => { handleOnOpen() }}
                        label="selected Column"
                        value={selectedColumn.originalName}
                        onChange={(event) => {
                            const newColumns = [...selectedColumns];
                            const newColumn = newColumns[index]
                            newColumn.originalName = event.target.value
                            setSelectedColumns(newColumns)
                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.alteratingColumns = newColumns
                            } else {
                                currentComponent.nodedata = { alteratingColumns: newColumns }
                            }
                        }}
                    >
                        {fileColumns.map(column => (
                            <MenuItem key={column.id} value={column.FieldName}>
                                {column.FieldName}
                            </MenuItem>
                        ))}
                    </Select>
                    <br />
                </FormControl>

                  <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        disableAutoFocus='true'
                         autoComplete='off'
                          placeholder='No Change'
                          label='New Column Name'
                        value={selectedColumn.newName}
                        onChange={(event) => {
                            const newColumns = [...selectedColumns];
                            const column = newColumns[index]
                            column.newName = event.target.value
                            setSelectedColumns(newColumns)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.alteratingColumns = newColumns
                            } else {
                                currentComponent.nodedata = { alteratingColumns: newColumns }
                            }

                            if (currentComponent.inputFields) {
                                const outputFields = Array.from(currentComponent.inputFields);
                                outputFields[index].FieldName = event.target.value
                                currentComponent.outputFields = outputFields;
                            }
               

                        }}
                    />
                  </FormControl>

                  <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                      <InputLabel id="cast-data-type-label">Cast Data Type</InputLabel>
                      <Select
                          labelId="cast-data-type-label"
                          id="cast-data-type"
                          label='Cast Data Type'
                          value={selectedColumn.newDataType}
                          onChange={(event) => {
                              const newColumns = [...selectedColumns];
                              const column = newColumns[index]
                              column.newDataType = event.target.value
                              setSelectedColumns(newColumns)

                              if (currentComponent.nodedata) {
                                  currentComponent.nodedata.alteratingColumns = newColumns
                              } else {
                                  currentComponent.nodedata = {
                                      alteratingColumns: newColumns
                                  }
                              }

                          }}
                      >
                          {DATA_TYPES.map((type, index) => (
                              <MenuItem key={index} value={type.value}>
                                  {type.id}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>



                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteSelectedColumn(event, index)}>
                    <ClearIcon />
                </IconButton>

            </Stack>
        ))
        }

        <IconButton aria-label="Add"
            sx={{
                minWidth: 30,
                maxWidth: 30,
            }} 
            onClick={handleAddSelectedColumn}>
            <AddIcon />
        </IconButton>

            </Box>
        </Stack>
        )
}
