import React, { FormEvent, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControlLabel, Checkbox, FormGroup, Box, Typography } from '@mui/material';
import { participantService } from '../../ts/modules/participants/service';
import { RoleComposite } from '../../ts/modules/roles/interface';
import { Participant } from '../../ts/modules/participants/interface';

interface NewRoleDialogProps {
	open: boolean;
	onClose: () => void;
	onSave: (compositeRole: RoleComposite) => void;
	availableParticipants: Participant[];
}
  
  
const NewRoleDialog: React.FC<NewRoleDialogProps> = ({ open, onClose, onSave }) => {
	const [roleName, setRoleName] = useState<string>('');
	const [selectedParticipants, setselectedParticipants] = useState<Participant[]>([]);
	const [availableParticipants, setAvailableParticipants] = useState<Participant[]>([]);
  
	useEffect(() => {
		participantService.fetchAllParticipants().then(setAvailableParticipants);
	}, []);

	console.log("NewRoleDialog");

	const handleParticipantChange = (participant: Participant) => {
		setselectedParticipants(prev => prev.includes(participant)
			? prev.filter(p => p.systemClientKey !== participant.systemClientKey)
			: [...prev, participant]);
	};

	const handleSave = () => {
		const newRole: RoleComposite = {
			userKey: 0,
			roleKey: Math.random(), // Replace with backend-generated ID upon integration
			roleDescription: roleName,
			createDate: new Date(),
			participantKeys: selectedParticipants.map(participant => participant.systemClientKey),
			permissions: []
		};

		onSave(newRole);
		onClose(); // Close dialog and assume reset logic is handled elsewhere or upon successful save
	};

	const handleSelectAllParticipants = () => {
		setselectedParticipants(availableParticipants);
	};

	const handleDeselectAllParticipants = () => {
		setselectedParticipants([]);
	};

	const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRoleName(event.target.value);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
		<DialogTitle>Add New Role</DialogTitle>
		<DialogContent>
			<Box mb={2}>
				<TextField
					autoFocus
					margin="dense"
					id="role-name"
					label="Role Name"
					type="text"
					fullWidth
					variant="outlined"
					value={roleName}
					onChange={handleRoleChange}
				/>
			</Box>
			<Typography variant="subtitle1" gutterBottom>
				Assign Participants:
			</Typography>
			<Button onClick={handleSelectAllParticipants}>Select All</Button>
			<Button onClick={handleDeselectAllParticipants}>DeSelect All</Button>
			<FormGroup>
					{availableParticipants.map(participant => (
					<FormControlLabel
							key={participant.systemClientKey}
						control={
							<Checkbox
								checked={selectedParticipants.includes(participant)}
								onChange={() => handleParticipantChange(participant)}
							/>
						}
							label={participant.participantName}
					/>
				))}
			</FormGroup>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>Cancel</Button>
			<Button onClick={handleSave}>Save</Button>
		</DialogActions>
		</Dialog>
	);
};
  

export { NewRoleDialog }