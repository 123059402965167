import { jsonHttp } from "./http-common";

class FileTypeDataService {
    getAll() {
        return jsonHttp.get("/FileType");
    }

    get(id) {
        return jsonHttp.get(`/FileType/${id}`);
    }

    getByClientKey(key) {
        return jsonHttp.get(`/FileType/client/${key}`);
    }

    getFileTypeByName(name) {
        return jsonHttp.get(`/FileType/Name/${name}`);
    }

    getFileTypeDataModel(id) {
        return jsonHttp.get(`/FileType/getFileTypeDataModel/${id}`);
    }

    getAuditModelSchemas() {
        return jsonHttp.get(`/FileType/GetAuditModelSchemas`);
    }

    createForClient(key, data) {
        return jsonHttp.post(`/FileType/createForClient/${key}`, data);
    }

    create(data) {
        return jsonHttp.post("/FileType", data);
    }

    update(id, data) {
        return jsonHttp.put(`/FileType/${id}`, data);
    }
    
    updateAuditModelSchema(data) {
        return jsonHttp.post(`/FileType/updateAuditModelSchema`, data);
    }

    delete(id) {
        return jsonHttp.delete(`/FileConfig/${id}`);
    }


    deleteFileType(id) {
        return jsonHttp.delete(`/FileType/DeleteFileType/${id}`);
    }
}
export default new FileTypeDataService();