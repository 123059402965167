// AuthContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';

import { AuthenticationResult } from '@azure/msal-browser';

import { useAuth } from './useAuth';
import { UserInfo, AuthService } from '../service/Authentication';

interface AuthContextProps {
	getUser: () => UserInfo | null;
	login: () => Promise<void>;
	logout: () => Promise<void>;
	setAuthToken: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
  authService: AuthService;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, authService }) => {
	const { getUser, login, logout, setAuthToken } = useAuth(authService);
  
  return (
	  <AuthContext.Provider value={{ getUser, login, logout, setAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within AuthProvider');
  }
  return context;
};
