import { Role } from '../roles/interface';
import { rolesService } from '../roles/service'; // Adjust the import path as needed
import { User } from './interface';
import { getConfiguration } from '../../../config.js';



class UserService {
	private localStorageKey: string = 'users';
	private apiUrl: string = getConfiguration().secApiUrl + '/SAIMUserRolePermission';
	private userApiUrl: string = getConfiguration().secApiUrl + '/User';

	//private  async generateMockUsers(): Promise<User[]> {
	//	const roles: Role[] = await rolesService.fetchRoles();

	//	const names = ['Snow', 'Lannister', 'Stark', 'Targaryen', 'Baratheon'];
	//	const firstNames = ['Jon', 'Cersei', 'Arya', 'Daenerys', 'Robert'];
	//	const numUsers = Math.floor(Math.random() * 40) + 1;
	//	const getRandomDate = (start: Date, end: Date) => 
	//	new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

	//	const users = Array.from({ length: numUsers }, (_, index) => ({
	//		userKey: index + 1,
	//		userName: `user${index + 1}`,
	//		lastName: names[Math.floor(Math.random() * names.length)],
	//		firstName: firstNames[Math.floor(Math.random() * firstNames.length)],
	//		email: `user${index + 1}@example.com`,
	//		//dob: getRandomDate(new Date(1950, 0, 1), new Date(2005, 0, 1)),
	//		createDate: getRandomDate(new Date(2015, 0, 1), new Date()),
	//		//lastLoginDate: getRandomDate(new Date(2022, 0, 1), new Date()),
	//		//accountStatus: ['Active', 'Suspended', 'Deleted'][Math.floor(Math.random() * 3)] as 'Active' | 'Suspended' | 'Deleted',
	//		roles: [roles[Math.floor(Math.random() * roles.length)]],
	//		//dataRoles: [],
	//	}));

	//	return users;
	//}

	//public async fetchUsers(): Promise<User[]> {
	//	return new Promise(async (resolve) => {
	//	setTimeout(async () => {
	//		let users: User[] = JSON.parse(localStorage.getItem('users') || '[]');
	//		if (users.length === 0) {
	//			users = await this.generateMockUsers();
	//			localStorage.setItem('users', JSON.stringify(users));
	//		}
	//		resolve(users);
	//	}, 1000); // Simulate async delay
	//	});
	//}

	public async fetchUsers(): Promise<User[]> {
		return new Promise<User[]>((resolve) => {
			try {
				const getUrl = this.apiUrl;

				fetch(getUrl, { method: 'GET', headers: { 'Content-Type': 'application/json', } })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							resolve(response.json()); //resolve(permissions); 
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem retrieving the users:', error);
				throw error;
			}
		});
	}


	public async addNewUser(user: Omit<User, 'userKey'>): Promise<User> {
		const users = await this.fetchUsers();
		const newUser: User = { ...user, userKey: Date.now() }; // Simplified ID assignment
		const updatedUsers = [...users, newUser];
		localStorage.setItem('users', JSON.stringify(updatedUsers));
		return newUser;
	}

	// Update user method, assuming a similar pattern
	//public  async updateUser(userKey: number, updates: Partial<Omit<User, 'userKey'>>): Promise<void> {
	//	const users = await this.fetchUsers();
	//	const updatedUsers = users.map(user => user.userKey === userKey ? { ...user, ...updates } : user);
	//	localStorage.setItem('users', JSON.stringify(updatedUsers));
	//}

	public async addUser(user: Omit<User, 'userKey'>): Promise<User> {
		return new Promise<User>((resolve) => {
			try {
				const postUrl = this.userApiUrl;

				console.log("New user in addUser: ", JSON.stringify(user));

				fetch(postUrl, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(user), })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status}, message: ${response.statusText})`);
						else {
							resolve(response.json());
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem updating the user:', error);
				throw error;
			}
		});
	}


	public async updateUser(userKey: number, updates: Partial<Omit<User, 'userKey' | 'roles'>>): Promise<User> {
		return new Promise<User>((resolve) => {
			try {
				const putUrl = this.userApiUrl + '/' + userKey;

				console.log("Updates in updateUser: ", JSON.stringify(updates));

				fetch(putUrl, { method: 'PUT', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(updates), })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							resolve(response.json()); 
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem updating the user:', error);
				throw error;
			}
		});
	}


	public async updateUserRoles(userKey: number, updatedRoles: Role[]): Promise<User> {
		return new Promise<User>((resolve) => {
			try {
				const putUrl = this.apiUrl + '/' + userKey;

				console.log("newRoles in updateUserRoles: ", JSON.stringify(updatedRoles));

				fetch(putUrl, { method: 'PUT', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(updatedRoles), })
					.then((response) => {
						if (!response.ok)
							throw new Error(`Network response was not ok (status: ${response.status})`);
						else {
							resolve(response.json());
						}
					})
					.catch((error) => {
						throw new Error('error: ' + error);
					});
			} catch (error) {
				console.error('There was a problem updating the user:', error);
				throw error;
			}
		});
	}



	//public async updateUserRoles(userKey: number, newRoles: Role[]): Promise<User> {
	//	return new Promise((resolve, reject) => {
	//		const simulatedDelay = 500;
	//		setTimeout(() => {

	//			const users: User[] = JSON.parse(localStorage.getItem('users') || '[]');
	//			const userIndex = users.findIndex(user => user.userKey === userKey);
	//			if (userIndex === -1) {
	//				reject(new Error('User not found'));
	//				return;
	//			}

	//			// Update the user's roles
	//			users[userIndex].roles = newRoles;
	//			//users[userIndex].dataRoles = newDataRoles;

	//			// Simulate persisting the updated users array back to an API
	//			localStorage.setItem('users', JSON.stringify(users));

	//			// Resolve the promise with the updated user
	//			resolve(users[userIndex]);
	//		}, simulatedDelay);
	//	});
	//}


//	public async updateUserRoles(userKey: number, newRoles: Role[]): Promise<User> {
//		return new Promise((resolve, reject) => {
//			const simulatedDelay = 500; 
//			setTimeout(() => {
       
//				const users: User[] = JSON.parse(localStorage.getItem('users') || '[]');
//				const userIndex = users.findIndex(user => user.userKey === userKey);
//				if (userIndex === -1) {
//					reject(new Error('User not found'));
//					return;
//				}

//				// Update the user's roles
//				users[userIndex].roles = newRoles;
//				//users[userIndex].dataRoles = newDataRoles;

//				// Simulate persisting the updated users array back to an API
//				localStorage.setItem('users', JSON.stringify(users));

//				// Resolve the promise with the updated user
//				resolve(users[userIndex]);
//			}, simulatedDelay);
//		});
//	}
}

export const userService = new UserService();