// useAuth.ts
import { AuthService, UserInfo } from '../service/Authentication';

import {  useMsal  } from "@azure/msal-react";

export const useAuth = (authService: AuthService) => {

	const { instance, accounts, inProgress } = useMsal();

	const login = async () => {
		const accountInfo = await authService.login();
	};

	const logout = async () => {
		await authService.logout();
	};

	const getUser = () => {
		return authService.getUserInfo(accounts[0]);
	}

	const setAuthToken = () => {
		authService.setAccessToken();
	}

	return { getUser, login, logout, setAuthToken};
};
