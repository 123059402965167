import React, { useState, useEffect, useCallback } from 'react';
import {
    Box as MuiBox,
    Button,
    IconButton,
    useTheme,
    TextField,
    Stack,
    Divider,
    Autocomplete,
    Drawer,
    Toolbar,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    useMediaQuery,
    InputAdornment,
    Modal,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Formik } from "formik";
import * as yup from 'yup';
import ClientDataService from "../../service/ClientDataService";
import AuditClientDataService from "../../service/AuditClientDataService";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RightContainer from "../global/RightContainer";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ContractDataService from "../../service/ContractDataService";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Header from "../../components/Header";
import RecursiveTreeView from '../../components/treeView/RecursiveTreeView';
import PdfViewer from '../../components/common/PdfViewer';
import ConfirmDialog from '../../components/common/confirmDialog';

// Define a style object for the modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function yyyymmdd(date) {
    var x = new Date(date);
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <MuiBox
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <MuiBox sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </MuiBox>
            )}
        </MuiBox>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const ContractList = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { contractListItems } = props;
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [drawerOpen, setDrawerOpen] = useState(false);



    const [selectedContract, setSelectedContract] = useState(null);

    const [clientNetworks, setClientNetworks] = useState({});
    const [termtypes, setTermtypes] = useState([]);
    const [clientNetworkOpen, setClientNetworkOpen] = useState(false);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [errorMessage, setErrorMessage] = useState("");

    const [networkValue, setNetworkValue] = useState('');
    const [termTypeValue, setTermTypeValue] = useState('');
    const [termNameValue, setTermNameValue] = useState('');
    const [networksSelected, setNetworksSelected] = useState([]);
    const [addTextValue, setAddTextValue] = useState('');
    const [pageReferenceValue, setPageReferenceValue] = useState('');
    const [alreadySelected, setAlreadySelected] = React.useState([]);
    const [selectedPair, setSelectedPair] = React.useState([]);
    const [selectedContractTerm, setSelecedtContractTerm] = React.useState({
        contracttermskey: 0
    });
    const [isShowComfirm, setIsShowComfirm] = React.useState(false);
    const [comfirmMessage, setComfirmMessage] = React.useState('');

    //useEffect(() => {
    //    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');
    //    ClientDataService.getUserNetworksByDate(userKey, -1, -1)
    //        .then((res) => {
    //            return res.data;
    //        })
    //        .then(data => {
    //            const groupedBy = Object.groupBy(data.value, x => x.participant.systemclientkey);

    //            const tree = Object.keys(groupedBy)
    //                .map((key) => ({
    //                    id: key,
    //                    isNetwork: false,
    //                    name: groupedBy[key][0].participant.participantname,
    //                    children: groupedBy[key].map((child) => ({
    //                        id: child.network.networkkey,
    //                        parentId: key,
    //                        isNetwork: true,
    //                        name: child.network.networkdesc || " "
    //                    }))
    //                }));

    //            setClientNetworks({
    //                id: 0,
    //                name: "client/Networks",
    //                children: tree
    //            });
    //        })
    //        .catch(err => console.log(err));

    //    ContractDataService.getTerms()
    //        .then((res) => {
    //            return res.data;
    //        })
    //        .then(data => {
    //            console.log(data);
    //            setTermtypes(data);
    //        })
    //        .catch(err => console.log(err));

    //}, []);


    useEffect(() => {
        if (selectedContract && selectedContract?.clientkey <= 0) return;
    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');
    ClientDataService.getUserNetworksByDate(userKey, -1, -1)
        .then((res) => {
            return res.data;
        })
        .then(data => {
            const filtersystemclientkey = [];
            filtersystemclientkey.push(selectedContract.clientkey);
            const filteredData = data.value.filter(item =>
                filtersystemclientkey.includes(item.participant.systemclientkey)
            );
            const groupedBy = Object.groupBy(filteredData, x => x.participant.systemclientkey);
            const tree = Object.keys(groupedBy)
                .map((key) => ({
                    id: key,
                    isNetwork: false,
                    name: groupedBy[key][0].participant.participantname,
                    children: groupedBy[key].map((child) => ({
                        id: child.network.networkkey,
                        parentId: key,
                        isNetwork: true,
                        name: child.network.networkdesc || " "
                    }))
                }));

            setClientNetworks({
                id: 0,
                name: "client/Networks",
                children: tree
            });
        })
        .catch(err => console.log(err));

    }, [selectedContract]);

useEffect(() => {

    ContractDataService.getTerms()
        .then((res) => {
            return res.data;
        })
        .then(data => {
            console.log(data);
            setTermtypes(data);
        })
        .catch(err => console.log(err));

}, []);

    const handleClientNetworkClose = () => {
        setClientNetworkOpen(false);
    }

    const onClientNetworkOpen = () => {
        setClientNetworkOpen(true); 
    }

    const handleNetworkChange = (e) => {
        setNetworkValue(e.target.value);
    };

    const handleTermTypeChange = (e) => {
        setTermTypeValue(e.target.value);
    };

    const handleTermNameChange = (e) => {
        setTermNameValue(e.target.value);
    };

    const handleAddTextChange = (e) => {
        setAddTextValue(e.target.value);
    };

    const handlePageReferenceChange = (e) => {
        setPageReferenceValue(e.target.value);
    };

    const handleClientNetworkSave = () => {

        console.log(selectedPair);
        handleClientNetworkClose();
    }

    const handleSave = () => {
        // Implement save functionality
        let data = [];
      
        if (selectedContractTerm.contracttermskey > 0) {
            //build update dto
            data.push({
                contracttermskey: selectedContractTerm.contracttermskey,
                termTypekey: termTypeValue,
                termname: termNameValue,
                termdesc: addTextValue,
                textpageref: pageReferenceValue,
            });
        }
        else {
            //build create new dto
            data = selectedPair.map(x => {
                return {
                    contractkey: selectedContract.contractkey,
                    networkkey: x.auditnetworkkey.id,
                    termTypekey: termTypeValue,
                    termname: termNameValue,
                    termdesc: addTextValue,
                    textpageref: pageReferenceValue,
                }
            });
            
            if (!data[0].contractkey) {
                alert("Please select at least one Client/Networks.");
                return;
            }
        }

        if (!data[0].termTypekey || !data[0].termname || !data[0].termdesc || !data[0].textpageref) {
            alert("Please fill in all fields.");
            return;
        }

        //console.log(data)
        ContractDataService.addTerms(data)
            .then((res) => {
                return res.data
            }).then(data => {

                setNetworkValue(null);
                setTermTypeValue(null);
                setTermNameValue("");
                setAddTextValue("");
                setPageReferenceValue("");
                setIsModalOpen(false);
                var _selectedContract = JSON.parse(JSON.stringify(selectedContract));
                if (selectedContractTerm.contracttermskey > 0) {
                    var _data = data.data[0];
                    var _contractNetworkItems = selectedContract.contractNetworkItems.map((row) => {
                        if (row.contracttermskey === selectedContractTerm.contracttermskey) {
                            row.termtypekey = _data.termtypekey;
                            row.termname = _data.termname;
                            row.contracttermsdesc = _data.contracttermsdesc;
                            row.textpageref = _data.textpageref;
                        }
                        return row;
                    });

                    setSelectedContract({ ...selectedContract, contractNetworkItems: _contractNetworkItems });
                    setSelecedtContractTerm({ contracttermskey : 0 });
                    return;
                }
                _selectedContract.contractNetworkItems.push(...data.data);
                setSelectedContract(_selectedContract);
                forceUpdate();
                alert(data.message);
            })
            .catch(err => console.log(err));
    };

    const handleDelete = () => {
        // Implement delete functionality
            ContractDataService.deleteTerms(selectedContractTerm).then((res) => {
                return res.data
            }).then(data => {
                const _contractNetworkItems = selectedContract.contractNetworkItems.filter(record => record.contracttermskey !== selectedContractTerm.contracttermskey);
                setSelectedContract({ ...selectedContract, contractNetworkItems: _contractNetworkItems });
                setSelecedtContractTerm({ contracttermskey: 0 });
                setIsModalOpen(false);
                setIsShowComfirm(false);
            }).catch(err => console.log(err));
    };

    const showDeleteModal = (item) => {
        setComfirmMessage("Are you sure you want to delete this item?");
        setIsShowComfirm(true);
    }

    const columns = [
        { field: "contractName", headerName: "Contract Name", width: 150 },
        { field: "affiliateName", headerName: "Affiliate", width: 100 },
        { field: "clientName", headerName: "Client", width: 100 },
        { field: "startDate", headerName: "Start Date", width: 100 },
        { field: "endDate", headerName: "End Date", width: 100 },
    ];

    const handleRowClick = (params) => {
        const selected = contractListItems.find((row) => row.id === params.row.id);
        setSelectedContract(selected);
        setAlreadySelected([selected]);
    };

    const onSelectedContractClick = (params) => {
        const selected = selectedContract.contractNetworkItems.find((row) => row.id === params.row.id);
        console.log(selected);
        setSelecedtContractTerm(selected);
        setTermTypeValue(selected.termtypekey);
        setTermNameValue(selected.termname);
        setAddTextValue(selected.termdesc);
        setPageReferenceValue(selected.textpageref);
        setIsModalOpen(true);
    }

    const additionalGridColumns = [
        { field: 'network', headerName: 'Network', width: 150 },
        { field: 'termtype', headerName: 'Term Type', width: 150 },
        { field: 'termname', headerName: 'Term Name', width: 150 },
    ];

    // State to handle modal open/close
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleManageTermsClick = (contract) => {
        setSelectedContract(contract);
        setDrawerOpen(true);
    };


    return (
        <MuiBox>
            <ConfirmDialog open={isShowComfirm} title='Confirm' onConfirm={() => { handleDelete() }} close={() => setIsShowComfirm(false)} message={comfirmMessage}></ConfirmDialog>
            <MuiBox>
                <MuiBox mt="20px">
                    <MuiBox display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h3">Contracts</Typography>
                        <Button onClick={() => setDrawerOpen(!drawerOpen)}>
                            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                        </Button>
                    </MuiBox>
                    <MuiBox
                        display="flex"
                        justifyContent="center"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                backgroundColor: "#F9F9F9",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: '#444e62',
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                    >
                        <MuiBox width="50%" height="600px">
                            <DataGrid
                                rows={contractListItems}
                                columns={columns}
                                onRowClick={handleRowClick}
                            />
                        </MuiBox>
                        <MuiBox
                            display="flex"
                            flexDirection="column"
                            width="50%"
                            p={2}
                        >
                            {selectedContract ? (
                                <>
                                    <Typography>Contract Name: {selectedContract.contractName}</Typography>
                                    <Typography>Affiliate: {selectedContract.affiliateName}</Typography>
                                    <Typography>Client: {selectedContract.clientName}</Typography>
                                    <Typography>Start Date: {selectedContract.startDate}</Typography>
                                    <Typography>End Date: {selectedContract.endDate}</Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2 }}
                                        onClick={() => handleManageTermsClick(selectedContract)}
                                    >
                                        Manage Terms
                                    </Button>
                                    <MuiBox
                                        p={2}
                                        height="400px"
                                        mt={2}
                                    >
                                        <Typography variant="h6" gutterBottom>Contract Terms</Typography>
                                        <DataGrid
                                            rows={selectedContract.contractNetworkItems}
                                            columns={additionalGridColumns}
                                        />
                                    </MuiBox>
                                </>
                            ) : (
                                <Typography variant="h6" color="textSecondary">Select a contract to see its details and terms.</Typography>
                            )}
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{ style: { width: '90%' } }}
            >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <MuiBox p={2} display="flex" flexDirection="column" alignItems="center">
                    <MuiBox display="flex" width="100%">
                        {/* Contract Details and Terms */}
                        <MuiBox width="40%" display="flex" flexDirection="column" p={2}>

                            {selectedContract ? (
                                <>
                                    <Typography>Contract Name: {selectedContract.contractName}</Typography>
                                    <Typography>Affiliate: {selectedContract.affiliateName}</Typography>
                                    <Typography>Client: {selectedContract.clientName}</Typography>
                                    <Typography>Start Date: {selectedContract.startDate}</Typography>
                                    <Typography>End Date: {selectedContract.endDate}</Typography>
                                    <Button variant="contained" onClick={handleOpenModal} sx={{ mt: 2 }}>
                                        Add Contract Term
                                    </Button>
                                    <MuiBox
                                        p={2}
                                        height="400px"
                                        mt={2}
                                    >
                                        <Typography variant="h6" gutterBottom>Contract Terms</Typography>
                                        <DataGrid
                                            rows={selectedContract.contractNetworkItems}
                                            columns={additionalGridColumns}
                                            onRowClick={onSelectedContractClick}
                                        />
                                    </MuiBox>
                                </>
                            ) : (
                                <Typography variant="h6" color="textSecondary">Select a contract to see its details and terms.</Typography>
                            )}
                        </MuiBox>
                        {/* PDF Viewer Placeholder */}
                        <MuiBox width="60%" display="flex" flexDirection="column" alignItems="center" p={2}>
                            <Typography variant="h4" color="textSecondary" sx={{marginBottom:"20px"}}>
                                PDF Viewer
                            </Typography>
                            {
                                selectedContract && selectedContract.contractkey
                                &&
                                <PdfViewer url={'/api/contract/download/' + selectedContract.contractkey}></PdfViewer>
                            }

                        </MuiBox>
                    </MuiBox>

                </MuiBox>
            </Drawer>
            <Modal open={isModalOpen} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description" width="50%">
                <MuiBox sx={modalStyle}>
                    <Grid container spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item>
                            <Typography id="modal-title" variant="h6" component="h2">Add Contract Term</Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleCloseModal} >Close</Button>
                        </Grid>
                    </Grid>


                    {/* Add Networks Dropdown */}
                    <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                        <Button type="submit" color="primary" variant="contained" disable={selectedContractTerm.contracttermskey > 0} onClick={() => { onClientNetworkOpen() }} sx={{ width: '200px' }}>
                            Select Client/Networks
                        </Button>
                    </FormControl>

                    {/* Term Type Dropdown */}
                    <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                        <InputLabel id="term-type-label">Term Type</InputLabel>
                        <Select
                            labelId="term-type-label"
                            id="term-type"
                            value={termTypeValue}
                            onChange={handleTermTypeChange}
                        >
                            {/* Add your menu items here */}
                            {
                                termtypes.map((item) =>
                                    <MenuItem value={item.contracttermstypekey}>{item.contracttermstypedesc} </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>

                    {/* Term Name TextField */}
                    <TextField
                        id="term-name"
                        label="Term Name"
                        variant="outlined"
                        fullWidth
                        value={termNameValue}
                        onChange={handleTermNameChange}
                        sx={{ mt: 2, mb: 2 }}
                    />

                    {/* Networks Selected */}
                    <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                        Networks Selected: {networksSelected.join(', ')}
                    </Typography>

                    {/* Add Text Area */}
                    <TextField
                        id="add-text"
                        label="Add text"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={addTextValue}
                        onChange={handleAddTextChange}
                        sx={{ mt: 2, mb: 2 }}
                    />

                    {/* Page Reference TextField */}
                    <TextField
                        id="page-reference"
                        label="Page Reference"
                        variant="outlined"
                        fullWidth
                        value={pageReferenceValue}
                        onChange={handlePageReferenceChange}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    {/* Save and Delete Buttons */}
                    <MuiBox display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
                        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
                        <Button onClick={showDeleteModal} variant="contained" color="secondary">Delete</Button>
                    </MuiBox>
                </MuiBox>
            </Modal>
            <Divider orientation="horizontal" />
            <Dialog
                modal={true}
                maxWidth='xl'
                width="100%"
                open={clientNetworkOpen}
                onClose={handleClientNetworkClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "50%",
                        minHeight: '50%',
                        maxHeight: '50%'
                    }
                }}>

                <DialogTitle id="alert-dialog-title">
                    <Header title="Client Networks" />
                </DialogTitle>

                <DialogContent>
                    {clientNetworks &&
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={8} md={9}>
                                    <RecursiveTreeView data={clientNetworks} setSelectedPair={setSelectedPair} alreadySelected={alreadySelected} setAlreadySelected={setAlreadySelected}></RecursiveTreeView>
                                </Grid>
                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" variant="contained" onClick={handleClientNetworkSave}>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiBox>
    );
};

export default ContractList;