import { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu, sidebarClasses } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton, Divider } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ManageSearchSharpIcon from '@mui/icons-material/ManageSearchSharp';
import DatasetLinkedSharpIcon from '@mui/icons-material/DatasetLinkedSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createGlobalStyle } from 'styled-components';
//import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
//import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../providers/AuthProvider';


import TextField from '@mui/material/TextField';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";

const Item = ({ title, to, icon, selected, setSelected, className }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
            component={<Link to={to} />}
            className={className}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};


const Logout = ({ title, to, icon, removeToken, setShow, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{ color: "#9DA4AE" }}
            onClick={() => {
                setSelected(title);
                removeToken();
            }}
            icon={icon}
            component={<Link to={to} />}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

//.ps-submenu-content => set <SubMenu/>
const GlobalStyle = createGlobalStyle`
.menu-container{
    box-shadow: 10px 0 5px -5px #dbd7d780;

    .ps-sidebar-container{
      overflow-y:hidden;
       transition: 1s;
      &:hover{
           transition: 1s;
        overflow-y:auto;
      }
    }

    .active-menu{
          border-radius: 50px;
          background-color: rgba(255, 255, 255, 0.16);
    }

    .ps-submenu-root {
       
       font-size:14px;
    }

    .menu-item{
        span{
               margin-left:0px
        }

        p{
            color: #25282E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }
    }

  .ps-menu-button:hover{
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.16);
  }
}
`;

const NFCIcon = () => {
    return (
        <img
            alt="workspaces"
            width="24px"
            height="24px"
            src={"../../assets/nfc.svg"} />
    )

}



const MyProSidebar = (props) => {


    const { getUser, login, logout, setAuthToken } = useAuthContext();

	const user = getUser()

	useEffect(() => {

		//call at most once before each api call to have a fresh token
		setAuthToken();
		//console.log("MyProSidebar, idToken", localStorage.getItem("userToken"));
	}, []);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Dashboard");
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const { instance, accounts, inProgress } = useMsal();
    const { sidebarRTL, setSidebarRTL, sidebarImage, removeToken } = useSidebarContext();
    const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
    const location = useLocation();
    const setActiveMenu = (name) => {
        let className = 'site-text menu-item ';
        if (!name) return className + "active-menu";
        let pathname = location.pathname.replace("/", "");
        if (pathname && pathname.toLowerCase() === name.toLowerCase()) return className + "active-menu";
        return className;
    }

    return (
        <>
            <GlobalStyle />
            <Box
                className="menu-container"
                sx={{
                    position: "sticky",
                    display: "flex",
                    height: "100vh",
                    top: 0,
                    bottom: 0,
                    zIndex: 1,
                    "& .sidebar": {
                        border: "none",
                    },
                    "& .menu-icon": {
                        backgroundColor: "transparent !important",
                    },
                    "& .menu-item": {
                        // padding: "5px 35px 5px 20px !important",
                        backgroundColor: "transparent !important",
                    },
                    "& .menu-anchor": {
                        color: "inherit !important",
                        backgroundColor: "transparent !important",
                    },
                    "& .menu-item:hover": {
                        color: `${colors.blueAccent[500]} !important`,
                        backgroundColor: "transparent !important",
                    },
                    "& .menu-item.active": {
                        color: `${colors.greenAccent[500]} !important`,
                        backgroundColor: "transparent !important",
                    },
                }}
            >
                {user?.email && <Sidebar
                    breakPoint="md"
                    rtl={sidebarRTL}
                    backgroundColor="white"
                    image={sidebarImage}
                    style={{ color: "#9DA4AE" }}
                >
                    <Menu iconshape="square">
                        {
                            collapsed &&
                            <MenuItem
                                icon={<MenuOutlinedIcon onClick={() => collapseSidebar()} />}
                                style={{
                                    margin: "10px 0 20px 0",
                                    color: "#9DA4AE",
                                }}>
                            </MenuItem>
                        }

                        {/*<MenuItem*/}
                        {/*  icon={*/}
                        {/*    collapsed ? (*/}
                        {/*      <MenuOutlinedIcon onClick={() => collapseSidebar()} />*/}
                        {/*    ) : sidebarRTL ? (*/}
                        {/*      <SwitchLeftOutlinedIcon*/}
                        {/*        onClick={() => setSidebarRTL(!sidebarRTL)}*/}
                        {/*      />*/}
                        {/*    ) : (*/}
                        {/*      <SwitchRightOutlinedIcon*/}
                        {/*        onClick={() => setSidebarRTL(!sidebarRTL)}*/}
                        {/*      />*/}
                        {/*    )*/}
                        {/*  }*/}
                        {/*  style={{*/}
                        {/*    margin: "10px 0 20px 0",*/}
                        {/*    color: colors.grey[100],*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {!collapsed && (*/}
                        {/*    <Box*/}
                        {/*      display="flex"*/}
                        {/*      justifyContent="space-between"*/}
                        {/*      alignItems="center"*/}
                        {/*      ml="15px"*/}
                        {/*    >*/}
                        {/*      <Typography variant="h3" color={colors.grey[100]}>*/}
                        {/*        ADMIN*/}
                        {/*      </Typography>*/}
                        {/*      <IconButton*/}
                        {/*        onClick={*/}
                        {/*          broken ? () => toggleSidebar() : () => collapseSidebar()*/}
                        {/*        }*/}
                        {/*      >*/}
                        {/*        <CloseOutlinedIcon />*/}
                        {/*      </IconButton>*/}
                        {/*    </Box>*/}
                        {/*  )}*/}
                        {/*</MenuItem>*/}

                        {!collapsed && (
                            <Box >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        padding: "0px 10px",
                                        "& .avater-image": {
                                            backgroundColor: colors.primary[500],
                                        },
                                    }}
                                >
                                    <img

                                        alt="profile user"
                                        width="175px"
                                        height="80px"
                                        src={"../../assets/symphonyai-media.png"}
                                    />
                                    <IconButton
                                        onClick={
                                            broken ? () => toggleSidebar() : () => collapseSidebar()
                                        }
                                    >
                                        <img

                                            alt="profile user"
                                            width="24px"
                                            height="24px"
                                            src={"../../assets/menu_collapse 1.svg"}
                                        />
                                    </IconButton>

                                </Box>
                                <Box display="flex"
                                    justifyContent="start"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: "#F0F4F8",
                                        padding: "0px 16px",
                                        height: "42px",
                                        color: "#9DA4AE !important",
                                        "button": {
                                            color: "#9DA4AE !important",
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        fontWeight="bold"
                                        mr={1}
                                        className="site-text site-text-bold"
                                    >
                                        Revedia
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        className="site-text"
                                    >
                                        Audit
                                    </Typography>
                                </Box>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '100%', paddingRight: "20px" },
                                    }}
                                    mt={2}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField id="outlined-basic" label="Search for..." size="small" />
                                </Box>
                            </Box>
                        )}
                        {
                            <Box display={false}
                            >
                                <Item
                                    title="Home Dashboard"
                                    to="/"
                                    icon={
                                        <img
                                            alt="home"
                                            width="24px"
                                            height="24px"
                                            src={"../../assets/home.svg"}
                                        />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    className={setActiveMenu()}
                                />
                                {/* 
                                <Item
                                    title="Dashboard"
                                    to="/"
                                    icon={
                                        <img
                                            alt="dashboard"
                                            width="24px"
                                            height="24px"
                                            src={"../../assets/dashboard.svg"}
                                        />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    className={setActiveMenu()}
                                /> */}

                                {/*<Item*/}
                                {/*    title="Report"*/}
                                {/*    icon={<SummarizeIcon />}*/}
                                {/*    selected={selected}*/}
                                {/*    setSelected={setSelected}*/}
                                {/*    className={setActiveMenu('Report')}*/}
                                {/*/>*/}


                                {/*Data Management*/}
                                <SubMenu className="submenu" label="Data Management"
                                    className="site-text site-text-bold"
                                    defaultOpen="false">

                                    <Item
                                        title="Data Ingestion"
                                        to="/DataIngestion"
                                        icon={
                                            <img
                                                alt="Data Ingestion"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/interests_black_24dp.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="Assign Schema"
                                        icon={
                                            <img
                                                alt="Create Audit"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/difference.svg"} />
                                        }
                                        to='/createSchema'
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="Assign Dates to Files"
                                        to="/assignDateToFile"
                                        icon={<NFCIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />



                                </SubMenu>

                                {/*Contract Models*/}
                                <SubMenu className="submenu site-text site-text-bold" label="Contract Models"
                                    className="site-text site-text-bold"
                                    defaultOpen="false">
                                    <Item
                                        title="Contract Management"
                                        to="/contractManagement"
                                        icon={
                                            <img
                                                alt="home"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/home.svg"}
                                            />}
                                        selected={selected}
                                        setSelected={setSelected}
                                        className={setActiveMenu()}
                                    />
                                    {/*<Item*/}
                                    {/*    title="View Contracts"*/}
                                    {/*    to="/"*/}
                                    {/*    icon={<NFCIcon />}*/}
                                    {/*    selected={selected}*/}
                                    {/*    setSelected={setSelected}*/}
                                    {/*    className="menu-item"*/}
                                    {/*/>*/}

                                    {/*<Item*/}
                                    {/*    title="Create Contract Models"*/}
                                    {/*    to="/"*/}
                                    {/*    icon={<NFCIcon />}*/}
                                    {/*    selected={selected}*/}
                                    {/*    setSelected={setSelected}*/}
                                    {/*    className="menu-item"*/}
                                    {/*/>*/}
                                    <Item
                                        title="Contract Ingestion"
                                        to="/ContractIngestion"
                                        icon={
                                            <img
                                                alt="Contract Ingestion"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/interests_black_24dp.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                </SubMenu>

                                {/*Audit Management*/}
                                <SubMenu
                                    label="Audit Management"
                                    className="site-text site-text-bold"
                                    defaultOpen="false"

                                >

                                    <Item
                                        title="Create Audit"
                                        to="/audit"
                                        icon={
                                            <img
                                                alt="Create Audit"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/newspaper.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="Create Audit Model"
                                        to="/auditModel"
                                        icon={
                                            <img
                                                alt="Create Audit Model"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/newspaper.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="Audit Configuration"
                                        icon={
                                            <img
                                                alt="Create Audit"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/backup_table.svg"} />
                                        }

                                        to="/compositepage"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="Assign Participants"
                                        icon={<NFCIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="Assign Data Files"
                                        icon={<NFCIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="Audit Terms"
                                        icon={
                                            <img
                                                alt="Create Audit"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/backup_table.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="Audit Discrepancies"
                                        icon={
                                            <img
                                                alt="Create Audit"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/difference.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="View Audit Data Set"
                                        icon={<NFCIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="Upload Audit Summary"
                                        icon={<NFCIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="Audit Publish"
                                        icon={
                                            <img
                                                alt="Create Audit"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/cloud_done.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="Audit Report"
                                        to="/AuditReport"
                                        icon={
                                            <img
                                                alt="Audit Report"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/cloud_done.svg"} />
                                        }
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    <Item
                                        title="Report Writer"
                                        icon={
                                            <img
                                            alt="Report Writer"
                                            width="24px"
                                            height="24px"
                                            src={"../../assets/cloud_done.svg"}
                                            />
                                        }
                                        to="/compositepage/report-writer"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                </SubMenu>

                                {/*Process Management*/}
                                <SubMenu className="submenu" label="Process Management"
                                    className="site-text site-text-bold"
                                    defaultOpen="false">

                                    <Item
                                        title="Execute Process Flow"
                                        icon={
                                            <img
                                                alt="workspaces"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/workspaces.svg"} />
                                        }
                                        to="/"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                </SubMenu>

                                {/*Rule Engine*/}
                                <SubMenu className="submenu" label="Rule Engine"
                                    className="site-text site-text-bold"
                                    defaultOpen="false">

                                    <Item
                                        title="Fieldwork"
                                        icon={
                                            <img
                                                alt="workspaces"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/workspaces.svg"} />
                                        }
                                        to="/dataOverview"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    {/*<Item*/}
                                    {/*    title="Data Overview"*/}
                                    {/*    icon={<NFCIcon />}*/}
                                    {/*    to="/dataOverview"*/}
                                    {/*    selected={selected}*/}
                                    {/*    setSelected={setSelected}*/}
                                    {/*    className="menu-item"*/}
                                    {/*/>*/}


                                    <Item
                                        title="Data Validation"
                                        icon={<NFCIcon />}
                                        to="/schemaProcessed"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    {/*<Item*/}
                                    {/*    title="Pivot Table"*/}
                                    {/*    icon={<NFCIcon />}*/}
                                    {/*    to="/contractManagement"*/}
                                    {/*    selected={selected}*/}
                                    {/*    setSelected={setSelected}*/}
                                    {/*    className="menu-item"*/}
                                    {/*/>*/}

                                    <Item
                                        title="Data View Wizard"
                                        icon={<NFCIcon />}
                                        to="/wizard"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                    {<Item
                                        title="Rule Engine"
                                        icon={<NFCIcon />}
                                        to="/processflow"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />}


                                    { /*<Item
                                        title="Data Set"
                                        icon={<NFCIcon />}
                                        to="/compositepage"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                       />*/ }
                                </SubMenu>


                                {/*Administration Management*/}
                                <SubMenu className="submenu" label="Administration"
                                    className="site-text site-text-bold"
                                    defaultOpen="false">

                                    <Item
                                        title="File Type Management"
                                        icon={
                                            <img
                                                alt="workspaces"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/workspaces.svg"} />
                                        }
                                        to="/fileTypeManagement"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />

                                    <Item
                                        title="User Management"
                                        icon={
                                            <img
                                                alt="workspaces"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/workspaces.svg"} />
                                        }
                                        to="/admin/usermanagement"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />


                                    <Item
                                        title="Role Management"
                                        icon={
                                            <img
                                                alt="workspaces"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/workspaces.svg"} />
                                        }
                                        to="/admin/roles/app"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />


                                    {/*<Item*/}
                                    {/*    title="Data Roles Management"*/}
                                    {/*    icon={*/}
                                    {/*        <img*/}
                                    {/*            alt="workspaces"*/}
                                    {/*            width="24px"*/}
                                    {/*            height="24px"*/}
                                    {/*            src={"../../assets/workspaces.svg"} />*/}
                                    {/*    }*/}
                                    {/*    to="/admin/roles/data"*/}
                                    {/*    selected={selected}*/}
                                    {/*    setSelected={setSelected}*/}
                                    {/*    className="menu-item"*/}
                                    {/*/>*/}



                                    <Item
                                        title="Permission Management"
                                        icon={
                                            <img
                                                alt="workspaces"
                                                width="24px"
                                                height="24px"
                                                src={"../../assets/workspaces.svg"} />
                                        }
                                        to="/admin/permission"
                                        selected={selected}
                                        setSelected={setSelected}
                                        className="menu-item"
                                    />
                                </SubMenu>




                            </Box>}

                        <Box sx={{ marginTop: "250px" }}>
                            <Item
                                title="Notifications"
                                icon={
                                    <img
                                        alt="workspaces"
                                        width="24px"
                                        height="24px"
                                        src={"../../assets/notifications.svg"} />
                                }
                                selected={selected}
                                setSelected={setSelected}
                                className="menu-item"
                            />
                            <Item
                                title="Settings"
                                icon={
                                    <img
                                        alt="workspaces"
                                        width="24px"
                                        height="24px"
                                        src={"../../assets/settings.svg"} />
                                }
                                selected={selected}
                                setSelected={setSelected}
                                className="menu-item"
                            />

                            <List sx={{ width: '100%' }}>
                                <ListItem>
                                    <ListItemAvatar sx={{ minWidth: "45px" }}>
                                        <Avatar
                                            sx={{ width: 32, height: 32 }}
                                            alt={user?.name} children={user?.name[0]} />
                                    </ListItemAvatar>
                                    <ListItemText primary={user?.name} secondary={user?.email} className="site-text"
                                        sx={{
                                            "span": {
                                                fontWeight: "bold"
                                            }
                                        }}
                                    />
                                </ListItem>
                                <ListItem>

                                    <Logout
                                        title="Logout"
                                        icon={<LogoutIcon />}
                                        to="/login"
                                        removeToken={logout}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Logout />
                                </ListItem>
                            </List>
                            {/*<Logout*/}
                            {/*    title="Logout"*/}
                            {/*    icon={<LogoutIcon />}*/}
                            {/*    to="/login"*/}
                            {/*    removeToken={removeToken}*/}
                            {/*    selected={selected}*/}
                            {/*    setSelected={setSelected}*/}
                            {/*    className="menu-item"*/}
                            {/*/>*/}
                        </Box>

                    </Menu>
                </Sidebar>}
            </Box>
        </>

    );
};

export default MyProSidebar;
