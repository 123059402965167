import React from 'react'
import { Box, Button, TextField, Stack, Divider, Autocomplete } from '@mui/material'
import { Formik } from "formik";
import { useField, useFormikContext } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService"
import AuditClientDataService from "../../service/AuditClientDataService"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, addMonths } from 'date-fns'

import RightContainer from "../global/RightContainer";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SAIMEntityDataService from '../../service/SAIMEntityDataService';

const Audit = () => {
    const [affliate_open, affliateSetOpen] = React.useState(false);
    const [affliate_Options, affliateSetOptions] = React.useState([]);
    const [auditStartDate, setauditStartDate] = React.useState();
    const [auditEndDate, setauditEndDate] = React.useState();

    const [client_open, clientSetOpen] = React.useState(false);
    const [client_Options, ClientSetOptions] = React.useState([]);

    const allicate_loading = affliate_open && affliate_Options.length === 0;

    const client_loading = client_open && client_Options.length === 0;
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [errorMessage, setErrorMessage] = React.useState('');
    const [auditName, setAuditName] = React.useState('');

    const [auditType, setAuditType] = React.useState();
    const [auditTeam, setAuditTeam] = React.useState();
    const [auditManager, setAuditManager] = React.useState();

    const [auditTypes, setAuditTypes] = React.useState([]);
    const [auditTeams, setAuditTeams] = React.useState([]);
    const [auditManagers, setAuditManagers] = React.useState([]);


    React.useEffect(() => {

	    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

        ClientDataService.getAffiliates(userKey).then((res) => {
            affliateSetOptions(res.data);
            affliateSetOpen(true)
        }).catch(e => {
            console.log(e)
        });


        AuditClientDataService.getAuditTypes().then((res) => {
            setAuditTypes(res.data);
        }).catch(e => {
            console.log(e)
        });

        SAIMEntityDataService.getAuditManagers().then((res) => {
            setAuditManagers(res.data);
        }).catch(e => {
            console.log(e)
        });

        SAIMEntityDataService.getAuditTeams().then((res) => {
            setAuditTeams(res.data);
        }).catch(e => {
            console.log(e)
        });


    }, [allicate_loading, client_loading]);



 
    const handleFormSubmit1 = (values) => {
        console.log(values);
    }

    const handleFormSubmit2 = (values) => {
        console.log("submit", values);
        let audit = {
            affiliatekey: values.affliate?.clientKey,
            auditname: auditName,
            audittypekey: auditType.audittypekey,
            auditmanagerkey: auditManager?.userkey,
            auditteamkey: auditTeam?.auditteamkey,
            auditstartdtkey: format(values.startDate, 'yyyyMMdd'),
            auditenddtkey: format(values.endDate, 'yyyyMMdd')
        }

        AuditClientDataService.create(audit).then(() => {
            setErrorMessage("Audit Created Successfully");
        }).catch(e => {
            setErrorMessage(e.message);
        })
    };



    const initialValues = {
        auditName: "",
        affliate: affliate_Options[0],
        startDate: "",
        endDate: "",
    };

    const checkoutSchema = yup.object().shape({
        auditName: yup.string().min(3, "Audit Name too short").max(10, "Audit Name too long").required('Required')
    })



    const DatePickerField = ({ ...props }) => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);
        return (
            <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
            />
        );
    };




        return (
            <Stack>
                <RightContainer title="Create Single Audit">
                    <Formik onSubmit={handleFormSubmit2} initialValues={initialValues} validator={() => ({})}>
                        {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    m={20}
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}>
                                    <Stack>

                                        {errorMessage ? <div className="error"> {errorMessage} </div> : null}

                                        <br />

                                        <FormControl sx={{ width: 280 }}>
                                            <TextField
                                                name="AuditName"
                                                type="text"
                                                id="AuditName"
                                                value={auditName}
                                                onChange={(event) => setAuditName(event.target.value)}
                                                label="Audit Name"
                                                size="medium"
                                            />
                                        </FormControl>


                                        <br />

                                        <Autocomplete
                                            name="affliate"
                                            disablePortal
                                            id="affliate"
                                            value={values.affliate}
                                            options={affliate_Options}
                                            onChange={(e, value) => {
                                                console.log(value);
                                                setFieldValue(
                                                    "affliate",
                                                    value !== null ? value : initialValues.affliate
                                                );
                                            }}
                                            getOptionLabel={(option) => option.clientName}
                                            isOptionEqualToValue={(option, value) =>
                                                option.clientkey === value.clientkey
                                            }
                                            renderInput={(params) => <TextField
                                                {...params} label="Affliate" />}
                                        />

                                        <br />
                                        <br />

                                        <Stack direction="row"
                                            spacing={10}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                <DatePickerField name="startDate"
                                                    value={values.startDate}
                                                    label="Audit Start Date"
                                                />

                                                <DatePickerField name="endDate"

                                                    value={values.endDate}
                                                    label="Audit End Date"
                                                />

                                            </LocalizationProvider>


                                            <FormControl sx={{ width: 280 }}>

                                                <InputLabel htmlFor="name-shared">Audit Type</InputLabel>
                                                <Select
                                                    label="AuditType"
                                                    value={auditType}
                                                    onChange={
                                                        (event) => {
                                                            setAuditType(event.target.value)
                                                        }}
                                                >
                                                    {auditTypes.map(type => (
                                                        <MenuItem key={type.audittypekey} value={type}>
                                                            {type.audittypedesc}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Stack>

                                        <br />
                                        <br />

                                        <Stack direction="row"
                                            spacing={10}
                                        >
                                            <FormControl sx={{ width: 400 }}>

                                                <InputLabel htmlFor="name-shared">Audit Team</InputLabel>
                                                <Select
                                                    label="AuditType"
                                                    value={auditTeam}
                                                    onChange={(event) => { setAuditTeam(event.target.value) }}
                                                >
                                                    {auditTeams.map(team => (
                                                        <MenuItem key={team.auditteamkey} value={team}>
                                                            {team.auditteamname}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl sx={{ width: 400 }}>

                                                <InputLabel htmlFor="name-shared">Audit Manager</InputLabel>
                                                <Select
                                                    label="AuditType"
                                                    value={auditManager}
                                                    onChange={(event) => { setAuditManager(event.target.value) }}
                                                >
                                                    {auditManagers.map(manager => (
                                                        <MenuItem key={manager.userkey} value={manager}>
                                                            {manager.username}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Stack>

                                        <br />

                                        <Box justifyContent="left" mt="20px">
                                            <Button type="submit" color="secondary" variant="contained" >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </RightContainer>
            </Stack>
        );
}

export default Audit