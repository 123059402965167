import { jsonHttp } from "./http-common";

class AuditReportService {

    getAuditReportSection(auditreportkey) {
		return jsonHttp.get(`/AuditReport/GetAuditReportSection/${auditreportkey}`);
    }

    getAuditReportSectionDesc() {
        return jsonHttp.get(`/AuditReport/GetAuditReportSectionDesc`);
    }

    getAuditReportSectionsByClient(clientKey, networkKey) {
        return jsonHttp.get(`/AuditReport/GetAuditReportSectionDesc/${clientKey}/${networkKey}`);
    }

    getAuditReportItem() {
        return jsonHttp.get(`/AuditReport/GetAuditReportItem`);
    }

    saveAuditReportItem(data) {
        return jsonHttp.put(`/AuditReport/SaveAuditReportItem`, data);
    }

    deleteAuditReportItem(data) {
        return jsonHttp.put(`/AuditReport/DeleteAuditReportItem`, data);
    }


    getAuditReport(id) {
        return jsonHttp.get(`/AuditReport/GetAuditReport/${id}`);
    }

    generateReport(data) {
        return jsonHttp.put(`/AuditReport/GenerateReport`, data);
    }

    saveAuditReport(data) {
        return jsonHttp.post(`/AuditReport/SaveAuditReport`, data);
    }

    updateReportApprovalFlg(data) {
        return jsonHttp.put(`/AuditReport/UpdateReportApprovalFlg`, data);
    }
}

const auditReportService = new AuditReportService();
export default auditReportService