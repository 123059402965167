import React from 'react'
import { Box, Button, IconButton,useTheme, TextField, Stack, Divider, Autocomplete } from '@mui/material'
import { Formik } from "formik";
import { useField, useFormikContext } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService";
import AuditClientDataService from "../../service/AuditClientDataService"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, addMonths } from 'date-fns'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CollapsibleTable from '../../components/common/collapsibleTable';
import FileConfigDataService from "../../service/FileConfigDataService";
import ConversionStatusService from "../../service/ConversionStatusService";
import DataLakeService from "../../service/DataLakeService";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Link, useNavigate } from 'react-router-dom';
import RightContainer from "../global/RightContainer";

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FileTypeDataService from "../../service/FileTypeDataService";
import AzureTableDataService from '../../service/AzureTableDataService';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import moment from 'moment';
import ConfirmDialog from '../../components/common/confirmDialog';

function yyyymmdd(date) {
    var x = new Date(date)
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AuditHomeTab = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const[, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [tableRows, setTableRows] = React.useState([]);

    const [gridRows, setGridRows] = React.useState([]);

    const [clientNetworkOpen, setClientNetworkOpen] = React.useState(false);


    const [affliate_open, affliateSetOpen] = React.useState(false);
    const [affliate_Options, affliateSetOptions] = React.useState([]);

    const [client_open, clientSetOpen] = React.useState(false);
    const [client_Options, ClientSetOptions] = React.useState([]);

    const [network_open, networkSetOpen] = React.useState(false);
    const [networkOptions, setNetworkOptions] = React.useState([]);

    const [auditStatus, setAuditStatus] = React.useState([]);
    const [auditTypes, setAuditTypes] = React.useState([]);

    const [auditManagers, setAuditManagers] = React.useState([]);
    const [auditTeams, setAuditTeams] = React.useState([]);


    const [clients, SetClients] = React.useState([]);

    const [fileSearchClient, setFileSearchClient] = React.useState();

    const [auditClientSearchClient, setAuditClientSearchClient] = React.useState(); 

    const [auditAffiliateSearchClient, setAuditAffiliateSearchClient] = React.useState();

    const [auditNetworkSearchClient, setAuditNetworkSearchClient] = React.useState();

    const [auditTypeSearch, setAuditTypeSearch] = React.useState();
    const [auditStatusSearch, setAuditStatusSearch] = React.useState();

    const [auditManagerSearch, setAuditManagerSearch] = React.useState();
    const [auditTeamSearch, setAuditTeamSearch] = React.useState();

    const [auditName, setAuditName] = React.useState();
    const [auditFileTypeSearch, setAuditFileTypeSearch] = React.useState();
    const [fileName, setFileName] = React.useState();
    const [fileResults, setFileResults] = React.useState([]);
    const [fileTypes, setFileTypes] = React.useState([]);
    const [fileSearchDto, setFileSearchDto] = React.useState({
        auditAffiliateSearchClient: null,
        auditFileTypeSearch: null,
        filename: "",
        auditNetworkSearchClient: null,
        auditClientSearchClient: null,
        conversionStatusSearch: null
    });

    const [conversionstatus, setConversionstatus] = React.useState([]);
    const [auditClientDisable, setAuditClientDisable] = React.useState(false);
    const [affliateDisable, setAffliateDisable] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const allicate_loading = affliate_open && affliate_Options.length === 0;

    const client_loading = client_open && client_Options.length === 0;

    const network_loading = network_open && networkOptions.length === 0;


    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [errorMessage, setErrorMessage] = React.useState('');


    const [auditConfigurations, setAuditConfigurations] = React.useState([]);
    const [isShowComfirm, setIsShowComfirm] = React.useState(false);
    const [comfirmMessage, setComfirmMessage] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState({});
    const [showFilePreview, setShowFilePreview] = React.useState(false);
    const [filePreviewData, setFilePreviewData] = React.useState({
        column: [
            { field: "name", headerName: "File Name",width:600 },
            { field: "createedDate", headerName: "Date Created", width: 200 , valueFormatter: (params) => moment(params.value).format('MM/DD/YYYY HH:mm:ss') },
        { field: "modifiedDate", headerName: "Last Modified", width: 200 ,valueFormatter: (params) => moment(params.value).format('MM/DD/YYYY  HH:mm:ss') }
        ],
        data: []
    });

    const seeClientsOnClick = (row) => {
        if (row.id) {
            AuditClientDataService
                .getAuditConfigurationsByAudit(row.id)
                .then(res => {
                    setAuditConfigurations(Object.groupBy(res.data, x => x.auditConfig.auditclientkey))
                    setClientNetworkOpen(true)
                } ).catch(err => console.log(err));
        }
    }

    const handleClientNetworkClose = () => {
        setClientNetworkOpen(false);
    }


    React.useEffect(() => {
	    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

        ClientDataService.getClients(userKey).then((res) => {
            ClientSetOptions(res.data);
            clientSetOpen(true);
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getAffiliates(userKey).then((res) => {
            affliateSetOptions(res.data);
            affliateSetOpen(true)
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getUserNetworks(userKey).then((res) => {
            setNetworkOptions(res.data);
            networkSetOpen(true)
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getManagers().then((res) => {
            setAuditManagers(res.data);
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getTeams().then((res) => {
            setAuditTeams(res.data);
        }).catch(e => {
            console.log(e)
        });


        AuditClientDataService.getAuditTypes().then((res) => {
            setAuditTypes(res.data);
        }).catch(e => {
            console.log(e)
        });

        AuditClientDataService.getAuditStatuses().then((res) => {
            setAuditStatus(res.data);
        }).catch(e => {
            console.log(e)
        });

        AuditClientDataService.getAuditStatuses().then((res) => {
            setAuditStatus(res.data);
        }).catch(e => {
            console.log(e)
        });

        ConversionStatusService.getAll().then((res) => {
            setConversionstatus(res.data);
        }).catch(e => {
            console.log(e)
        });

        setAuditAffiliateSearchClient(null);
        setAuditClientSearchClient(null);
        setAuditNetworkSearchClient(null);
        setAuditTypeSearch(null);
    }, [allicate_loading, client_loading, network_loading]);



    const handleFormSubmit1 = (values) => {
        console.log(values);
    }

    const clearOnClick = () => {
        setAuditAffiliateSearchClient(null);
        setAuditClientSearchClient(null);
        setAuditNetworkSearchClient(null);
        setAuditTypeSearch(null)
        setAuditTeamSearch(null)
        setAuditManagerSearch(null)
        setAuditStatusSearch(null)
        setStartDate(null)
        setEndDate(null)
        setAuditName('');
        setGridRows([])
        forceUpdate();   
    }

  
    const initialValues = {
        affliate: affliate_Options[0],
        client: client_Options[0],
        network: networkOptions[0],
        startDate: null,
        endDate: null
    };

    const checkoutSchema = yup.object().shape({
        auditName: yup.string().min(3, "Audit Name too short").max(10, "Audit Name too long").required('Required')
    })


    const columns = [
        {
            field: "audit", headerName: "Audit Name", width: 100,
           // renderCell: (params) => <div>{params.row.audit && params.row.audit.auditname}</div>
            renderCell: (params) => <Link to="/compositepage" underline="always" state={{ currentAudit: params.row }} >
                {params.row.audit && params.row.audit.auditname}</Link>
        },
        {
            field: "auditType",
            headerName: "Audit Type",
            headerAlign: "left",
            align: "left",
            width: 100,
            renderCell: (params) => <div>{params.row.auditType && params.row.auditType.audittypedesc}</div>
        },
        {
            field: "auditTeam",
            headerName: "Ownership Team",
            cellClassName: "name-column--cell",
            width: 200,
            renderCell: (params) => <div>{params.row.auditTeam && params.row.auditTeam.auditteamname}</div>
        },

        {
            field: "participant", headerName: "Affliate", width: 100,
            renderCell: (params) => <div>{params.row.participant && params.row.participant.participantname}</div>
        },

        {
            field: "clientNetworks", headerName: "Client/Network", width: 100,
            renderCell: (params) => <Link onClick={() => seeClientsOnClick(params.row)}>See Client</Link>,
        },
        {
            field: "startDate", headerName: "Start Date", width: 100,
            renderCell: (params) => <div>{params.row.audit && params.row.audit.auditstartdtkey}</div>
        },
        {
            field: "endDate", headerName: "End Date", width: 100,
            renderCell: (params) => <div>{params.row.audit && params.row.audit.auditenddtkey}</div>
        }
    ];


    const initialValues1 = {
        client: clients[0]
    }



    const handleAuditStatusSearchChange = (event) => {
        setAuditStatusSearch(event.target.value)
        forceUpdate();
    };



    const handleAuditNameChange = (event) => {
        setAuditName(event.target.value);
    };



    const handleFileSearchChange = (event) => {
        FileConfigDataService.getFilesByClient(event.target.value.clientKey)
            .then(res => setTableRows(res.data))
            .catch(err => console.log(err));
        setFileSearchClient(event.target.value);
    };

    const handleAuditTypeSearchChange = (event) => {
        setAuditTypeSearch(event.target.value)
    };

    const handleFormSubmit2 = (values) => {
        console.log("submit", values);

        var data
        if (auditStatusSearch && auditStatusSearch == "new") {
            data = {
                clientkey:  -1,
                affiliatekey: auditAffiliateSearchClient ? auditAffiliateSearchClient.clientKey : - 1,
                networkkey: -1,
                auditstatuskey: -1,
                audittypekey: auditTypeSearch ? auditTypeSearch.audittypekey : -1,
                auditname: auditName ? auditName : "-",
                startdate: startDate ? yyyymmdd(startDate) : -1,
                enddate: endDate ? yyyymmdd(endDate) : -1,
                enddate: endDate ? yyyymmdd(endDate) : -1,
            }

        } else {
            data = {
                clientkey: auditClientSearchClient ? auditClientSearchClient.clientKey : -1,
                affiliatekey: auditAffiliateSearchClient ? auditAffiliateSearchClient.clientKey : - 1,
                networkkey: auditNetworkSearchClient ? auditNetworkSearchClient.networkkey : -1,
                auditstatuskey: auditStatusSearch ? auditStatusSearch.auditstatuskey : -1,
                audittypekey: auditTypeSearch ? auditTypeSearch.audittypekey : -1,
                managerkey: auditManagerSearch ? auditManagerSearch.userkey : -1,
                auditteamkey: auditTeamSearch ? auditTeamSearch.auditteamkey : -1,
                auditname: auditName ? auditName : "-",
                startdate: startDate ? yyyymmdd(startDate) : -1,
                enddate: endDate ? yyyymmdd(endDate) : -1,
            }

        }


        AuditClientDataService.getAuditByParticipants(data)
                .then((res) => {
                    return res.data.map(function (item) {
                        return {
                            id: item.audit.auditkey,
                            audit: item.audit,
                            auditManager: item.auditManagerTemp,
                            auditTeam: item.auditTeamTemp,
                            auditType: item.auditTypeTemp,
                            participant: item.participantTemp
                        }
                    })
                })
            .then(data => {
                setGridRows(data)
            })
            .catch(err => console.log(err));
    };

 
    const momentDate = (date) => {
        if (!date) return null;
        return moment(date).format("MM/DD/YYYY");
    }

    const handleFormSearchFile = (values) => {
        //console.log("submit", values);

        var data = {
            filetypekey: fileSearchDto.auditFileTypeSearch ? fileSearchDto.auditFileTypeSearch.filetypekey : -1,
            affiliatekey: fileSearchDto.auditAffiliateSearchClient ? fileSearchDto.auditAffiliateSearchClient.clientKey : - 1,
            filename: fileSearchDto.fileName ? fileSearchDto.fileName : "",
            clientkey: fileSearchDto.auditClientSearchClient ? fileSearchDto.auditClientSearchClient.clientkey : -1,
            networkkey: fileSearchDto.auditNetworkSearchClient ? fileSearchDto.auditNetworkSearchClient.networkkey : -1,
            conversionstatuskey: fileSearchDto.conversionstatus ? fileSearchDto.conversionstatus.conversionstatuskey : -1
        }

        AuditClientDataService.getFiles(data)
            .then((res) => {
                console.log(res.data);
                setFileResults(res.data);
            }).catch(err => console.log(err));
    };

    const onConversionStatusSearch = (value) => {
        setFileSearchDto({ ...fileSearchDto, conversionStatusSearch: value });
        console.log(value);
    }

    const getFileTypeByClientKey = (item) => {

        if (!item || !item.clientKey) {
            setFileTypes([]);
            return;
        }

        FileTypeDataService.getByClientKey(item.clientKey).then((res) => {
            setFileTypes(res.data);
        }).catch(e => {
            console.log(e)
        });
    }

    const downloadFile = (id) => {
        setOpenBackdrop(true);
        DataLakeService.downloadFile(id)
            .then(response => {
                console.log(response)
                setOpenBackdrop(false);
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
                    console.log(filenameMatch)
                    const filename = filenameMatch[1] ? filenameMatch[1] : 'default-filename.ext';

                    //console.log(filename)

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch(error => {
                setOpenBackdrop(false);
                if (error.response && error.response.status === 404) {
                    alert('The file does not exist.');
                } else {
                    alert('An error occurred, please try again later.');
                }
            });
    }

    const showDeleteFile = (item) => {
        setComfirmMessage("Are you sure you want to delete the currently selected file?");
        setIsShowComfirm(true);
        setSelectedFile(item);
    }

    const deleteFile = () => {
        FileConfigDataService.delete(selectedFile.fileconfigkey)
            .then(res => {
                setIsShowComfirm(false);
                handleFormSearchFile();
            })
            .catch(err => console.log(err));
    }

    const previewFile = (item) => {
        setOpenBackdrop(true);
        setSelectedFile(item);

        DataLakeService.previewFile(item)
            .then(response => {

                setOpenBackdrop(false);
                setShowFilePreview(true)
                console.log(response);
                setFilePreviewData({ ...filePreviewData, data: response.data })


            })
            .catch(err => console.log(err));
    }

    const handleDataSetClose = () => {
        setShowFilePreview(false);
    }


    /**
     *
     * 
     * DATA FILE SEARCH
     *  
     * @param {any} props
     * @returns
     */
    const Form1 = (props) => {
        const [fileSearchName, setFileSearchName] = React.useState(fileSearchDto.fileName);
        const reset = () => {

            setFileSearchDto({
                auditAffiliateSearchClient: null,
                auditFileTypeSearch: null,
                filename: "",
                auditNetworkSearchClient: null,
                auditClientSearchClient: null,
                conversionStatusSearch: null
            });
            setFileSearchName("");
            setAffliateDisable(false);
            setAuditClientDisable(false);
            forceUpdate();
        }
        return (
            <Box>
                <Backdrop style={{ color: '#fff', zIndex: 9999 }} open={openBackdrop} onClick={() => setOpenBackdrop(false)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ConfirmDialog open={isShowComfirm} title='Confirm' onConfirm={() => { deleteFile(false) }} close={()=>setIsShowComfirm(false)} message={comfirmMessage}></ConfirmDialog>
                <Box>

                    <Formik onSubmit={handleFormSearchFile} initialValues={initialValues} >
                        {({ handleSubmit }) => (
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        marginTop: "20px",
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}>
                                    <Stack >
                                        <Stack direction="row" spacing={2}>

                                            <Autocomplete
                                                id="country-select-demo"
                                                sx={{ width: 300 }}
                                                value={fileSearchDto.auditAffiliateSearchClient || ""}
                                                isOptionEqualToValue={(option) => option.clientKey}
                                                onChange={(event, value) => { setFileSearchDto({ ...fileSearchDto, auditAffiliateSearchClient: value }); setAuditClientDisable(true); getFileTypeByClientKey(value) }}
                                                options={affliate_Options}
                                                disabled={affliateDisable}
                                                autoHighlight
                                                getOptionLabel={(option) => option.clientName || ""}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
											   {option.clientName}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Affliate"
                                                    />
                                                )}
                                            />

                                            <Autocomplete
                                                id="country-select-demo"
                                                sx={{ width: 300 }}
                                                value={fileSearchDto.auditClientSearchClient || ""}
                                                isOptionEqualToValue={(option) => option.clientkey}
                                                onChange={(event, value) => { setFileSearchDto({ ...fileSearchDto, auditClientSearchClient: value }); setAffliateDisable(true); getFileTypeByClientKey(value) }}
                                                options={client_Options}
                                                disabled={auditClientDisable}
                                                autoHighlight
                                                getOptionLabel={(option) => option.clientName || ""}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.clientName}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Client"
                                                    />
                                                )}
                                            />

                                            <Autocomplete
                                                id="country-select-demo"
                                                sx={{ width: 300 }}
                                                value={fileSearchDto.auditNetworkSearchClient || ""}
                                                isOptionEqualToValue={(option) => option.networkkey}
                                                onChange={(event, value) => { setFileSearchDto({ ...fileSearchDto, auditNetworkSearchClient: value }); setAffliateDisable(true); }}
                                                options={networkOptions}
                                                disabled={auditClientDisable}
                                                autoHighlight
                                                getOptionLabel={(option) => option.networkdesc || ""}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.networkdesc}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Network"
                                                    />
                                                )}
                                            />

                                        </Stack>

                                        <br />
                                        <Stack direction="row" spacing={2}>

                                            <Autocomplete
                                                id="file-type"
                                                sx={{ width: 300 }}
                                                value={fileSearchDto.auditFileTypeSearch || ""}
                                                isOptionEqualToValue={(option) => option.filetypekey}
                                                onChange={(event, value) => setFileSearchDto({ ...fileSearchDto, auditFileTypeSearch: value })}
                                                options={fileTypes}
                                                disabled={!fileSearchDto.auditClientSearchClient && !fileSearchDto.auditAffiliateSearchClient}
                                                autoHighlight
                                                getOptionLabel={(option) => option.filedesc || ""}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.filedesc}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="File Type"
                                                    />
                                                )}
                                            />

                                            <FormControl sx={{ width: 280 }}>
                                                <TextField
                                                    name="FileName"
                                                    type="text"
                                                    id="FileName"
                                                    value={fileSearchName }
                                                    onChange={(event) => setFileSearchName(event.target.value)}
                                                    label="File Name"
                                                    onBlur={() => {setFileSearchDto({ ...fileSearchDto, fileName: fileSearchName }) }}
                                                    size="medium"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>

                                            <br />
                                            <Button sx={{ width: 125 }} color="secondary" variant="contained" onClick={reset} >
                                                Clear
                                            </Button>
                                            <Button sx={{ width: 125 }} type="submit" color="secondary" variant="contained" >
                                                Search
                                            </Button>

                                            {errorMessage ? <div className="error"> {errorMessage} </div> : null}
                                        </Stack>
                                    </Stack>
                                </Box>
                            </form>
                        )}
                    </Formik>

                    <Box mt="20px">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h3">Files</Typography>
                        </Box>


                        <br />
                        <Box>
                            <Stack direction="row" spacing={2}>
                                <Autocomplete
                                    id="file-type"
                                    sx={{ width: 300 }}
                                    value={fileSearchDto.conversionStatusSearch || ""}
                                    isOptionEqualToValue={(option) => option.conversionstatuskey}
                                    onChange={(event, value) => onConversionStatusSearch(value)}
                                    options={conversionstatus}
                                    autoHighlight
                                    getOptionLabel={(option) => option.statusdesc || ""}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.statusdesc}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Conversion Status"
                                        />
                                    )}
                                />
                            </Stack>
                        </Box>
                        <br />
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            {fileResults.map((section, index) => (
                                (fileSearchDto.conversionStatusSearch == null || fileSearchDto.conversionStatusSearch.statusdesc === section.statusdesc) &&
                                <li key={`section-${index}`}>
                                    <ul>
                                        <ListSubheader sx={{ fontSize: "1.1rem", backgroundColor: "#babbbd", color: "#FFF" }}>{`${section.statusdesc}`}</ListSubheader>
                                        {section.fileitems.map((item, subindex) => (
                                            <ListItem key={`item-${index}-${subindex}`}
                                                sx={{
                                                    border: "1px solid gray",
                                                    ':firsh-child': { borderBottom: "0px" }
                                                }}

                                                secondaryAction={
                                                    <>
                                                        
                                                        <IconButton aria-label="download"
                                                            disabled={!item.canDownload}
                                                            style={{
                                                                filter: `${!item.canDownload ? 'grayscale(100%) opacity(0.5)' : 'none'}`
                                                            }}
                                                            onClick={() => { downloadFile(item.fileconfigkey) }}>
                                                            <img
                                                                alt="Data Ingestion"
                                                                src={"../../assets/edit.png"} />
                                                        </IconButton>
                                                        <IconButton aria-label="preview"
                                                            disabled={!item.canDownload}
                                                            style={{
                                                                border: "1px solid #0074E8",
                                                                borderRadius: "0",
                                                                filter: `${!item.canDownload?'grayscale(100%) opacity(0.5)':'none'}`
                                                            }}
                                                            onClick={() => { previewFile(item) }}>
                                                            <img
                                                                alt="Data Ingestion"
                                                                src={"../../assets/view.png"} />
                                                        </IconButton>
                                                        <IconButton aria-label="delete" disabled={!item.canDelete}
                                                            sx={{
                                                                marginRight: "5px",
                                                                filter: `${!item.canDelete ? 'grayscale(100%) opacity(0.5)' : 'none'}`
                                                            }}
                                                            onClick={() => { showDeleteFile(item) }}>
                                                            <img
                                                                alt="Data Ingestion"
                                                                width="35px"
                                                                height="35px"
                                                                src={"../../assets/delete.png"} />
                                                        </IconButton>
                                                        
                                                    </>
                                                }
                                            >

                                                <ListItemText primary={`File Name: ${item.filenm} | Start Date: ${momentDate(item.filedatefrom)} | End Date: ${momentDate(item.filedateto)} | Upload Date: ${momentDate(item.lastupdatedate)} `}/>
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>
                    </Box>
                </Box>
                <Dialog
                    modal={true}
                    maxWidth='xl'
                    width="100%"
                    open={showFilePreview}
                    onClose={handleDataSetClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                    PaperProps={{
                        sx: {
                            width: "90%",
                            minHeight: '90%',
                            maxHeight: '90%'
                        }
                    }}>

                    <DialogTitle id="alert-dialog-title">
                        <Header title={selectedFile.filenm} handleModalClose={handleDataSetClose} />
                    </DialogTitle>

                    <DialogContent>
                        <Box
                            m="8px 0 0 0"
                            width="100%"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .name-column--cell": {
                                    color: colors.greenAccent[300],
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: colors.primary[400],
                                },
                                "& .MuiCheckbox-root": {
                                    color: `${colors.greenAccent[200]} !important`,
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${colors.grey[100]} !important`,
                                },
                            }}
                            className="overwrite-table"
                        >
                            <DataGrid
                                autoHeight={true}
                                rows={filePreviewData.data}
                                pageSize={10}
                                columns={filePreviewData.column}
                                components={{ Toolbar: GridToolbar }}
                                getRowId={row => row.name}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }


    const DatePickerField = ({ ...props }) => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);
        return (
            <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
            />
        );
    };




    return (
        <RightContainer title="Home Dashboard" >
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab color={colors.primary} label="Audit Search" {...a11yProps(0)} />
                        <Tab label="Data File Search " {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
				    <Typography sx={{ mb: "15px", fontSize: "25px" }}>Audit Search</Typography>
                    <Box>
                       
                        <Formik onSubmit={handleFormSubmit2} initialValues={initialValues} >
                            {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}>
                                    <Box
                                        display="grid"
                                        gap="20px"
                                        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                        sx={{
                                            marginTop: "20px",
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        }}>
                                        <Stack >
                                            <Stack direction="row" spacing={2}>
                                           
                                                    <Autocomplete
                                                        id="country-select-demo"
                                                        sx={{ width: 300 }}
                                                        value={auditAffiliateSearchClient || ""}
                                                        isOptionEqualToValue={(option) => option.clientKey}
                                                        onChange={(event, value) => setAuditAffiliateSearchClient(value)}
                                                        options={affliate_Options}
                                                        autoHighlight
											 getOptionLabel={(option) => option.clientName || ""}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
													 {option.clientName}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Choose an Affiliate"
                                                            />
                                                        )}
                                                />


                                                <Autocomplete
                                                    id="country-select-demo"
                                                    sx={{ width: 300 }}
                                                    value={auditClientSearchClient || ""}
                                                    isOptionEqualToValue={(option) => option.networkey}
                                                    onChange={(event, value) => setAuditClientSearchClient(value)}
                                                    options={client_Options}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.clientName || ""}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.clientName}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose a Client"
                                                        />
                                                    )}
                                                />


                                                <Autocomplete
                                                    id="country-select-demo"
                                                    sx={{ width: 300 }}
                                                    value={auditNetworkSearchClient || ""}
                                                    isOptionEqualToValue={(option) => option.networkey}
                                                    onChange={(event, value) => setAuditNetworkSearchClient(value)}
                                                    options={networkOptions}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.networkdesc || ""}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.networkdesc}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose a Network"
                                                        />
                                                    )}
                                                />



                                                <FormControl sx={{ width: 280 }}>
                                                    <InputLabel htmlFor="name-shared">Audit Type</InputLabel>
                                                    <Select
                                                        label="AuditType"
                                                        value={auditTypeSearch || ''}
                                                        onChange={handleAuditTypeSearchChange}
                                                    >
                                                        <MenuItem value={null}>NONE</MenuItem>
                                                        {auditTypes.map(type => (
                                                            <MenuItem key={type.audittypekey} value={type}>
                                                                {type.audittypedesc}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <Autocomplete
                                                    id="country-select-demo"
                                                    sx={{ width: 300 }}
                                                    value={auditTeamSearch || ""}
                                                    isOptionEqualToValue={(option) => option.auditteamkey}
                                                    onChange={(event, value) => setAuditTeamSearch(value)}
                                                    options={auditTeams}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.auditteamname || ""}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.auditteamname}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose a Team"
                                                        />
                                                    )}
                                                />


                                                <Autocomplete
                                                    id="country-select-demo"
                                                    sx={{ width: 300 }}
                                                    value={auditManagerSearch || ""}
                                                    isOptionEqualToValue={(option) => option.userkey}
                                                    onChange={(event, value) => setAuditManagerSearch(value)}
                                                    options={auditManagers}
                                                    autoHighlight
                                                    getOptionLabel={(option) => option.username || ""}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.username}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choose a Manager"
                                                        />
                                                    )}
                                                />


                                                <Button color="secondary" variant="contained" onClick={clearOnClick} >
                                                    Clear Filters
                                                </Button>
                                            </Stack>

                                            <br />
                                            <Stack direction="row" spacing={2}>

                                                <FormControl sx={{ width: 280 }}>
                                                    <TextField
                                                        name="AuditName"
                                                        type="text"
                                                        id="AuditName"
                                                        value={auditName}
                                                        onChange={handleAuditNameChange}
                                                        label="Audit Name"
                                                        size="medium"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormControl>


                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="Start Date"
                                                        dateFormat="yyyy-MM-dd"
                                                        value={startDate}
                                                        onChange={(newValue) => {
                                                            setStartDate(newValue)
                                                        }
                                                       }
                                                    />

                                                    <DatePicker
                                                        label="End Date"
                                                        value={endDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        onChange={(newValue) => {
                                                            setEndDate(newValue)
                                                        }
                                                        }
                                                    />
                                                </LocalizationProvider>

                                                <FormControl sx={{ width: 280 }}>

                                                    <InputLabel htmlFor="name-shared">Status</InputLabel>
                                                    <Select
                                                        label="Status"
                                                        value={auditStatusSearch || ''}
                                                        onChange={handleAuditStatusSearchChange}
                                                    >
                                                        <MenuItem value={null}>NONE</MenuItem>
                                                        <MenuItem value={"new"}>New</MenuItem>
                                                        {auditStatus.map(status => (
                                                            <MenuItem key={status.auditstatuskey} value={status}>
                                                                {status.auditstatusdesc}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>


                                                <br />
                                                <Button type="submit" color="secondary" variant="contained" >
                                                    Apply Filters
                                                </Button>

                                                {errorMessage ? <div className="error"> {errorMessage} </div> : null}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </form>
                            )}
                        </Formik>

                        <Box mt="20px">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h3">Files</Typography>
                            </Box>
                            <Box
                                width="100%"
                                height="80vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        backgroundColor: "#F9F9F9",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.greenAccent[300],
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: colors.primary[400],
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: '#444e62'
                                    },
                                    "& .MuiCheckbox-root": {
                                        color: `${colors.greenAccent[200]} !important`,
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${colors.grey[100]} !important`,
                                    },
                                }}
                                className="overwrite-table"
                            >
                                <DataGrid
                                    rows={gridRows}
                                    columns={columns}
                                    components={{ Toolbar: GridToolbar }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </TabPanel>
                <Divider orientation='horizontal' />
                <TabPanel value={value} index={1}>
                    <Box>
					<Typography sx={{ mb: "15px", fontSize: "25px" }}>Data File Search</Typography>
					<Form1 />
                    </Box>
                </TabPanel>
            </Box>




            <Dialog
                modal={true}
                maxWidth='xl'
                width="100%"
                open={clientNetworkOpen}
                onClose={handleClientNetworkClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "50%",
                        minHeight: '50%',
                        maxHeight: '50%'
                    }
                }}>

                <DialogTitle id="alert-dialog-title">
                    <Header title="Client/Networks" />
                </DialogTitle>

                <DialogContent>
                    <Box m="8px 0 0 0"
                        width="100%">
                        <div class="ul-list w-full">
                            <ul>
                                {
                                    Object.keys(auditConfigurations).map((clientKey) => (
                                        <>
                                            <li class="pl-3" key={`Client-${clientKey}`}> <label>{`Client: ${auditConfigurations[clientKey][0].participant.participantname}`}</label></li>
                                            {
                                                auditConfigurations[clientKey].map((configuration) => (
                                                    <li class="text-normal pl-5" key={`${configuration.auditConfig.auditkey}`}>
                                                        <label>Network: {configuration.network.networkkey + ":" + configuration.network.networkdesc} </label>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                            </ul>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </RightContainer>
        );
}

export default AuditHomeTab