import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./providers/AuthProvider";
import "./index.css";
import App from "./App";
import { AuthService } from "./service/Authentication";
import {LogLevel , EventType, PublicClientApplication , InteractionType, RedirectRequest} from "@azure/msal-browser"
import { MsalAuthenticationTemplate,MsalProvider,AuthenticatedTemplate, UnauthenticatedTemplate   } from "@azure/msal-react";


export const msalConfig = {
  auth: {
      clientId: 'a1cc3616-5f52-4630-ab22-b605b0fb57f2',
      authority: 'https://login.microsoftonline.com/f583b0bb-8ec2-4bf0-afd4-1dcdefb95c8f',
      redirectUri: window.location.origin,
    //   navigateToLoginRequestUrl: true,
      knownAuthorities: [],
      postLogoutRedirectUri: '/', 
  },
  cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
        logLevel: LogLevel.Verbose,
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    return;
            }
        },
        piiLoggingEnabled: false
    },
},
};


const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents()



// msalInstance.initialize().then(async () => {
    msalInstance.addEventCallback((event) => {
        console.warn('APP EVENT',event)
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            // const account = event.payload.account;
            // msalInstance.setActiveAccount(account);
        }
        if (event.eventType === EventType.LOGOUT_SUCCESS) {
            msalInstance.setActiveAccount(null);
            msalInstance.clearCache(true);
        }

        if (event.eventType === EventType.HANDLE_REDIRECT_END) {
           
        }
    });


    // if(window.location.hash.startsWith("#code=")){
    //      const response = await msalInstance.handleRedirectPromise()
    //      console.warn('after handle',response)
    //       if(response != null){
    //           msalInstance.setActiveAccount(response.account);
    //  }
    //    //  login()
    // }

// })




// const accounts = msalInstance.getAllAccounts()
// console.warn('APP START ACCOUNTS',accounts)
// if (!msalInstance.getActiveAccount() && accounts.length > 0) {
//     msalInstance.setActiveAccount(accounts[0]);
// }



const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const redirectURL = new URLSearchParams(window.location.search).get('redirectRoute')

const loginRequest: RedirectRequest = {
    scopes: ["openid", "profile", "offline_access","User.Read"],
    // redirectUri: window.location.origin + redirectURL,
    authenticationScheme: 'Bearer',         
        // prompt: ''
};

root.render(
    <StrictMode>
    <BrowserRouter>


    <MsalProvider instance={msalInstance}>
                    <AuthProvider authService={new AuthService(msalInstance)} >
             <App  />   
             </AuthProvider>

    </MsalProvider> 


    </BrowserRouter>
  </StrictMode>
);
