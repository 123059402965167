import { jsonHttp } from "./http-common";

export class AuditClientDataService {
    getAll() {
        return jsonHttp.get("/AuditParticipants");
    }

    get(id) {
        return jsonHttp.get(`/AuditParticipants/${id}`);
    }


    getAuditByClient(clientKey, startDate, endDate ) {
        return jsonHttp.get(`/AuditParticipants/client/${clientKey}/${startDate}/${endDate}`);
    }

    getAuditByAffiliate(clientKey, startDate, endDate) {
        return jsonHttp.get(`/AuditParticipants/affiliate/${clientKey}/${startDate}/${endDate}`);
    }

    getAuditByParticipants(AuditSearchParameters) {
        return jsonHttp.post(`/AuditParticipants/participants/searchAudit`, AuditSearchParameters);
    }

    getFiles(SearchParameters) {
        return jsonHttp.post(`/AuditParticipants/participants/searchFile`, SearchParameters);
    }

    getAuditConfigurations() {
        return jsonHttp.get(`/AuditParticipants/auditConfigurations/`);
    }

    getAuditConfigurationsByAudit(auditkey) {
        return jsonHttp.get(`/AuditParticipants/auditConfigurations/${auditkey}`);
    }


    createAuditConfigurationsByAudit(configs) {
        return jsonHttp.post("/AuditParticipants/auditConfigurations", configs).catch(error => {
            let e = error;
            if (error.response) {
                if (error.response.data) {
                    e = error.response.data;
                }
            } else if (error.message) {
                e = error.message;
            } else {
                e = "Unknown error occured";
            }
            throw new Error(e);
        });
    }


    getAuditByName(auditName) {
        return jsonHttp.get(`/AuditParticipants/auditName/${auditName}`);
    }

    getAuditTypes() {
        return jsonHttp.get(`/AuditParticipants/auditTypes`);
    }

    getAuditStatuses() {
        return jsonHttp.get(`/AuditParticipants/auditStatuses`);
    }

    getClientNetworkByAuditKey(auditkey) {
        return jsonHttp.get(`/AuditParticipants/getClientNetworkByAuditKey/${auditkey}`);
    }

    create(data) {
        return jsonHttp.post("/AuditParticipants", data).catch(error => {
            let e = error;
            if (error.response) {
                if (error.response.data) {
                    e = error.response.data;           
                }
            } else if (error.message) {
                e = error.message;
            } else {
                e = "Unknown error occured";
            }
            throw new Error(e);
        })
    }

    update(id, data) {
        return jsonHttp.put(`/AuditParticipants/${id}`, data);
    }

    delete(id) {
        return jsonHttp.delete(`/AuditParticipants/${id}`);
    }

}
export default new AuditClientDataService();