import { jsonHttp } from "./http-common";

class FileSchemaDataService {
    getAll() {
        return jsonHttp.get("/FileSchema");
    }

    getFileSchemaByID(id) {
        return jsonHttp.get(`/FileSchema/${id}`);
    }

    getFileSchemaByClientFileSchemaKey(id) {
        return jsonHttp.get(`/FileSchema/clientFileSchemaKey/${id}`);
    }

    createNewSchemaForUser(data) {

        console.log("calling create new Schema")
        return jsonHttp.post(`/FileSchema/createNewSchema`, data);
    }

    updateFileSchemas(data) {
        return jsonHttp.post(`/FileSchema/updateFileSchemas`, data);
    }
}
export default new FileSchemaDataService();