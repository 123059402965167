import React, {  } from 'react';
import { Stack, TextField, Button, CircularProgress} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import RightContainer from "../global/RightContainer";

import './RoleManagement.css';
import { NewRoleDialog } from './NewRoleDialog';
import { ParticipantDialog } from './ParticipantDialog';
import { RoleComposite } from '../../ts/modules/roles/interface';
import { Participant } from '../../ts/modules/participants/interface';


interface RoleManagementProps {
	isLoading: boolean;
	title: string;
	roles: RoleComposite[];
	participant: Participant[];
	searchText: string;
	onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onSearch: () => void;
	onReset: () => void;
	onOpenNewRoleDialog: () => void;
	isNewRoleDialogOpen: boolean;
	onCloseNewRoleDialog: () => void;
	onSaveNewRole: (newRole: Omit<RoleComposite, 'roleKey'>) => void;
	participantDialogOpen: boolean;
	selectedRole: RoleComposite | null;
	onOpenParticipantsDialog: (role: RoleComposite) => void;
	onCloseParticipantsDialog: () => void;
	onUpdateRole: (updatedRole: RoleComposite) => void;
	editRowId: GridRowId | undefined;
	deleteRowId: GridRowId | undefined;
	editValue: string;
	onEditChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onStartEdit: (params: GridRenderCellParams<any, any, any>) => void;
	onStartDelete: (params: GridRenderCellParams<any, any, any>) => void;
	onSaveEdit: () => void;
	onConfirmDelete: () => void;
	onCancelEdit: () => void;
	onCancelDelete: () => void;
	children?: React.ReactNode;
}


const RoleManagement: React.FC<RoleManagementProps> = ({
	isLoading,
	roles,
	participant,
	searchText,
	onSearchChange,
	onSearch,
	onReset,
	onOpenNewRoleDialog,
	isNewRoleDialogOpen,
	onCloseNewRoleDialog,
	onSaveNewRole,
	participantDialogOpen,
	selectedRole,
	onOpenParticipantsDialog,
	onCloseParticipantsDialog,
	onUpdateRole,
	editRowId,
	deleteRowId,
	editValue,
	onEditChange,
	onStartEdit,
	onStartDelete,
	onSaveEdit,
	onConfirmDelete,
	onCancelEdit,
	onCancelDelete,
	children,
	title,
}) => {
	const columns: GridColDef[] = [
		{ 
			field: 'roleDescription', 
			headerName: 'Role Name', 
			width: 200,
			renderCell: (params) => {
				if (params.id === editRowId) {
					return (
						<TextField
							value={editValue}
							onChange={(e) => onEditChange(e)}
							autoFocus
							size="small"
							fullWidth
						/>
					);
				}
				return params.value;
			},
		},
		{
			field: 'createDate',
			headerName: 'Created On',
			width: 130,
			valueFormatter: (params) => {
				return params.value instanceof Date ? params.value.toLocaleDateString() : new Date(params.value).toLocaleDateString();
			},
		},
		{
			field: 'participantCount',
			headerName: 'Affiliate/Client Count',
			width: 200,
			renderCell: (params: GridRenderCellParams<RoleComposite>) => {
				const participantCount = params.row.participantKeys.length;
				return <span>{participantCount}</span>;
			},
		},
		{
			field: 'actions',
			headerName: 'Actions',
			width: 250,
			renderCell: (params) => (
				<div>
					{editRowId === params.id ? (
						<>
							<Button
								variant="contained"
								color="primary"
								size="small"
								onClick={() => onSaveEdit()}
								style={{ marginRight: 16 }}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								color="secondary"
								size="small"
								onClick={onCancelEdit}
							>
								Cancel
							</Button>
						</>
					) : (
						deleteRowId === params.id ? (
							<>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => onConfirmDelete()}
									style={{ marginRight: 16 }}
								>
									Confirm?
								</Button>
								<Button
									variant="outlined"
									color="secondary"
									size="small"
									onClick={onCancelDelete}
								>
									Cancel
								</Button>
							</>
						) : (
							<>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => onStartEdit(params)}
									style={{ marginLeft: 16 }}
								>
									Edit
								</Button>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => onStartDelete(params)}
									style={{ marginLeft: 16 }}
								>
									Delete
								</Button>
							</>
						)
					)}
				</div>
			),
		},
		{
			field: 'assign',
			headerName: 'Affiliate/Client',
			width: 160,
			renderCell: (params) => (
				<Button
				variant="contained"
				color="secondary"
				size="small"
				onClick={() => onOpenParticipantsDialog(params.row)}
				>
					Assign
				</Button>
			),
		},
  ];

	return (
	<Stack>
		<RightContainer title={title}>
			<div style={{ marginBottom: 20, display: 'flex', alignItems: 'center', gap: '10px' }}>
				<TextField
					label="Search"
					variant="outlined"
					value={searchText}
					onChange={onSearchChange}
					style={{ width: '50%' }}
				/>
				<Button variant="contained" color="primary" onClick={onSearch}>
					Search
				</Button>
				<Button variant="outlined" onClick={onReset}>
					Reset
				</Button>
				<Button
					variant="contained"
					color="secondary"
					onClick={onOpenNewRoleDialog}
					style={{ marginLeft: 'auto' }}
				>
					Add New Role
				</Button>
			</div>
			<div style={{ height: 400, width: '100%' }}>
			{
					isLoading ? (
						<CircularProgress />
					) : (
						<DataGrid
							rows={roles}
							columns={columns}
							pageSize={5}
							getRowId={(row) => row.roleKey}
							rowsPerPageOptions={[5]}
						/>
			)}
			</div>
		</RightContainer>
		{isNewRoleDialogOpen && (
			<NewRoleDialog
			open={isNewRoleDialogOpen}
			onClose={onCloseNewRoleDialog}
			onSave={onSaveNewRole}
			availableParticipants={participant}
			/>
		)}
		{participantDialogOpen && selectedRole && (
			<ParticipantDialog
				isLoading={isLoading}
				open={participantDialogOpen}
				onClose={onCloseParticipantsDialog}
				role={selectedRole}
				participants={participant}
				onUpdateRole={onUpdateRole}
			/>
		)}
		{children}
	</Stack>
	);
};
export { RoleManagement };
