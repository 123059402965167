import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const PdfViewer = ({ url }) => {
        const [pdfUrl, setPdfUrl] = useState(null);

        async function fetchPdfBlob(url) {
            const response = await axios({
                method: 'get',
                url: url,
                responseType: 'blob'
            });
            return new Blob([response.data], { type: 'application/pdf' });
        }

        useEffect(() => {
            async function loadPdf() {
                try {
                    const blob = await fetchPdfBlob(url);
                    const pdfUrl = URL.createObjectURL(blob);
                    setPdfUrl(pdfUrl);
                } catch (error) {
                    console.error('Error loading PDF:', error);
                }
            }

            loadPdf();
        }, [url]);
    return (
        <div className="pdf-viewer" style={{width:"100%"}}>
            <iframe
                title="PDF"
                src={pdfUrl}
                width="100%"
                height="800px"
            ></iframe>
        </div>
    );
};
export default PdfViewer;

