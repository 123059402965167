import React from "react";
import createEngine, { DiagramModel } from "@projectstorm/react-diagrams";
import { BusinessRuleNodeFactory } from "../../components/diagram/businessRuleNode/BusinessRule";
import { FunctionNodeFactory } from "../../components/diagram/functionNode/Function";
import { CreatorWidget } from "../../components/diagram/creator-widget/CreatorWidget";
import { Box, Stack, Button, useTheme } from '@mui/material'
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import FormControl from '@mui/material/FormControl';
import ClientFileSchemaService from '../../service/ClientFileSchemaService';
import FileConfigDataService from '../../service/FileConfigDataService';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import "./styles.css";
import { InputNodeFactory } from "../../components/diagram/inputNode/Input";
import { OutputNodeFactory } from "../../components/diagram/outputNode/Output";
import ProcessFlowDataService from "../../service/ProcessFlowDataService";
import * as _ from 'lodash';
import TextField from '@mui/material/TextField';

import { Graph } from "../../components/diagram/GraphDistance";
import { FilterNodeFactory } from "../../components/diagram/filterNode/Filter";
import { JoinDataNodeFactory } from "../../components/diagram/joinDataNode/JoinData";
import { GroupByNodeFactory } from "../../components/diagram/groupByNode/GroupBy";
import { SelectNodeFactory } from "../../components/diagram/selectNode/Select";

import { AppendRowNodeFactory } from "../../components/diagram/appendRowNode/AppendRow";
import { AlterationNodeFactory } from "../../components/diagram/alterationNode/Alteration";

import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import RightContainer from "../global/RightContainer";


const MyDiagram = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [fileSearchClient, setFileSearchClient] = React.useState();

    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const [processFlow, setProcessFlow] = React.useState();

    const [processFlowName, setProcessFlowName] = React.useState('');

    const [searchText, setsearchText] = React.useState('');

    const [clients, SetClients] = React.useState([]);

    const [diagramEngine, setDiagramEngine] = React.useState();

    const [dataGridRows, setDataGridRows] = React.useState([]);

    const forceUpdate = React.useReducer(bool => !bool)[1];

    const [currentComponent, setCurrentComponent] = React.useState();

    React.useEffect(() => {

        const engine = createEngine({
            registerDefaultPanAndZoomCanvasAction: false,
            registerDefaultZoomCanvasAction: false
        });
        var model = new DiagramModel();
        engine.setModel(model);

        // Create custom node
        engine.getNodeFactories().registerFactory(new InputNodeFactory());
        engine.getNodeFactories().registerFactory(new OutputNodeFactory());
        engine.getNodeFactories().registerFactory(new BusinessRuleNodeFactory());
        engine.getNodeFactories().registerFactory(new FunctionNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new JoinDataNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new SelectNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendRowNodeFactory());
        engine.getNodeFactories().registerFactory(new AlterationNodeFactory());

        setDiagramEngine(engine)

        model.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity || {})
                         }
                    }
                })
              console.log(event.entity.options.id)
            },
        });

        ProcessFlowDataService.getAll().then(res => {
            return res.data;
        }).then(processFlows => {
            setDataGridRows(processFlows);
        }).catch(e => {
            console.log(e)
        });
    }, []);


   //engine.getLinkFactories().registerFactory(new AdvancedLinkFactory());

    const handleFileSearchChange = (event) => {
        event.preventDefault();
        ClientFileSchemaService.getClientFileSchemasByClient(event.target.value.clientkey)
            .then(res => {
              
            })
            .catch(err => {
                console.log(err);
            });

        FileConfigDataService.getFilesByClient(event.target.value.clientkey)
            .then((res) => {
    
            })
            .catch(err => console.log(err));
        setFileSearchClient(event.target.value);
    };


    const saveProcessFlow = (event) => {
        event.preventDefault();

        //var nodesCount = _.keys(diagramEngine.getModel().getNodes()).length;
        let g = new Graph(diagramEngine.getModel().getNodes());

        diagramEngine.getModel().getNodes().forEach(node => {
            node.precendentNodes = new Set();
        })


        diagramEngine.getModel().getLinks().forEach((link) => {
            g.addEdge(link.sourcePort.parent.options.id, link.targetPort.parent.options.id, 1);
            link.targetPort.parent.precendentNodes.add(link.sourcePort.parent.options.id)
        })

        let s = null; 

        diagramEngine.getModel().getNodes().forEach(node => {
            if (node.precendentNodes.size == 0) {
                s = node;
            }
        })
        console.log("Following are longest distances from source vertex " + s);
        const dist = g.longestPath(s);

        diagramEngine.getModel().getNodes().forEach(node => {
            node.depth = dist.get(node.options.id);
        })



        if (processFlow) {
            diagramEngine.getModel().getNodes().forEach(node => {
                if (node.options.type == 'Output' && node.nodedata.OutPutDataLocation ) {
                    node.nodedata.OutPutDataLocation = node.nodedata.OutPutDataLocation + processFlow.processflowkey;
                }
            })

            //ser
            var str = JSON.stringify(diagramEngine.getModel().serialize());

            processFlow.processflownm = processFlowName
            processFlow.processflowjsondata = str
            ProcessFlowDataService.update(processFlow.processflowkey, processFlow).then((res) => {
                console.log(res.data)
                handleModalClose();
            }).catch((err) => {
                console.log(err);
            })
        } else {

            //ser
            var str = JSON.stringify(diagramEngine.getModel().serialize());

            var data = {
                processflownm: processFlowName,
                processflowjsondata: str
            }

            ProcessFlowDataService.create(data).then((res) => {

                diagramEngine.getModel().getNodes().forEach(node => {
                    if (node.options.type == 'Output') {

                        if (node.nodedata) {
                            node.nodedata.OutPutDataLocation = node.nodedata.OutPutDataLocation + res.data.processflowkey;
                        } else {
                            node.nodedata = {
                                OutPutDataLocation : "defaultOutPutDataLocation"
                            }
                        }
                    }
                })
                //ser
                var str = JSON.stringify(diagramEngine.getModel().serialize());

                res.data.processflowjsondata = str

                ProcessFlowDataService.update(res.data.processflowkey, res.data).then((res) => {
                    console.log(res.data)
                    handleModalClose();
                }).catch((err) => {
                    console.log(err);
                })


                handleModalClose();
            }).catch((err) => {
                console.log(err);
            })
        }     
    }

    const deleteProcessFlow = (event) => {
        if (processFlow) {
            ProcessFlowDataService.delete(processFlow.processflowkey).then((res) => {
                console.log(res.data)
                handleModalClose();
            }).catch((err) => {
                console.log(err);
            })
        }
    }


    const handleModalClose = () => {
        setModalIsOpen(false);
        setProcessFlow()
    } 

    const createNewProcessFlow = () => {
        const engine = createEngine({
            registerDefaultPanAndZoomCanvasAction: false,
            registerDefaultZoomCanvasAction: false
        });
        var model = new DiagramModel();
        engine.setModel(model);

        // Create custom node
        engine.getNodeFactories().registerFactory(new InputNodeFactory());
        engine.getNodeFactories().registerFactory(new OutputNodeFactory());
        engine.getNodeFactories().registerFactory(new BusinessRuleNodeFactory());
        engine.getNodeFactories().registerFactory(new FunctionNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new JoinDataNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new SelectNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendRowNodeFactory());
        engine.getNodeFactories().registerFactory(new AlterationNodeFactory());

        setDiagramEngine(engine)

        model.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity)
                            forceUpdate();
                            console.log(nodeSelectionChange.entity.options.id)
                        }

                    }
                })
                console.log(event.entity.options.id)
            },
        });

        setModalIsOpen(true);
    }


    const handleProcessFlowRowClick = (params) => {
      
        setProcessFlow(params.row)

        //deser
        var model2 = new DiagramModel();
        setProcessFlowName(params.row.processflownm)
        model2.deserializeModel(JSON.parse(params.row.processflowjsondata), diagramEngine);
        diagramEngine.setModel(model2);

        model2.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity)
                            forceUpdate();
                            console.log(nodeSelectionChange.entity.options.id)
                        }

                    }
                })
                
            },
        });



        model2.getNodes().forEach((node) => {
            node.registerListener({
                selectionChanged: (e) => {
                    if (e.isSelected) {
                        setCurrentComponent(e.entity)
                        forceUpdate();
                        console.log(e.entity.options.id)
                    }

                }
            });
        })
        setModalIsOpen(true);
    };

    const handleProcessFlowNameChange = event => {
        setProcessFlowName(event.target.value);
    };


    const handleComponentSearchChange = event => {
        setsearchText(event.target.value);
    };


    const mock_column = [
        {
            field: "processflowkey",
            headerName: "Process Flow Key",
            width: 200
        },
        {
            field: "processflownm",
            headerName: "Process Flow Name",
            width: 200
        },
        ,
        {
            field: "processflowjsondata",
            headerName: "Process Flow json data",
            width: 200
        }
    ]


    return (
        <Box>
            <RightContainer title="Engine Rule">
                <Box>
                    {/*
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel htmlFor="name-shared">Client</InputLabel>
                        <Select
                            label="Client"
                            value={fileSearchClient}
                            onChange={handleFileSearchChange}
                            inputProps={{
                                name: "person-name",
                                id: "name-shared"
                            }}
                        >
                            {clients.map(client => (
                                <MenuItem key={client.clientkey} value={client}>
                                    {client.clientname}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>*/
                    }
                    <Box >
                        <Button  type="submit" color="primary" variant="contained" onClick={createNewProcessFlow}>
                            Create New Process Flow
                        </Button>
                    </Box>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            onRowClick={handleProcessFlowRowClick}
                            getRowId={(row) => row.processflowkey}
                            rows={dataGridRows}
                            pageSize={10}
                            columns={mock_column}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </Box>


                <Dialog
                    fullWidth={true}
                    maxWidth='xl'
                    width="100%"
                    open={modalIsOpen}
                    onClose={handleModalClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Header title="Creating New Engine Rule" handleModalClose={handleModalClose} showHrLine={ true } />
                    </DialogTitle>

                    <DialogContent>
                        <Stack>
                            <Box>
                                <Grid container spacing={2}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Grid item>
                                        <Typography variant="h5" gutterBottom>
                                            Process Flow Name
                                        </Typography>
                                        <Box width={220} mb={1}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="processname"
                                                    autoComplete='off'
                                                    type="text"
                                                    id="processname"
                                                    value={processFlowName}
                                                    label="Process Name"
                                                    onChange={handleProcessFlowNameChange}
                                                    size="small"
                                                />
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        {processFlow &&
                                            <Button type="submit" color="error" variant="contained" sx={{marginRight:"10px"}} onClick={deleteProcessFlow}>
                                                DELETE
                                            </Button>
                                        }
                                        {/*<Button onClick={handleModalClose}>Cancel</Button>*/}

                                        <Button type="submit" color="secondary" variant="contained" onClick={saveProcessFlow}>
                                            Save Process Flow
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ "padding-top": "10px", "padding-bottom": "10px" }}>
                                <hr />
                            </Box>
                            
                            <Box width={220} mb={ 1 }>
                                <FormControl fullWidth>
                                    <TextField
                                        name="searchText"
                                        autoComplete='off'
                                        type="text"
                                        id="searchText"
                                        value={searchText}
                                        label="Search for an action"
                                        onChange={handleComponentSearchChange}
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            
                            <Box width={"100%"}>
                                <CreatorWidget engine={diagramEngine} currentComponent={currentComponent} count={0} />
                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        {/*{processFlow &&*/}
                        {/*    <Button type="submit" color="error" variant="contained" onClick={deleteProcessFlow}>*/}
                        {/*        DELETE*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button onClick={handleModalClose}>Cancel</Button>*/}

                        {/*<Button type="submit" color="secondary" variant="contained" onClick={saveProcessFlow}>*/}
                        {/*    Save Process Flow*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
            </RightContainer>
        </Box>
    );
}

export default MyDiagram;
