import React from "react";
import "./node-type-label.css";
import { Box, Stack, useTheme } from '@mui/material'


// Nodes Labels (left panel)
export const NodeTypeLabel = props => {

    return (
        <div
            className="node-type-label"
            draggable
            onDragStart={event => {
                event.dataTransfer.setData(
                    "storm-diagram-node",
                    JSON.stringify(props.model)
                );
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding:'3px'
            }}>
                {props.icon}
            </Box>
            <Box className="label-name">{props.name}</Box>
            
        </div>
    );
};