import { jsonHttp, secHttp } from "./http-common";


class ClientDataService {

	getAll(userKey) {
		return secHttp.get(`/Participant/${userKey}`);
    }

	getClients(userKey) {
		return secHttp.get(`/Participant/clients/${userKey}`);
    }

	getAffiliates(userKey) {
		return secHttp.get(`/Participant/affiliates/${userKey}`);
	}

	getClientNetworks(clientKey) {
		return secHttp.get(`/Participant/clientnetworks/${clientKey}`);
	}

	getNetworks() {
		return jsonHttp.get("/Participant/networks");
	}

	getUserNetworks(userKey) {
		return secHttp.get(`/Participant/networks/${userKey}`);
    }

    getManagers() {
        return jsonHttp.get("/Participant/auditmanagers");
    }

    getTeams() {
        return jsonHttp.get("/Participant/auditteams");
    }

	getUserNetworksByDate(userKey, startDate, endDate) {
		return secHttp.get(`/Participant/networks/${userKey}/${startDate}/${endDate}`);
	}

    getNetworksByDate(startDate, endDate) {
        return jsonHttp.get(`/Participant/networks/${startDate}/${endDate}`);
    }

    get(id) {
        return jsonHttp.get(`/Participant/${id}`);
    }

    create(data) {
        return jsonHttp.post("/Participant", data);
    }

    update(id, data) {
        return jsonHttp.put(`/Participant/${id}`, data);
    }

    delete(id) {
        return jsonHttp.delete(`/Participant/${id}`);
    }

}


 const clientDataService = new ClientDataService();
export default clientDataService