import * as React from "react";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox, FormControlLabel } from "@mui/material";
import { RenderTree} from "./renderTree";

const RecursiveTreeView = (props) => {


     const alreadySelected: RenderTree[] = props.alreadySelected.map(item => ({
         id: item.network.networkkey,
         parentId:item.participant.systemclientkey,
         isNetwork: true,
         name: item.network.networkdesc,
         children: []
    }))


    const [selected, setSelected] = React.useState<RenderTree[]>(alreadySelected);

    console.log(selected);

    const selectedSet = React.useMemo(() => new Set(selected.map(item => item.id)), [selected]);

    const data = props.data;



    const parentMap = React.useMemo(() => {

        if (data) {
            return goThroughAllNodes(data);
        }

        //if (alreadySelected) {
          //  setSelected(alreadySelected);
       // }
    }, []);

    // console.log("parentMAp", parentMap);

    function goThroughAllNodes(nodes: RenderTree, map: Record<string, any> = {}) {
        if (!nodes.children) {
            return null;
        }

        map[nodes.id] = getAllChild(nodes).splice(1);

        for (let childNode of nodes.children) {
            goThroughAllNodes(childNode, map);
        }

        return map;
    }

    // Get all children from the current node.
    function getAllChild(
        childNode: RenderTree | null,
        collectedNodes: any[] = []
    ) {
        if (childNode === null) return collectedNodes;

        collectedNodes.push(childNode);

        if (Array.isArray(childNode.children)) {
            for (const node of childNode.children) {
                getAllChild(node, collectedNodes);
            }
        }

        return collectedNodes;
    }

    const getChildById = (nodes: RenderTree, id: string) => {
        let array: string[] = [];
        let path: string[] = [];

        // recursive DFS
        function getNodeById(node: RenderTree, id: string, parentsPath: string[]) {
            let result = null;

            if (node.id === id) {
                return node;
            } else if (Array.isArray(node.children)) {
                for (let childNode of node.children) {
                    result = getNodeById(childNode, id, parentsPath);

                    if (!!result) {
                        parentsPath.push(node.id);
                        return result;
                    }
                }

                return result;
            }

            return result;
        }

        const nodeToToggle = getNodeById(nodes, id, path);
        // console.log(path);

        return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
    };

    function getOnChange(checked: boolean, nodes: RenderTree) {

        const { childNodesToToggle, path } = getChildById(data, nodes.id);

        const childNodesIdToToggle = childNodesToToggle.map(childNode => childNode.id)


        console.log("childNodesToChange", { childNodesToToggle, checked });

        let array = checked
            ? [...selected, ...childNodesToToggle]
            : selected
                .filter((value) => !childNodesIdToToggle.includes(value.id))
                .filter((value) => !path.includes(value.id));

        array = array.filter((v, i) => array.indexOf(v) === i);

        setSelected(array);

        const selectedPair = array.map(child => {
            if (child.isNetwork) {
                return { auditnetworkkey: child, auditclientkey: child.parentId };
            }
        })

        props.setSelectedPair(selectedPair)

    }

    const renderTree = (nodes: RenderTree) => {
        const allSelectedChildren = parentMap[
            nodes.id
        ]?.every((childNodeId: RenderTree) => selectedSet.has(childNodeId.id));
        const checked = selectedSet.has(nodes.id) || allSelectedChildren || false;

        const indeterminate =
            parentMap[nodes.id]?.some((childNodeId: RenderTree) =>
                selectedSet.has(childNodeId.id)
            ) || false;

        if (allSelectedChildren && !selectedSet.has(nodes.id)) {
            console.log("if allSelectedChildren");

            setSelected([...selected, nodes]);
        }

        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                label={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                indeterminate={!checked && indeterminate}
                                onChange={(event) =>
                                    getOnChange(event.currentTarget.checked, nodes)
                                }
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        label={<>{nodes.name}</>}
                        key={nodes.id}
                        className="site-text"
                    />
                }
            >
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
            </TreeItem>
        );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={["0", "3", "4"]}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {data && renderTree(data)}
        </TreeView>
    );
}

export default RecursiveTreeView