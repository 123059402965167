import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Divider, TextField, Button, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, FormHelperText, Chip, Box } from '@mui/material';
import { userService } from '../../ts/modules/users/service';
import { RolesSelection } from './RolesSelection';
import { Role } from '../../ts/modules/roles/interface';
import { User } from '../../ts/modules/users/interface';


interface AddUserDialogProps {
	allRoles: Role[];
	open: boolean;
	onClose: () => void;
	onUserAdded: (user: User) => void;
}



const AddUserDialog: React.FC<AddUserDialogProps> = ({ allRoles, open, onClose, onUserAdded }) => {
	const [newUser, setNewUser] = useState({
		userName: '',
		firstName: '',
		lastName: '',
		email: '',
		roles: [],
	//    dob: '',
	//    dataRoles: [],
	});
	const [errors, setErrors] = useState({
		userName: '',
		firstName: '',
		lastName: '',
		email: '',
		roles: '',
	//    dob: '',
	//    dataRoles: '',
	});


	const [selectedUserRoles, setSelectedUserRoles] = useState<Role[]>([]);
	const [isLoading, setIsLoading] = useState(false);


    // Error checks
	const validateForm = () => {
		let tempErrors = { userName: '', firstName: '', lastName: '', email: '', roles: '' };//, dob: '', dataRoles: '' };
		if (!newUser.userName) tempErrors.userName = "User Name is required";
		if (!newUser.firstName) tempErrors.firstName = "First Name is required";
		if (!newUser.lastName) tempErrors.lastName = "Last Name is required";
		if (!newUser.email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) tempErrors.email = "Email is invalid";
		//if (!newUser.dob) tempErrors.dob = "Date of Birth is required";
		// if (newUser.roles.length === 0) tempErrors.roles = "At least one role is required";
		// if (newUser.dataRoles.length === 0) tempErrors.dataRoles = "At least one data role is required";
		setErrors({ ...tempErrors });
		return Object.values(tempErrors).every(x => x === "");
	};

	//const handleSearch = () => {
	//	const searchLowerCase = searchText.toLowerCase();
	//	const filtered = roles.filter((role) => role.roleDescription.toLowerCase().includes(searchLowerCase));
	//	setFilteredRows(filtered);
	//};

	const handleSubmit = async () => {
		if (validateForm()) {
			setIsLoading(true);
			try {
				const addedUser = await userService.addUser({
					...newUser,
					userName: newUser.userName,
					firstName: newUser.firstName,
					lastName: newUser.lastName,
					email: newUser.email,
					createDate: new Date(),
					roles: selectedUserRoles
				});

				console.log("AddUserDialog, HandleSubmit, addedUser", addedUser);

				onUserAdded(addedUser);
				onClose();
				setNewUser({ userName: '', firstName: '', lastName: '', email: '', roles: [] });//, dob: '', dataRoles: [] }); // Reset form
				setErrors({ userName: '', firstName: '', lastName: '', email: '', roles: '' });//, dob: '', roles: '', dataRoles: '' }); // Reset errors
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				console.error('Failed to add user', error);
			}
		}
	};

	const handleChange = (e: { target: { name: any; value: any; }; }) => {
		const { name, value } = e.target;
		setNewUser(prev => ({ ...prev, [name]: value }));
	};

	const handleRoleChange = (event: { target: { value: any; }; }) => {
		const {
			target: { value },
		} = event;
		setNewUser(prev => ({ ...prev, roles: typeof value === 'string' ? value.split(',') : value }));
	};

	//const handleDataRoleChange = (event: { target: { value: any; }; }) => {
	//    const {
	//        target: { value },
	//    } = event;
	//    setNewUser(prev => ({ ...prev, dataRoles: typeof value === 'string' ? value.split(',') : value }));
	//};



	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
			<DialogTitle>Add New User</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Box sx={{ '& .MuiFormControl-root': { m: 1 }, display: 'flex', flexDirection: 'column', gap: 2 }}>
							<TextField
								label="User Name"
								name="userName"
								fullWidth
								onChange={handleChange}
								error={!!errors.userName}
								helperText={errors.userName}
							/>
							<TextField
								label="First Name"
								name="firstName"
								fullWidth
								onChange={handleChange}
								error={!!errors.firstName}
								helperText={errors.firstName}
							/>
							<TextField
								label="Last Name"
								name="lastName"
								fullWidth
								onChange={handleChange}
								error={!!errors.lastName}
								helperText={errors.lastName}
							/>
							<TextField
								label="Email"
								name="email"
								type="email"
								fullWidth
								onChange={handleChange}
								error={!!errors.email}
								helperText={errors.email}
							/>
						</Box>
					</Grid>
					<Grid item xs={1}><Divider orientation="vertical" variant="fullWidth" /></Grid>
					<Grid item xs={5}>
						{/*<Box>*/}
						{/*	<FormControl fullWidth error={!!errors.roles}>*/}
						{/*		<InputLabel>Roles</InputLabel>*/}
						{/*		<Select*/}
						{/*			multiple*/}
						{/*			value={newUser.roles}*/}
						{/*			onChange={handleRoleChange}*/}
						{/*			renderValue={(selected) => (*/}
						{/*				<div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>*/}
						{/*				{selected.map((id) => */}
						{/*				<Chip key={id} label={availableRoles.find((role) => role.roleKey === id)?.roleDescription || ''} />*/}
						{/*				)}*/}
						{/*				</div>*/}
						{/*			)}*/}
						{/*			>*/}
						{/*			{availableRoles.map((role) => (*/}
						{/*				<MenuItem key={role.roleKey} value={role.roleKey}>*/}
						{/*				{role.roleDescription}*/}
						{/*				</MenuItem>*/}
						{/*			))}*/}
						{/*		</Select>*/}
						{/*		{errors.roles && <FormHelperText>{errors.roles}</FormHelperText>}*/}
						{/*	</FormControl>*/}
						{/*</Box>*/}
						<Box sx={{ '& .MuiFormControl-root': { m: 1 }, display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: '300px', gap: 2, overflowY: 'scroll' }}>
							<RolesSelection
								allRoles={allRoles}
								selectedRoles={selectedUserRoles}
								setSelectedRoles={setSelectedUserRoles}
								title="Roles"
								key={'roles'}
							/>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSubmit} disabled={isLoading}>{isLoading ? 'Adding...' : 'Add User'}</Button>
			</DialogActions>
		</Dialog>
    );
};


export { AddUserDialog }