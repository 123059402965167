import { jsonHttp } from "./http-common";

class SAIMEntityDataService {
    getAuditManagers() {
        return jsonHttp.get("/SAIMEntities/auditManagers");
    }

    getAuditTeams() {
        return jsonHttp.get("/SAIMEntities/auditTeams");
    }



    create(data) {
        return jsonHttp.post("/SAIMEntities", data);
    }

    update(id, data) {
        return jsonHttp.put(`/SAIMEntities/${id}`, data);
    }

    delete(id) {
        return jsonHttp.delete(`/SAIMEntities/${id}`);
    }

}
export default new SAIMEntityDataService();