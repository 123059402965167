import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { Box, ListItemIcon, Stack, ListItemText, Typography } from '@mui/material'

import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FileSchemaDataService from "../../../service/FileSchemaService";
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';


export const AppendRowNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div >*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">Append Rows</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            {/*<div className="node-content">*/}
            {/*    <Box>*/}
            {/*        <ViewColumnIcon sx={{ fontSize: 40 }} />*/}
            {/*    </Box>*/}
            {/*</div>*/}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white',
                border: '5px solid #a4a9fc'
            }}>
                <ViewColumnIcon
                    width="65px"
                    height="65px"
                    sx={{ width: "65px", height: '65px' }}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class AppendRowModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            name: options?.name || "Append",
            type: "AppendRow"
        });
        this.depth = 0;
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.precendentNodes = new Set();
        this.nodedata = options?.nodedata;

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            inputFields: this.inputFields,
            outputFields: this.outputFields,
            name: this.options.name,
            precendentNodes: Array.from(this.precendentNodes),
            nodedata: this.nodedata,
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.nodedata = event.data.nodedata;
        this.options.name = event.data.name;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }


}

// TODO: Refactor to hooks
export class AppendRowNodeFactory extends AbstractReactFactory {
    constructor() {
        super("AppendRow");
    }

    generateModel(initialConfig) {
        return new AppendRowModel();
    }

    generateReactWidget(event) {
        return <AppendRowNodeWidget engine={this.engine} node={event.model} />;
    }
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export function AppendRowComponent(props) {

    const currentComponent = props.currentComponent;

    const [selected, setSelected] = React.useState([]);

    const [inputFiles, setInputFiles] = React.useState([]);

    const [open, setOpen] = React.useState(false);

    const [fileColumns, setFileColumns] = React.useState([]);

    const [nodeName, setNodeName] = React.useState('');

    
    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (currentComponent.nodedata && currentComponent.nodedata.appendColumns) {
            setSelected(currentComponent.nodedata.appendColumns)
        }

        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes.length > 0) {
            if (parentsNodes[0].outputFields) {
                props.currentComponent.inputFields = parentsNodes[0].outputFields
                props.currentComponent.outputFields = parentsNodes[0].outputFields
            }
        }

        setInputFiles(parentsNodes.map(parent => parent.outputFields));

    }, []);
    

    const handleOnOpen = () => {

        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes.length > 0) {
            if (parentsNodes[0].outputFields) {
                props.currentComponent.inputFields = parentsNodes[0].outputFields
            }
        }

        setInputFiles(parentsNodes.map(parent => parent.outputFields));

        setFileColumns(props.currentComponent.inputFields)


        setOpen(true)
        console.log("calling handleOnOpen")
    }


    function arrayDifference(columns) {
        const difference = [];
        for (let i = 0; i < columns.length && i < fileColumns.length; i++) {
            if (columns[i].FieldName != fileColumns[i].FieldName) {
                difference.push(columns[i]);
            }
        }
        return difference;
    }

    const handleSelectedColumnChange = (event) => {
        const value = event.target.value;

        if (value[value.length - 1] === "all") {
            setSelected(selected.length === fileColumns.length ? [] : fileColumns.map(columns => columns.FieldName));
            return;
        }
        setSelected(value);

        if (currentComponent.nodedata) {
            currentComponent.nodedata.appendColumns = event.target.value;
        } else {
            currentComponent.nodedata = { appendColumns: event.target.value }
        }

    }

    return (

        <Stack direction="row" spacing={5} width={"50%"}>
        <Box spacing={5} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        autoComplete='off'
                        label="Node Name"
                        value={nodeName}
                        onChange={(event) => {
                            setNodeName(event.target.value)
                            currentComponent.options.name = event.target.value;
                        }}
                    />
                </FormControl>
                <br />

                <Typography variant="h5" gutterBottom>
                    1. which columns should be included in this append?
                </Typography>
                <br />

                <Box>
        <FormControl fullWidth>
            <InputLabel id="input-file-select-label">Columns Included</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="input-file-select-label"
                multiple
                label="Columns Included"
                value={selected}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => {handleOnOpen()}}
                onChange={handleSelectedColumnChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >

                <MenuItem value="all">
                    <ListItemIcon>
                        <Checkbox
                            checked={fileColumns.length > 0 && selected.length === fileColumns.length}
                            indeterminate={selected.length > 0 && selected.length < selected.length}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem>

                {fileColumns.map(column => (
                    <MenuItem key={column.id} value={column.FieldName}>
                        <Checkbox checked={selected.indexOf(column.FieldName) > -1} />
                        {column.FieldName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
                    </Box>

                <br />

                <Typography variant="h5" gutterBottom>
                    2. Review the changes that will be made to each DataSet
                </Typography>

                {inputFiles.slice(1).map((inputfile, index) => (
                    <Box>
                        <Divider />
                         <br />
                        <Stack direction="row" spacing={2} width={"100%"}>
                           
                            <Box width={"30%"} sx={{ height: '25%' }}>
                                {inputfile && inputfile.FileName}
                            </Box>

                            <Box width={"70%"} sx={{ height: '25%' }}>
                            {
                                    arrayDifference(inputfile).length > 0
                                    && <Alert severity="error">column does not matched:</Alert>}
                            </Box>
                        </Stack>
                    </Box>
                     ))
                }
         </Box>
        </Stack>
        )
}
