import { jsonHttp } from "./http-common";

class ClientFileSchemaDataService {
    getAll() {
        return jsonHttp.get("/ClientFileSchema");
    }

    getClientFileSchemasByClient(id) {
        return jsonHttp.get(`/ClientFileSchema/client/${id}`);
    }
    delete(id) {
        return jsonHttp.delete(`/ClientFileSchema/DeleteClientFileSchema/${id}`);
    }
}
export default new ClientFileSchemaDataService();