import React, { useRef, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const QuillEditor = ({ initialValue, onContentChange, width }) => {
    const { quill, quillRef, onEditorChange } = useQuill({
        modules: { toolbar: true },
        theme: 'snow',
        placeholder: 'Please enter...',
    });

    const handleChange = (content, delta, source, editor) => {
        console.log('content:', content);
        //console.log('delta:', delta);
        //console.log('source:', source);
        //console.log('Quill editor:', editor);
    };

    useEffect(() => {
        if (onEditorChange) {
            onEditorChange(handleChange);
        }
    }, [onEditorChange]);

    useEffect(() => {
        if (quill) {
            
            quill.root.innerHTML = initialValue;
            //quill.root.addEventListener('input', () => {
            //    const content = quill.getContents();
            //    handleChange(content, null, 'user', quill);
            //});

            quill.on('text-change', () => {
                //const contentAsString = quill.getText(); 
                //console.log('contentAsString:', contentAsString);

                const htmlContent = quill.root.innerHTML; 
                //console.log('content as HTML:', htmlContent);
                onContentChange(htmlContent); 
            });

            return () => {
                quill.root.removeEventListener('text-change', () => { });
            };
        }
    }, [quill]);

    return (
        <div style={{ width: `${width}`}}>
            <div ref={quillRef} />
        </div>
    );
};

export default QuillEditor;