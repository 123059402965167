import React, { useEffect, useState } from 'react';
import { Stack, TextField, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import RightContainer from "../global/RightContainer";
import './UserManagement.css';
import { userService } from '../../ts/modules/users/service';
import { rolesService } from '../../ts/modules/roles/service';
//import { rolesService as dataRoleService } from '../../ts/modules/dataroles/service';
import { EditUserRolesDialog } from './EditUserRolesDialog';
import { AddUserDialog } from './AddUserDialog';
import { Role } from '../../ts/modules/roles/interface';
import { User } from '../../ts/modules/users/interface';

const UserManagement: React.FC = () => {

	// State for users
	const [rows, setRows] = useState<User[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [filteredRows, setFilteredRows] = useState<User[]>([]);

	// State for roles
	const [allRoles, setAllRoles] = useState<Role[]>([]);
	// const [dataRoles, setAllDataRoles] = useState<Role[]>([]);

	// State for managing the edit dialog
	const [editRolesDialogOpen, setEditRolesDialogOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);


	useEffect(() => {
		// Fetch users
		setIsLoading(true);
		userService
			.fetchUsers()
			.then((users) => {
				setRows(users);
				setFilteredRows(users);
				setIsLoading(false);
			}).catch(error => {
				console.error("Failed to fetch Users", error);
				setIsLoading(false);
			});

		// Fetch roles
		rolesService.fetchUserRoles()
			.then(setAllRoles)
			.catch(error => {
				console.error("Failed to fetch User Roles", error);
			});
	}, []);


	useEffect(() => {
		setFilteredRows(rows);
	}, [rows]); 


	const handleReset = () => {
		setSearchText('');
		setFilteredRows(rows);
	};

	const handleSearch = () => {
		const searchLowerCase = searchText.toLowerCase();
		const filtered = rows.filter((user) => user.firstName.toLowerCase().includes(searchLowerCase));
		setFilteredRows(filtered);
	};


	const handleModifyUser = (user: User) => {
		setSelectedUser(user);
		setEditRolesDialogOpen(true);
	};
  

	const onUpdateUser = (updatedUser: User) => {

		console.log("onUpdateUser, updateduser", updatedUser);
		console.log("onUpdateUser  setRows", rows);

		setRows((prevRows) =>
			prevRows.map((user) => (user.userKey === updatedUser.userKey ? updatedUser : user))
		);

		const updatedRows = rows.map((r) =>
			r.userKey === updatedUser.userKey ? updatedUser : r
		);

		console.log("onUpdateUser updatedRows", updatedRows);

		setRows(updatedRows);
		setFilteredRows(updatedRows);

		console.log("onUpdateUser after setRows", filteredRows);
	};

  
	const columns: GridColDef[] = [
		{ field: 'userKey', headerName: 'ID', width: 70 },
		{ field: 'userName', headerName: 'User Name', width: 130 },
		{ field: 'firstName', headerName: 'First Name', width: 130 },
		{ field: 'lastName', headerName: 'Last Name', width: 130 },
		{ field: 'email', headerName: 'Email', width: 200 },
		//    { field: 'dob', headerName: 'Date of Birth', width: 110 },
		{ field: 'createDate', headerName: 'Account Created', width: 160 },
		//    { field: 'lastLoginDate', headerName: 'Last Login Date', width: 160 },
		//    { field: 'accountStatus', headerName: 'Account Status', width: 120 },
		// Previous column definitions
		//   {
		//    field: 'roles',
		//    headerName: 'Roles',
		//    width: 200,
		//    valueGetter: (params) => params.value.map((role: Role) => role.name).join(' | ')
		//},

		//  {
		//    field: 'dataRoles',
		//    headerName: 'Data Roles',
		//    width: 200,
		//    valueGetter: (params) => params.value.map((role: Role) => role.name).join(' | ')
		//},

		{
			field: 'modify',
			headerName: 'Modify',
			width: 150,
			renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				style={{ marginLeft: 16 }}
				onClick={() =>  handleModifyUser(params.row)}
			>
			Modify
			</Button>
			),
		},
	];

	return (
		<Stack>
			<RightContainer title="User Management">
				<div style={{ marginBottom: 20, display: 'flex', alignItems: 'center', gap: '10px' }}>
					<TextField
						label="Search User"
						variant="outlined"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						style={{ width: '50%' }}
					/>
					<Button variant="contained" color="primary" onClick={handleSearch}>
						Search
					</Button>
					<Button variant="outlined" onClick={handleReset}>
						Reset
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => setAddUserDialogOpen(true)}
						style={{ marginLeft: 'auto', order: 3 }} 
					>
					Add New User
					</Button>
				</div>

				<div style={{ height: '70vh', width: '100%' }}>
					{isLoading ? (
						<CircularProgress />
					) : (
						<DataGrid
								rows={filteredRows}
								columns={columns}
								pageSize={10}
								rowsPerPageOptions={[5]}
								checkboxSelection={false}
								getRowId={(row) => row.userKey}
						/>
					)}
				</div>
			</RightContainer>
			<EditUserRolesDialog
					key={selectedUser?.userKey}
					roles={allRoles}
					//dataRoles={dataRoles}
					open={editRolesDialogOpen}
					onClose={() => setEditRolesDialogOpen(false)}
					user={selectedUser}
					onUserUpdated={onUpdateUser}
			/>
			<AddUserDialog
				allRoles={allRoles}
				open={addUserDialogOpen}
				onClose={() => setAddUserDialogOpen(false)}
				onUserAdded={(user) => {
					setRows(currentRows => [...currentRows, user]);
					setFilteredRows(currentFilteredRows => [...currentFilteredRows, user]);
				}}
			/>
		</Stack>
	);
};

export { UserManagement };
