import axios from "axios";
import { getConfiguration } from '../config.js';

//todo: call acquireTokenSilent before each api call to get a fresh token since this will eventually expire after an hour or 2
const userToken = localStorage.getItem("userToken") ?? "";

//console.log("http-common, userToken", userToken);

export const secHttp = axios.create({
		baseURL: getConfiguration().secApiUrl,
		headers: {
			"Content-type": "application/json",
			"Authorization": "Bearer ".concat(userToken)
		}
});


export const jsonHttp = axios.create({
    baseURL: getConfiguration().apiUrl,
    headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer ".concat(userToken)
    }
});

export const fileHttp = axios.create({
    baseURL: getConfiguration().apiUrl,
    headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer ".concat(userToken)
    }
});


export const fileDownloadHttp = axios.create({
    baseURL: getConfiguration().apiUrl,
    responseType: 'blob'
});
