import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, TextField, Stack, Divider, useTheme, Autocomplete } from '@mui/material'
import { Formik } from "formik";
import { useField, useFormikContext } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService"
import AuditClientDataService from "../../service/AuditClientDataService"

import { Graph } from "../../components/diagram/GraphDistance";
import createEngine, { DiagramModel } from "@projectstorm/react-diagrams";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { tokens } from "../../theme";

import { CreatorWidget } from "../../components/diagram/creator-widget/CreatorWidget";
import FormControl from '@mui/material/FormControl';
import { FilterNodeFactory } from "../../components/diagram/filterNode/Filter";
import { JoinDataNodeFactory } from "../../components/diagram/joinDataNode/JoinData";
import { GroupByNodeFactory } from "../../components/diagram/groupByNode/GroupBy";
import { SelectNodeFactory } from "../../components/diagram/selectNode/Select";

import { AppendRowNodeFactory } from "../../components/diagram/appendRowNode/AppendRow";
import { AlterationNodeFactory } from "../../components/diagram/alterationNode/Alteration";
import { InputNodeFactory } from "../../components/diagram/inputNode/Input";
import { OutputNodeFactory } from "../../components/diagram/outputNode/Output";
import { BusinessRuleNodeFactory } from "../../components/diagram/businessRuleNode/BusinessRule";
import { FunctionNodeFactory } from "../../components/diagram/functionNode/Function";
import ProcessFlowDataService from "../../service/ProcessFlowDataService";
import DataLakeService from "../../service/DataLakeService";
import * as _ from 'lodash'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import RightContainer from "../global/RightContainer";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { CSVLink, CSVDownload } from "react-csv";

import AzureTableDataService from '../../service/AzureTableDataService';
import FileConfigDataService from '../../service/FileConfigDataService';
import CompositeTab from './CompositeTab';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "unset",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: "#000",
    width: "100%",
    height: "40px",
    padding: "11px 190px 12px 212px",
    minWidth: "600px",
    marginBottom:"2px"
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CompositePage(props) { 

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation()
    const tempCurrentAudit = location.state?.currentAudit

    const[, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [currentComponent, setCurrentComponent] = React.useState();
    const [currentAudit, setCurrentAudit] = React.useState();

    const [auditName, setAuditName] = React.useState();
    const [audits, setAudits] = React.useState([]);


    const [affliate_open, affliateSetOpen] = React.useState(false);
    const [affliate_Options, affliateSetOptions] = React.useState([]);

    const [client_open, clientSetOpen] = React.useState(false);
    const [client_Options, ClientSetOptions] = React.useState([]);

    const [network_open, networkSetOpen] = React.useState(false);
    const [networkOptions, setNetworkOptions] = React.useState([]);

    const [searchText, setsearchText] = React.useState('');

    const [dataGridRows, setDataGridRows] = React.useState([]);

    const [datatSetRows, setDatatSetRows] = React.useState([]);
    const [dataSetColumns, setDataSetColumns] = React.useState([]);


    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const [auditModalIsOpen, setAuditModalIsOpen] = React.useState(false);
    const [modalAudit, setModalAudit] = React.useState();

    const [processFlow, setProcessFlow] = React.useState();
    const [processFlowName, setProcessFlowName] = React.useState('');
    const [diagramEngine, setDiagramEngine] = React.useState();

    const [inputDateSets, setInputDateSets] = React.useState([]);
    const [outputDateSets, setOutputDateSets] = React.useState([]);


    const [value, setValue] = React.useState(0);


    const [dataSetIsOpen, setDataSetIsOpen] = React.useState(false);

    const [dataSetName, setDataSetName] = React.useState();

    const [errorMessage, setErrorMessage] = React.useState('');


    const [auditClientSearchClient, setAuditClientSearchClient] = React.useState();

    const [auditAffiliateSearchClient, setAuditAffiliateSearchClient] = React.useState();

    const [auditNetworkSearchClient, setAuditNetworkSearchClient] = React.useState();

    const [auditTypeSearch, setAuditTypeSearch] = React.useState();

    const [auditTypes, setAuditTypes] = React.useState([]);
    const [searchDto, setSearchDto] = React.useState({});


    const handleAuditClientSearchChange = (event) => {
        setAuditClientSearchClient(event.target.value)
    };


    const handleAuditTypeSearchChange = (event) => {
        setAuditTypeSearch(event.target.value)
    };

    const handleAuditAffiliateSearchChange = (event) => {
        setAuditAffiliateSearchClient(event.target.value);
    };

    const handleAuditNetworkSearchChange = (event) => {
        setAuditNetworkSearchClient(event.target.value);
    };

    const clearOnClick = () => {
        setAuditAffiliateSearchClient(null);
        setAuditClientSearchClient(null);
        setAuditNetworkSearchClient(null);
        setAuditTypeSearch(null)
        setAuditTypeSearch(null)
        setAuditName('');
        setAudits([])
        forceUpdate();

    }



    const allicate_loading = affliate_open && affliate_Options.length === 0;



    const client_loading = client_open && client_Options.length === 0;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const audit_column = [
        {
            field: "id", headerName: "id", width: 100,
        },
        {
            field: "audit", headerName: "auditName", width: 100,
            renderCell: (params) => <div>{params.row.participant && params.row.audit.auditname}</div>
        },
        {
            field: "participant", headerName: "client", width: 100,
            renderCell: (params) => <div>{params.row.participant && params.row.participant.participantname}</div>
        }
    ];


    const mock_column = [
        {
            field: "processflowkey",
            headerName: "Process Flow Key",
            width: 200
        },
        {
            field: "processflownm",
            headerName: "Process Flow Name",
            width: 200
        },
        ,
        {
            field: "processflowjsondata",
            headerName: "Process Flow json data",
            width: 200
        }
    ]

	React.useEffect(() => {

        if (tempCurrentAudit) {
            setCurrentAudit(tempCurrentAudit)

            ProcessFlowDataService.getProcessFlowByAuditKey(tempCurrentAudit.audit.auditkey).then(res => {
                return res.data;
            }).then(processFlows => {
                setDataGridRows(processFlows);
            }).catch(e => {
                console.log(e)
            });

        }

        let active = true;
		const userKey = JSON.parse(localStorage.getItem('userKey') || '0');


        ClientDataService.getClients(userKey).then((res) => {
            ClientSetOptions(res.data);
            clientSetOpen(true);
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getAffiliates(userKey).then((res) => {
            affliateSetOptions(res.data);
            affliateSetOpen(true)
        }).catch(e => {
            console.log(e)
        });

		ClientDataService.getUserNetworks(userKey).then((res) => {
            setNetworkOptions(res.data);
            networkSetOpen(true)
        }).catch(e => {
            console.log(e)
        });

        AuditClientDataService.getAuditTypes().then((res) => {
            setAuditTypes(res.data);
        }).catch(e => {
            console.log(e)
        });
        
        const engine = createEngine({
            registerDefaultPanAndZoomCanvasAction: false,
            registerDefaultZoomCanvasAction: false
        });
        var model = new DiagramModel();
        engine.setModel(model);

        // Create custom node
        engine.getNodeFactories().registerFactory(new InputNodeFactory());
        engine.getNodeFactories().registerFactory(new OutputNodeFactory());
        engine.getNodeFactories().registerFactory(new BusinessRuleNodeFactory());
        engine.getNodeFactories().registerFactory(new FunctionNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new JoinDataNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new SelectNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendRowNodeFactory());
        engine.getNodeFactories().registerFactory(new AlterationNodeFactory());

        setDiagramEngine(engine)

        model.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity || {})
                        }

                    }
                })
                console.log(event.entity.options.id)
            },
        });

        return () => {
            active = false;
        };


    }, [allicate_loading, client_loading, currentAudit]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setAuditName(newValue);
    };

    const handleAuditNameChange = (event) => {
        setAuditName(event.target.value);
    };



    const handleProcessFlowNameChange = (event) => {
        setProcessFlowName(event.target.value);
    };


    const handleComponentSearchChange = event => {
        setsearchText(event.target.value);
    };

    const handleAuditClientSearch = event => {
        let _setSearchDto = {
            clientkey: auditClientSearchClient ? auditClientSearchClient.clientKey : -1,
            affiliatekey: auditAffiliateSearchClient ? auditAffiliateSearchClient.clientKey : - 1,
            networkkey: auditNetworkSearchClient ? auditNetworkSearchClient.networkkey : -1,
            auditstatuskey: -1,
            audittypekey: auditTypeSearch ? auditTypeSearch.audittypekey : -1,
            auditname: auditName ? auditName : "-",
            managerkey: -1,
            auditteamkey: -1,
            startdate: -1,
            enddate: -1
        }
        setSearchDto(_setSearchDto);
        AuditClientDataService.getAuditByParticipants(_setSearchDto)
            .then((res) => {
                return res.data.map(function (item) {
                    return {
                        id: item.audit.auditkey,
                        audit: item.audit,
                        auditManager: item.auditManagerTemp,
                        auditTeam: item.auditTeamTemp,
                        auditType: item.auditTypeTemp,
                        participant: item.participantTemp
                    }
                })
            }).then(data => {
                setAudits(data);
                setCurrentAudit()
            }).catch(error => {
                console.log(error)
            })
    };

    const handleModalClose = () => {
        setModalIsOpen(false);
        setProcessFlow()
    }

    const handleAuditModalClose = () => {
        setAuditModalIsOpen(false);
    }


    const handleDataSetClose = () => {
        setDataSetIsOpen(false);
    }

    const saveProcessFlow = (event) => {
        event.preventDefault();

        //var nodesCount = _.keys(diagramEngine.getModel().getNodes()).length;
        let g = new Graph(diagramEngine.getModel().getNodes());

        diagramEngine.getModel().getNodes().forEach(node => {
            node.precendentNodes = new Set();
        })

        diagramEngine.getModel().getLinks().forEach((link) => {
            g.addEdge(link.sourcePort.parent.options.id, link.targetPort.parent.options.id, 1);
            link.targetPort.parent.precendentNodes.add(link.sourcePort.parent.options.id)
        })

        let s = null;

        diagramEngine.getModel().getNodes().forEach(node => {
            if (node.precendentNodes.size == 0) {
                s = node;
            }
        })
        console.log("Following are longest distances from source vertex " + s);
        const dist = g.longestPath(s);

        diagramEngine.getModel().getNodes().forEach(node => {
            node.depth = dist.get(node.options.id);
        })



        if (processFlow) {
            diagramEngine.getModel().getNodes().forEach(node => {
                if (node.options.type == 'Output' && node.nodedata.OutPutDataLocation) {
                    node.nodedata.OutPutDataLocation = node.nodedata.OutPutDataLocation + processFlow.processflowkey;
                }
            })

            //ser
            var str = JSON.stringify(diagramEngine.getModel().serialize());

            processFlow.processflownm = processFlowName
            processFlow.processflowjsondata = str
            processFlow.auditkey = modalAudit.auditkey
            ProcessFlowDataService.update(processFlow.processflowkey, processFlow).then((res) => {
                console.log(res.data)
                handleModalClose();
            }).catch((err) => {
                console.log(err);
            })
        } else {

            //ser
            var str = JSON.stringify(diagramEngine.getModel().serialize());

            var data = {
                auditkey: modalAudit.auditkey,
                processflownm: processFlowName,
                processflowjsondata: str
            }

            ProcessFlowDataService.create(data).then((res) => {

                diagramEngine.getModel().getNodes().forEach(node => {
                    if (node.options.type == 'Output') {

                        if (node.nodedata) {
                            node.nodedata.OutPutDataLocation = node.nodedata.OutPutDataLocation + res.data.processflowkey;
                        } else {
                            node.nodedata = {
                                OutPutDataLocation: "defaultOutPutDataLocation"
                            }
                        }
                    }
                })
                //ser
                var str = JSON.stringify(diagramEngine.getModel().serialize());

                res.data.processflowjsondata = str

                ProcessFlowDataService.update(res.data.processflowkey, res.data).then((res) => {
                    console.log(res.data)
                    handleModalClose();
                }).catch((err) => {
                    console.log(err);
                })


                handleModalClose();
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const handleDataSetClick = (dataSet) => {

        if (dataSet && dataSet.nodedata &&  dataSet.nodedata.FileName) {
            setDataSetName(dataSet.nodedata.FileName);

            setDataSetIsOpen(true)

            AzureTableDataService.getByFile(dataSet.nodedata).then(res => {
                var firstElement = res.data[0];
                var resultData = res.data.slice(1);

                if (firstElement['rowKeyPrefixes'] == 'csv' || firstElement['rowKeyPrefixes'] == 'txt') {
                    var columns = [];
                    var columnNames = [];
                    if (firstElement) {
                        let i = 0;
                        while (resultData[0]["COL" + String(i).padStart(3, '0')]) {
                            var columnName = "COL" + String(i).padStart(3, '0')
                            columnNames.push(columnName);
                            columns.push(
                                {
                                    field: columnName,
                                    headerName: columnName,
                                    width: 200
                                }
                            );
                            i++;
                        }
                    }
                    setDataSetColumns(columns);

                    var rows = resultData.map((data, index) => {

                        var object = {};
                        object.id = index;

                        columnNames.forEach(colNm => {
                            object[colNm] = data[colNm];
                        });
                        return object;
                    });
                    setDatatSetRows(rows);
                } else {
                    //setSheets(firstElement['rowKeyPrefixes'].split("*"));
                    //setSheetsData(resultData);
                }

            }).catch(err => {
                console.log(err);
            })
        }
    }

    const getFileConfigByProcessFlowKey = (dataSet, callback) => {
        let outputname = dataSet.nodedata.OutPutDataNm;
        FileConfigDataService.getFileConfigByProcessFlowKey(processFlow.processflowkey).then((res) => {
            let fileData = res.data.filter(data => data.processflowcsvlocation.includes(outputname))[0]
            if (fileData && callback) {
                callback(fileData);
            }
        }).catch(err => {
            console.log(err);
        })
    }




    const getOutPutDataFromAzureTable = (fileData) => {

        DataLakeService.getParquetFile(fileData).then(res => {
            var columnNames = res.data.columnNames;
            var table = res.data.table;

            var columns = [];
            var rows = [];


            var rowCount = res.data.table.length;
            var colCount = columnNames.length;

            for (let i = 0; i < colCount; i++) {
                columns.push(
                    {
                        field: columnNames[i],
                        headerName: columnNames[i],
                        width: 200
                    })
            }

            for (let j = 0; j < rowCount; j++) {
                var object = {};
                object.id = j;

                table[j].map((row, index) => {
                    object[columns[index].field] = row
                })
                rows.push(object);
            }

            setDataSetColumns(columns);
            setDatatSetRows(rows);
            setDataSetIsOpen(true)
            //setTableHeader(columns);
           // setTableData(rows);
        });

    }

    const exportOutPutDataFromAzureTable = (fileData) => {
      
        AzureTableDataService.exportOutPutDataFromAzureTable(fileData).then(result => {
            console.log(result)
            if (result.status == 200) {
                window.open(result.data, '_blank');
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const handleDataSetOutPutClick = (dataSet) => {
        getFileConfigByProcessFlowKey(dataSet, getOutPutDataFromAzureTable);
    };

    const handleExportDataSetOutPutClick = (dataSet) => {
        getFileConfigByProcessFlowKey(dataSet, exportOutPutDataFromAzureTable);
    };



    const handleProcessFlowRowClick = (params) => {
        setProcessFlow(params.row)

        //deser
        var model2 = new DiagramModel();
        setProcessFlowName(params.row.processflownm)
        model2.deserializeModel(JSON.parse(params.row.processflowjsondata), diagramEngine);
        diagramEngine.setModel(model2);

        const nodes = model2.getNodes();

        const inputNodes = nodes.filter(node => node.options.type == 'Input');
        setInputDateSets(inputNodes)

        const outputNodes = nodes.filter(node => node.options.type == 'Output');
        setOutputDateSets(outputNodes)

        //

        model2.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {
                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity)
                            console.log(nodeSelectionChange.entity.options.id)
                        }

                    }
                })

            },
        });

        model2.getNodes().forEach((node) => {
            node.registerListener({
                selectionChanged: (e) => {
                    if (e.isSelected) {
                        setCurrentComponent(e.entity)
                        console.log(e.entity.options.id)
                    }

                }
            });
        })
        //setModalIsOpen(true);
    };


    const createNewProcessFlow = () => {

        setProcessFlow()
        setInputDateSets([])
        setOutputDateSets([])

        const engine = createEngine({
            registerDefaultPanAndZoomCanvasAction: false,
            registerDefaultZoomCanvasAction: false
        });
        var model = new DiagramModel();
        engine.setModel(model);

        // Create custom node
        engine.getNodeFactories().registerFactory(new InputNodeFactory());
        engine.getNodeFactories().registerFactory(new OutputNodeFactory());
        engine.getNodeFactories().registerFactory(new BusinessRuleNodeFactory());
        engine.getNodeFactories().registerFactory(new FunctionNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new JoinDataNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new SelectNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendRowNodeFactory());
        engine.getNodeFactories().registerFactory(new AlterationNodeFactory());

        setDiagramEngine(engine)

        model.registerListener({
            linksUpdated: function (event) {
                event.link.registerListener({
                    sourcePortChanged: function (sourceChange) {
                        console.log(sourceChange)
                    },
                    targetPortChanged: function (targetChange) {

                        console.log(targetChange)
                    }
                })
            },
            nodesUpdated: function (event) {
                event.node.registerListener({
                    selectionChanged: function (nodeSelectionChange) {
                        if (nodeSelectionChange.isSelected) {
                            setCurrentComponent(nodeSelectionChange.entity)
                            forceUpdate();
                            console.log(nodeSelectionChange.entity.options.id)
                        }

                    }
                })
                console.log(event.entity.options.id)
            },
        });

        setModalIsOpen(true);
    }


    const executeProcessFlow = () => {
        
        var data = {
            processflowKey: processFlow.processflowkey || 1
        }
        FileConfigDataService.executeProcessFlow(data).then((res) => {
            console.log(res);
            if (res.status == 200) {
                alert("Process flow pipeline sent successfully");
            } 
            else {
                alert("operation failed");
            }
        })
        .catch(err => {
            console.log(err);
            if (err.response.status == 400) {
                let data = err.response;
                if (data.data) {
                    alert(data.data);
                }
            }
        })
    }

    const handleAuditsRowClick = (params) => {
        setCurrentAudit(params.row)
        setModalAudit(params.row);
        forceUpdate();
        //setAuditModalIsOpen(true);
        ProcessFlowDataService.getProcessFlowByAuditKey(params.row.audit.auditkey).then(res => {
            return res.data;
        }).then(processFlows => {
            setDataGridRows(processFlows);
        }).catch(e => {
            console.log(e)
        });
    };


    return (
        <Box>
            <Stack>
                <RightContainer title="Audit Configuration">
                    <Box>
                        <Stack direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            spacing={2}
                        >
                            <Box>
                                <Stack direction="row" spacing={2} margin={1}>
                                    <Stack direction="row" spacing={2}>

                                        <FormControl sx={{ width: 280 }}>
                                            <InputLabel htmlFor="name-shared">Audit Type</InputLabel>
                                            <Select
                                                label="AuditType"
                                                value={auditTypeSearch || ''}
                                                onChange={handleAuditTypeSearchChange}
                                            >
                                                <MenuItem value={null}>NONE</MenuItem>
                                                {auditTypes.map(type => (
                                                    <MenuItem key={type.audittypekey} value={type}>
                                                        {type.audittypedesc}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl sx={{ width: 280 }}>
                                            <TextField
                                                name="AuditName"
                                                autoComplete='off'
                                                type="text"
                                                id="AuditName"
                                                value={auditName}
                                                onChange={handleAuditNameChange}
                                                label="Audit Name"
                                                size="medium"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={2} margin={1}>
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ width: 280 }}
                                        value={auditAffiliateSearchClient || ""}
                                        isOptionEqualToValue={(option) => option.clientKey}
                                        onChange={(event, value) => setAuditAffiliateSearchClient(value)}
                                        options={affliate_Options}
                                        autoHighlight
                                        getOptionLabel={(option) => option.clientName || ""}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.clientName}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose a Affliate"
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ width: 280 }}
                                        value={auditNetworkSearchClient || ""}
                                        isOptionEqualToValue={(option) => option.networkey}
                                        onChange={(event, value) => setAuditNetworkSearchClient(value)}
                                        options={networkOptions}
                                        autoHighlight
                                        getOptionLabel={(option) => option.networkdesc || ""}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.networkdesc}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose a Network"
                                            />
                                        )}
                                    />

                                </Stack>
                                <Stack direction="row" spacing={2} margin={1}>
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ width: 280 }}
                                        value={auditClientSearchClient || ""}
                                        isOptionEqualToValue={(option) => option.clientKey}
                                        onChange={(event, value) => setAuditClientSearchClient(value)}
                                        options={client_Options}
                                        autoHighlight
                                        getOptionLabel={(option) => option.clientName || ""}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.clientName}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose a Client"
                                            />
                                        )}
                                    />

                                    <Button color="primary" variant="contained" startIcon={<SearchIcon />} onClick={handleAuditClientSearch} sx={{ marginRight: "10px", minWidth: "70px" }}>
                                        Search
                                    </Button>

                                    <Button color="primary" variant="outlined" startIcon={<img
                                        alt="Plus"
                                        width="26px"
                                        height="25px"
                                        src={"../../assets/clear_all.svg"}
                                    />} sx={{ minWidth: "70px" }}
                                        onClick={clearOnClick}
                                    >
                                        Clear
                                    </Button>
                                </Stack>
                            </Box>
                            <Box
                                display="grid"
                                gap="30px"
                                m={1}
                                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}>
                                <Box
                                    m="8px 0 0 0"
                                    width="100%"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .name-column--cell": {
                                            color: colors.greenAccent[300],
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: colors.primary[400],
                                        },
                                        "& .MuiCheckbox-root": {
                                            color: `${colors.greenAccent[200]} !important`,
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${colors.grey[100]} !important`,
                                        },
                                    }}
                                    className="overwrite-table"
                                >
                                    <DataGrid
                                        autoHeight={true}
                                        onRowClick={handleAuditsRowClick}
                                        rows={audits}
                                        pageSize={5}
                                        columns={audit_column}
                                        components={{ Toolbar: GridToolbar }}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                        <Box sx={{ padding: '20px 10px' }}>
                            <CompositeTab audit={currentAudit} processFlows={dataGridRows} searchDto={searchDto} />
                        </Box>


                        <Dialog
                            fullWidth={true}
                            modal={true}
                            maxWidth='xl'
                            width="100%"
                            open={auditModalIsOpen}
                            onClose={handleAuditModalClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                            PaperProps={{
                                sx: {
                                    width: "100%",
                                    minHeight: 800,
                                    maxHeight: 800
                                }
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Header title={modalAudit == null ? 'Process Flow List' : modalAudit.auditname} handleModalClose={handleAuditModalClose} showHrLine={true} />
                            </DialogTitle>

                            <DialogContent>
                                <Box sx={{ fontSize: "16px", fontWeight: "600", marginBottom:"10px!important" }}>
                                    Audit Info
                                </Box>
                                <Box sx={{ border: " 2px solid var(--gray-500, #D0D7DE)", borderRadius: '3px' }}>
                                    <Stack>
                                        <Item>Audit Key: {auditModalIsOpen && modalAudit.auditkey }</Item>
                                        <Item>Audit Name: {auditModalIsOpen && modalAudit.auditname}</Item>
                                        <Item sx={{ boxShadow:"none" }}>Audit Client Key: {auditModalIsOpen && modalAudit.auditclientkey}</Item>
                                    </Stack>
                                </Box>

                                <Box sx={{margin:"30px 0px 15px 0px"} }>
                                    <Button type="submit" color="primary" variant="contained" onClick={createNewProcessFlow}>
                                        Create New Process Flow
                                    </Button>
                                </Box>

                                <Box
                                    display="grid"
                                    gap="30px"
                                    m={1}
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}>
                                    <Box
                                        m="8px 0 0 0"
                                        width="100%"
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                border: "none",
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: "none",
                                            },
                                            "& .name-column--cell": {
                                                color: colors.greenAccent[300],
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                backgroundColor: colors.primary[400],
                                            },
                                            "& .MuiCheckbox-root": {
                                                color: `${colors.greenAccent[200]} !important`,
                                            },
                                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                color: `${colors.grey[100]} !important`,
                                            },
                                        }}
                                        className="overwrite-table"
                                    >
                                        <DataGrid
                                            autoHeight={true}
                                            onRowClick={handleProcessFlowRowClick}
                                            getRowId={(row) => row.processflowkey}
                                            rows={dataGridRows}
                                            pageSize={10}
                                            columns={mock_column}
                                            components={{ Toolbar: GridToolbar }}
                                        />
                                    </Box>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </RightContainer>
            </Stack>

            <Dialog
                fullWidth={true}
                modal={true}
                    maxWidth='xl'
                    width="100%"
                    open={modalIsOpen}
                    onClose={handleModalClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                    PaperProps={{
                        sx: {
                            width: "100%",
                            minHeight: '95%',
                            maxHeight: '95%',
                        }
                    }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Header handleModalClose={handleModalClose} />
                </DialogTitle>

                    <DialogContent>
                    
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab color={colors.primary} label="Settings" {...a11yProps(0)} />
                                <Tab label="Data Sets" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <Box>
                            <Button type="submit" color="primary" variant="outlined" sx={{marginRight:"10px"}}>
                                Edit Process Flow
                            </Button>

                            <Button type="submit" color="primary" variant="contained" onClick={executeProcessFlow }>
                                Execute Process Flow
                            </Button>
                        </Box>
                    </Stack>
                    <Box sx={{ border: "2px solid #e0e0e0;"}} padding={2}>
                        <TabPanel value={value} index={0}>
                            <Stack>
                                <Stack direction='row' justifyContent="space-between">
                                <Box width={220} mb={1}>
                                        <Grid container spacing={2}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                <Typography variant="h5" gutterBottom>
                                                    Process Flow Name
                                                </Typography>

                                                <Box width={220} mb={1}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            key="processFlowName"
                                                            name="processname"
                                                            autoComplete='off'
                                                            type="text"
                                                            id="processFlowName"
                                                            value={processFlowName}
                                                            onChange={handleProcessFlowNameChange}
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                </Box>
                                    <Box mb={1}>
                                        <Button variant="contained" onClick={() => diagramEngine.zoomToFitSelectedNodes(10)}>zoom to fit</Button>
                                    </Box>
                                </Stack>

                                <Box sx={{ width: '100%', height: '25%' }}>
                                    <CreatorWidget engine={diagramEngine} currentComponent={currentComponent} processFlowNameChanger={setProcessFlowName} count={0} />
                                </Box>
                            </Stack>

                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <Box>
                                <Typography variant="h6"
                                    sx={{
                                        //fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: 100,
                                        lineHeight: '32px',
                                        color: 'var(--gray-1200, #393F48)'
                                    }}
                                    gutterBottom>
                                    Input DataSet ({inputDateSets.length})
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginLeft:"0px!important"}}>
                                    {inputDateSets.map((inputDataSet, index) => (
                                        <Box sx={{
                                            border: 1, width: '490px',
                                            borderRadius: '5px',
                                            background: 'var(--gray-400, #E7ECF1)'
                                        }} margin={2} >
                                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{padding:"0 10px 0 0"}}>
                                                <Box>
                                                    <List>
                                                        <ListItem height={100}>
                                                            <ListItemAvatar>
                                                                <img
                                                                    alt="dataset"
                                                                    width="48px"
                                                                    height="48px"
                                                                    src={"../../assets/dataset.svg"}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={inputDataSet.nodedata && inputDataSet.nodedata.FileName } onClick={() => { handleDataSetClick(inputDataSet) }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <CSVLink data={datatSetRows} className="export-button">EXPORT DATASET</CSVLink>
                                                    { /*<Button type="submit" variant="outlined" sx={{ backgroundColor:'white' }}>
                                                        Export DataSet
                                                    </Button>*/}
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))
                                    }
                                </Grid>
                            </Box>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <Box sx={{ width: '75%' }}>
                                <Typography variant="h6"
                                    sx={{
                                        //fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontStyle: 'normal',
                                        fontWeight: 100,
                                        lineHeight: '32px',
                                        color: 'var(--gray-1200, #393F48)'
                                    }}
                                    gutterBottom>
                                    Output DataSet ({outputDateSets.length})
                                </Typography>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginLeft: "0px!important" }}>
                                    {outputDateSets.map((outputDateSet, index) => (
                                        <Box sx={{
                                            border: 1, width: '490px',
                                            borderRadius: '5px',
                                            background: 'var(--gray-400, #E7ECF1)'
                                        }} margin={2} >
                                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ padding: "0 10px 0 0" }}>
                                                <Box>
                                                    <List>
                                                        <ListItem height={100}>
                                                            <ListItemAvatar>
                                                                <img
                                                                    alt="dataset"
                                                                    width="48px"
                                                                    height="48px"
                                                                    src={"../../assets/dataset_linked.svg"}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={outputDateSet.nodedata.OutPutDataNm}  onClick={() => {handleDataSetOutPutClick(outputDateSet) }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                                <Box>
                                                    <Button type="submit" variant="outlined" sx={{ backgroundColor: 'white' }} onClick={() => handleExportDataSetOutPutClick(outputDateSet)}>
                                                        Export DataSet
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ))
                                    }
                                </Grid>
                            </Box>

                        </TabPanel>
                    </Box>
                    
                    </DialogContent>
           
                    <DialogActions>
                        {/*{processFlow &&*/}
                        {/*    <Button type="submit" color="error" variant="contained" onClick={deleteProcessFlow}>*/}
                        {/*        DELETE*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button onClick={handleModalClose}>Cancel</Button>*/}

                       <Button type="submit" color="secondary" variant="contained" onClick={saveProcessFlow}>
                        Save Process Flow
                        </Button>
                    </DialogActions>
            </Dialog>

            <Dialog
                modal={true}
                maxWidth='xl'
                width="100%"
                open={dataSetIsOpen}
                onClose={handleDataSetClose}
                aria-labelledby="example-modal-sizes-title-lg"
                PaperProps={{
                    sx: {
                        width: "50%",
                        minHeight: '50%',
                        maxHeight: '50%'
                    }
                }}>

                <DialogTitle id="alert-dialog-title">
                    <Header title={dataSetName} />
                </DialogTitle>

            <DialogContent>
                    <Box
                        m="8px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            rows={datatSetRows}
                            pageSize={10}
                            columns={dataSetColumns}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
            </DialogContent>

        </Dialog>

        </Box>
        );
}
