import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ConfirmDialog(props) {
    const [loading, setLoading] = React.useState(false);

    const onConfirm = async () => {
        setLoading(true);
        props.onConfirm();
    }

    useEffect(() => {
        if (props.open) {
            setLoading(false);
        }
    }, [props.open]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={() => { onConfirm() }} loading={loading} loadingPosition="start" variant="contained" color="primary" size="small" style={{ background: "secondary", width: "80px" }} startIcon={<CheckIcon />}>Yes</LoadingButton>
                    <Button onClick={props.close} variant="contained" color="secondary" size="small" style={{ width: "80px", marginRight: "10px" }} startIcon={<CancelIcon />} autoFocus>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
