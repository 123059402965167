import React, { useState, useEffect } from 'react';
//import Divider from '@mui/material/Divider';

import {RolesSelection } from './RolesSelection';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Button,
	Grid,
	TextField,
	Box} from '@mui/material';

import { userService } from '../../ts/modules/users/service';
import { rolesService } from '../../ts/modules/roles/service';
import { Role } from '../../ts/modules/roles/interface';
import { User } from '../../ts/modules/users/interface';

	interface EditUserRolesDialogProps {
		roles: Role[];
		open: boolean;
		onClose: () => void;
		user: User | undefined;
		onUserUpdated: (updatedUser: User) => void;
	}

const EditUserRolesDialog: React.FC<EditUserRolesDialogProps> = ({ roles, open, onClose, user, onUserUpdated }) => {

	const [newUser, setNewUser] = useState({
		userName: user?.userName,
		firstName: user?.firstName,
		lastName: user?.lastName,
		email: user?.email,
		roles: [],
	});
	const [errors, setErrors] = useState({
		userName: '',
		firstName: '',
		lastName: '',
		email: '',
		roles: '',
	});


	const [isLoading, setIsLoading] = useState(false);
	const [selectedUserRoles, setSelectedUserRoles] = useState<Role[]>(user?.roles || []);
	// const [allRoles, setAllRoles] = useState<Role[]>(roles);

	console.log("EditUserRolesDialog, selectedUserRoles", selectedUserRoles);

	const [availableRoles, setAvailableRoles] = useState<Role[]>([]);

	useEffect(() => {
		// You could replace these with actual fetch calls if the roles are dynamic
		rolesService.fetchUserRoles().then(setAvailableRoles).catch(console.error);
		//dataRoleService.fetchRoles().then(setAvailableDataRoles).catch(console.error);
	}, []);

	useEffect(() => {
		// Fetch all roles when the component mounts or when the dialog opens
		// const fetchRoles = async () => {
		//     const roles = await rolesService.fetchRoles();
		//     setAllRoles(roles);
		//     if (user) {
		//         setSelectedUserRoles(user.roles);
		//         setSelectedUserDataRoles(user.dataRoles);
		//     }
		// };
          
		if (open) {
			// fetchRoles();
         
		}
	}, [open, user]);


	const handleUserChange = (e: { target: { name: any; value: any; }; }) => {

		const { name, value } = e.target;
		setNewUser(prev => ({ ...prev, [name]: value }));
	};

	const handleRoleChange = (event: { target: { value: any; }; }) => {
		const {
			target: { value },
		} = event;
		setNewUser(prev => ({ ...prev, roles: typeof value === 'string' ? value.split(',') : value }));
	};

	const handleSaveRoles = async () => {
		if (!user) return;
		setIsLoading(true);

		try {

			const updatedUser = await userService.updateUserRoles(user.userKey, selectedUserRoles);

			onUserUpdated(updatedUser); // Update the state in the parent component
			setIsLoading(false);

			alert("User Roles/Permissions were updated successfully");
		} catch (error) {
			setIsLoading(false);
			console.error('Failed to update user roles:', error);
		}
	};

	const handleSaveUser = async () => {
		if (!user) return;


		setIsLoading(true);

		try {
			const updatedUser = await userService.updateUser(user.userKey, newUser);

			onUserUpdated(updatedUser); // Update the state in the parent component

			setIsLoading(false);

			alert("User information was updated successfully");
		} catch (error) {
			setIsLoading(false);
			console.error('Failed to update user roles:', error);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
			<DialogTitle id="form-dialog-title">Edit Roles for {user?.userName}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Box sx={{ '& .MuiFormControl-root': { m: 1 }, display: 'flex', flexDirection: 'column', gap: 2 }} >
							<TextField
								label="User Name"
								name="userName"
								fullWidth
								onChange={handleUserChange}
								error={!!errors.userName}
								helperText={errors.userName}
								value={newUser.userName}
							/>
							<TextField
								label="First Name"
								name="firstName"
								fullWidth
								onChange={handleUserChange}
								error={!!errors.firstName}
								helperText={errors.firstName}
								value={newUser.firstName}
							/>
							<TextField
								label="Last Name"
								name="lastName"
								fullWidth
								onChange={handleUserChange}
								error={!!errors.lastName}
								helperText={errors.lastName}
								value={newUser.lastName}
							/>
							<TextField
								label="Email"
								name="email"
								type="email"
								fullWidth
								onChange={handleUserChange}
								error={!!errors.email}
								helperText={errors.email}
								value={newUser.email}
							/>
						</Box>
					</Grid>
					<Grid item xs={1}><Divider orientation="vertical" variant="fullWidth" /></Grid>
					<Grid item xs={5}>
						{/* <Typography variant="h6">User Roles</Typography> */}
						<Box sx={{ '& .MuiFormControl-root': { m: 1 }, display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: '300px', gap: 2, overflowY: 'scroll'}}>
							<RolesSelection 
								allRoles={roles} 
								selectedRoles={selectedUserRoles} 
								setSelectedRoles={setSelectedUserRoles} 
								title="Roles" 
								key={'roles'}
							/>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Grid item xs={7}>
						<Button onClick={handleSaveUser} disabled={isLoading} color="primary">
							{isLoading ? 'Updating...' : 'Update User'}
						</Button>
					</Grid>
					<Grid item xs={4}>
						<Button onClick={handleSaveRoles} disabled={isLoading} color="primary">
							{isLoading ? 'Updating...' : 'Update Roles'}
						</Button>
					</Grid>
					<Grid item xs={1}>
						<Button onClick={onClose} disabled={isLoading} color="primary">
							Close
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export { EditUserRolesDialog}
