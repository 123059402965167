import { fileHttp, jsonHttp, fileDownloadHttp } from "./http-common";


export class DataLakeService {
    uploadFile(data) {
       return fileHttp.post('/DataLakeService', data);
    }

    uploadContractConfigFile(data) {
        return fileHttp.post('/DataLakeService/ConrtactConfig', data);
    }

    downloadFile(id) {
        return fileDownloadHttp.get(`/DataLakeService/downloadfile/${id}`);
    }

    previewFile(file) {
        return jsonHttp.post(`/DataLakeService/previewfiles/`, file);
    }

    getParquetFile(file) {
        return jsonHttp.post(`/DataLakeService/getParquetFile/`, file);
    }

}
export default new DataLakeService();


