import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, MenuItem, Select, FormControl, InputLabel, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FileConfigDataService from "../../service/FileConfigDataService";

// Sample data for output nodes and their data models
const outputNodeData = [
    { id: 1, outputNodeName: 'Node A', fileName: 'fileA.csv', type: 'Type 1', dataModel: ['id', 'name', 'address', 'phone'] },
    { id: 2, outputNodeName: 'Node B', fileName: 'fileB.csv', type: 'Type 2', dataModel: ['code', 'type', 'description', 'value'] },
];

// Sample data for file types and their associated data models
const fileTypes = [
    {
        name: 'File Type 1',
        dataModel: ['siteId', 'division', 'franchise', 'code', 'description', 'servType'],
    },
    {
        name: 'File Type 2',
        dataModel: ['location', 'department', 'service', 'identifier', 'summary', 'type'],
    },
];

const DataModelTypography = styled(Typography)`
  font-size: 1rem;
  padding: 10px 0;
`;

const Approval = (prop) => {
    const { approvalModel, processFlowReadOnly } = prop;
    const [selectedFileType, setSelectedFileType] = useState(null); // No initial file type selected
    const [selectedNode, setSelectedNode] = useState(null);
    const [schemaSelections, setSchemaSelections] = useState({});
    const [isApproved, setIsApproved] = useState(false); // New state to track approval status
    const [revertData, setRevertData] = useState({})


    useEffect(() => {
        if (approvalModel.auditkey) {
            getApproveDetail();
        }
      
    }, [approvalModel]);

    const handleFileTypeChange = (event) => {
        const selectedType = approvalModel.fileTypes.find(type => type.name === event.target.value);
        console.log(selectedType)
        setSelectedFileType(selectedType);
        setSchemaSelections({});
        setSelectedNode(null); 
    };

    const handleRowSelection = (params) => {
        const node = approvalModel.outputItems.find(node => node.id === params.id);
        console.log(node)
        setSelectedNode(node);
    };

    const handleSchemaChange = (index) => (event) => {
        const newSelections = { ...schemaSelections, [index]: event.target.value };
        setSchemaSelections(newSelections);
    };

    const getApproveDetail = () => {
        FileConfigDataService.getApproveDetail(approvalModel.auditkey,approvalModel.processflowkey).then((res) => {
            let result = res.data;
            if (result.filetype) {
                const selectedType = approvalModel.fileTypes.find(type => type.name === result.filetype.filedesc);
                setSelectedFileType(selectedType);
            }

            if (result.auditModelAssignment) {
                const node = approvalModel.outputItems.find(node => node.fileConfigKey === result.auditModelAssignment.fileconfigkey);
                setSelectedNode(node);
            }

            if (result.auditModelMappings && result.auditModelMappings.length > 0) {
                const newObj = result.auditModelMappings.reduce((acc, cur, index) => {
                    acc[index] = cur.mapfieldname;
                    return acc;
                }, {});
                setSchemaSelections(newObj);
            }
        })
        .catch(err => console.log(err))
    }

    const handleToggleApproval = () => {

        if (isApproved) {
            FileConfigDataService.revert(revertData).then((res) => {
                setSchemaSelections({});
                alert("Revert successful.");
            })
                .catch(err => console.log(err))
        }
        else {
            var _auditModelMappingItems = selectedFileType.dataModel.map((x, index) => { return { fieldname: x, mapfieldname: schemaSelections[index] } });

            var dto = {
                auditkey: approvalModel.auditkey,
                processflowkey: approvalModel.processflowkey,
                fileconfigkey: selectedNode.fileConfigKey,
                filetypename: selectedFileType.name,
                auditModelMappingItems: _auditModelMappingItems
            };

            FileConfigDataService.approve(dto).then((res) => {
                setRevertData(res.data);
                alert("Approve successful.");
            })
            .catch(err => console.log(err))
        }
       
        setIsApproved(!isApproved);
    };

    return (
        <Box p={3} style={{ backgroundColor: 'white', minHeight: '100vh' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center">
                    <FormControl variant="outlined" style={{ minWidth: '200px', marginRight: '16px' }}>
                        <InputLabel>File Type</InputLabel>
                        <Select
                            label="File Type"
                            value={selectedFileType?.name || ''}
                            onChange={handleFileTypeChange}
                            disabled={processFlowReadOnly}
                        >
                            {approvalModel.fileTypes.map(type => (
                                <MenuItem key={type} value={type.name}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant="h6">State: ({isApproved ? "Approved" : "Open"})</Typography>
                </Box>
                <Box>
                    <Button variant="contained" color="primary" onClick={handleToggleApproval} disabled={processFlowReadOnly}>
                        {isApproved ? "Revert" : "Approve"}
                    </Button>
                </Box>
            </Box>

            {selectedFileType && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>
                            (Output Node) Information Here
                        </Typography>
                        <div style={{ height: 200, width: '100%' }}>
                            <DataGrid
                                rows={approvalModel.outputItems}
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 90 },
                                    { field: 'outputNodeName', headerName: 'Output Node Name', width: 150 },
                                    { field: 'fileName', headerName: 'File Name', width: 150 }
                                ]}
                                pageSize={5}
                                disabled={processFlowReadOnly}
                                onRowClick={handleRowSelection}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>
                            Data Model
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <DataModelTypography variant="subtitle2">Data Model Columns</DataModelTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DataModelTypography variant="subtitle2">Schema Columns</DataModelTypography>
                                    </Grid>
                                </Grid>
                                {selectedFileType.dataModel.map((model, index) => (
                                    <Grid container key={index} alignItems="center">
                                        <Grid item xs={6}>
                                            <DataModelTypography variant="body2">{model}</DataModelTypography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth margin="dense">
                                                <Select
                                                    value={schemaSelections[index] || ''}
                                                    onChange={handleSchemaChange(index)}
                                                    disabled={!selectedNode || processFlowReadOnly} // Disable if no node is selected
                                                >
                                                    <MenuItem value="">(None)</MenuItem>
                                                    {selectedNode && selectedNode.dataModel.map((schema, idx) => (
                                                        <MenuItem key={idx} value={schema}>
                                                            {schema}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default Approval;