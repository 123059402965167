import React, { useState } from 'react'
import { Box, Button, useTheme, Stack, TextField, Autocomplete } from '@mui/material'
import Header from "../../components/Header";

import { tokens } from "../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ClientFileSchemaService from '../../service/ClientFileSchemaService';
import FileConfigDataService from '../../service/FileConfigDataService';
import FileSchemaDataService from '../../service/FileSchemaService';
import AzureTableDataService from '../../service/AzureTableDataService';
import ClientDataService from '../../service/ClientDataService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import RightContainer from "../global/RightContainer";
import FileTypeDataService from '../../service/FileTypeDataService';
import { event } from 'jquery';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function CreateSchema(props) {
    //const location = useLocation();
    //const file = location.state?.data;

    const [currentFileConfig, setCurrentFileConfig] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [warning, setWarnig] = useState(false);
    const [warningMessage, setWarnigMessage] = useState('');

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const forceUpdate = React.useReducer(bool => !bool)[1];

    const handleClose = () => {
        setWarnig(false);
    };

    const [hideCreate, setHideCreate] = React.useState(false);
    const [hideApply, setHideApply] = React.useState(false);

    const [clientSchemas, setClientSchemas] = React.useState([]);

    const [columnsToShow, setColumnsToShow] = React.useState([]);
    const [rowsToShow, setRowsToShow] = React.useState([]);

    const [totalRows, setTotalRows] = React.useState([]);

    const [sheets, setSheets] = React.useState([]);

    const [sheetsData, setSheetsData] = React.useState([]);

    const [sheetToShow, setSheetToShow] = React.useState();

    const [schemaRow, setSchemaRow] = React.useState();

    const [schemaToassign, setSchemaToassign] = React.useState();


    const [schemaToApply, setSchemaToApply] = React.useState([]);

    const [newSchema, setNewSchema] = React.useState([]);

    const [newSchemaName, setNewSchemaName] = React.useState();

    const [fileSearchClient, setFileSearchClient] = React.useState();

    const [dataGridRows, setDataGridRows] = React.useState([]);


    const [affliate_open, affliateSetOpen] = useState(false);
    const [affiliate_Options, setAffiliateOptions] = useState([]);

    const [client_open, clientSetOpen] = useState(false);
    const [client_Options, setClientOptions] = useState([]);

    const [fileTypes, setFileTypes] = React.useState([]);
    const [fileType, setFileType] = React.useState();
    const [currentClient, setCurrentClient] = React.useState();
    const [newFileTypeName, setNewFileTypeName] = React.useState();



    const handleFileSearchChange = (value) => {

        if (value) {
            setCurrentClient(value)
            ClientFileSchemaService.getClientFileSchemasByClient(value.clientKey)
                .then(res => {
                    setClientSchemas(res.data);
                })
                .catch(err => {
                    console.log(err);
                });

            FileConfigDataService.getFilesByClient(value.clientKey)
                .then((res) => {
                    setDataGridRows(res.data.map((data, index) => {
                        return {
                            id: index,
                            FileName: data.filenm,
                            FileTypeDesc: data.filetypedesc,
                            ConversionStatus: data.conversionstatus,
                            fileConfigKey: data.fileconfigkey
                        }

                    })
                    )
                })
                .catch(err => console.log(err));
            setFileSearchClient(value);
        }

    };

    React.useEffect(() => {

	    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

        ClientDataService.getClients(userKey).then((res) => {
            return res.data;
        }).then(clients => {
            clientSetOpen(true);
            setClientOptions(clients);
        }).catch(e => {
            console.log(e)
        });


        ClientDataService.getAffiliates(userKey).then((res) => {
            return res.data;
        }).then(affliates => {
            affliateSetOpen(true)
            setAffiliateOptions(affliates);
        }).catch(e => {
            console.log(e)
        });

    }, []);


    const checkValue = (value) => {

        if (value === undefined || value === null || value) {
            return 1
        } else return 2;
}


    const saveSchemaClick = (event) => {
        var schema = newSchema.map((data, index) =>  {
            return {
                fieldorder: index,
                fieldname: data.FileSchema,
                fielddesc: data.ProposedColumn,
                fieldstatuskey: checkValue(data.fieldStatus),
                fieldformatdesckey: data.DataType ? data.DataType : 1
            }
        });

        var metadata = {
            clientkey: fileSearchClient.clientKey,
            fileconfigkey: currentFileConfig.fileConfigKey,
            schemaname: newSchemaName,
            sheetname: sheetToShow,
            skiprows: schemaRow,
            fileschemas: schema,
            filetypekey: fileType.filetypekey
        }

        FileSchemaDataService.createNewSchemaForUser(metadata)
            .then(res => {
                var data = {
                    conversionstatuskey: 200,
                    fileconfigKey: currentFileConfig.fileConfigKey
                }
                FileConfigDataService.updateClientFileSchemaKeyWithoutStartingPipeline(data)
                    .then(res => {
                        setRowsToShow([])
                        setModalIsOpen(false);
                        setDataGridRows(dataGridRows.filter(row => row.fileConfigKey != currentFileConfig.fileConfigKey));
                    }).then(() => {
                        setWarnigMessage("Created New Schema Successfully");
                        setWarnig(true)
                        setTotalRows([])
                        setNewSchemaName();
                        setNewSchema([]);

                        setSheets([]);
                        setSheetToShow();
                        handleFileSearchChange(fileSearchClient);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch(err => {
                setWarnigMessage("Fail to save schema to DB !!!")
                setWarnig(true)
                console.log(err);
            })
    }

    const applySchema = (event) => {
        setHideApply(!hideApply);
        setHideCreate(false);
        var schema = schemaToassign;

        if (schema && schema.clientfileschemakey) {
            FileSchemaDataService.getFileSchemaByClientFileSchemaKey(schema.clientfileschemakey)
                .then((res) => {
                    setSchemaToApply(res.data.map((data, index) => {
                        return {
                            id: index,
                            FieldOrder: data.fieldorder,
                            FieldName: data.fieldname,
                            FieldDesc: data.fielddesc,
                            FieldFormat: data.fieldformatdesc
                        }
                    }));
                })
                .catch(err => {
                    setWarnigMessage("Fail to load scheam from DB !!!")
                    setWarnig(true)
                    console.log(err);
                })
        }
    }



    const confirmAssignment = (event) => {

        if (!currentFileConfig.filedatefrom ||  !currentFileConfig.filedateto) {
            setWarnigMessage("Please select start and end date")
            setWarnig(true)
            return;

        }

        if (currentFileConfig) {

            var data = {
                fileconfigkey: currentFileConfig.fileConfigKey,
                filedatefrom: currentFileConfig.filedatefrom,
                filedateto: currentFileConfig.filedateto
            }
            FileConfigDataService
                .update(currentFileConfig.fileConfigKey, data)
                .then(() => {
                    var schema = schemaToassign;
                    if (schema && schema.clientfileschemakey && currentFileConfig.fileConfigKey) {
                        var data = {
                            clientfileschemakey: schema.clientfileschemakey,
                            fileConfigkey: currentFileConfig.fileConfigKey,
                            filetypekey: schema.filetypekey
                        }
                        FileConfigDataService.updateClientFileSchemaKey(data)
                            .then(() => {
                                setHideApply(false);
                                setSchemaToassign({ key: -1, value: '' });
                                setDataGridRows(dataGridRows.filter(row => row.fileConfigKey != currentFileConfig.fileConfigKey));
                                setWarnigMessage("Schema Assigned Successfully !!!")
                                setWarnig(true);
                                forceUpdate();
                                handleFileSearchChange(fileSearchClient);
                            })
                            .catch(err => {
                                setWarnigMessage("Schema Assigned Failed !!!")
                                setWarnig(true)
                                console.log(err);
                            })
                    } else {
                        setWarnigMessage("Please select file and Schema")
                        setWarnig(true)

                    }
                })
                .catch(err => console.log(err))
        }



    }


    const createNewFileType = (event) => {

        if (fileTypes.find(x => x.filedesc == newFileTypeName)) {
            setWarnigMessage("This File Type already Exists");
            setWarnig(true)

            return;
        }

        var date = {
            filedesc: newFileTypeName
        }

        FileTypeDataService.createForClient(currentClient.clientKey, date)
            .then(res => {
                FileTypeDataService.getByClientKey(currentClient.clientKey).then(res => {
                    setFileTypes(res.data);
                }).catch(err => {
                    console.log(err)
                })
            })
            .catch(err => {
                setWarnigMessage("FileType Creation Failed Failed !!!")
                setWarnig(true)
                console.log(err);
            })
    }



    const createNewSchema = (event) => {

        setModalIsOpen(true);

        setHideCreate(!hideCreate)
        setHideApply(false);
        setTotalRows([]);
        setRowsToShow([])

        setSheets([]);
        setSheetToShow();

        setNewSchemaName();
        setNewSchema([]);


        if (currentClient) {
            FileTypeDataService.getByClientKey(currentClient.clientKey).then(res => {
                setFileTypes(res.data);

            }).catch(err => {
                console.log(err)
            })

        }
    
        AzureTableDataService.getByFile(currentFileConfig).then(res => {
            var firstElement = res.data[0];
            var resultData = res.data.slice(1);

            if (firstElement['rowKeyPrefixes'] == 'csv' || firstElement['rowKeyPrefixes'] == 'txt') {
                var columns = [];
                var columnNames = [];
                if (firstElement) {
                    let i = 0;
                    while (resultData[0]["COL" + String(i).padStart(3, '0')]) {
                        var columnName = "COL" + String(i).padStart(3, '0')
                        columnNames.push(columnName);
                        columns.push(
                            {
                                field: columnName,
                                headerName: columnName,
                                width: 200
                            }
                        );
                        i++;
                    }
                }
                setColumnsToShow(columns);

                var rows = resultData.map((data, index) => {

                    var object = {};
                    object.id = index;

                    columnNames.forEach(colNm => {
                        object[colNm] = data[colNm];
                    });
                    return object;
                });
                setTotalRows(rows);
            } else {
                setSheets(firstElement['rowKeyPrefixes'].split("*"));
                setSheetsData(resultData);
                //resultData.group(data => { data["rowKey"].Split("-")[0] });
            } 

            }).catch(err => {
                console.log(err);
            })

    }

    const handleChange = (event) => {
        setRowsToShow(totalRows.slice(0, event.target.value));
    };

    const handleFileRowClick = (params) => {
        setCurrentFileConfig(params.row)
    };


    const handleRowClick = (params) => {

        var newSchemaRows = [];
        let keys = Object.keys(params.row);

        setSchemaRow(params.id)
       
        keys.map((key, index) => {

            if (key != 'id') { 
                newSchemaRows.push(
                        {
                            id: index,
                            FileSchema: params.row[key],
                            ProposedColumn: params.row[key]
                        }
                    );
            }
        })
        setNewSchema(newSchemaRows);
    };

    const onSheetSelectChange = (event) => {
        setSheetToShow(event.target.value);
        var sheetData = sheetsData.filter(data => data["RowKey"].startsWith(event.target.value));

        var firstElement = sheetData[0];

        var columns = [];
        var columnNames = [];
        if (firstElement) {
            let i = 0;
            while (firstElement["COL" + String(i).padStart(3, '0')]) {
                var columnName = "COL" + String(i).padStart(3, '0')
                columnNames.push(columnName);
                columns.push(
                    {
                        field: columnName,
                        headerName: columnName,
                        width: 200
                    }
                );
                i++;
            }
        }
        setColumnsToShow(columns);

        var rows = sheetData.map((data, index) => {
            var object = {};
            object.id = index;
            columnNames.forEach(colNm => {
                object[colNm] = data[colNm];
            });
            return object;
        });
        setTotalRows(rows);           
    }


    const columns = [
        {
            field: "FileSchema", headerName: "File Schema", width: 100,
        },
        { field: "ProposedColumn", headerName: "Proposed Column", width: 150 },

        {
            field: "FieldStatus",
            headerName: "Field Status",
            width: 100,
            rowHeight: 100,
            renderCell: (params) => <Checkbox
                onChange={(event) => {
                    params.row.fieldStatus = event.target.checked;
                }}
                defaultChecked />
        },
        {
            field: "DataType",
            headerName: "DataType",
            width: 150,
            rowHeight:100,
            renderCell: (params) =>
                <FormControl fullWidth >
                    <Select
                        labelId="DataTypeLabel"
                        id="Data-Type-Select"
                        label="DateType"
                        defaultValue={1}
                        onChange={(event) => {
                            params.row.DataType = event.target.value;
                        }}
                    >
                        <MenuItem value={1}>String</MenuItem>
                        <MenuItem value={2}>Long</MenuItem>
                        <MenuItem value={3}>Double</MenuItem>
                        <MenuItem value={4}>Timestamp</MenuItem>
                        <MenuItem value={5}>varchar </MenuItem>
                    </Select>
                </FormControl>
        },

    ];


    const existingSchemaColumns = [
        {
            field: "FieldOrder", headerName: "Field Order", width: 100,
        },
        { field: "FieldName", headerName: "Field Name", width: 200 },
        {
            field: "FieldDesc",
            headerName: "Field Desc",
            width: 200,
            rowHeight: 100,
        },
        {
            field: "FieldFormat",
            headerName: "Field Format",
            width: 200,
            rowHeight: 100,
        },

    ];

    const mockData_column = [
        {
            field: "FileName",
            headerName: "File Name",
            width: 200
        },
        {
            field: "FileTypeDesc",
            headerName: "File Type Desc",
            width: 200
        },
        {
            field: "ConversionStatus",
            headerName: "Conversion Status",
            width: 200
        }
    ]

    const handleModalOpen = () => setModalIsOpen(true);
    const handleModalClose = () => setModalIsOpen(false);


    return (
        <Box>
            <RightContainer title="Assign Schema" subtitle="Subheading/03" >
           
                <Box >
                    <Stack direction="row" spacing={ 5}>
                        <Autocomplete
                            id="country-select-demo"
                            sx={{ width: 280 }}
                            onChange={(event, value) => handleFileSearchChange(value)}
                            options={client_Options}
                            autoHighlight
                            getOptionLabel={(option) => option.clientName}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.clientName}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Client"
                                />
                            )}
                        />



                        <Autocomplete
                            id="country-select-demo"
                            sx={{ width: 280 }}
                            onChange={(event, value) => handleFileSearchChange(value)}
                            options={affiliate_Options}
                            autoHighlight
                            getOptionLabel={(option) => option.clientName}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.clientName}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Affilliate"
                                />
                            )}
                        />
                    </Stack>


                    <Box
                        m="18px 0 0 0"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        className="overwrite-table"
                    >
                        <DataGrid
                            autoHeight={true}
                            onRowClick={handleFileRowClick}
                            rows={dataGridRows}
                            pageSize={5}
                            columns={mockData_column}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                    <Box>
                        <Typography variant="h3">{'File Name : ' + (currentFileConfig && currentFileConfig.FileName ? currentFileConfig.FileName:"")} </Typography>
                        <Box mt="10px" justifyContent="flex-end">

                            <Stack direction="row" width='100%' justifyContent="space-between" >
                                <Box mt="11px">
                                    <FormControl style={{ width: 300}} size="small">
                                        <InputLabel htmlFor="showToShow">Existing Schema for this Client</InputLabel>
                                        <Select
                                            labelId="show"
                                            id="Data-Type-Select"
                                            label="Existing Schema for this Client"
                                            value={schemaToassign}
                                            onChange={(event) => {
                                                setSchemaToassign(event.target.value);
                                            }}
                                        >
                                            {clientSchemas.map((schema, index) => (
                                                <MenuItem key={index} value={schema}>
                                                    {schema.clientfiletypedesc}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button style={{ width: 150 ,marginLeft:"10px",marginRight:"10px"}} type="submit" color="secondary" onClick={applySchema} variant="contained">
                                        Apply
                                    </Button>
                                    <Button style={{ width: 150 }} type="submit" color="secondary" variant="contained" onClick={createNewSchema}>
                                        Create Schema
                                    </Button>
                                </Box>
                            </Stack>
                            <br />
                            <br />
                            <br />
                            {hideApply && <Stack direction="row" spacing={10}>
                              <Box
                                m="8px 0 0 0"
                                width="30%"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.greenAccent[300],
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: colors.primary[400],
                                    },
                                    "& .MuiCheckbox-root": {
                                        color: `${colors.greenAccent[200]} !important`,
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${colors.grey[100]} !important`,
                                    },
                                }}
                                className="overwrite-table"
                            >
                                <DataGrid
                                    autoHeight={true}
                                    hideFooter={true}
                                    rows={schemaToApply}
                                    columns={existingSchemaColumns}
                                />
                                </Box>
         
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={5}>
                                            <DatePicker
                                                label="Start Date"
                                                // dateFormat="yyyy-MM-dd"

                                                value={currentFileConfig.filedatefrom ? moment(currentFileConfig.filedatefrom) : null}
                                                //value={currentFileConfig.filedatefrom}
                                                onChange={(newValue) => {
                                                    // setStartDate(newValue)
                                                    currentFileConfig.filedatefrom = newValue
                                                }
                                                }
                                            />
                                            <br />
                                            <DatePicker
                                                label="endDate"
                                                value={currentFileConfig.filedateto ? moment(currentFileConfig.filedateto) : null}
                                                //value={currentFileConfig.filedateto}
                                                dateFormat="yyyy-MM-dd"
                                                onChange={(newValue) => {
                                                    // setEndDate(newValue)
                                                    currentFileConfig.filedateto = newValue
                                                }
                                                }
                                            />
                                            <Box justifyContent="end" mt="20px">
                                                <Button mt={10} type="submit" color="secondary" onClick={confirmAssignment} variant="contained">
                                                    Confirm
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </LocalizationProvider>
                                </Box>
                            <br />
                            </Stack>}
                           </Box>
                    </Box>

                    <div>
                        <Dialog
                        fullWidth='true'
                        maxWidth='lg'
                        width="100%"
                        open={modalIsOpen}
                        onClose={handleModalClose}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Creating New Schema"}
                        </DialogTitle>

                            <DialogContent>
                                <Stack>
                                {sheets?.length > 0 &&
                                <Box>
                                    <InputLabel htmlFor="showToShow">sheet to Show</InputLabel>
                                    <Select
                                        labelId="show"
                                        sx={{ width: 300 }}
                                        id="Data-Type-Select"
                                        label="DateType"
                                        value={sheetToShow}
                                        onChange={onSheetSelectChange}
                                    >
                                        {sheets.map((sheet, index) => (
                                            <MenuItem key={index} value={sheet}>
                                                {sheet}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                        </Box>}

                                       <br />

                                    <Stack direction="row" spacing={5} >
      
                                        <FormControl sx={{ width: 280 }}>
                                            <InputLabel htmlFor="showToShow">Rows to Show</InputLabel>
                                            <Select
                                                labelId="show "
                                                id="Data-Type-Select"
                                                label="DateType"
                                                onChange={handleChange}
                                             
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                            </Select>
                                          
                                            </FormControl>

                                        <br />
                                        <br />
 
                                        <FormControl sx={{ width: 280 }}>
                                            <InputLabel htmlFor="name-shared">File Type</InputLabel>
                                            <Select
                                           
                                                label="FileType"
                                                value={fileType || ''}
                                                onChange={(event) => { setFileType(event.target.value) }}
                                            >
                                                <MenuItem value={null}>NONE</MenuItem>
                                                {fileTypes.map(type => (
                                                    <MenuItem key={type.filetypekey} value={type}>
                                                        {type.filedesc}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                        </FormControl>

                                        <FormControl sx={{ width: 200 }}>

                                            <TextField
                                                id="newFileType"
                                                name="newFileType"
                                                required="true"
                                                value={newFileTypeName}
                                                onChange={(event) => { setNewFileTypeName(event.target.value) }}
                                                label="New FileType Name"
                                                size="small"
                                                variant="outlined" />
                                                <br />

                                            <Button color="primary" onClick={createNewFileType} variant="contained">Create New File Type</Button>
                                        </FormControl>

                    

                                       </Stack>
            
                                       <br />

                                     <Stack direction="row" spacing='5%'>
                                            <Box
                                                width="45%"
                                                height="80vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .name-column--cell": {
                                                        color: colors.greenAccent[300],
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: colors.primary[400],
                                                    },
                                                    "& .MuiCheckbox-root": {
                                                        color: `${colors.greenAccent[200]} !important`,
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${colors.grey[100]} !important`,
                                                    },
                                                }}
                                                className="overwrite-table"
                                            >
                                                <DataGrid
                                                    autoHeight={true}
                                                    hideFooter={true}
                                                    onRowClick={handleRowClick}
                                                    rows={rowsToShow}
                                                    columns={columnsToShow}/>
                                            </Box>
                                            <Box
                                                    width="45%"
                                                    height="80vh"
                                                    sx={{
                                                        "& .MuiDataGrid-root": {
                                                            border: "none",
                                                        },
                                                        "& .MuiDataGrid-cell": {
                                                            borderBottom: "none",
                                                        },
                                                        "& .name-column--cell": {
                                                            color: colors.greenAccent[300],
                                                        },
                                                        "& .MuiDataGrid-virtualScroller": {
                                                            backgroundColor: colors.primary[400],
                                                        },
                                                        "& .MuiCheckbox-root": {
                                                            color: `${colors.greenAccent[200]} !important`,
                                                        },
                                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                            color: `${colors.grey[100]} !important`,
                                                        },
                                                    }}
                                                    className="overwrite-table"
                                                >
                                                    <DataGrid
                                                        autoHeight={true}
                                                        hideFooter={true}
                                                        rows={newSchema}
                                                        columns={columns}
                                                    />

                                                    <br />

                                                    <TextField
                                                        id="newSchemaName"
                                                        name="newSchemaName"
                                                        required="true"
                                                        value={newSchemaName}
                                                        onChange={(event) => { setNewSchemaName(event.target.value) }}
                                                        label="New Schema Name"
                                                        size="small"
                                                        variant="outlined" />
                                                </Box>
                                    </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleModalClose}>Cancel</Button>
                            <Button type="submit" color="secondary" variant="contained" onClick={saveSchemaClick}>
                                Save Schema
                            </Button>
                        </DialogActions>
                        </Dialog>
                        </div>
                </Box>

                <Box>
                    <div>
                        <Dialog
                            open={warning}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                ALERT
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {warningMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} autoFocus>OK</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    </Box>

            </RightContainer>
        </Box>


      );
}

