import React from 'react'
import { Box, Button, TextField, Stack, Divider, Autocomplete } from '@mui/material'
import { Formik } from "formik";
import { useField, useFormikContext } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService"
import AuditClientDataService from "../../service/AuditClientDataService"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, addMonths } from 'date-fns'

import RightContainer from "../global/RightContainer";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SAIMEntityDataService from '../../service/SAIMEntityDataService';

const CreateAuditModel = () => {
    const [affliate_open, affliateSetOpen] = React.useState(false);
    const [affliate_Options, affliateSetOptions] = React.useState([]);
    const [auditStartDate, setauditStartDate] = React.useState();
    const [auditEndDate, setauditEndDate] = React.useState();


    const allicate_loading = affliate_open && affliate_Options.length === 0;

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [errorMessage, setErrorMessage] = React.useState('');
    const [auditModelName, setAuditModelName] = React.useState('');

    const [auditType, setAuditType] = React.useState();
    const [auditTypes, setAuditTypes] = React.useState([]);


    React.useEffect(() => {
	    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');


        ClientDataService.getAffiliates(userKey).then((res) => {
            affliateSetOptions(res.data);
            affliateSetOpen(true)
        }).catch(e => {
            console.log(e)
        });

        AuditClientDataService.getAuditTypes().then((res) => {
            setAuditTypes(res.data);
        }).catch(e => {
            console.log(e)
        });
    }, [allicate_loading,]);



    const handleFormSubmit2 = (values) => {
        console.log("submit", values);
        let auditModel = {
            auditModelName: auditModelName,
            auditkey: -1,
            audittypekey: auditType.audittypekey,
            auditstartdtkey: format(values.startDate, 'yyyyMMdd'),
            auditenddtkey: format(values.endDate, 'yyyyMMdd')
        }

       /* AuditClientDataService.create(audit).then(() => {
            setErrorMessage("AuditClient Saved");
        }).catch(e => {
            setErrorMessage(e.message);
        })*/
    };



    const initialValues = {
        auditName: "",
        affliate: affliate_Options[0],
        startDate: new Date(),
        endDate: addMonths(new Date(), 1),
    };

    const DatePickerField = ({ ...props }) => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);
        return (
            <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
            />
        );
    };


        return (
            <Stack>
                <RightContainer title="Create Audit Model">
                    <Formik onSubmit={handleFormSubmit2} initialValues={initialValues} validator={() => ({})}>
                        {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    m={20}
                                    gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}>
                                    <Stack>

                                        <FormControl sx={{ width: 280 }}>
                                            <TextField
                                                name="AuditModelName"
                                                type="text"
                                                id="AuditModelName"
                                                value={auditModelName}
                                                onChange={(event) => setAuditModelName(event.target.value)}
                                                label="Audit Model Name"
                                                size="medium"
                                            />
                                        </FormControl>


                                        <br />

                                        <Autocomplete
                                            name="affliate"
                                            disablePortal
                                            id="affliate"
                                            value={values.affliate}
                                            options={affliate_Options}
                                            onChange={(e, value) => {
                                                console.log(value);
                                                setFieldValue(
                                                    "affliate",
                                                    value !== null ? value : initialValues.affliate
                                                );
                                            }}
                                            getOptionLabel={(option) => option.clientName}
                                            isOptionEqualToValue={(option, value) =>
                                                option.clientkey === value.clientkey
                                            }
                                            renderInput={(params) => <TextField
                                                {...params} label="Affliate" />}
                                        />

                                        <br />
                                        <br />

                                        <Stack direction="row"
                                            spacing={10}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                <DatePickerField name="startDate"
                                                    value={values.startDate}
                                                    label="Audit Start Date"
                                                />

                                                <DatePickerField name="endDate"

                                                    value={values.endDate}
                                                    label="Audit End Date"
                                                />

                                            </LocalizationProvider>


                                            <FormControl sx={{ width: 280 }}>

                                                <InputLabel htmlFor="name-shared">Audit Type</InputLabel>
                                                <Select
                                                    label="AuditType"
                                                    value={auditType}
                                                    onChange={
                                                        (event) => {
                                                            setAuditType(event.target.value)
                                                        }}
                                                >
                                                    {auditTypes.map(type => (
                                                        <MenuItem key={type.audittypekey} value={type}>
                                                            {type.audittypedesc}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                        </Stack>

                                        <br />
                                        <br />

                                        <br />

                                        {errorMessage ? <div className="error"> {errorMessage} </div> : null}
                                        <Box justifyContent="left" mt="20px">
                                            <Button type="submit" color="secondary" variant="contained" >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </RightContainer>
            </Stack>
        );
}

export default CreateAuditModel