import React from "react";
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { NodeModel, DefaultPortModel } from "@projectstorm/react-diagrams";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";

import Header from "../../Header";
import { Box, Button, Stack } from '@mui/material'

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';



export const FilterNodeWidget = props => {
    return (
        <div className="node" >
            {/*<div*/}
            {/*    className="node-header-container"*/}
            {/*    style={{ backgroundColor: props.node.color }}*/}
            {/*>*/}
            {/*    <div className="my-icon" />*/}
            {/*    <div className="node-header-text">Filter</div>*/}
            {/*</div>*/}

            <PortWidget
                className="port-container left-port"
                engine={props.engine}
                port={props.node.getPort("in")}
            >
                <div className="node-port" />
            </PortWidget>

            {/*<div className="node-content">Filter Description</div>*/}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: '0',
                boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
                padding: '5px',
                backgroundColor: 'white'
            }}>
                <FilterAltOutlinedIcon
                    width="65px"
                    height="65px"
                    sx={{ width: "65px", height: '65px' }}
                />
            </Box>
            <Box className="label-name">{props.node.options.name}</Box>

            <PortWidget
                className="port-container right-port"
                engine={props.engine}
                port={props.node.getPort("out")}
            >
                <div className="node-port" />
            </PortWidget>
        </div>
    );
};


export class FilterModel extends NodeModel {
    constructor(options) {
        super({
            id: options?.id,
            name: options?.name || "Filter",
            type: "Filter"
        });
        this.depth = 0;
        this.inputFields = new Array();
        this.outputFields = new Array();
        this.precendentNodes = new Set();
        this.nodedata = options?.nodedata;

        if (options) {
            this.color = options.color || "black";
        }

        // setup an in and out port
        this.addPort(
            new DefaultPortModel({
                in: true,
                name: "in"
            })
        );
        this.addPort(
            new DefaultPortModel({
                in: false,
                name: "out"
            })
        );
    }

    serialize() {
        return {
            ...super.serialize(),
            depth: this.depth,
            name: this.options.name,
            inputFields : this.inputFields,
            outputFields : this.outputFields,
            precendentNodes: Array.from(this.precendentNodes),
            nodedata: this.nodedata,
        }
    }

    deserialize(event, engine) {
        super.deserialize(event, engine);
        this.depth = event.data.depth;
        this.options.name = event.data.name;
        this.inputFields = event.data.inputFields;
        this.outputFields = event.data.outputFields;
        this.nodedata = event.data.nodedata;
        this.precendentNodes = new Set(event.data.precendentNodes);
        this.options.selected = false;
    }


}

// TODO: Refactor to hooks
export class FilterNodeFactory extends AbstractReactFactory {
    constructor() {
        super("Filter");
    }

    generateModel(initialConfig) {
        return new FilterModel();
    }

    generateReactWidget(event) {
        return <FilterNodeWidget engine={this.engine} node={event.model} />;
    }
}



export function FilterComponent(props) {

    const currentComponent = props.currentComponent;


    const [filters, setFilters] = React.useState([{ filterName: '', criteria: '' }]);
    const [nodeName, setNodeName] = React.useState('');


    React.useEffect(() => {

        if (currentComponent.options && currentComponent.options.name) {
            setNodeName(currentComponent.options.name)
        }

        if (currentComponent.nodedata && currentComponent.nodedata.filters) {
            setFilters(currentComponent.nodedata.filters);
        }

        const parentsNodes = Object.values(props.currentComponent.ports['in'].links).map(link => link.sourcePort.parent);
        if (parentsNodes) {
            parentsNodes.forEach(parent => {
                if (parent.outputFields) {
                    props.currentComponent.inputFields = parent.outputFields
                    props.currentComponent.outputFields = parent.outputFields
                }
            })
        }

    }, []);


    const handleDeleteSelectedColumn = (event, id) => {
        setFilters(filters => filters.filter((column, index) => index !== id))
    };

    const handleClick = () => {
        setFilters((oldRows) => [...oldRows, {filterName: '', criteria: '' }]);
    };

    return (<Box spacing={5}
        sx={{
            height: 500,
            width: '50%',
            '& .actions': {
                color: 'text.secondary',
            },
            '& .textPrimary': {
                color: 'text.primary',
            },
        }}
    >
        <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
            <TextField
                autoComplete='off'
                label="Node Name"
                value={nodeName}
                onChange={(event) => {
                    setNodeName(event.target.value)
                    currentComponent.options.name = event.target.value;
                }}
            />
        </FormControl>

        <br />

        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Criteria
        </Button>
        {filters.map((filter, index) => (
            <Stack direction="row" spacing={2} width={"50%"}>
                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        disableAutoFocus='true'
                        autoComplete='off'
                        placeholder='Filter Name'
                        label='Filter Name'
                        value={filter.filterName}
                        onChange={(event) => {
                            const newColumns = [...filters];
                            const column = newColumns[index]
                            column.filterName = event.target.value
                            setFilters(newColumns)
                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.filters = newColumns
                            } else {
                                currentComponent.nodedata = { filters: newColumns }
                            }

                        }}
                    />
                    <br />
                </FormControl>

                <FormControl style={{ minWidth: 300, maxWidth: 300 }}>
                    <TextField
                        disableAutoFocus='true'
                        autoComplete='off'
                        placeholder='Criteria'
                        label='Criteria'
                        value={filter.criteria}
                        onChange={(event) => {
                            const newColumns = [...filters];
                            const column = newColumns[index]
                            column.criteria = event.target.value
                            setFilters(newColumns)

                            if (currentComponent.nodedata) {
                                currentComponent.nodedata.filters = newColumns
                            } else {
                                currentComponent.nodedata = { filters: newColumns }
                            }

                        }}
                    />
                </FormControl>

                <IconButton aria-label="delete"
                    sx={{
                        minWidth: 20,
                        maxWidth: 20,
                    }} onClick={(event) => handleDeleteSelectedColumn(event, index)}>
                    <ClearIcon />
                </IconButton>

            </Stack>
        ))
        }
    </Box>

    )

}