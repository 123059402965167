import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, List, ListItem, ListItemText, ListItemButton, DialogContent, DialogActions, Checkbox, CircularProgress} from '@mui/material';
import { rolesService } from '../../ts/modules/roles/service';
import { RoleComposite } from '../../ts/modules/roles/interface';
import { Participant } from '../../ts/modules/participants/interface';
import './RoleManagement.css';


interface ParticipantDialogProps {
	isLoading: boolean;
	open: boolean;
	onClose: () => void;
	role?: RoleComposite;
	participants: Participant[];
	onUpdateRole: (updatedRole: RoleComposite) => void;
}
  
  
  
const ParticipantDialog: React.FC<ParticipantDialogProps> = ({ open, onClose, role, participants, onUpdateRole}) => {
	const [availableParticipants, setAvailableParticipants] = useState<Participant[]>(participants);
	const [roleParticipants, setRoleParticipants] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState(false);
  

	useEffect(() => {
		setAvailableParticipants(participants); // Always refresh available participants when they change

		if (role) {
			setRoleParticipants(role.participantKeys); // Update role participants based on the current role
		} else {
			setRoleParticipants([]); // Reset if no role is selected
		}
	}, [role, participants]);
  
	const handleToggleParticipant = (participantId: number) => {
		setRoleParticipants((currentParticipants) => {
			if (currentParticipants.includes(participantId)) {
				return currentParticipants.filter(id => id !== participantId);
			} else {
					return [...currentParticipants, participantId];
			}
		});
	};
  

	const handleSelectAllParticipants = () => {
		setRoleParticipants(availableParticipants.map(p => p.systemClientKey));
	};

	const handleDeselectAllParticipants = () => {
		setRoleParticipants([]);
	};
  
	const handleSaveParticipants = async () => {
		if (!role) return;
    
		setIsLoading(true);
		try {
			const updatedParticipants = availableParticipants.filter(p => roleParticipants.includes(p.systemClientKey));
			const updatedRole = await rolesService.updateParticipantRole(role.roleKey, updatedParticipants.map(rp => rp.systemClientKey));

			console.log("handleSaveParticipants, updatedRole", updatedRole);

			onUpdateRole(updatedRole); // Invoke the callback with the updated role
			onClose(); // Close the dialog
		} catch (error) {
			console.error("Failed to save participants", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>Assign Participants for {role?.roleDescription}</DialogTitle>
			<DialogContent>
				<Button onClick={handleSelectAllParticipants}>Select All</Button>
				<Button onClick={handleDeselectAllParticipants}>DeSelect All</Button>
				{isLoading ? (
					<CircularProgress />
				) : (
					<List>
						{availableParticipants
							.sort((a, b) => a.participantName > b.participantName ? 1 : -1)
							.map((participant) => (
								<ListItem key={participant.systemClientKey} disablePadding>
									<ListItemButton onClick={() => handleToggleParticipant(participant.systemClientKey)} dense>
										<Checkbox
											edge="start"
											checked={roleParticipants.includes(participant.systemClientKey)}
											tabIndex={-1}
											key={`check-${participant.systemClientKey}`}
											disableRipple
											inputProps={{ 'aria-labelledby': `checkbox-list-label-${participant.systemClientKey}` }}
										/>
										<ListItemText id={`checkbox-list-label-${participant.systemClientKey}`} primary={participant.participantName} />
									</ListItemButton>
								</ListItem>
							))}
					</List>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} style={{ marginRight: '10px' }}>Close</Button>
				<Button onClick={handleSaveParticipants} disabled={isLoading} variant="contained" color="primary">
					{isLoading ? 'Saving...' : 'Save'}
				</Button>
			</DialogActions>
		</Dialog>
	);
 };
  
  


export { ParticipantDialog}