import { fileHttp, jsonHttp } from "./http-common";


export class AzureTableDataService {
    getByFile(file) {
        return fileHttp.get(`/AzureTableHelper/${file.fileConfigKey}`);  
    }

    getOutPutDataFromAzureTable(data) {
        return jsonHttp.post(`/AzureTableHelper/GetOutPutDataFromAzureTable`,data);
    }

    exportOutPutDataFromAzureTable(data) {
        return jsonHttp.post(`/AzureTableHelper/ExportOutPutDataFromAzureTable`, data);
    }
    
}


export default new AzureTableDataService();
