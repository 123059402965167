import React from "react";
import { useContext } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import { createGlobalStyle } from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const GlobalStyle = createGlobalStyle`
.right-container{
    background-color:#FFF;
    width:auto;
    padding-bottom:50px;

    .nav-link{
        padding: 20px 0px 0 30px;
    }

    .header-title-box{
        .MuiListItemText-root{
             margin-top:0px;
             margin-left:20px;
        }
    }

    .header-title{
        span{
            color: var(--gray-1200, #393F48);
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
            letter-spacing: -0.288px;
            margin-top:-5px;
        }
        
        width: 335px;
        flex-shrink: 0;
    }

  .rc-header {    
    background-color: #FFF;
    padding:10px;
    padding-bottom:0px;
    margin-bottom:0px;
  }

  .rc-body{
      background-color:#FFF;
      margin:0px 10px 0px 10px;
      padding:15px;
      border-radius: 10px;
  }
}
`;

const RightContainer = (prop) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    return (
        <>
            <GlobalStyle/>
            <Box className="right-container">
                <Box className="nav-link">
                    Home {'>'}  <strong>{prop.title}</strong>
                </Box>
                <Box mb="30px" className="rc-header">
                    {/*<Typography*/}
                    {/*    variant="h2"*/}
                    {/*    fontWeight="bold"*/}
                    {/*    sx={{ color:"#5b5c5e"} }*/}
                    {/*>*/}
                    {/*    {prop.title}*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="h5">*/}
                    {/*    {prop.subtitle}*/}
                    {/*</Typography>*/}

                    <List sx={{ width: '100%' }}>
                        <ListItem className="header-title-box">
                            <ListItemAvatar sx={{ minWidth: "45px" }}>
                                    <img
                                        alt="home"
                                        width="48px"
                                        height="48px"
                                        src={"../../assets/home.svg"}/>
                            </ListItemAvatar>
                            <ListItemText className="header-title" primary={prop.title} secondary={prop.subtitle} />
                        </ListItem>
                    </List>

                </Box>
                <Box className="rc-body">
                    {prop.children}
                </Box>
            </Box>
        </>
       
    );
};

export default RightContainer;
