import React, { useState } from 'react'
import { Box, Button, TextField, Stack, Divider, Autocomplete } from '@mui/material'
import { Formik, setFieldValue } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import ClientDataService from "../../service/ClientDataService"
import DataLakeService from "../../service/DataLakeService"
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import RightContainer from "../global/RightContainer";
import FileTypeDataService from "../../service/FileTypeDataService"

const DataIngestion = () => {

    const [warning, setWarnig] = useState(false);
    const [warningMessage, setWarnigMessage] = useState('');

    const handleClose = () => {
        setWarnig(false);
    };

    const [uploading, setUploading] = useState(false);

    const [affliate_open, affliateSetOpen] = useState(false);
    const [affiliate_Options, setAffiliateOptions] = useState([]);

    const [client_open, clientSetOpen] = useState(false);
    const [client_Options, setClientOptions] = useState([]);

    const [network_open, networkSetOpen] = React.useState(false);
    const [networkOptions, setNetworkOptions] = React.useState([]);
    const [filteredNetworkOptions, setFilteredNetworkOptions] = React.useState([]);

    const [fileTypes, setFileTypes] = React.useState([]);


    const [messages, setMessages] = useState([]);

    React.useEffect(() => {

	    const userKey = JSON.parse(localStorage.getItem('userKey') || '0');

        ClientDataService.getAffiliates(userKey).then((res) => {
            return res.data;
        }).then(affliates => {
            affliateSetOpen(true)
            setAffiliateOptions(affliates);
        }).catch(e => {
            console.log(e)
        });

	    ClientDataService.getUserNetworks(userKey).then((res) => {
            setNetworkOptions(res.data);
            networkSetOpen(true)
        }).catch(e => {
            console.log(e)
        });

        ClientDataService.getClients(userKey).then((res) => {
            return res.data;
        }).then(clients => {
            clientSetOpen(true);
            setClientOptions(clients);
        }).catch(e => {
            console.log(e)
        });


        //FileTypeDataService.getAll().then((res) => {
        //    setFileTypes(res.data);
        //}).catch(e => {
        //    console.log(e)
        //});



    }, []);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    function validateFileType(filename) {
        var parts = filename.split('.');
        var ext = parts[parts.length - 1];
        switch (ext.toLowerCase()) {
            case 'csv':
            case 'xls':
            case 'xlsx':
            case 'pdf':
            case 'txt':
            return true;
        }
        return false;
    }

  
    const handleFormSubmit = (values) => {

        if (!values.fileSelected || !((!values.client && !values.network) || !values.affiliate )) {
            setWarnig(true);
            setWarnigMessage("please select file, and client, network, and file type")
            return;
        }

        const formData = new FormData();

        var file = values.fileSelected;

        if (validateFileType(file.name)) {

            //formData.append("fileType", values.fileType);

            if (values.client) {
                formData.append("client",values.client.clientKey);
            }
            if (values.affiliate) {
                formData.append("affiliate", values.affiliate.clientKey);
            }
            if (values.network) {
                formData.append("network", values.network.networkkey);
            }

            formData.append(file.name, file);
        } else {
            setWarnig(true);
            setWarnigMessage("Only csv, xls, pdf, txt files are accepted format !!!")
            return;
        }

        try {
            setUploading(true);
            DataLakeService.uploadFile(formData)
                .then((res) => {
                    setUploading(false);
                    setMessages([...messages, "Upload File Successfully"]);
                }).catch((err) => {
                    setMessages([...messages, "Upload Failed: " + err]);
                    console.log(err);
                    setUploading(false);
                })
        } catch (err) {
            console.log(err);
            setUploading(false);
        }
    };

    const initialValues = {
        client: null,
        affiliate: null,
        network:null,
        participantType: null,
        fileSelected: null,
        fileType: null
    };

    const checkoutSchema = yup.object().shape({
        fileSelected: yup.string().required('Required').min(10, "please choose file type"),
        fileType: yup.string().required('Required').min(2, "please file type")
    });

    const Form = (props) => {
        return (
                <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
                    {({ handleSubmit, setFieldValue, handleChange, values }) => (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}>

                            <Stack>

                                <Autocomplete
                                    disablePortal
                                    name="participantType"
                                    id="participantType"
                                    value={values.participantType}
    
                                    onChange={(event, value) => {
                                        setFieldValue("participantType", value)
                                    }}
                                    options={["Client", "Affliate"]}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Participant Type" />}
                                    size="small"
                                />

                                <br />
                                {values.participantType == "Client" && <div>
                                    <Autocomplete
                                        size="small"
                                        name="client"
                                        disablePortal
                                        id="client"
                                        value={values.client}
                                        getOptionLabel={(option) => option.qbclientkey + " - " +option.clientName}
                                        options={client_Options}
                                        onChange={(event, value) => {
                                            setFieldValue("client",value !== null ? value : initialValues.client)
                                            setFieldValue("affiliate", null)
                                            //setFilteredNetworkOptions(networkOptions.filter(x => x.systemclientkey == value.clientKey))
                                        }}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Client" />}
                                    />
                                    <br />
                                </div>
                              
                               }
                                   
                                  

                                {values.client && values.participantType == "Client" && <div>
                                    <Autocomplete
                                        size="small"
                                        name="network"
                                        disablePortal
                                        id="network"
                                        value={values.network}
                                        getOptionLabel={(option) => option.qbnetworkkey + " - " + option.networkdesc}
                                        options={networkOptions.filter(x => x.systemclientkey == values.client.clientKey)}
                                        onChange={(event, value) => {
                                            setFieldValue("network", value !== null ? value : initialValues.network)
                                        }}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Network" />}
                                    />
                                    <br />
                                </div>}
                             
                            
                               
                                    
                                {values.participantType == "Affliate" && <div>
                                    <Autocomplete
                                        size="small"
                                        name="affiliate"
                                        disablePortal
                                        id="affliate"
                                        value={values.affiliate}
                                        getOptionLabel={(option) => option.qbclientkey + " - " + option.clientName}
                                        options={affiliate_Options}
                                        onChange={(event, value) => {
                                            setFieldValue("affiliate", value !== null ? value : initialValues.affliate)
                                            setFieldValue("client", null)
                                        }}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Affliate" />}
                                    />
                                            <br />
                                        </div>
                                }

                                {/*<Autocomplete*/}
                                {/*    disablePortal*/}
                                {/*    name="fileType"*/}
                                {/*    id="fileType"*/}
                                {/*    value={values.fileType}*/}
                                {/*    getOptionLabel={(option) => option.filedesc}*/}
                                {/*    onChange={(event, value) => { setFieldValue("fileType", value) }}*/}
                                {/*    options={fileTypes}*/}
                                {/*    sx={{ width: 300 }}*/}
                                {/*    renderInput={(params) => <TextField {...params} label="Type" />}*/}
                                {/*    size="small"*/}
                                {/*/>*/}
                                {/*<br />*/}


                                <Typography
                                    variant="h3"
                                    sx={{
                                        marginBottom:"10px"
                                    }}
                                >Upload Files Locally</Typography>

                                    <input id="fileSelected" type="file" name="file"
                                        accept=".csv, .txt, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={(event) => { setFieldValue("fileSelected", event.target.files[0]) }} />

                                {messages && <div>
                                    {messages.map((message) => (
                                        <li>{message}</li>
                                    ))} </div>
                                    }
                            </Stack>

                  
                        </Box>
                            <Box justifyContent="end" mt="20px">
                            <LoadingButton type="submit" loading={uploading}  color="secondary" variant="contained">
                                <span>Upload</span> 
                            </LoadingButton>
                            </Box>
                            <br />
                        </form>
                    )}
                </Formik>
        )
    }

    return (


        <Stack>
            <RightContainer title="Manual Data Ingestion" >
                <div>
                    <Dialog
                        open={warning}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Invalid input !!!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {warningMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} autoFocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Box m="20px">
                    <Form />
                    <Divider />

                   
                </Box>
            </RightContainer>
        
            </Stack>
        );
}

export default DataIngestion