import { jsonHttp } from "./http-common";

export class ProcessFlowDataService {
    getAll() {
        return jsonHttp.get("/ProcessFlow");
    }

    get(id) {
        return jsonHttp.get(`/ProcessFlow/${id}`);
    }


    getProcessFlowByClient(clientKey ) {
        return jsonHttp.get(`/ProcessFlow/client/${clientKey}`);
    }

    getProcessFlowByAuditKey(auditKey) {
        return jsonHttp.get(`/ProcessFlow/audit/${auditKey}`);
    }



    create(data) {
        return jsonHttp.post("/ProcessFlow", data).catch(error => {
            let e = error;
            if (error.response) {
                if (error.response.data) {
                    e = error.response.data;           
                }
            } else if (error.message) {
                e = error.message;
            } else {
                e = "Unknown error occured";
            }
            throw new Error(e);
        })
    }

    update(id, data) {
        return jsonHttp.put(`/ProcessFlow/${id}`, data);
    }

    delete(id) {
        return jsonHttp.delete(`/ProcessFlow/${id}`);
    }

}
export default new ProcessFlowDataService();